const WORDS = [
  ["the", "a", 22038615, "Word"],
  ["be", "v", 12545825, "Word"],
  ["and", "c", 10741073, "Word"],
  ["of", "i", 10343885, "Word"],
  ["in", "i", 6996437, "Word"],
  ["have", "v", 4303955, "Word"],
  ["to", "i", 3856916, "Word"],
  ["it", "p", 3872477, "Word"],
  ["that", "c", 3430996, "Word"],
  ["for", "i", 3281454, "Word"],
  ["you", "p", 3081151, "Word"],
  ["he", "p", 2909254, "Word"],
  ["with", "i", 2683014, "Word"],
  ["on", "i", 2485306, "Word"],
  ["do", "v", 2573587, "Word"],
  ["say", "v", 1915138, "Word"],
  ["this", "d", 1885366, "Word"],
  ["they", "p", 1865580, "Word"],
  ["at", "i", 1767638, "Word"],
  ["but", "c", 1776767, "Word"],
  ["we", "p", 1820935, "Word"],
  ["his", "a", 1801708, "Word"],
  ["from", "i", 1635914, "Word"],
  ["that", "d", 1712406, "Word"],
  ["by", "i", 1490548, "Word"],
  ["she", "p", 1484869, "Word"],
  ["or", "c", 1379320, "Word"],
  ["as", "c", 1296879, "Word"],
  ["what", "d", 1181023, "Word"],
  ["go", "v", 1151045, "Word"],
  ["their", "a", 1083029, "Word"],
  ["can", "v", 1022775, "Word"],
  ["who", "p", 1018283, "Word"],
  ["get", "v", 992596, "Word"],
  ["if", "c", 933542, "Word"],
  ["would", "v", 925515, "Word"],
  ["her", "a", 969591, "Word"],
  ["all", "d", 892102, "Word"],
  ["my", "a", 919821, "Word"],
  ["make", "v", 857168, "Word"],
  ["about", "i", 874406, "Word"],
  ["know", "v", 892535, "Word"],
  ["will", "v", 824568, "Word"],
  ["as", "i", 829018, "Word"],
  ["up", "r", 795534, "Word"],
  ["one", "m", 768232, "Word"],
  ["time", "n", 764657, "Word"],
  ["year", "n", 769254, "Word"],
  ["so", "r", 756550, "Word"],
  ["think", "v", 772787, "Word"],
  ["when", "c", 678626, "Word"],
  ["which", "d", 685982, "Word"],
  ["them", "p", 677870, "Word"],
  ["some", "d", 674193, "Word"],
  ["me", "p", 709623, "Word"],
  ["people", "n", 691468, "Word"],
  ["take", "v", 670745, "Word"],
  ["out", "r", 678603, "Word"],
  ["into", "i", 668172, "Word"],
  ["just", "r", 677711, "Word"],
  ["see", "v", 663645, "Word"],
  ["him", "p", 677707, "Word"],
  ["your", "a", 659622, "Word"],
  ["come", "v", 628254, "Word"],
  ["could", "v", 617932, "Word"],
  ["now", "r", 605997, "Word"],
  ["than", "c", 579757, "Word"],
  ["like", "i", 568850, "Word"],
  ["other", "j", 547799, "Word"],
  ["how", "r", 538893, "Word"],
  ["then", "r", 543977, "Word"],
  ["its", "a", 539719, "Word"],
  ["our", "a", 525107, "Word"],
  ["two", "m", 511027, "Word"],
  ["more", "r", 517536, "Word"],
  ["these", "d", 513864, "Word"],
  ["want", "v", 514972, "Word"],
  ["way", "n", 470401, "Word"],
  ["look", "v", 491707, "Word"],
  ["first", "m", 463566, "Word"],
  ["also", "r", 464606, "Word"],
  ["new", "j", 435993, "Word"],
  ["because", "c", 438539, "Word"],
  ["day", "n", 432773, "Word"],
  ["more", "d", 420170, "Word"],
  ["use", "v", 420781, "Word"],
  ["no", "a", 402222, "Word"],
  ["man", "n", 409760, "Word"],
  ["find", "v", 395203, "Word"],
  ["here", "r", 412315, "Word"],
  ["thing", "n", 400724, "Word"],
  ["give", "v", 384503, "Word"],
  ["many", "d", 385348, "Word"],
  ["well", "r", 411776, "Word"],
  ["only", "r", 379574, "Word"],
  ["those", "d", 378007, "Word"],
  ["tell", "v", 388155, "Word"],
  ["one", "p", 369553, "Word"],
  ["very", "r", 391821, "Word"],
  ["her", "p", 397950, "Word"],
  ["even", "r", 361067, "Word"],
  ["back", "r", 367844, "Word"],
  ["any", "d", 348100, "Word"],
  ["good", "j", 353973, "Word"],
  ["woman", "n", 341422, "Word"],
  ["through", "i", 340921, "Word"],
  ["us", "p", 351088, "Word"],
  ["life", "n", 333085, "Word"],
  ["child", "n", 333849, "Word"],
  ["there", "r", 333433, "Word"],
  ["work", "v", 318210, "Word"],
  ["down", "r", 329409, "Word"],
  ["may", "v", 324569, "Word"],
  ["after", "i", 311902, "Word"],
  ["should", "v", 310265, "Word"],
  ["call", "v", 308050, "Word"],
  ["world", "n", 303506, "Word"],
  ["over", "i", 300349, "Word"],
  ["school", "n", 304183, "Word"],
  ["still", "r", 296953, "Word"],
  ["try", "v", 294023, "Word"],
  ["in", "r", 285035, "Word"],
  ["as", "r", 281483, "Word"],
  ["last", "m", 289843, "Word"],
  ["ask", "v", 284632, "Word"],
  ["need", "v", 276744, "Word"],
  ["too", "r", 280396, "Word"],
  ["feel", "v", 275214, "Word"],
  ["three", "m", 266744, "Word"],
  ["when", "r", 268219, "Word"],
  ["state", "n", 272193, "Word"],
  ["never", "r", 262584, "Word"],
  ["become", "v", 259102, "Word"],
  ["between", "i", 264158, "Word"],
  ["high", "j", 255936, "Word"],
  ["really", "r", 263414, "Word"],
  ["something", "p", 254910, "Word"],
  ["most", "r", 246360, "Word"],
  ["another", "d", 240646, "Word"],
  ["much", "d", 244507, "Word"],
  ["family", "n", 243267, "Word"],
  ["own", "d", 240452, "Word"],
  ["out", "i", 242443, "Word"],
  ["leave", "v", 240482, "Word"],
  ["put", "v", 237480, "Word"],
  ["old", "j", 236577, "Word"],
  ["while", "c", 234555, "Word"],
  ["mean", "v", 242198, "Word"],
  ["on", "r", 236980, "Word"],
  ["keep", "v", 231760, "Word"],
  ["student", "n", 255047, "Word"],
  ["why", "r", 235442, "Word"],
  ["let", "v", 240300, "Word"],
  ["great", "j", 225005, "Word"],
  ["same", "d", 222836, "Word"],
  ["big", "j", 227169, "Word"],
  ["group", "n", 229435, "Word"],
  ["begin", "v", 218617, "Word"],
  ["seem", "v", 219627, "Word"],
  ["country", "n", 223138, "Word"],
  ["help", "v", 216082, "Word"],
  ["talk", "v", 229429, "Word"],
  ["where", "c", 213744, "Word"],
  ["turn", "v", 221392, "Word"],
  ["problem", "n", 217728, "Word"],
  ["every", "a", 212739, "Word"],
  ["start", "v", 213952, "Word"],
  ["hand", "n", 225247, "Word"],
  ["might", "v", 209059, "Word"],
  ["american", "j", 214968, "Word"],
  ["show", "v", 208037, "Word"],
  ["part", "n", 207861, "Word"],
  ["about", "r", 208550, "Word"],
  ["against", "i", 204379, "Word"],
  ["place", "n", 202427, "Word"],
  ["over", "r", 208260, "Word"],
  ["such", "d", 207065, "Word"],
  ["again", "r", 206895, "Word"],
  ["few", "d", 197266, "Word"],
  ["case", "n", 200773, "Word"],
  ["most", "d", 197086, "Word"],
  ["week", "n", 199268, "Word"],
  ["company", "n", 203345, "Word"],
  ["where", "r", 194427, "Word"],
  ["system", "n", 200175, "Word"],
  ["each", "d", 196522, "Word"],
  ["right", "r", 205250, "Word"],
  ["program", "n", 195985, "Word"],
  ["hear", "v", 196070, "Word"],
  ["so", "c", 191893, "Word"],
  ["question", "n", 192070, "Word"],
  ["during", "i", 190729, "Word"],
  ["work", "n", 187533, "Word"],
  ["play", "v", 188328, "Word"],
  ["government", "n", 191314, "Word"],
  ["run", "v", 187325, "Word"],
  ["small", "j", 185463, "Word"],
  ["number", "n", 186005, "Word"],
  ["off", "r", 183854, "Word"],
  ["always", "r", 179474, "Word"],
  ["move", "v", 179388, "Word"],
  ["like", "v", 182341, "Word"],
  ["night", "n", 184511, "Word"],
  ["live", "v", 176144, "Word"],
  ["mr", "n", 188555, "Word"],
  ["point", "n", 177481, "Word"],
  ["believe", "v", 178397, "Word"],
  ["hold", "v", 177368, "Word"],
  ["today", "r", 183724, "Word"],
  ["bring", "v", 174366, "Word"],
  ["happen", "v", 182714, "Word"],
  ["next", "m", 176306, "Word"],
  ["without", "i", 172448, "Word"],
  ["before", "i", 172769, "Word"],
  ["large", "j", 175611, "Word"],
  ["all", "r", 177317, "Word"],
  ["million", "m", 176895, "Word"],
  ["must", "v", 171043, "Word"],
  ["home", "n", 170527, "Word"],
  ["under", "i", 164766, "Word"],
  ["water", "n", 167666, "Word"],
  ["room", "n", 172472, "Word"],
  ["write", "v", 161824, "Word"],
  ["mother", "n", 169407, "Word"],
  ["area", "n", 165812, "Word"],
  ["national", "j", 166359, "Word"],
  ["money", "n", 164794, "Word"],
  ["story", "n", 163582, "Word"],
  ["young", "j", 160011, "Word"],
  ["fact", "n", 164401, "Word"],
  ["month", "n", 162685, "Word"],
  ["different", "j", 162411, "Word"],
  ["lot", "n", 169570, "Word"],
  ["right", "n", 163259, "Word"],
  ["study", "n", 174069, "Word"],
  ["book", "n", 154013, "Word"],
  ["eye", "n", 169150, "Word"],
  ["job", "n", 154743, "Word"],
  ["word", "n", 152891, "Word"],
  ["though", "c", 152182, "Word"],
  ["business", "n", 154468, "Word"],
  ["issue", "n", 156417, "Word"],
  ["side", "n", 152559, "Word"],
  ["kind", "n", 155032, "Word"],
  ["four", "m", 150646, "Word"],
  ["head", "n", 160131, "Word"],
  ["far", "r", 148621, "Word"],
  ["black", "j", 150718, "Word"],
  ["long", "j", 149050, "Word"],
  ["both", "d", 146338, "Word"],
  ["little", "j", 149658, "Word"],
  ["house", "n", 149251, "Word"],
  ["yes", "u", 157364, "Word"],
  ["after", "c", 142289, "Word"],
  ["since", "c", 141264, "Word"],
  ["long", "r", 142007, "Word"],
  ["provide", "v", 150879, "Word"],
  ["service", "n", 146122, "Word"],
  ["around", "i", 143766, "Word"],
  ["friend", "n", 142697, "Word"],
  ["important", "j", 144194, "Word"],
  ["father", "n", 145051, "Word"],
  ["sit", "v", 147185, "Word"],
  ["away", "r", 144713, "Word"],
  ["until", "c", 140819, "Word"],
  ["power", "n", 141357, "Word"],
  ["hour", "n", 138955, "Word"],
  ["game", "n", 146311, "Word"],
  ["often", "r", 140731, "Word"],
  ["yet", "r", 135484, "Word"],
  ["line", "n", 135986, "Word"],
  ["political", "j", 144437, "Word"],
  ["end", "n", 134104, "Word"],
  ["among", "i", 138192, "Word"],
  ["ever", "r", 135774, "Word"],
  ["stand", "v", 140937, "Word"],
  ["bad", "j", 134910, "Word"],
  ["lose", "v", 134102, "Word"],
  ["however", "r", 142282, "Word"],
  ["member", "n", 134731, "Word"],
  ["pay", "v", 133133, "Word"],
  ["law", "n", 133706, "Word"],
  ["meet", "v", 128737, "Word"],
  ["car", "n", 133571, "Word"],
  ["city", "n", 132684, "Word"],
  ["almost", "r", 127907, "Word"],
  ["include", "v", 133563, "Word"],
  ["continue", "v", 126029, "Word"],
  ["set", "v", 127369, "Word"],
  ["later", "r", 126495, "Word"],
  ["community", "n", 133057, "Word"],
  ["much", "r", 126029, "Word"],
  ["name", "n", 127139, "Word"],
  ["five", "m", 125571, "Word"],
  ["once", "r", 126203, "Word"],
  ["white", "j", 126760, "Word"],
  ["least", "r", 123961, "Word"],
  ["president", "n", 134203, "Word"],
  ["learn", "v", 124346, "Word"],
  ["real", "j", 124187, "Word"],
  ["change", "v", 123183, "Word"],
  ["team", "n", 131489, "Word"],
  ["minute", "n", 126660, "Word"],
  ["best", "j", 124850, "Word"],
  ["several", "d", 124039, "Word"],
  ["idea", "n", 122140, "Word"],
  ["kid", "n", 126428, "Word"],
  ["body", "n", 125165, "Word"],
  ["information", "n", 127331, "Word"],
  ["nothing", "p", 126717, "Word"],
  ["ago", "r", 125252, "Word"],
  ["right", "j", 126278, "Word"],
  ["lead", "v", 122691, "Word"],
  ["social", "j", 132899, "Word"],
  ["understand", "v", 121354, "Word"],
  ["whether", "c", 121921, "Word"],
  ["back", "n", 125006, "Word"],
  ["watch", "v", 124976, "Word"],
  ["together", "r", 119186, "Word"],
  ["follow", "v", 119425, "Word"],
  ["around", "r", 122789, "Word"],
  ["parent", "n", 119610, "Word"],
  ["only", "j", 117700, "Word"],
  ["stop", "v", 121481, "Word"],
  ["face", "n", 127291, "Word"],
  ["anything", "p", 120292, "Word"],
  ["create", "v", 119419, "Word"],
  ["public", "j", 119825, "Word"],
  ["already", "r", 115220, "Word"],
  ["speak", "v", 117358, "Word"],
  ["others", "n", 115771, "Word"],
  ["read", "v", 114094, "Word"],
  ["level", "n", 121704, "Word"],
  ["allow", "v", 114892, "Word"],
  ["add", "v", 117842, "Word"],
  ["office", "n", 114791, "Word"],
  ["spend", "v", 114569, "Word"],
  ["door", "n", 124993, "Word"],
  ["health", "n", 117762, "Word"],
  ["person", "n", 113650, "Word"],
  ["art", "n", 117851, "Word"],
  ["sure", "j", 116186, "Word"],
  ["such", "i", 119125, "Word"],
  ["war", "n", 117804, "Word"],
  ["history", "n", 114904, "Word"],
  ["party", "n", 112962, "Word"],
  ["within", "i", 114599, "Word"],
  ["grow", "v", 110020, "Word"],
  ["result", "n", 116277, "Word"],
  ["open", "v", 111857, "Word"],
  ["change", "n", 112426, "Word"],
  ["morning", "n", 114002, "Word"],
  ["walk", "v", 113787, "Word"],
  ["reason", "n", 106863, "Word"],
  ["low", "j", 108990, "Word"],
  ["win", "v", 111478, "Word"],
  ["research", "n", 114802, "Word"],
  ["girl", "n", 110409, "Word"],
  ["guy", "n", 110409, "Word"],
  ["early", "j", 108171, "Word"],
  ["food", "n", 107728, "Word"],
  ["before", "c", 107448, "Word"],
  ["moment", "n", 109720, "Word"],
  ["himself", "p", 109288, "Word"],
  ["air", "n", 105932, "Word"],
  ["teacher", "n", 116100, "Word"],
  ["force", "n", 108005, "Word"],
  ["offer", "v", 106473, "Word"],
  ["enough", "r", 105880, "Word"],
  ["both", "r", 106361, "Word"],
  ["education", "n", 113731, "Word"],
  ["across", "i", 105559, "Word"],
  ["although", "c", 107925, "Word"],
  ["remember", "v", 106879, "Word"],
  ["foot", "n", 107285, "Word"],
  ["second", "m", 103621, "Word"],
  ["boy", "n", 107447, "Word"],
  ["maybe", "r", 108421, "Word"],
  ["toward", "i", 105984, "Word"],
  ["able", "j", 103171, "Word"],
  ["age", "n", 103402, "Word"],
  ["off", "i", 104122, "Word"],
  ["policy", "n", 107601, "Word"],
  ["everything", "p", 103591, "Word"],
  ["love", "v", 103681, "Word"],
  ["process", "n", 107341, "Word"],
  ["music", "n", 102657, "Word"],
  ["including", "i", 103650, "Word"],
  ["consider", "v", 101987, "Word"],
  ["appear", "v", 100671, "Word"],
  ["actually", "r", 105155, "Word"],
  ["buy", "v", 101105, "Word"],
  ["probably", "r", 99754, "Word"],
  ["human", "j", 101224, "Word"],
  ["wait", "v", 102463, "Word"],
  ["serve", "v", 99660, "Word"],
  ["market", "n", 100435, "Word"],
  ["die", "v", 98376, "Word"],
  ["send", "v", 96613, "Word"],
  ["expect", "v", 95566, "Word"],
  ["home", "r", 97937, "Word"],
  ["sense", "n", 95896, "Word"],
  ["build", "v", 96651, "Word"],
  ["stay", "v", 96933, "Word"],
  ["fall", "v", 96908, "Word"],
  ["oh", "u", 103613, "Word"],
  ["nation", "n", 97212, "Word"],
  ["plan", "n", 95824, "Word"],
  ["cut", "v", 96012, "Word"],
  ["college", "n", 97038, "Word"],
  ["interest", "n", 96620, "Word"],
  ["death", "n", 93222, "Word"],
  ["course", "r", 96224, "Word"],
  ["someone", "p", 95608, "Word"],
  ["experience", "n", 98106, "Word"],
  ["behind", "i", 95047, "Word"],
  ["reach", "v", 92375, "Word"],
  ["local", "j", 92970, "Word"],
  ["kill", "v", 92660, "Word"],
  ["six", "m", 90571, "Word"],
  ["remain", "v", 91319, "Word"],
  ["effect", "n", 95216, "Word"],
  ["use", "n", 96564, "Word"],
  ["yeah", "u", 103389, "Word"],
  ["suggest", "v", 92643, "Word"],
  ["class", "n", 91323, "Word"],
  ["control", "n", 90301, "Word"],
  ["raise", "v", 87036, "Word"],
  ["care", "n", 88862, "Word"],
  ["perhaps", "r", 87060, "Word"],
  ["little", "r", 88697, "Word"],
  ["late", "j", 86421, "Word"],
  ["hard", "j", 86817, "Word"],
  ["field", "n", 87243, "Word"],
  ["else", "r", 87876, "Word"],
  ["pass", "v", 86184, "Word"],
  ["former", "d", 88930, "Word"],
  ["sell", "v", 87865, "Word"],
  ["major", "j", 87487, "Word"],
  ["sometimes", "r", 84845, "Word"],
  ["require", "v", 89280, "Word"],
  ["along", "i", 84926, "Word"],
  ["development", "n", 91995, "Word"],
  ["themselves", "p", 85256, "Word"],
  ["report", "v", 88138, "Word"],
  ["role", "n", 88666, "Word"],
  ["better", "j", 83895, "Word"],
  ["economic", "j", 90392, "Word"],
  ["effort", "n", 86473, "Word"],
  ["up", "i", 85759, "Word"],
  ["decide", "v", 84035, "Word"],
  ["rate", "n", 87224, "Word"],
  ["strong", "j", 83677, "Word"],
  ["possible", "j", 85084, "Word"],
  ["heart", "n", 84536, "Word"],
  ["drug", "n", 86231, "Word"],
  ["show", "n", 86828, "Word"],
  ["leader", "n", 85438, "Word"],
  ["light", "n", 87427, "Word"],
  ["voice", "n", 89379, "Word"],
  ["wife", "n", 83601, "Word"],
  ["whole", "j", 83756, "Word"],
  ["police", "n", 85880, "Word"],
  ["mind", "n", 82808, "Word"],
  ["finally", "r", 81951, "Word"],
  ["pull", "v", 87243, "Word"],
  ["return", "v", 81812, "Word"],
  ["free", "j", 82090, "Word"],
  ["military", "j", 85152, "Word"],
  ["price", "n", 84443, "Word"],
  ["report", "n", 83174, "Word"],
  ["less", "r", 82930, "Word"],
  ["according", "i", 83773, "Word"],
  ["decision", "n", 82429, "Word"],
  ["explain", "v", 80797, "Word"],
  ["son", "n", 80895, "Word"],
  ["hope", "v", 81385, "Word"],
  ["even", "c", 79087, "Word"],
  ["develop", "v", 84835, "Word"],
  ["view", "n", 81338, "Word"],
  ["relationship", "n", 84549, "Word"],
  ["carry", "v", 79513, "Word"],
  ["town", "n", 79821, "Word"],
  ["road", "n", 80987, "Word"],
  ["drive", "v", 80476, "Word"],
  ["arm", "n", 84865, "Word"],
  ["true", "j", 79299, "Word"],
  ["federal", "j", 81826, "Word"],
  ["break", "v", 79310, "Word"],
  ["better", "r", 78334, "Word"],
  ["difference", "n", 82911, "Word"],
  ["thank", "v", 88574, "Word"],
  ["receive", "v", 80250, "Word"],
  ["value", "n", 82942, "Word"],
  ["international", "j", 81610, "Word"],
  ["building", "n", 78487, "Word"],
  ["action", "n", 80009, "Word"],
  ["full", "j", 77547, "Word"],
  ["model", "n", 82973, "Word"],
  ["join", "v", 80609, "Word"],
  ["season", "n", 83743, "Word"],
  ["society", "n", 81192, "Word"],
  ["because", "i", 77116, "Word"],
  ["tax", "n", 80713, "Word"],
  ["director", "n", 79813, "Word"],
  ["early", "r", 75605, "Word"],
  ["position", "n", 77124, "Word"],
  ["player", "n", 81358, "Word"],
  ["agree", "v", 76753, "Word"],
  ["especially", "r", 76304, "Word"],
  ["record", "n", 77509, "Word"],
  ["pick", "v", 77197, "Word"],
  ["wear", "v", 77921, "Word"],
  ["paper", "n", 75383, "Word"],
  ["special", "j", 75298, "Word"],
  ["space", "n", 75395, "Word"],
  ["ground", "n", 74713, "Word"],
  ["form", "n", 78493, "Word"],
  ["support", "v", 76544, "Word"],
  ["event", "n", 75559, "Word"],
  ["official", "n", 79026, "Word"],
  ["whose", "d", 74914, "Word"],
  ["matter", "n", 73842, "Word"],
  ["everyone", "p", 74534, "Word"],
  ["center", "n", 74559, "Word"],
  ["couple", "n", 74871, "Word"],
  ["site", "n", 75932, "Word"],
  ["end", "v", 73099, "Word"],
  ["project", "n", 75446, "Word"],
  ["hit", "v", 73999, "Word"],
  ["base", "v", 75887, "Word"],
  ["activity", "n", 77980, "Word"],
  ["star", "n", 73695, "Word"],
  ["table", "n", 75228, "Word"],
  ["need", "n", 74877, "Word"],
  ["court", "n", 74112, "Word"],
  ["produce", "v", 74169, "Word"],
  ["eat", "v", 73505, "Word"],
  ["american", "n", 73063, "Word"],
  ["teach", "v", 72668, "Word"],
  ["oil", "n", 74386, "Word"],
  ["half", "d", 70930, "Word"],
  ["situation", "n", 72603, "Word"],
  ["easy", "j", 71602, "Word"],
  ["cost", "n", 73512, "Word"],
  ["industry", "n", 73055, "Word"],
  ["figure", "n", 74858, "Word"],
  ["face", "v", 69493, "Word"],
  ["street", "n", 71645, "Word"],
  ["image", "n", 72216, "Word"],
  ["itself", "p", 71093, "Word"],
  ["phone", "n", 71599, "Word"],
  ["either", "r", 69497, "Word"],
  ["data", "n", 76594, "Word"],
  ["cover", "v", 69387, "Word"],
  ["quite", "r", 69966, "Word"],
  ["picture", "n", 69229, "Word"],
  ["clear", "j", 68662, "Word"],
  ["practice", "n", 73745, "Word"],
  ["piece", "n", 68901, "Word"],
  ["land", "n", 69750, "Word"],
  ["recent", "j", 70685, "Word"],
  ["describe", "v", 71377, "Word"],
  ["product", "n", 70883, "Word"],
  ["doctor", "n", 69446, "Word"],
  ["wall", "n", 71390, "Word"],
  ["patient", "n", 72217, "Word"],
  ["worker", "n", 69962, "Word"],
  ["news", "n", 70051, "Word"],
  ["test", "n", 69870, "Word"],
  ["movie", "n", 69387, "Word"],
  ["certain", "j", 67228, "Word"],
  ["north", "n", 68046, "Word"],
  ["love", "n", 67495, "Word"],
  ["personal", "j", 67917, "Word"],
  ["open", "j", 67218, "Word"],
  ["support", "n", 69431, "Word"],
  ["simply", "r", 66712, "Word"],
  ["third", "m", 67037, "Word"],
  ["technology", "n", 69565, "Word"],
  ["catch", "v", 68214, "Word"],
  ["step", "n", 66232, "Word"],
  ["baby", "n", 67881, "Word"],
  ["computer", "n", 67399, "Word"],
  ["type", "n", 68481, "Word"],
  ["attention", "n", 65271, "Word"],
  ["draw", "v", 65198, "Word"],
  ["film", "n", 66724, "Word"],
  ["republican", "n", 71611, "Word"],
  ["tree", "n", 66630, "Word"],
  ["source", "n", 66862, "Word"],
  ["red", "j", 66217, "Word"],
  ["nearly", "r", 64860, "Word"],
  ["organization", "n", 67278, "Word"],
  ["choose", "v", 64348, "Word"],
  ["cause", "v", 64531, "Word"],
  ["hair", "n", 69564, "Word"],
  ["look", "n", 66194, "Word"],
  ["point", "v", 62980, "Word"],
  ["century", "n", 65667, "Word"],
  ["evidence", "n", 65360, "Word"],
  ["window", "n", 68303, "Word"],
  ["difficult", "j", 63947, "Word"],
  ["listen", "v", 64984, "Word"],
  ["soon", "r", 63168, "Word"],
  ["culture", "n", 67128, "Word"],
  ["billion", "m", 65243, "Word"],
  ["chance", "n", 62682, "Word"],
  ["brother", "n", 63406, "Word"],
  ["energy", "n", 64139, "Word"],
  ["period", "n", 64534, "Word"],
  ["course", "n", 64012, "Word"],
  ["summer", "n", 62503, "Word"],
  ["less", "d", 62154, "Word"],
  ["realize", "v", 61732, "Word"],
  ["hundred", "m", 61266, "Word"],
  ["available", "j", 63187, "Word"],
  ["plant", "n", 63476, "Word"],
  ["likely", "j", 63002, "Word"],
  ["opportunity", "n", 62422, "Word"],
  ["term", "n", 62962, "Word"],
  ["short", "j", 60451, "Word"],
  ["letter", "n", 60369, "Word"],
  ["condition", "n", 63489, "Word"],
  ["choice", "n", 60487, "Word"],
  ["place", "v", 60927, "Word"],
  ["single", "j", 60072, "Word"],
  ["rule", "n", 61062, "Word"],
  ["daughter", "n", 60424, "Word"],
  ["administration", "n", 62071, "Word"],
  ["south", "n", 60630, "Word"],
  ["husband", "n", 60126, "Word"],
  ["congress", "n", 62841, "Word"],
  ["floor", "n", 62458, "Word"],
  ["campaign", "n", 64172, "Word"],
  ["material", "n", 62440, "Word"],
  ["population", "n", 62999, "Word"],
  ["well", "i", 61219, "Word"],
  ["call", "n", 59543, "Word"],
  ["economy", "n", 60990, "Word"],
  ["medical", "j", 59424, "Word"],
  ["hospital", "n", 58669, "Word"],
  ["church", "n", 59466, "Word"],
  ["close", "v", 60884, "Word"],
  ["thousand", "m", 58307, "Word"],
  ["risk", "n", 60432, "Word"],
  ["current", "j", 61252, "Word"],
  ["fire", "n", 59386, "Word"],
  ["future", "n", 58020, "Word"],
  ["wrong", "j", 58988, "Word"],
  ["involve", "v", 59542, "Word"],
  ["defense", "n", 59701, "Word"],
  ["anyone", "p", 58274, "Word"],
  ["increase", "v", 60442, "Word"],
  ["security", "n", 58914, "Word"],
  ["bank", "n", 58992, "Word"],
  ["myself", "p", 59716, "Word"],
  ["certainly", "r", 59739, "Word"],
  ["west", "n", 58169, "Word"],
  ["sport", "n", 59006, "Word"],
  ["board", "n", 58436, "Word"],
  ["seek", "v", 58495, "Word"],
  ["per", "i", 59432, "Word"],
  ["subject", "n", 61397, "Word"],
  ["officer", "n", 57617, "Word"],
  ["private", "j", 57248, "Word"],
  ["rest", "n", 56714, "Word"],
  ["behavior", "n", 62625, "Word"],
  ["deal", "v", 57462, "Word"],
  ["performance", "n", 59909, "Word"],
  ["fight", "v", 56886, "Word"],
  ["throw", "v", 57784, "Word"],
  ["top", "n", 57743, "Word"],
  ["quickly", "r", 56454, "Word"],
  ["past", "j", 56588, "Word"],
  ["goal", "n", 58728, "Word"],
  ["second", "n", 56022, "Word"],
  ["bed", "n", 60304, "Word"],
  ["order", "n", 56483, "Word"],
  ["author", "n", 58300, "Word"],
  ["fill", "v", 56915, "Word"],
  ["represent", "v", 58744, "Word"],
  ["focus", "v", 57177, "Word"],
  ["foreign", "j", 57540, "Word"],
  ["drop", "v", 56448, "Word"],
  ["plan", "v", 55829, "Word"],
  ["blood", "n", 56351, "Word"],
  ["upon", "i", 57033, "Word"],
  ["agency", "n", 56954, "Word"],
  ["push", "v", 56103, "Word"],
  ["nature", "n", 57929, "Word"],
  ["color", "n", 56978, "Word"],
  ["no", "r", 54602, "Word"],
  ["recently", "r", 55992, "Word"],
  ["store", "n", 56147, "Word"],
  ["reduce", "v", 57029, "Word"],
  ["sound", "n", 56828, "Word"],
  ["note", "v", 57025, "Word"],
  ["fine", "j", 55174, "Word"],
  ["before", "r", 55608, "Word"],
  ["near", "i", 54869, "Word"],
  ["movement", "n", 56201, "Word"],
  ["page", "n", 55937, "Word"],
  ["enter", "v", 54479, "Word"],
  ["share", "v", 54010, "Word"],
  ["than", "i", 55719, "Word"],
  ["common", "j", 55940, "Word"],
  ["poor", "j", 53820, "Word"],
  ["other", "p", 54372, "Word"],
  ["natural", "j", 55526, "Word"],
  ["race", "n", 54838, "Word"],
  ["concern", "n", 55203, "Word"],
  ["series", "n", 54549, "Word"],
  ["significant", "j", 58947, "Word"],
  ["similar", "j", 55901, "Word"],
  ["hot", "j", 54601, "Word"],
  ["language", "n", 55799, "Word"],
  ["each", "p", 53663, "Word"],
  ["usually", "r", 53477, "Word"],
  ["response", "n", 56342, "Word"],
  ["dead", "j", 55111, "Word"],
  ["rise", "v", 53542, "Word"],
  ["animal", "n", 53127, "Word"],
  ["factor", "n", 57612, "Word"],
  ["decade", "n", 53727, "Word"],
  ["article", "n", 54871, "Word"],
  ["shoot", "v", 53038, "Word"],
  ["east", "n", 53010, "Word"],
  ["save", "v", 52067, "Word"],
  ["seven", "m", 52011, "Word"],
  ["artist", "n", 54353, "Word"],
  ["away", "i", 52005, "Word"],
  ["scene", "n", 51248, "Word"],
  ["stock", "n", 54305, "Word"],
  ["career", "n", 52101, "Word"],
  ["despite", "i", 51526, "Word"],
  ["central", "j", 52501, "Word"],
  ["eight", "m", 50871, "Word"],
  ["thus", "r", 57039, "Word"],
  ["treatment", "n", 53390, "Word"],
  ["beyond", "i", 50742, "Word"],
  ["happy", "j", 51669, "Word"],
  ["exactly", "r", 52685, "Word"],
  ["protect", "v", 50649, "Word"],
  ["approach", "n", 53980, "Word"],
  ["lie", "v", 51657, "Word"],
  ["size", "n", 51868, "Word"],
  ["dog", "n", 52347, "Word"],
  ["fund", "n", 53859, "Word"],
  ["serious", "j", 50285, "Word"],
  ["occur", "v", 52768, "Word"],
  ["media", "n", 51798, "Word"],
  ["ready", "j", 50833, "Word"],
  ["sign", "n", 50045, "Word"],
  ["thought", "n", 51509, "Word"],
  ["list", "n", 50678, "Word"],
  ["individual", "n", 54738, "Word"],
  ["simple", "j", 50583, "Word"],
  ["quality", "n", 52224, "Word"],
  ["pressure", "n", 50773, "Word"],
  ["accept", "v", 49952, "Word"],
  ["answer", "n", 49969, "Word"],
  ["hard", "r", 50793, "Word"],
  ["resource", "n", 53398, "Word"],
  ["identify", "v", 53484, "Word"],
  ["left", "j", 50479, "Word"],
  ["meeting", "n", 49916, "Word"],
  ["determine", "v", 52130, "Word"],
  ["prepare", "v", 49208, "Word"],
  ["disease", "n", 51211, "Word"],
  ["whatever", "d", 50200, "Word"],
  ["success", "n", 50541, "Word"],
  ["argue", "v", 51013, "Word"],
  ["cup", "n", 53633, "Word"],
  ["particularly", "r", 50329, "Word"],
  ["amount", "n", 49944, "Word"],
  ["ability", "n", 51476, "Word"],
  ["staff", "n", 50177, "Word"],
  ["recognize", "v", 50042, "Word"],
  ["indicate", "v", 53215, "Word"],
  ["character", "n", 49530, "Word"],
  ["growth", "n", 50904, "Word"],
  ["loss", "n", 49759, "Word"],
  ["degree", "n", 50612, "Word"],
  ["wonder", "v", 50875, "Word"],
  ["attack", "n", 50898, "Word"],
  ["herself", "p", 52915, "Word"],
  ["region", "n", 50914, "Word"],
  ["television", "n", 49673, "Word"],
  ["box", "n", 49667, "Word"],
  ["tv", "n", 49438, "Word"],
  ["training", "n", 49997, "Word"],
  ["pretty", "r", 50385, "Word"],
  ["trade", "n", 49696, "Word"],
  ["deal", "n", 48975, "Word"],
  ["election", "n", 51322, "Word"],
  ["everybody", "p", 51147, "Word"],
  ["physical", "j", 51451, "Word"],
  ["lay", "v", 49358, "Word"],
  ["general", "j", 49657, "Word"],
  ["feeling", "n", 47349, "Word"],
  ["standard", "n", 49876, "Word"],
  ["bill", "n", 49011, "Word"],
  ["message", "n", 47533, "Word"],
  ["fail", "v", 47503, "Word"],
  ["outside", "i", 46628, "Word"],
  ["arrive", "v", 47435, "Word"],
  ["analysis", "n", 53840, "Word"],
  ["benefit", "n", 48594, "Word"],
  ["name", "v", 47375, "Word"],
  ["sex", "n", 47827, "Word"],
  ["forward", "r", 47877, "Word"],
  ["lawyer", "n", 47853, "Word"],
  ["present", "v", 49659, "Word"],
  ["section", "n", 49764, "Word"],
  ["environmental", "j", 51192, "Word"],
  ["glass", "n", 49686, "Word"],
  ["answer", "v", 47661, "Word"],
  ["skill", "n", 50431, "Word"],
  ["sister", "n", 48183, "Word"],
  ["pm", "r", 54765, "Word"],
  ["professor", "n", 46575, "Word"],
  ["operation", "n", 47276, "Word"],
  ["financial", "j", 47803, "Word"],
  ["crime", "n", 48010, "Word"],
  ["stage", "n", 46346, "Word"],
  ["ok", "r", 54697, "Word"],
  ["compare", "v", 48094, "Word"],
  ["authority", "n", 47309, "Word"],
  ["miss", "v", 46525, "Word"],
  ["design", "n", 48605, "Word"],
  ["sort", "n", 47086, "Word"],
  ["one", "n", 45596, "Word"],
  ["act", "v", 45648, "Word"],
  ["ten", "m", 46827, "Word"],
  ["knowledge", "n", 50335, "Word"],
  ["gun", "n", 47305, "Word"],
  ["station", "n", 46299, "Word"],
  ["blue", "j", 47622, "Word"],
  ["state", "v", 47977, "Word"],
  ["strategy", "n", 49126, "Word"],
  ["little", "d", 45469, "Word"],
  ["clearly", "r", 45912, "Word"],
  ["discuss", "v", 46852, "Word"],
  ["indeed", "r", 46184, "Word"],
  ["force", "v", 44931, "Word"],
  ["truth", "n", 45155, "Word"],
  ["song", "n", 45352, "Word"],
  ["example", "n", 47134, "Word"],
  ["democratic", "j", 47299, "Word"],
  ["check", "v", 45760, "Word"],
  ["environment", "n", 47807, "Word"],
  ["leg", "n", 47477, "Word"],
  ["dark", "j", 47565, "Word"],
  ["public", "n", 45684, "Word"],
  ["various", "j", 46777, "Word"],
  ["rather", "r", 44934, "Word"],
  ["laugh", "v", 48567, "Word"],
  ["guess", "v", 48046, "Word"],
  ["executive", "n", 47338, "Word"],
  ["set", "n", 45121, "Word"],
  ["study", "v", 44897, "Word"],
  ["prove", "v", 44304, "Word"],
  ["hang", "v", 46632, "Word"],
  ["entire", "j", 43997, "Word"],
  ["rock", "n", 45225, "Word"],
  ["design", "v", 45946, "Word"],
  ["enough", "d", 44378, "Word"],
  ["forget", "v", 45571, "Word"],
  ["since", "i", 45591, "Word"],
  ["claim", "v", 44259, "Word"],
  ["note", "n", 44720, "Word"],
  ["remove", "v", 44683, "Word"],
  ["manager", "n", 46187, "Word"],
  ["help", "n", 43721, "Word"],
  ["close", "j", 43568, "Word"],
  ["sound", "v", 45311, "Word"],
  ["enjoy", "v", 44020, "Word"],
  ["network", "n", 45122, "Word"],
  ["legal", "j", 44820, "Word"],
  ["religious", "j", 46425, "Word"],
  ["cold", "j", 44649, "Word"],
  ["form", "v", 44646, "Word"],
  ["final", "j", 43589, "Word"],
  ["main", "j", 43707, "Word"],
  ["science", "n", 45726, "Word"],
  ["green", "j", 44673, "Word"],
  ["memory", "n", 43531, "Word"],
  ["card", "n", 43605, "Word"],
  ["above", "i", 44130, "Word"],
  ["seat", "n", 44404, "Word"],
  ["cell", "n", 44831, "Word"],
  ["establish", "v", 45921, "Word"],
  ["nice", "j", 44792, "Word"],
  ["trial", "n", 44489, "Word"],
  ["expert", "n", 43898, "Word"],
  ["that", "r", 43359, "Word"],
  ["spring", "n", 44085, "Word"],
  ["firm", "n", 44704, "Word"],
  ["democrat", "n", 46905, "Word"],
  ["radio", "n", 43156, "Word"],
  ["visit", "v", 42941, "Word"],
  ["management", "n", 45112, "Word"],
  ["care", "v", 42978, "Word"],
  ["avoid", "v", 42890, "Word"],
  ["imagine", "v", 43487, "Word"],
  ["tonight", "r", 47549, "Word"],
  ["huge", "j", 42582, "Word"],
  ["ball", "n", 43512, "Word"],
  ["no", "u", 44951, "Word"],
  ["close", "r", 42826, "Word"],
  ["finish", "v", 43143, "Word"],
  ["yourself", "p", 43401, "Word"],
  ["talk", "n", 43267, "Word"],
  ["theory", "n", 45553, "Word"],
  ["impact", "n", 43782, "Word"],
  ["respond", "v", 42139, "Word"],
  ["statement", "n", 42941, "Word"],
  ["maintain", "v", 43493, "Word"],
  ["charge", "n", 42353, "Word"],
  ["popular", "j", 42399, "Word"],
  ["traditional", "j", 43681, "Word"],
  ["onto", "i", 43538, "Word"],
  ["reveal", "v", 42605, "Word"],
  ["direction", "n", 41280, "Word"],
  ["weapon", "n", 43158, "Word"],
  ["employee", "n", 42824, "Word"],
  ["cultural", "j", 45673, "Word"],
  ["contain", "v", 42542, "Word"],
  ["peace", "n", 42273, "Word"],
  ["head", "v", 41658, "Word"],
  ["control", "v", 41771, "Word"],
  ["base", "n", 41154, "Word"],
  ["pain", "n", 41350, "Word"],
  ["apply", "v", 42391, "Word"],
  ["play", "n", 41662, "Word"],
  ["measure", "n", 43938, "Word"],
  ["wide", "j", 41233, "Word"],
  ["shake", "v", 45160, "Word"],
  ["fly", "v", 41373, "Word"],
  ["interview", "n", 41594, "Word"],
  ["manage", "v", 40536, "Word"],
  ["chair", "n", 43256, "Word"],
  ["fish", "n", 41488, "Word"],
  ["particular", "j", 42291, "Word"],
  ["camera", "n", 41218, "Word"],
  ["structure", "n", 43638, "Word"],
  ["politics", "n", 41758, "Word"],
  ["perform", "v", 41667, "Word"],
  ["bit", "n", 41915, "Word"],
  ["weight", "n", 41919, "Word"],
  ["suddenly", "r", 43382, "Word"],
  ["discover", "v", 40489, "Word"],
  ["candidate", "n", 43251, "Word"],
  ["top", "j", 41484, "Word"],
  ["production", "n", 42052, "Word"],
  ["treat", "v", 40264, "Word"],
  ["trip", "n", 40423, "Word"],
  ["evening", "n", 40881, "Word"],
  ["affect", "v", 41496, "Word"],
  ["inside", "i", 40856, "Word"],
  ["conference", "n", 40643, "Word"],
  ["unit", "n", 41087, "Word"],
  ["best", "r", 39959, "Word"],
  ["style", "n", 40889, "Word"],
  ["adult", "n", 40705, "Word"],
  ["worry", "v", 40210, "Word"],
  ["range", "n", 41326, "Word"],
  ["mention", "v", 39815, "Word"],
  ["rather", "i", 41558, "Word"],
  ["far", "j", 39967, "Word"],
  ["deep", "j", 40126, "Word"],
  ["past", "n", 39559, "Word"],
  ["edge", "n", 40960, "Word"],
  ["individual", "j", 42325, "Word"],
  ["specific", "j", 42397, "Word"],
  ["writer", "n", 39666, "Word"],
  ["trouble", "n", 39659, "Word"],
  ["necessary", "j", 40776, "Word"],
  ["throughout", "i", 40170, "Word"],
  ["challenge", "n", 40380, "Word"],
  ["fear", "n", 38857, "Word"],
  ["shoulder", "n", 42843, "Word"],
  ["institution", "n", 42153, "Word"],
  ["middle", "j", 40402, "Word"],
  ["sea", "n", 39516, "Word"],
  ["dream", "n", 39613, "Word"],
  ["bar", "n", 39853, "Word"],
  ["beautiful", "j", 40052, "Word"],
  ["property", "n", 40027, "Word"],
  ["instead", "r", 39051, "Word"],
  ["improve", "v", 40214, "Word"],
  ["stuff", "n", 40180, "Word"],
  ["detail", "n", 38750, "Word"],
  ["method", "n", 42667, "Word"],
  ["sign", "v", 39418, "Word"],
  ["somebody", "p", 41639, "Word"],
  ["magazine", "n", 39197, "Word"],
  ["hotel", "n", 39642, "Word"],
  ["soldier", "n", 39552, "Word"],
  ["reflect", "v", 40687, "Word"],
  ["heavy", "j", 39160, "Word"],
  ["sexual", "j", 41865, "Word"],
  ["cause", "n", 38843, "Word"],
  ["bag", "n", 40007, "Word"],
  ["heat", "n", 40363, "Word"],
  ["fall", "n", 38822, "Word"],
  ["marriage", "n", 38517, "Word"],
  ["tough", "j", 39600, "Word"],
  ["sing", "v", 38575, "Word"],
  ["surface", "n", 39367, "Word"],
  ["purpose", "n", 40084, "Word"],
  ["exist", "v", 39341, "Word"],
  ["pattern", "n", 40397, "Word"],
  ["whom", "p", 37950, "Word"],
  ["skin", "n", 39893, "Word"],
  ["agent", "n", 38265, "Word"],
  ["owner", "n", 39492, "Word"],
  ["machine", "n", 38407, "Word"],
  ["gas", "n", 38194, "Word"],
  ["down", "i", 39633, "Word"],
  ["ahead", "r", 39705, "Word"],
  ["generation", "n", 38323, "Word"],
  ["commercial", "j", 40608, "Word"],
  ["address", "v", 39774, "Word"],
  ["cancer", "n", 39407, "Word"],
  ["test", "v", 38644, "Word"],
  ["item", "n", 40336, "Word"],
  ["reality", "n", 38397, "Word"],
  ["coach", "n", 40716, "Word"],
  ["step", "v", 39356, "Word"],
  ["mrs", "n", 40545, "Word"],
  ["yard", "n", 39253, "Word"],
  ["beat", "v", 38268, "Word"],
  ["violence", "n", 38474, "Word"],
  ["total", "j", 38798, "Word"],
  ["tend", "v", 38295, "Word"],
  ["investment", "n", 38810, "Word"],
  ["discussion", "n", 39616, "Word"],
  ["finger", "n", 40842, "Word"],
  ["garden", "n", 38800, "Word"],
  ["notice", "v", 38910, "Word"],
  ["collection", "n", 38431, "Word"],
  ["modern", "j", 38372, "Word"],
  ["task", "n", 39209, "Word"],
  ["partner", "n", 37540, "Word"],
  ["positive", "j", 39310, "Word"],
  ["civil", "j", 37742, "Word"],
  ["kitchen", "n", 39332, "Word"],
  ["consumer", "n", 38079, "Word"],
  ["shot", "n", 37505, "Word"],
  ["budget", "n", 39165, "Word"],
  ["wish", "v", 37532, "Word"],
  ["painting", "n", 39264, "Word"],
  ["scientist", "n", 37700, "Word"],
  ["safe", "j", 36547, "Word"],
  ["agreement", "n", 38141, "Word"],
  ["capital", "n", 37443, "Word"],
  ["mouth", "n", 40200, "Word"],
  ["nor", "c", 37230, "Word"],
  ["victim", "n", 36878, "Word"],
  ["newspaper", "n", 37522, "Word"],
  ["instead", "i", 36247, "Word"],
  ["threat", "n", 37022, "Word"],
  ["responsibility", "n", 37084, "Word"],
  ["smile", "v", 41473, "Word"],
  ["attorney", "n", 37959, "Word"],
  ["score", "n", 39294, "Word"],
  ["account", "n", 36786, "Word"],
  ["interesting", "j", 37473, "Word"],
  ["break", "n", 40348, "Word"],
  ["audience", "n", 36227, "Word"],
  ["rich", "j", 35940, "Word"],
  ["dinner", "n", 37156, "Word"],
  ["figure", "v", 36137, "Word"],
  ["vote", "n", 39464, "Word"],
  ["western", "j", 37389, "Word"],
  ["relate", "v", 39143, "Word"],
  ["travel", "v", 35746, "Word"],
  ["debate", "n", 37228, "Word"],
  ["prevent", "v", 36421, "Word"],
  ["citizen", "n", 36408, "Word"],
  ["majority", "n", 36758, "Word"],
  ["none", "p", 35359, "Word"],
  ["front", "i", 37363, "Word"],
  ["born", "v", 35352, "Word"],
  ["admit", "v", 35143, "Word"],
  ["senior", "j", 36598, "Word"],
  ["assume", "v", 35960, "Word"],
  ["wind", "n", 36478, "Word"],
  ["key", "j", 36056, "Word"],
  ["professional", "j", 36319, "Word"],
  ["mission", "n", 35217, "Word"],
  ["fast", "r", 35474, "Word"],
  ["alone", "r", 34982, "Word"],
  ["customer", "n", 36000, "Word"],
  ["suffer", "v", 34675, "Word"],
  ["speech", "n", 35504, "Word"],
  ["successful", "j", 35459, "Word"],
  ["option", "n", 35434, "Word"],
  ["participant", "n", 41462, "Word"],
  ["southern", "j", 35314, "Word"],
  ["fresh", "j", 35974, "Word"],
  ["eventually", "r", 34534, "Word"],
  ["no", "p", 35016, "Word"],
  ["forest", "n", 35429, "Word"],
  ["video", "n", 35230, "Word"],
  ["global", "j", 36381, "Word"],
  ["senate", "n", 36809, "Word"],
  ["reform", "n", 36103, "Word"],
  ["access", "n", 35310, "Word"],
  ["restaurant", "n", 35898, "Word"],
  ["judge", "n", 35464, "Word"],
  ["publish", "v", 35062, "Word"],
  ["cost", "v", 34941, "Word"],
  ["relation", "n", 36963, "Word"],
  ["like", "c", 35636, "Word"],
  ["release", "v", 34301, "Word"],
  ["own", "v", 34577, "Word"],
  ["bird", "n", 34835, "Word"],
  ["opinion", "n", 34501, "Word"],
  ["credit", "n", 34578, "Word"],
  ["critical", "j", 35824, "Word"],
  ["corner", "n", 35496, "Word"],
  ["concerned", "j", 34331, "Word"],
  ["recall", "v", 34442, "Word"],
  ["version", "n", 34456, "Word"],
  ["stare", "v", 39266, "Word"],
  ["safety", "n", 34188, "Word"],
  ["effective", "j", 35851, "Word"],
  ["neighborhood", "n", 34691, "Word"],
  ["original", "j", 34368, "Word"],
  ["act", "n", 34104, "Word"],
  ["troop", "n", 36001, "Word"],
  ["income", "n", 34925, "Word"],
  ["directly", "r", 34090, "Word"],
  ["hurt", "v", 34445, "Word"],
  ["species", "n", 35804, "Word"],
  ["immediately", "r", 33067, "Word"],
  ["track", "n", 33788, "Word"],
  ["basic", "j", 34788, "Word"],
  ["strike", "v", 33238, "Word"],
  ["hope", "n", 33019, "Word"],
  ["sky", "n", 35141, "Word"],
  ["freedom", "n", 34174, "Word"],
  ["absolutely", "r", 36740, "Word"],
  ["plane", "n", 33900, "Word"],
  ["nobody", "p", 34280, "Word"],
  ["achieve", "v", 35166, "Word"],
  ["object", "n", 35040, "Word"],
  ["attitude", "n", 35437, "Word"],
  ["labor", "n", 34400, "Word"],
  ["refer", "v", 34317, "Word"],
  ["concept", "n", 35944, "Word"],
  ["client", "n", 34027, "Word"],
  ["powerful", "j", 33151, "Word"],
  ["perfect", "j", 33456, "Word"],
  ["nine", "m", 32967, "Word"],
  ["therefore", "r", 35842, "Word"],
  ["conduct", "v", 34909, "Word"],
  ["announce", "v", 33305, "Word"],
  ["conversation", "n", 33046, "Word"],
  ["examine", "v", 35826, "Word"],
  ["touch", "v", 34737, "Word"],
  ["please", "r", 34709, "Word"],
  ["attend", "v", 33380, "Word"],
  ["completely", "r", 32550, "Word"],
  ["vote", "v", 35224, "Word"],
  ["variety", "n", 34242, "Word"],
  ["sleep", "v", 34801, "Word"],
  ["turn", "n", 32944, "Word"],
  ["involved", "j", 33428, "Word"],
  ["investigation", "n", 33849, "Word"],
  ["nuclear", "j", 34264, "Word"],
  ["researcher", "n", 34742, "Word"],
  ["press", "n", 33410, "Word"],
  ["conflict", "n", 34710, "Word"],
  ["spirit", "n", 32942, "Word"],
  ["experience", "v", 34009, "Word"],
  ["replace", "v", 32688, "Word"],
  ["british", "j", 32929, "Word"],
  ["encourage", "v", 33348, "Word"],
  ["argument", "n", 33394, "Word"],
  ["by", "r", 32560, "Word"],
  ["once", "c", 32180, "Word"],
  ["camp", "n", 32659, "Word"],
  ["brain", "n", 32852, "Word"],
  ["feature", "n", 33344, "Word"],
  ["afternoon", "n", 33202, "Word"],
  ["am", "r", 34451, "Abbreviation"],
  ["weekend", "n", 33239, "Word"],
  ["dozen", "m", 32432, "Word"],
  ["possibility", "n", 32466, "Word"],
  ["along", "r", 32475, "Word"],
  ["insurance", "n", 32928, "Word"],
  ["department", "n", 32252, "Word"],
  ["battle", "n", 31989, "Word"],
  ["beginning", "n", 31866, "Word"],
  ["date", "n", 31467, "Word"],
  ["generally", "r", 33191, "Word"],
  ["african", "j", 34557, "Word"],
  ["very", "j", 31807, "Word"],
  ["sorry", "j", 34270, "Word"],
  ["crisis", "n", 32924, "Word"],
  ["complete", "v", 32813, "Word"],
  ["fan", "n", 32919, "Word"],
  ["stick", "v", 32259, "Word"],
  ["define", "v", 33958, "Word"],
  ["easily", "r", 31755, "Word"],
  ["through", "r", 31838, "Word"],
  ["hole", "n", 32302, "Word"],
  ["element", "n", 33053, "Word"],
  ["vision", "n", 32358, "Word"],
  ["status", "n", 33511, "Word"],
  ["normal", "j", 31265, "Word"],
  ["chinese", "j", 32334, "Word"],
  ["ship", "n", 32588, "Word"],
  ["solution", "n", 32052, "Word"],
  ["stone", "n", 32531, "Word"],
  ["slowly", "r", 33403, "Word"],
  ["scale", "n", 34375, "Word"],
  ["bit", "r", 32087, "Word"],
  ["university", "n", 33000, "Word"],
  ["introduce", "v", 31345, "Word"],
  ["driver", "n", 31633, "Word"],
  ["attempt", "n", 31944, "Word"],
  ["park", "n", 32322, "Word"],
  ["spot", "n", 31612, "Word"],
  ["lack", "n", 32157, "Word"],
  ["ice", "n", 31686, "Word"],
  ["boat", "n", 32079, "Word"],
  ["drink", "v", 32078, "Word"],
  ["sun", "n", 32646, "Word"],
  ["front", "j", 32087, "Word"],
  ["distance", "n", 31380, "Word"],
  ["wood", "n", 31801, "Word"],
  ["handle", "v", 30634, "Word"],
  ["truck", "n", 31536, "Word"],
  ["return", "n", 31058, "Word"],
  ["mountain", "n", 31322, "Word"],
  ["survey", "n", 32784, "Word"],
  ["supposed", "j", 31005, "Word"],
  ["tradition", "n", 32110, "Word"],
  ["winter", "n", 31227, "Word"],
  ["village", "n", 30741, "Word"],
  ["soviet", "j", 36193, "Word"],
  ["refuse", "v", 30173, "Word"],
  ["sales", "n", 32145, "Word"],
  ["roll", "v", 31592, "Word"],
  ["communication", "n", 31577, "Word"],
  ["run", "n", 31325, "Word"],
  ["screen", "n", 30758, "Word"],
  ["gain", "v", 30667, "Word"],
  ["resident", "n", 31836, "Word"],
  ["hide", "v", 31098, "Word"],
  ["gold", "n", 30733, "Word"],
  ["club", "n", 30800, "Word"],
  ["future", "j", 31494, "Word"],
  ["farm", "n", 30291, "Word"],
  ["potential", "j", 31289, "Word"],
  ["increase", "n", 31247, "Word"],
  ["middle", "n", 30199, "Word"],
  ["european", "j", 31455, "Word"],
  ["presence", "n", 30624, "Word"],
  ["independent", "j", 31044, "Word"],
  ["district", "n", 31327, "Word"],
  ["shape", "n", 30396, "Word"],
  ["reader", "n", 31442, "Word"],
  ["ms", "n", 31610, "Word"],
  ["contract", "n", 30906, "Word"],
  ["crowd", "n", 30542, "Word"],
  ["christian", "j", 30726, "Word"],
  ["express", "v", 30947, "Word"],
  ["apartment", "n", 31012, "Word"],
  ["willing", "j", 29635, "Word"],
  ["strength", "n", 29769, "Word"],
  ["previous", "j", 30490, "Word"],
  ["band", "n", 29769, "Word"],
  ["obviously", "r", 31299, "Word"],
  ["horse", "n", 30993, "Word"],
  ["interested", "j", 29155, "Word"],
  ["target", "n", 29866, "Word"],
  ["prison", "n", 30248, "Word"],
  ["ride", "v", 30476, "Word"],
  ["guard", "n", 30159, "Word"],
  ["terms", "i", 30996, "Word"],
  ["demand", "n", 30519, "Word"],
  ["reporter", "n", 30416, "Word"],
  ["deliver", "v", 29169, "Word"],
  ["text", "n", 32316, "Word"],
  ["share", "n", 30752, "Word"],
  ["tool", "n", 30286, "Word"],
  ["wild", "j", 29473, "Word"],
  ["vehicle", "n", 29421, "Word"],
  ["observe", "v", 30737, "Word"],
  ["flight", "n", 29535, "Word"],
  ["inside", "r", 30715, "Word"],
  ["facility", "n", 30107, "Word"],
  ["understanding", "n", 31319, "Word"],
  ["average", "j", 29717, "Word"],
  ["emerge", "v", 29496, "Word"],
  ["advantage", "n", 29340, "Word"],
  ["quick", "j", 29108, "Word"],
  ["light", "j", 29731, "Word"],
  ["leadership", "n", 30047, "Word"],
  ["earn", "v", 29560, "Word"],
  ["pound", "n", 29946, "Word"],
  ["basis", "n", 30377, "Word"],
  ["bright", "j", 29780, "Word"],
  ["operate", "v", 29345, "Word"],
  ["guest", "n", 29328, "Word"],
  ["sample", "n", 32436, "Word"],
  ["contribute", "v", 30090, "Word"],
  ["tiny", "j", 29756, "Word"],
  ["block", "n", 28826, "Word"],
  ["protection", "n", 29336, "Word"],
  ["settle", "v", 28827, "Word"],
  ["feed", "v", 28494, "Word"],
  ["collect", "v", 28946, "Word"],
  ["additional", "j", 29771, "Word"],
  ["while", "n", 29579, "Word"],
  ["highly", "r", 29554, "Word"],
  ["identity", "n", 31163, "Word"],
  ["title", "n", 29210, "Word"],
  ["mostly", "r", 28178, "Word"],
  ["lesson", "n", 28623, "Word"],
  ["faith", "n", 28710, "Word"],
  ["river", "n", 28862, "Word"],
  ["promote", "v", 29679, "Word"],
  ["living", "n", 28736, "Word"],
  ["present", "j", 30645, "Word"],
  ["count", "v", 28836, "Word"],
  ["unless", "c", 27908, "Word"],
  ["marry", "v", 28722, "Word"],
  ["tomorrow", "r", 30098, "Word"],
  ["technique", "n", 29828, "Word"],
  ["path", "n", 28344, "Word"],
  ["ear", "n", 29597, "Word"],
  ["shop", "n", 28589, "Word"],
  ["folk", "n", 28698, "Word"],
  ["order", "v", 27950, "Word"],
  ["principle", "n", 30011, "Word"],
  ["survive", "v", 27682, "Word"],
  ["lift", "v", 29354, "Word"],
  ["border", "n", 28636, "Word"],
  ["competition", "n", 28752, "Word"],
  ["jump", "v", 28504, "Word"],
  ["gather", "v", 27659, "Word"],
  ["limit", "v", 28901, "Word"],
  ["fit", "v", 27875, "Word"],
  ["claim", "n", 28609, "Word"],
  ["cry", "v", 29690, "Word"],
  ["equipment", "n", 28023, "Word"],
  ["worth", "i", 27687, "Word"],
  ["associate", "v", 30406, "Word"],
  ["critic", "n", 28244, "Word"],
  ["warm", "j", 28600, "Word"],
  ["aspect", "n", 29460, "Word"],
  ["result", "v", 29515, "Word"],
  ["insist", "v", 27205, "Word"],
  ["failure", "n", 28234, "Word"],
  ["annual", "j", 28471, "Word"],
  ["french", "j", 27590, "Word"],
  ["christmas", "n", 28046, "Word"],
  ["comment", "n", 27712, "Word"],
  ["responsible", "j", 27569, "Word"],
  ["affair", "n", 27432, "Word"],
  ["approach", "v", 27449, "Word"],
  ["until", "i", 27007, "Word"],
  ["procedure", "n", 29044, "Word"],
  ["regular", "j", 27244, "Word"],
  ["spread", "v", 27266, "Word"],
  ["chairman", "n", 28560, "Word"],
  ["baseball", "n", 28551, "Word"],
  ["soft", "j", 27970, "Word"],
  ["ignore", "v", 27023, "Word"],
  ["egg", "n", 27917, "Word"],
  ["measure", "v", 28612, "Word"],
  ["belief", "n", 28844, "Word"],
  ["demonstrate", "v", 29170, "Word"],
  ["anybody", "p", 28704, "Word"],
  ["murder", "n", 28335, "Word"],
  ["gift", "n", 27050, "Word"],
  ["religion", "n", 28220, "Word"],
  ["review", "n", 28141, "Word"],
  ["editor", "n", 27734, "Word"],
  ["past", "i", 28278, "Word"],
  ["engage", "v", 28194, "Word"],
  ["coffee", "n", 28331, "Word"],
  ["document", "n", 29437, "Word"],
  ["speed", "n", 27740, "Word"],
  ["cross", "v", 27524, "Word"],
  ["influence", "n", 28387, "Word"],
  ["anyway", "r", 28170, "Word"],
  ["threaten", "v", 26587, "Word"],
  ["commit", "v", 26943, "Word"],
  ["female", "j", 27952, "Word"],
  ["youth", "n", 27755, "Word"],
  ["wave", "n", 26844, "Word"],
  ["move", "n", 26717, "Word"],
  ["afraid", "j", 27727, "Word"],
  ["quarter", "n", 27125, "Word"],
  ["background", "n", 26907, "Word"],
  ["native", "j", 28026, "Word"],
  ["broad", "j", 27191, "Word"],
  ["wonderful", "j", 27494, "Word"],
  ["deny", "v", 26675, "Word"],
  ["apparently", "r", 26513, "Word"],
  ["slightly", "r", 26887, "Word"],
  ["reaction", "n", 26771, "Word"],
  ["twice", "r", 26450, "Word"],
  ["suit", "n", 27066, "Word"],
  ["perspective", "n", 28324, "Word"],
  ["growing", "j", 26858, "Word"],
  ["blow", "v", 27254, "Word"],
  ["construction", "n", 27114, "Word"],
  ["kind", "r", 29474, "Word"],
  ["intelligence", "n", 27555, "Word"],
  ["destroy", "v", 26177, "Word"],
  ["cook", "v", 27725, "Word"],
  ["connection", "n", 26552, "Word"],
  ["charge", "v", 26432, "Word"],
  ["burn", "v", 26646, "Word"],
  ["shoe", "n", 26945, "Word"],
  ["view", "v", 27258, "Word"],
  ["grade", "n", 27178, "Word"],
  ["context", "n", 29113, "Word"],
  ["committee", "n", 26697, "Word"],
  ["hey", "u", 27659, "Word"],
  ["mistake", "n", 26080, "Word"],
  ["focus", "n", 26815, "Word"],
  ["smile", "n", 28698, "Word"],
  ["location", "n", 26463, "Word"],
  ["clothes", "n", 27033, "Word"],
  ["indian", "j", 27100, "Word"],
  ["quiet", "j", 26732, "Word"],
  ["dress", "v", 26669, "Word"],
  ["promise", "v", 25720, "Word"],
  ["aware", "j", 25503, "Word"],
  ["neighbor", "n", 25497, "Word"],
  ["complete", "j", 25699, "Word"],
  ["drive", "n", 26373, "Word"],
  ["function", "n", 28011, "Word"],
  ["bone", "n", 26282, "Word"],
  ["active", "j", 26507, "Word"],
  ["extend", "v", 25969, "Word"],
  ["chief", "j", 26456, "Word"],
  ["average", "n", 26568, "Word"],
  ["combine", "v", 26570, "Word"],
  ["wine", "n", 26682, "Word"],
  ["below", "r", 26765, "Word"],
  ["cool", "j", 26279, "Word"],
  ["voter", "n", 27768, "Word"],
  ["mean", "n", 28991, "Word"],
  ["demand", "v", 25207, "Word"],
  ["learning", "n", 28869, "Word"],
  ["bus", "n", 25850, "Word"],
  ["hell", "n", 27278, "Word"],
  ["dangerous", "j", 25170, "Word"],
  ["remind", "v", 25525, "Word"],
  ["moral", "j", 26980, "Word"],
  ["united", "j", 26396, "Word"],
  ["category", "n", 27219, "Word"],
  ["relatively", "r", 26476, "Word"],
  ["victory", "n", 26422, "Word"],
  ["key", "n", 25436, "Word"],
  ["academic", "j", 28216, "Word"],
  ["visit", "n", 25000, "Word"],
  ["internet", "n", 26983, "Word"],
  ["healthy", "j", 26009, "Word"],
  ["fire", "v", 25390, "Word"],
  ["negative", "j", 26847, "Word"],
  ["following", "j", 26643, "Word"],
  ["historical", "j", 27266, "Word"],
  ["medicine", "n", 25511, "Word"],
  ["tour", "n", 25781, "Word"],
  ["depend", "v", 25405, "Word"],
  ["photo", "n", 25492, "Word"],
  ["finding", "n", 28246, "Word"],
  ["grab", "v", 26951, "Word"],
  ["direct", "j", 26008, "Word"],
  ["classroom", "n", 27531, "Word"],
  ["contact", "n", 25218, "Word"],
  ["justice", "n", 25377, "Word"],
  ["participate", "v", 26500, "Word"],
  ["daily", "j", 25179, "Word"],
  ["fair", "j", 25064, "Word"],
  ["pair", "n", 25289, "Word"],
  ["famous", "j", 24631, "Word"],
  ["exercise", "n", 25934, "Word"],
  ["knee", "n", 25912, "Word"],
  ["flower", "n", 25642, "Word"],
  ["tape", "n", 25730, "Word"],
  ["hire", "v", 25073, "Word"],
  ["familiar", "j", 24515, "Word"],
  ["appropriate", "j", 26237, "Word"],
  ["supply", "n", 24920, "Word"],
  ["fully", "r", 24842, "Word"],
  ["cut", "n", 25327, "Word"],
  ["will", "n", 24351, "Word"],
  ["actor", "n", 24848, "Word"],
  ["birth", "n", 24515, "Word"],
  ["search", "n", 27087, "Word"],
  ["tie", "v", 24408, "Word"],
  ["democracy", "n", 26039, "Word"],
  ["eastern", "j", 25103, "Word"],
  ["primary", "j", 26043, "Word"],
  ["yesterday", "r", 26215, "Word"],
  ["circle", "n", 24735, "Word"],
  ["device", "n", 24918, "Word"],
  ["progress", "n", 24645, "Word"],
  ["next", "i", 25720, "Word"],
  ["front", "n", 24402, "Word"],
  ["bottom", "n", 24653, "Word"],
  ["island", "n", 24311, "Word"],
  ["exchange", "n", 24826, "Word"],
  ["clean", "j", 24325, "Word"],
  ["studio", "n", 24765, "Word"],
  ["train", "v", 23990, "Word"],
  ["lady", "n", 25436, "Word"],
  ["colleague", "n", 24370, "Word"],
  ["application", "n", 25735, "Word"],
  ["neck", "n", 25716, "Word"],
  ["lean", "v", 26896, "Word"],
  ["damage", "n", 24155, "Word"],
  ["plastic", "n", 24563, "Word"],
  ["tall", "j", 25304, "Word"],
  ["plate", "n", 24592, "Word"],
  ["hate", "v", 24921, "Word"],
  ["otherwise", "r", 23793, "Word"],
  ["writing", "n", 24995, "Word"],
  ["press", "v", 24716, "Word"],
  ["male", "j", 24873, "Word"],
  ["start", "n", 24071, "Word"],
  ["alive", "j", 24184, "Word"],
  ["expression", "n", 24748, "Word"],
  ["football", "n", 25271, "Word"],
  ["intend", "v", 23554, "Word"],
  ["attack", "v", 23971, "Word"],
  ["chicken", "n", 24465, "Word"],
  ["army", "n", 23929, "Word"],
  ["abuse", "n", 24534, "Word"],
  ["theater", "n", 24447, "Word"],
  ["shut", "v", 24773, "Word"],
  ["map", "n", 23666, "Word"],
  ["extra", "j", 24006, "Word"],
  ["session", "n", 24010, "Word"],
  ["danger", "n", 23244, "Word"],
  ["welcome", "v", 25241, "Word"],
  ["domestic", "j", 24313, "Word"],
  ["lots", "p", 23750, "Word"],
  ["literature", "n", 26254, "Word"],
  ["rain", "n", 24134, "Word"],
  ["desire", "n", 23994, "Word"],
  ["assessment", "n", 26318, "Word"],
  ["injury", "n", 23935, "Word"],
  ["respect", "n", 23274, "Word"],
  ["northern", "j", 23783, "Word"],
  ["nod", "v", 27283, "Word"],
  ["paint", "v", 24038, "Word"],
  ["fuel", "n", 23934, "Word"],
  ["leaf", "n", 24044, "Word"],
  ["direct", "v", 23298, "Word"],
  ["dry", "j", 23809, "Word"],
  ["russian", "j", 23739, "Word"],
  ["instruction", "n", 25190, "Word"],
  ["fight", "n", 23211, "Word"],
  ["pool", "n", 23346, "Word"],
  ["climb", "v", 24367, "Word"],
  ["sweet", "j", 23843, "Word"],
  ["lead", "n", 23301, "Word"],
  ["engine", "n", 23617, "Word"],
  ["fourth", "m", 23227, "Word"],
  ["salt", "n", 24711, "Word"],
  ["expand", "v", 23417, "Word"],
  ["importance", "n", 24946, "Word"],
  ["metal", "n", 23317, "Word"],
  ["fat", "n", 24844, "Word"],
  ["ticket", "n", 23902, "Word"],
  ["software", "n", 23954, "Word"],
  ["disappear", "v", 23389, "Word"],
  ["corporate", "j", 23621, "Word"],
  ["strange", "j", 23744, "Word"],
  ["lip", "n", 25250, "Word"],
  ["reading", "n", 23919, "Word"],
  ["urban", "j", 23889, "Word"],
  ["mental", "j", 23650, "Word"],
  ["increasingly", "r", 23529, "Word"],
  ["lunch", "n", 23220, "Word"],
  ["educational", "j", 25282, "Word"],
  ["somewhere", "r", 23527, "Word"],
  ["farmer", "n", 23005, "Word"],
  ["above", "r", 23866, "Word"],
  ["sugar", "n", 24310, "Word"],
  ["planet", "n", 23567, "Word"],
  ["favorite", "j", 23159, "Word"],
  ["explore", "v", 23744, "Word"],
  ["obtain", "v", 24538, "Word"],
  ["enemy", "n", 22621, "Word"],
  ["greatest", "j", 22673, "Word"],
  ["complex", "j", 23751, "Word"],
  ["surround", "v", 22391, "Word"],
  ["athlete", "n", 23868, "Word"],
  ["invite", "v", 22158, "Word"],
  ["repeat", "v", 22622, "Word"],
  ["carefully", "r", 22837, "Word"],
  ["soul", "n", 22713, "Word"],
  ["scientific", "j", 23691, "Word"],
  ["impossible", "j", 22233, "Word"],
  ["panel", "n", 22529, "Word"],
  ["meaning", "n", 24064, "Word"],
  ["mom", "n", 23450, "Word"],
  ["married", "j", 22352, "Word"],
  ["alone", "j", 23106, "Word"],
  ["instrument", "n", 23831, "Word"],
  ["predict", "v", 22942, "Word"],
  ["weather", "n", 22586, "Word"],
  ["presidential", "j", 24057, "Word"],
  ["emotional", "j", 22773, "Word"],
  ["commitment", "n", 23152, "Word"],
  ["supreme", "j", 23904, "Word"],
  ["bear", "v", 22190, "Word"],
  ["pocket", "n", 23580, "Word"],
  ["thin", "j", 23194, "Word"],
  ["temperature", "n", 23111, "Word"],
  ["surprise", "n", 22275, "Word"],
  ["poll", "n", 24009, "Word"],
  ["proposal", "n", 22830, "Word"],
  ["consequence", "n", 23307, "Word"],
  ["half", "n", 22029, "Word"],
  ["breath", "n", 24668, "Word"],
  ["sight", "n", 22878, "Word"],
  ["cover", "n", 22301, "Word"],
  ["balance", "n", 22243, "Word"],
  ["adopt", "v", 22880, "Word"],
  ["minority", "n", 23167, "Word"],
  ["straight", "r", 22929, "Word"],
  ["attempt", "v", 22758, "Word"],
  ["connect", "v", 22100, "Word"],
  ["works", "n", 22722, "Word"],
  ["teaching", "n", 24387, "Word"],
  ["belong", "v", 21856, "Word"],
  ["aid", "n", 22642, "Word"],
  ["advice", "n", 21970, "Word"],
  ["okay", "r", 24243, "Word"],
  ["photograph", "n", 22016, "Word"],
  ["empty", "j", 23365, "Word"],
  ["regional", "j", 23182, "Word"],
  ["trail", "n", 23030, "Word"],
  ["novel", "n", 22392, "Word"],
  ["code", "n", 22015, "Word"],
  ["somehow", "r", 22267, "Word"],
  ["organize", "v", 22389, "Word"],
  ["jury", "n", 24072, "Word"],
  ["breast", "n", 22362, "Word"],
  ["iraqi", "j", 25446, "Word"],
  ["human", "n", 22022, "Word"],
  ["acknowledge", "v", 21983, "Word"],
  ["theme", "n", 22494, "Word"],
  ["storm", "n", 22562, "Word"],
  ["union", "n", 22380, "Word"],
  ["record", "v", 21939, "Word"],
  ["desk", "n", 23455, "Word"],
  ["fear", "v", 21333, "Word"],
  ["thanks", "n", 23061, "Word"],
  ["fruit", "n", 22401, "Word"],
  ["under", "r", 21521, "Word"],
  ["expensive", "j", 21706, "Word"],
  ["yellow", "j", 22452, "Word"],
  ["conclusion", "n", 23034, "Word"],
  ["prime", "j", 22075, "Word"],
  ["shadow", "n", 22803, "Word"],
  ["struggle", "v", 21370, "Word"],
  ["conclude", "v", 22247, "Word"],
  ["analyst", "n", 22662, "Word"],
  ["dance", "n", 21799, "Word"],
  ["limit", "n", 21714, "Word"],
  ["like", "j", 21723, "Word"],
  ["regulation", "n", 22479, "Word"],
  ["being", "n", 21695, "Word"],
  ["last", "v", 21219, "Word"],
  ["ring", "n", 21873, "Word"],
  ["largely", "r", 22097, "Word"],
  ["shift", "v", 21323, "Word"],
  ["revenue", "n", 22445, "Word"],
  ["mark", "v", 21236, "Word"],
  ["locate", "v", 21843, "Word"],
  ["county", "n", 22675, "Word"],
  ["appearance", "n", 21146, "Word"],
  ["package", "n", 21665, "Word"],
  ["difficulty", "n", 22288, "Word"],
  ["bridge", "n", 21497, "Word"],
  ["recommend", "v", 21707, "Word"],
  ["obvious", "j", 21052, "Word"],
  ["train", "n", 21766, "Word"],
  ["basically", "r", 23265, "Word"],
  ["email", "n", 22467, "Word"],
  ["generate", "v", 22213, "Word"],
  ["anymore", "r", 21771, "Word"],
  ["propose", "v", 21691, "Word"],
  ["thinking", "n", 21663, "Word"],
  ["possibly", "r", 20953, "Word"],
  ["trend", "n", 21894, "Word"],
  ["visitor", "n", 21490, "Word"],
  ["loan", "n", 22324, "Word"],
  ["currently", "r", 21636, "Word"],
  ["comfortable", "j", 21101, "Word"],
  ["investor", "n", 22410, "Word"],
  ["but", "i", 20961, "Word"],
  ["profit", "n", 21754, "Word"],
  ["angry", "j", 21485, "Word"],
  ["crew", "n", 21238, "Word"],
  ["deep", "r", 21413, "Word"],
  ["accident", "n", 20964, "Word"],
  ["male", "n", 22398, "Word"],
  ["meal", "n", 21556, "Word"],
  ["hearing", "n", 21560, "Word"],
  ["traffic", "n", 21296, "Word"],
  ["muscle", "n", 21931, "Word"],
  ["notion", "n", 21801, "Word"],
  ["capture", "v", 21018, "Word"],
  ["prefer", "v", 20946, "Word"],
  ["truly", "r", 20576, "Word"],
  ["earth", "n", 21350, "Word"],
  ["japanese", "j", 21800, "Word"],
  ["chest", "n", 22508, "Word"],
  ["search", "v", 20943, "Word"],
  ["thick", "j", 21932, "Word"],
  ["cash", "n", 21343, "Word"],
  ["museum", "n", 21715, "Word"],
  ["beauty", "n", 21060, "Word"],
  ["emergency", "n", 20796, "Word"],
  ["unique", "j", 21436, "Word"],
  ["feature", "v", 21678, "Word"],
  ["internal", "j", 22025, "Word"],
  ["ethnic", "j", 22632, "Word"],
  ["link", "v", 21335, "Word"],
  ["stress", "n", 21813, "Word"],
  ["content", "n", 21821, "Word"],
  ["select", "v", 21617, "Word"],
  ["root", "n", 20774, "Word"],
  ["nose", "n", 21938, "Word"],
  ["declare", "v", 20550, "Word"],
  ["outside", "r", 21556, "Word"],
  ["appreciate", "v", 20806, "Word"],
  ["actual", "j", 21321, "Word"],
  ["bottle", "n", 21569, "Word"],
  ["hardly", "r", 20757, "Word"],
  ["setting", "n", 22004, "Word"],
  ["launch", "v", 20767, "Word"],
  ["dress", "n", 21362, "Word"],
  ["file", "n", 20832, "Word"],
  ["sick", "j", 20906, "Word"],
  ["outcome", "n", 22721, "Word"],
  ["ad", "n", 21064, "Word"],
  ["defend", "v", 20510, "Word"],
  ["matter", "v", 20534, "Word"],
  ["judge", "v", 20320, "Word"],
  ["duty", "n", 20204, "Word"],
  ["sheet", "n", 20787, "Word"],
  ["ought", "v", 21552, "Word"],
  ["ensure", "v", 21341, "Word"],
  ["catholic", "j", 20866, "Word"],
  ["extremely", "r", 20310, "Word"],
  ["extent", "n", 21727, "Word"],
  ["component", "n", 22085, "Word"],
  ["mix", "v", 20642, "Word"],
  ["longterm", "j", 20841, "Word"],
  ["slow", "j", 20404, "Word"],
  ["contrast", "n", 21581, "Word"],
  ["zone", "n", 20472, "Word"],
  ["wake", "v", 21330, "Word"],
  ["challenge", "v", 20510, "Word"],
  ["airport", "n", 20897, "Word"],
  ["chief", "n", 20453, "Word"],
  ["brown", "j", 21175, "Word"],
  ["standard", "j", 20917, "Word"],
  ["shirt", "n", 21486, "Word"],
  ["pilot", "n", 20192, "Word"],
  ["warn", "v", 19996, "Word"],
  ["ultimately", "r", 20387, "Word"],
  ["cat", "n", 21135, "Word"],
  ["contribution", "n", 20912, "Word"],
  ["capacity", "n", 21102, "Word"],
  ["ourselves", "p", 19926, "Word"],
  ["estate", "n", 20673, "Word"],
  ["guide", "n", 20646, "Word"],
  ["circumstance", "n", 20310, "Word"],
  ["snow", "n", 21011, "Word"],
  ["english", "j", 20235, "Word"],
  ["politician", "n", 20360, "Word"],
  ["steal", "v", 20296, "Word"],
  ["pursue", "v", 20136, "Word"],
  ["slip", "v", 21233, "Word"],
  ["percentage", "n", 20774, "Word"],
  ["meat", "n", 20271, "Word"],
  ["funny", "j", 20399, "Word"],
  ["neither", "r", 20100, "Word"],
  ["soil", "n", 20916, "Word"],
  ["influence", "v", 21448, "Word"],
  ["surgery", "n", 20118, "Word"],
  ["correct", "j", 20613, "Word"],
  ["jewish", "j", 20196, "Word"],
  ["blame", "v", 19798, "Word"],
  ["estimate", "v", 20474, "Word"],
  ["due", "i", 21141, "Word"],
  ["basketball", "n", 20955, "Word"],
  ["late", "r", 19727, "Word"],
  ["golf", "n", 20979, "Word"],
  ["investigate", "v", 20117, "Word"],
  ["crazy", "j", 20345, "Word"],
  ["significantly", "r", 22038, "Word"],
  ["chain", "n", 19688, "Word"],
  ["address", "n", 19564, "Word"],
  ["branch", "n", 19633, "Word"],
  ["combination", "n", 20026, "Word"],
  ["just", "j", 20096, "Word"],
  ["frequently", "r", 20508, "Word"],
  ["governor", "n", 20802, "Word"],
  ["relief", "n", 19493, "Word"],
  ["user", "n", 20681, "Word"],
  ["dad", "n", 20164, "Word"],
  ["kick", "v", 20092, "Word"],
  ["part", "r", 19900, "Word"],
  ["manner", "n", 20012, "Word"],
  ["ancient", "j", 19818, "Word"],
  ["silence", "n", 21169, "Word"],
  ["rating", "n", 20413, "Word"],
  ["golden", "j", 19852, "Word"],
  ["motion", "n", 19649, "Word"],
  ["german", "j", 20096, "Word"],
  ["gender", "n", 22190, "Word"],
  ["solve", "v", 19501, "Word"],
  ["fee", "n", 20263, "Word"],
  ["landscape", "n", 20171, "Word"],
  ["used", "j", 19130, "Word"],
  ["bowl", "n", 20662, "Word"],
  ["equal", "j", 19952, "Word"],
  ["long", "c", 19227, "Word"],
  ["official", "j", 19628, "Word"],
  ["forth", "r", 19531, "Word"],
  ["frame", "n", 20016, "Word"],
  ["typical", "j", 19724, "Word"],
  ["except", "i", 19594, "Word"],
  ["conservative", "j", 19778, "Word"],
  ["eliminate", "v", 19773, "Word"],
  ["host", "n", 19389, "Word"],
  ["hall", "n", 20236, "Word"],
  ["trust", "v", 19482, "Word"],
  ["ocean", "n", 19421, "Word"],
  ["score", "v", 20391, "Word"],
  ["row", "n", 19519, "Word"],
  ["producer", "n", 19679, "Word"],
  ["afford", "v", 19035, "Word"],
  ["meanwhile", "r", 19504, "Word"],
  ["regime", "n", 21101, "Word"],
  ["division", "n", 19772, "Word"],
  ["confirm", "v", 19327, "Word"],
  ["fix", "v", 19349, "Word"],
  ["appeal", "n", 19561, "Word"],
  ["mirror", "n", 20081, "Word"],
  ["tooth", "n", 20515, "Word"],
  ["smart", "j", 19370, "Word"],
  ["length", "n", 19624, "Word"],
  ["entirely", "r", 19075, "Word"],
  ["rely", "v", 19614, "Word"],
  ["topic", "n", 20640, "Word"],
  ["complain", "v", 19102, "Word"],
  ["issue", "v", 19300, "Word"],
  ["variable", "n", 22868, "Word"],
  ["back", "v", 19213, "Word"],
  ["range", "v", 20067, "Word"],
  ["telephone", "n", 19311, "Word"],
  ["perception", "n", 21086, "Word"],
  ["attract", "v", 19197, "Word"],
  ["confidence", "n", 18955, "Word"],
  ["bedroom", "n", 20387, "Word"],
  ["secret", "n", 19125, "Word"],
  ["debt", "n", 19506, "Word"],
  ["rare", "j", 18978, "Word"],
  ["his", "p", 19663, "Word"],
  ["tank", "n", 19158, "Word"],
  ["nurse", "n", 19385, "Word"],
  ["coverage", "n", 19522, "Word"],
  ["opposition", "n", 19847, "Word"],
  ["aside", "r", 19056, "Word"],
  ["anywhere", "r", 18970, "Word"],
  ["bond", "n", 19856, "Word"],
  ["file", "v", 19416, "Word"],
  ["pleasure", "n", 19099, "Word"],
  ["master", "n", 18880, "Word"],
  ["era", "n", 19209, "Word"],
  ["requirement", "n", 20358, "Word"],
  ["check", "n", 18764, "Word"],
  ["stand", "n", 18910, "Word"],
  ["fun", "n", 18984, "Word"],
  ["expectation", "n", 19603, "Word"],
  ["wing", "n", 19028, "Word"],
  ["separate", "j", 19064, "Word"],
  ["now", "c", 19126, "Word"],
  ["clear", "v", 19013, "Word"],
  ["struggle", "n", 19014, "Word"],
  ["mean", "j", 19792, "Word"],
  ["somewhat", "r", 18801, "Word"],
  ["pour", "v", 19300, "Word"],
  ["stir", "v", 20174, "Word"],
  ["judgment", "n", 19315, "Word"],
  ["clean", "v", 18766, "Word"],
  ["except", "c", 18857, "Word"],
  ["beer", "n", 19349, "Word"],
  ["english", "n", 18719, "Word"],
  ["reference", "n", 19559, "Word"],
  ["tear", "n", 19819, "Word"],
  ["doubt", "n", 18542, "Word"],
  ["grant", "v", 18598, "Word"],
  ["seriously", "r", 18328, "Word"],
  ["account", "v", 19403, "Word"],
  ["minister", "n", 19154, "Word"],
  ["totally", "r", 18720, "Word"],
  ["hero", "n", 18348, "Word"],
  ["industrial", "j", 19413, "Word"],
  ["cloud", "n", 19214, "Word"],
  ["stretch", "v", 19014, "Word"],
  ["winner", "n", 19216, "Word"],
  ["volume", "n", 18989, "Word"],
  ["travel", "n", 18634, "Word"],
  ["seed", "n", 19079, "Word"],
  ["surprised", "j", 18822, "Word"],
  ["rest", "v", 18915, "Word"],
  ["fashion", "n", 18483, "Word"],
  ["pepper", "n", 20220, "Word"],
  ["separate", "v", 18251, "Word"],
  ["busy", "j", 18500, "Word"],
  ["intervention", "n", 21021, "Word"],
  ["copy", "n", 18244, "Word"],
  ["tip", "n", 18868, "Word"],
  ["below", "i", 18353, "Word"],
  ["cheap", "j", 18371, "Word"],
  ["aim", "v", 18194, "Word"],
  ["cite", "v", 19011, "Word"],
  ["welfare", "n", 19580, "Word"],
  ["vegetable", "n", 19363, "Word"],
  ["gray", "j", 19408, "Word"],
  ["dish", "n", 18887, "Word"],
  ["beach", "n", 18451, "Word"],
  ["improvement", "n", 18977, "Word"],
  ["everywhere", "r", 18126, "Word"],
  ["opening", "n", 18058, "Word"],
  ["overall", "j", 19031, "Word"],
  ["divide", "v", 18337, "Word"],
  ["initial", "j", 19019, "Word"],
  ["terrible", "j", 18659, "Word"],
  ["oppose", "v", 18484, "Word"],
  ["contemporary", "j", 19293, "Word"],
  ["route", "n", 18313, "Word"],
  ["multiple", "j", 19512, "Word"],
  ["essential", "j", 19030, "Word"],
  ["question", "v", 17924, "Word"],
  ["league", "n", 19766, "Word"],
  ["criminal", "j", 18424, "Word"],
  ["careful", "j", 17885, "Word"],
  ["core", "n", 18571, "Word"],
  ["upper", "j", 18196, "Word"],
  ["rush", "v", 18561, "Word"],
  ["necessarily", "r", 18334, "Word"],
  ["specifically", "r", 18873, "Word"],
  ["tired", "j", 18597, "Word"],
  ["rise", "n", 18055, "Word"],
  ["tie", "n", 17787, "Word"],
  ["employ", "v", 18912, "Word"],
  ["holiday", "n", 18299, "Word"],
  ["dance", "v", 18263, "Word"],
  ["vast", "j", 17836, "Word"],
  ["resolution", "n", 18922, "Word"],
  ["household", "n", 18450, "Word"],
  ["fewer", "d", 18223, "Word"],
  ["abortion", "n", 18925, "Word"],
  ["apart", "r", 17872, "Word"],
  ["witness", "n", 18920, "Word"],
  ["match", "v", 17788, "Word"],
  ["barely", "r", 18533, "Word"],
  ["sector", "n", 19103, "Word"],
  ["representative", "n", 18189, "Word"],
  ["lack", "v", 18215, "Word"],
  ["beneath", "i", 19047, "Word"],
  ["beside", "i", 19952, "Word"],
  ["black", "n", 18441, "Word"],
  ["incident", "n", 17880, "Word"],
  ["limited", "j", 18532, "Word"],
  ["proud", "j", 17841, "Word"],
  ["flow", "n", 18469, "Word"],
  ["faculty", "n", 20104, "Word"],
  ["increased", "j", 18984, "Word"],
  ["waste", "n", 19069, "Word"],
  ["merely", "r", 18094, "Word"],
  ["mass", "n", 18107, "Word"],
  ["emphasize", "v", 18933, "Word"],
  ["experiment", "n", 18440, "Word"],
  ["definitely", "r", 18214, "Word"],
  ["bomb", "n", 18284, "Word"],
  ["enormous", "j", 17482, "Word"],
  ["tone", "n", 17954, "Word"],
  ["liberal", "j", 18228, "Word"],
  ["massive", "j", 17551, "Word"],
  ["engineer", "n", 17991, "Word"],
  ["wheel", "n", 18296, "Word"],
  ["female", "n", 18932, "Word"],
  ["decline", "v", 18058, "Word"],
  ["invest", "v", 18080, "Word"],
  ["promise", "n", 17342, "Word"],
  ["cable", "n", 18277, "Word"],
  ["towards", "i", 18305, "Word"],
  ["expose", "v", 17581, "Word"],
  ["rural", "j", 18665, "Word"],
  ["aids", "n", 19384, "Word"],
  ["jew", "n", 17893, "Word"],
  ["narrow", "j", 17840, "Word"],
  ["cream", "n", 18443, "Word"],
  ["secretary", "n", 17922, "Word"],
  ["gate", "n", 17999, "Word"],
  ["solid", "j", 17675, "Word"],
  ["hill", "n", 18131, "Word"],
  ["typically", "r", 18254, "Word"],
  ["noise", "n", 18024, "Word"],
  ["grass", "n", 18139, "Word"],
  ["unfortunately", "r", 17482, "Word"],
  ["hat", "n", 18198, "Word"],
  ["legislation", "n", 17934, "Word"],
  ["succeed", "v", 17423, "Word"],
  ["either", "d", 17257, "Word"],
  ["celebrate", "v", 17421, "Word"],
  ["achievement", "n", 18998, "Word"],
  ["fishing", "n", 18103, "Word"],
  ["drink", "n", 18227, "Word"],
  ["accuse", "v", 17778, "Word"],
  ["hand", "v", 18231, "Word"],
  ["useful", "j", 18205, "Word"],
  ["land", "v", 17401, "Word"],
  ["secret", "j", 17305, "Word"],
  ["reject", "v", 17656, "Word"],
  ["talent", "n", 17474, "Word"],
  ["taste", "n", 17409, "Word"],
  ["characteristic", "n", 19621, "Word"],
  ["milk", "n", 17812, "Word"],
  ["escape", "v", 17195, "Word"],
  ["cast", "v", 17163, "Word"],
  ["sentence", "n", 17162, "Word"],
  ["unusual", "j", 16997, "Word"],
  ["closely", "r", 17220, "Word"],
  ["convince", "v", 16948, "Word"],
  ["height", "n", 17403, "Word"],
  ["physician", "n", 17707, "Word"],
  ["assess", "v", 19220, "Word"],
  ["sleep", "n", 17882, "Word"],
  ["plenty", "p", 17445, "Word"],
  ["ride", "n", 17654, "Word"],
  ["virtually", "r", 17426, "Word"],
  ["first", "r", 17332, "Word"],
  ["addition", "i", 17641, "Word"],
  ["sharp", "j", 17403, "Word"],
  ["creative", "j", 17597, "Word"],
  ["lower", "v", 17206, "Word"],
  ["behind", "r", 17347, "Word"],
  ["approve", "v", 17329, "Word"],
  ["explanation", "n", 17712, "Word"],
  ["outside", "j", 16797, "Word"],
  ["gay", "j", 17428, "Word"],
  ["campus", "n", 17541, "Word"],
  ["proper", "j", 17024, "Word"],
  ["live", "j", 17365, "Word"],
  ["guilty", "j", 17596, "Word"],
  ["living", "j", 16823, "Word"],
  ["acquire", "v", 17435, "Word"],
  ["compete", "v", 17264, "Word"],
  ["technical", "j", 17594, "Word"],
  ["plus", "i", 17202, "Word"],
  ["mind", "v", 17378, "Word"],
  ["potential", "n", 17523, "Word"],
  ["immigrant", "n", 17615, "Word"],
  ["weak", "j", 16628, "Word"],
  ["illegal", "j", 17258, "Word"],
  ["hi", "u", 18910, "Word"],
  ["alternative", "n", 17197, "Word"],
  ["interaction", "n", 19144, "Word"],
  ["column", "n", 16810, "Word"],
  ["personality", "n", 16933, "Word"],
  ["signal", "n", 16835, "Word"],
  ["curriculum", "n", 19193, "Word"],
  ["list", "v", 17006, "Word"],
  ["honor", "n", 16686, "Word"],
  ["passenger", "n", 16933, "Word"],
  ["assistance", "n", 17396, "Word"],
  ["forever", "r", 16890, "Word"],
  ["fun", "j", 16932, "Word"],
  ["regard", "v", 16890, "Word"],
  ["israeli", "j", 17967, "Word"],
  ["association", "n", 17759, "Word"],
  ["twenty", "m", 17491, "Word"],
  ["knock", "v", 17146, "Word"],
  ["review", "v", 16916, "Word"],
  ["wrap", "v", 17073, "Word"],
  ["lab", "n", 16498, "Word"],
  ["offer", "n", 16612, "Word"],
  ["display", "v", 16806, "Word"],
  ["criticism", "n", 17049, "Word"],
  ["asset", "n", 16977, "Word"],
  ["depression", "n", 16976, "Word"],
  ["spiritual", "j", 17074, "Word"],
  ["musical", "j", 16931, "Word"],
  ["journalist", "n", 16858, "Word"],
  ["prayer", "n", 16296, "Word"],
  ["suspect", "v", 16194, "Word"],
  ["scholar", "n", 17482, "Word"],
  ["warning", "n", 16205, "Word"],
  ["climate", "n", 17189, "Word"],
  ["cheese", "n", 17416, "Word"],
  ["observation", "n", 17500, "Word"],
  ["childhood", "n", 16268, "Word"],
  ["payment", "n", 16704, "Word"],
  ["sir", "n", 17733, "Word"],
  ["permit", "v", 16764, "Word"],
  ["cigarette", "n", 17160, "Word"],
  ["definition", "n", 17499, "Word"],
  ["priority", "n", 16586, "Word"],
  ["bread", "n", 16686, "Word"],
  ["creation", "n", 16967, "Word"],
  ["graduate", "n", 16803, "Word"],
  ["request", "n", 16241, "Word"],
  ["emotion", "n", 16062, "Word"],
  ["scream", "v", 17342, "Word"],
  ["dramatic", "j", 16233, "Word"],
  ["universe", "n", 16596, "Word"],
  ["gap", "n", 16216, "Word"],
  ["excellent", "j", 16094, "Word"],
  ["deeply", "r", 15848, "Word"],
  ["prosecutor", "n", 17260, "Word"],
  ["mark", "n", 15973, "Word"],
  ["green", "n", 16776, "Word"],
  ["lucky", "j", 16550, "Word"],
  ["drag", "v", 16685, "Word"],
  ["airline", "n", 17164, "Word"],
  ["library", "n", 16314, "Word"],
  ["agenda", "n", 16449, "Word"],
  ["recover", "v", 15786, "Word"],
  ["factory", "n", 16240, "Word"],
  ["selection", "n", 16706, "Word"],
  ["primarily", "r", 17015, "Word"],
  ["roof", "n", 16520, "Word"],
  ["unable", "j", 15901, "Word"],
  ["expense", "n", 16274, "Word"],
  ["initiative", "n", 16445, "Word"],
  ["diet", "n", 16933, "Word"],
  ["arrest", "v", 16205, "Word"],
  ["funding", "n", 16282, "Word"],
  ["therapy", "n", 16351, "Word"],
  ["wash", "v", 16314, "Word"],
  ["schedule", "n", 15987, "Word"],
  ["sad", "j", 16163, "Word"],
  ["brief", "j", 15770, "Word"],
  ["housing", "n", 16403, "Word"],
  ["post", "n", 15693, "Word"],
  ["purchase", "v", 16064, "Word"],
  ["existing", "j", 16654, "Word"],
  ["dark", "n", 16865, "Word"],
  ["steel", "n", 15982, "Word"],
  ["regarding", "i", 17220, "Word"],
  ["shout", "v", 17239, "Word"],
  ["remaining", "j", 15992, "Word"],
  ["visual", "j", 17316, "Word"],
  ["fairly", "r", 15701, "Word"],
  ["chip", "n", 16162, "Word"],
  ["violent", "j", 15765, "Word"],
  ["silent", "j", 16802, "Word"],
  ["suppose", "v", 16562, "Word"],
  ["self", "n", 16708, "Word"],
  ["bike", "n", 17306, "Word"],
  ["tea", "n", 16373, "Word"],
  ["perceive", "v", 17180, "Word"],
  ["comparison", "n", 16809, "Word"],
  ["settlement", "n", 16172, "Word"],
  ["layer", "n", 16250, "Word"],
  ["planning", "n", 16434, "Word"],
  ["far", "c", 16006, "Word"],
  ["description", "n", 16415, "Word"],
  ["later", "j", 15734, "Word"],
  ["slow", "v", 15633, "Word"],
  ["slide", "v", 16805, "Word"],
  ["widely", "r", 16000, "Word"],
  ["wedding", "n", 15829, "Word"],
  ["inform", "v", 15725, "Word"],
  ["portion", "n", 15677, "Word"],
  ["territory", "n", 15831, "Word"],
  ["immediate", "j", 15680, "Word"],
  ["opponent", "n", 15803, "Word"],
  ["abandon", "v", 15323, "Word"],
  ["link", "n", 15738, "Word"],
  ["mass", "j", 15939, "Word"],
  ["lake", "n", 15836, "Word"],
  ["transform", "v", 15815, "Word"],
  ["tension", "n", 15614, "Word"],
  ["display", "n", 15575, "Word"],
  ["leading", "j", 15665, "Word"],
  ["bother", "v", 15870, "Word"],
  ["consist", "v", 16648, "Word"],
  ["alcohol", "n", 15877, "Word"],
  ["enable", "v", 16293, "Word"],
  ["bend", "v", 16285, "Word"],
  ["saving", "n", 15917, "Word"],
  ["gain", "n", 15822, "Word"],
  ["desert", "n", 15653, "Word"],
  ["shall", "v", 15997, "Word"],
  ["error", "n", 15980, "Word"],
  ["release", "n", 15540, "Word"],
  ["cop", "n", 16228, "Word"],
  ["arab", "j", 16732, "Word"],
  ["double", "j", 15233, "Word"],
  ["walk", "n", 15632, "Word"],
  ["sand", "n", 15658, "Word"],
  ["spanish", "j", 15512, "Word"],
  ["rule", "v", 15310, "Word"],
  ["hit", "n", 15766, "Word"],
  ["print", "n", 15634, "Word"],
  ["preserve", "v", 15527, "Word"],
  ["passage", "n", 15702, "Word"],
  ["formal", "j", 16078, "Word"],
  ["transition", "n", 16058, "Word"],
  ["existence", "n", 15969, "Word"],
  ["album", "n", 15869, "Word"],
  ["participation", "n", 17187, "Word"],
  ["arrange", "v", 15258, "Word"],
  ["atmosphere", "n", 15310, "Word"],
  ["joint", "j", 15444, "Word"],
  ["reply", "v", 16312, "Word"],
  ["cycle", "n", 15775, "Word"],
  ["opposite", "j", 15554, "Word"],
  ["lock", "v", 15616, "Word"],
  ["whole", "n", 15558, "Word"],
  ["deserve", "v", 14944, "Word"],
  ["consistent", "j", 16334, "Word"],
  ["resistance", "n", 15686, "Word"],
  ["discovery", "n", 15358, "Word"],
  ["tear", "v", 15575, "Word"],
  ["exposure", "n", 15979, "Word"],
  ["pose", "v", 15230, "Word"],
  ["stream", "n", 15400, "Word"],
  ["sale", "n", 15351, "Word"],
  ["trust", "n", 15170, "Word"],
  ["benefit", "v", 15424, "Word"],
  ["pot", "n", 15407, "Word"],
  ["grand", "j", 15659, "Word"],
  ["mine", "p", 15794, "Word"],
  ["hello", "u", 16600, "Word"],
  ["coalition", "n", 15720, "Word"],
  ["tale", "n", 15004, "Word"],
  ["knife", "n", 15792, "Word"],
  ["resolve", "v", 15260, "Word"],
  ["racial", "j", 15687, "Word"],
  ["phase", "n", 16045, "Word"],
  ["present", "n", 15267, "Word"],
  ["joke", "n", 15284, "Word"],
  ["coat", "n", 16010, "Word"],
  ["mexican", "j", 15514, "Word"],
  ["symptom", "n", 15886, "Word"],
  ["contact", "v", 15359, "Word"],
  ["manufacturer", "n", 15741, "Word"],
  ["philosophy", "n", 15746, "Word"],
  ["potato", "n", 15561, "Word"],
  ["interview", "v", 15687, "Word"],
  ["foundation", "n", 15273, "Word"],
  ["quote", "v", 15415, "Word"],
  ["online", "j", 15848, "Word"],
  ["pass", "n", 15599, "Word"],
  ["negotiation", "n", 15680, "Word"],
  ["good", "n", 15259, "Word"],
  ["urge", "v", 14894, "Word"],
  ["occasion", "n", 14792, "Word"],
  ["dust", "n", 15475, "Word"],
  ["breathe", "v", 15813, "Word"],
  ["elect", "v", 15350, "Word"],
  ["investigator", "n", 15258, "Word"],
  ["jacket", "n", 15692, "Word"],
  ["glad", "j", 15556, "Word"],
  ["ordinary", "j", 14776, "Word"],
  ["reduction", "n", 15862, "Word"],
  ["rarely", "r", 14912, "Word"],
  ["shift", "n", 15114, "Word"],
  ["pack", "v", 15169, "Word"],
  ["suicide", "n", 15145, "Word"],
  ["numerous", "j", 15389, "Word"],
  ["touch", "n", 14939, "Word"],
  ["substance", "n", 15909, "Word"],
  ["discipline", "n", 15684, "Word"],
  ["elsewhere", "r", 14884, "Word"],
  ["iron", "n", 15043, "Word"],
  ["practical", "j", 15303, "Word"],
  ["moreover", "r", 16163, "Word"],
  ["passion", "n", 14632, "Word"],
  ["volunteer", "n", 14958, "Word"],
  ["implement", "v", 16124, "Word"],
  ["essentially", "r", 15020, "Word"],
  ["gene", "n", 15377, "Word"],
  ["enforcement", "n", 15052, "Word"],
  ["vs", "i", 16003, "Word"],
  ["sauce", "n", 15903, "Word"],
  ["independence", "n", 15259, "Word"],
  ["marketing", "n", 15259, "Word"],
  ["priest", "n", 14889, "Word"],
  ["amazing", "j", 15124, "Word"],
  ["intense", "j", 14452, "Word"],
  ["advance", "n", 14645, "Word"],
  ["employer", "n", 14846, "Word"],
  ["shock", "n", 14551, "Word"],
  ["inspire", "v", 14771, "Word"],
  ["adjust", "v", 14564, "Word"],
  ["retire", "v", 14979, "Word"],
  ["sure", "r", 14854, "Word"],
  ["visible", "j", 14691, "Word"],
  ["kiss", "v", 16128, "Word"],
  ["illness", "n", 14726, "Word"],
  ["cap", "n", 14672, "Word"],
  ["habit", "n", 14501, "Word"],
  ["competitive", "j", 14938, "Word"],
  ["juice", "n", 15388, "Word"],
  ["congressional", "j", 14936, "Word"],
  ["involvement", "n", 15640, "Word"],
  ["dominate", "v", 14768, "Word"],
  ["previously", "r", 15092, "Word"],
  ["whenever", "r", 14484, "Word"],
  ["transfer", "v", 14686, "Word"],
  ["analyze", "v", 15587, "Word"],
  ["another", "p", 14441, "Word"],
  ["attach", "v", 14552, "Word"],
  ["for", "c", 14594, "Word"],
  ["indian", "n", 15021, "Word"],
  ["disaster", "n", 14684, "Word"],
  ["parking", "n", 14970, "Word"],
  ["prospect", "n", 14521, "Word"],
  ["boss", "n", 14587, "Word"],
  ["complaint", "n", 14551, "Word"],
  ["championship", "n", 16008, "Word"],
  ["coach", "v", 15910, "Word"],
  ["exercise", "v", 14766, "Word"],
  ["fundamental", "j", 15199, "Word"],
  ["severe", "j", 14609, "Word"],
  ["enhance", "v", 15393, "Word"],
  ["mystery", "n", 14211, "Word"],
  ["impose", "v", 14881, "Word"],
  ["poverty", "n", 14891, "Word"],
  ["other", "i", 14165, "Word"],
  ["entry", "n", 14643, "Word"],
  ["fat", "j", 14826, "Word"],
  ["spending", "n", 14965, "Word"],
  ["king", "n", 14445, "Word"],
  ["evaluate", "v", 15780, "Word"],
  ["symbol", "n", 14596, "Word"],
  ["still", "j", 14308, "Word"],
  ["trade", "v", 14624, "Word"],
  ["maker", "n", 14586, "Word"],
  ["mood", "n", 14227, "Word"],
  ["accomplish", "v", 14325, "Word"],
  ["emphasis", "n", 15337, "Word"],
  ["illustrate", "v", 15091, "Word"],
  ["boot", "n", 15033, "Word"],
  ["monitor", "v", 14499, "Word"],
  ["asian", "j", 14873, "Word"],
  ["entertainment", "n", 14615, "Word"],
  ["bean", "n", 14996, "Word"],
  ["evaluation", "n", 16213, "Word"],
  ["creature", "n", 14852, "Word"],
  ["commander", "n", 14425, "Word"],
  ["digital", "j", 15048, "Word"],
  ["arrangement", "n", 14403, "Word"],
  ["concentrate", "v", 14155, "Word"],
  ["total", "n", 14559, "Word"],
  ["usual", "j", 14305, "Word"],
  ["anger", "n", 14241, "Word"],
  ["psychological", "j", 15249, "Word"],
  ["heavily", "r", 13964, "Word"],
  ["peak", "n", 14481, "Word"],
  ["approximately", "r", 15172, "Word"],
  ["increasing", "j", 14886, "Word"],
  ["disorder", "n", 14780, "Word"],
  ["missile", "n", 15147, "Word"],
  ["equally", "r", 14389, "Word"],
  ["vary", "v", 14954, "Word"],
  ["wire", "n", 14310, "Word"],
  ["round", "n", 14539, "Word"],
  ["distribution", "n", 15249, "Word"],
  ["transportation", "n", 14516, "Word"],
  ["holy", "j", 14090, "Word"],
  ["ring", "v", 14757, "Word"],
  ["twin", "n", 14610, "Word"],
  ["command", "n", 14022, "Word"],
  ["commission", "n", 14371, "Word"],
  ["interpretation", "n", 15613, "Word"],
  ["breakfast", "n", 14397, "Word"],
  ["stop", "n", 14117, "Word"],
  ["strongly", "r", 14459, "Word"],
  ["engineering", "n", 14853, "Word"],
  ["luck", "n", 14186, "Word"],
  ["socalled", "j", 14065, "Word"],
  ["constant", "j", 14069, "Word"],
  ["race", "v", 14233, "Word"],
  ["clinic", "n", 14016, "Word"],
  ["veteran", "n", 14366, "Word"],
  ["smell", "v", 15214, "Word"],
  ["tablespoon", "n", 15804, "Word"],
  ["capable", "j", 13933, "Word"],
  ["nervous", "j", 14100, "Word"],
  ["tourist", "n", 13895, "Word"],
  ["light", "v", 14720, "Word"],
  ["toss", "v", 14565, "Word"],
  ["crucial", "j", 14234, "Word"],
  ["bury", "v", 14030, "Word"],
  ["pray", "v", 14006, "Word"],
  ["tomato", "n", 14864, "Word"],
  ["exception", "n", 14136, "Word"],
  ["butter", "n", 14780, "Word"],
  ["deficit", "n", 15008, "Word"],
  ["bathroom", "n", 14725, "Word"],
  ["objective", "n", 15107, "Word"],
  ["block", "v", 13688, "Word"],
  ["electronic", "j", 14057, "Word"],
  ["ally", "n", 14025, "Word"],
  ["journey", "n", 13599, "Word"],
  ["reputation", "n", 13681, "Word"],
  ["mixture", "n", 14856, "Word"],
  ["surely", "r", 13904, "Word"],
  ["tower", "n", 13948, "Word"],
  ["smoke", "n", 14327, "Word"],
  ["confront", "v", 13757, "Word"],
  ["pure", "j", 13671, "Word"],
  ["glance", "v", 16008, "Word"],
  ["dimension", "n", 14965, "Word"],
  ["toy", "n", 13935, "Word"],
  ["prisoner", "n", 13928, "Word"],
  ["fellow", "j", 13585, "Word"],
  ["smooth", "j", 14148, "Word"],
  ["nearby", "j", 13820, "Word"],
  ["peer", "n", 14738, "Word"],
  ["designer", "n", 14210, "Word"],
  ["personnel", "n", 14095, "Word"],
  ["shape", "v", 13859, "Word"],
  ["educator", "n", 15447, "Word"],
  ["relative", "n", 13541, "Word"],
  ["immigration", "n", 14526, "Word"],
  ["belt", "n", 13826, "Word"],
  ["teaspoon", "n", 15767, "Word"],
  ["birthday", "n", 13757, "Word"],
  ["implication", "n", 14791, "Word"],
  ["perfectly", "r", 13671, "Word"],
  ["coast", "n", 13515, "Word"],
  ["supporter", "n", 13970, "Word"],
  ["accompany", "v", 13666, "Word"],
  ["silver", "n", 14033, "Word"],
  ["teenager", "n", 13615, "Word"],
  ["recognition", "n", 14249, "Word"],
  ["retirement", "n", 13964, "Word"],
  ["flag", "n", 13655, "Word"],
  ["recovery", "n", 13703, "Word"],
  ["whisper", "v", 15383, "Word"],
  ["watch", "n", 13996, "Word"],
  ["gentleman", "n", 14648, "Word"],
  ["corn", "n", 13739, "Word"],
  ["moon", "n", 13959, "Word"],
  ["inner", "j", 13545, "Word"],
  ["junior", "j", 13797, "Word"],
  ["rather", "c", 13611, "Word"],
  ["throat", "n", 14681, "Word"],
  ["salary", "n", 13885, "Word"],
  ["swing", "v", 14317, "Word"],
  ["observer", "n", 13806, "Word"],
  ["due", "j", 13252, "Word"],
  ["straight", "j", 13474, "Word"],
  ["publication", "n", 14045, "Word"],
  ["pretty", "j", 13951, "Word"],
  ["crop", "n", 13733, "Word"],
  ["dig", "v", 13633, "Word"],
  ["strike", "n", 13686, "Word"],
  ["permanent", "j", 13390, "Word"],
  ["plant", "v", 13526, "Word"],
  ["phenomenon", "n", 14087, "Word"],
  ["anxiety", "n", 14088, "Word"],
  ["unlike", "i", 13248, "Word"],
  ["wet", "j", 13963, "Word"],
  ["literally", "r", 13425, "Word"],
  ["resist", "v", 13330, "Word"],
  ["convention", "n", 14497, "Word"],
  ["embrace", "v", 13183, "Word"],
  ["supply", "v", 13399, "Word"],
  ["assist", "v", 13748, "Word"],
  ["exhibition", "n", 13987, "Word"],
  ["construct", "v", 14234, "Word"],
  ["viewer", "n", 13479, "Word"],
  ["pan", "n", 14148, "Word"],
  ["consultant", "n", 13646, "Word"],
  ["soon", "c", 13547, "Word"],
  ["line", "v", 13373, "Word"],
  ["administrator", "n", 14058, "Word"],
  ["date", "v", 13231, "Word"],
  ["occasionally", "r", 13321, "Word"],
  ["mayor", "n", 13903, "Word"],
  ["consideration", "n", 14047, "Word"],
  ["ceo", "n", 13956, "Word"],
  ["secure", "v", 13181, "Word"],
  ["pink", "j", 13849, "Word"],
  ["smoke", "v", 13438, "Word"],
  ["estimate", "n", 13718, "Word"],
  ["buck", "n", 13693, "Word"],
  ["historic", "j", 13507, "Word"],
  ["poem", "n", 13702, "Word"],
  ["grandmother", "n", 13670, "Word"],
  ["bind", "v", 13109, "Word"],
  ["fifth", "m", 13150, "Word"],
  ["constantly", "r", 13033, "Word"],
  ["enterprise", "n", 13542, "Word"],
  ["favor", "v", 13235, "Word"],
  ["testing", "n", 13725, "Word"],
  ["stomach", "n", 13845, "Word"],
  ["apparent", "j", 13434, "Word"],
  ["weigh", "v", 13051, "Word"],
  ["install", "v", 13584, "Word"],
  ["sensitive", "j", 13115, "Word"],
  ["suggestion", "n", 13145, "Word"],
  ["mail", "n", 13024, "Word"],
  ["recipe", "n", 13840, "Word"],
  ["reasonable", "j", 13130, "Word"],
  ["preparation", "n", 13553, "Word"],
  ["wooden", "j", 13518, "Word"],
  ["elementary", "j", 13831, "Word"],
  ["concert", "n", 13270, "Word"],
  ["aggressive", "j", 13191, "Word"],
  ["false", "j", 12871, "Word"],
  ["intention", "n", 13207, "Word"],
  ["channel", "n", 12975, "Word"],
  ["extreme", "j", 13064, "Word"],
  ["tube", "n", 13364, "Word"],
  ["drawing", "n", 13377, "Word"],
  ["protein", "n", 14036, "Word"],
  ["quit", "v", 13045, "Word"],
  ["absence", "n", 13597, "Word"],
  ["roll", "n", 13053, "Word"],
  ["latin", "j", 13797, "Word"],
  ["rapidly", "r", 13066, "Word"],
  ["jail", "n", 13324, "Word"],
  ["comment", "v", 12930, "Word"],
  ["diversity", "n", 13872, "Word"],
  ["honest", "j", 12916, "Word"],
  ["palestinian", "j", 14008, "Word"],
  ["pace", "n", 12891, "Word"],
  ["employment", "n", 13673, "Word"],
  ["speaker", "n", 12889, "Word"],
  ["impression", "n", 12746, "Word"],
  ["essay", "n", 13802, "Word"],
  ["respondent", "n", 15057, "Word"],
  ["giant", "j", 12963, "Word"],
  ["cake", "n", 13336, "Word"],
  ["historian", "n", 13437, "Word"],
  ["negotiate", "v", 13043, "Word"],
  ["restore", "v", 12814, "Word"],
  ["substantial", "j", 13376, "Word"],
  ["pop", "v", 13123, "Word"],
  ["particular", "r", 13259, "Word"],
  ["specialist", "n", 13041, "Word"],
  ["origin", "n", 13654, "Word"],
  ["approval", "n", 12795, "Word"],
  ["mine", "n", 12824, "Word"],
  ["quietly", "r", 13503, "Word"],
  ["advise", "v", 12709, "Word"],
  ["conventional", "j", 13209, "Word"],
  ["drop", "n", 12757, "Word"],
  ["count", "n", 13007, "Word"],
  ["depth", "n", 12927, "Word"],
  ["wealth", "n", 12886, "Word"],
  ["disability", "n", 14197, "Word"],
  ["shell", "n", 12957, "Word"],
  ["general", "r", 13256, "Word"],
  ["criticize", "v", 12906, "Word"],
  ["fast", "j", 12738, "Word"],
  ["professional", "n", 13402, "Word"],
  ["effectively", "r", 13277, "Word"],
  ["biological", "j", 13233, "Word"],
  ["pack", "n", 13238, "Word"],
  ["onion", "n", 13769, "Word"],
  ["deputy", "n", 12972, "Word"],
  ["flat", "j", 12899, "Word"],
  ["brand", "n", 13103, "Word"],
  ["assure", "v", 12542, "Word"],
  ["mad", "j", 12903, "Word"],
  ["award", "n", 12874, "Word"],
  ["criteria", "n", 14160, "Word"],
  ["dealer", "n", 12999, "Word"],
  ["via", "i", 13064, "Word"],
  ["alternative", "j", 13289, "Word"],
  ["utility", "n", 13150, "Word"],
  ["precisely", "r", 12878, "Word"],
  ["arise", "v", 13351, "Word"],
  ["armed", "j", 12966, "Word"],
  ["nevertheless", "r", 13190, "Word"],
  ["highway", "n", 12649, "Word"],
  ["clinical", "j", 13593, "Word"],
  ["routine", "n", 12576, "Word"],
  ["schedule", "v", 12881, "Word"],
  ["wage", "n", 13033, "Word"],
  ["normally", "r", 12402, "Word"],
  ["phrase", "n", 12558, "Word"],
  ["ingredient", "n", 13382, "Word"],
  ["stake", "n", 12603, "Word"],
  ["muslim", "j", 13147, "Word"],
  ["dream", "v", 12887, "Word"],
  ["fiber", "n", 13606, "Word"],
  ["activist", "n", 12737, "Word"],
  ["islamic", "j", 13323, "Word"],
  ["snap", "v", 13362, "Word"],
  ["terrorism", "n", 13806, "Word"],
  ["refugee", "n", 13010, "Word"],
  ["incorporate", "v", 13310, "Word"],
  ["hip", "n", 12863, "Word"],
  ["ultimate", "j", 12542, "Word"],
  ["switch", "v", 12508, "Word"],
  ["corporation", "n", 12612, "Word"],
  ["valuable", "j", 12628, "Word"],
  ["assumption", "n", 13434, "Word"],
  ["gear", "n", 12872, "Word"],
  ["graduate", "v", 12480, "Word"],
  ["barrier", "n", 12772, "Word"],
  ["minor", "j", 12346, "Word"],
  ["provision", "n", 13256, "Word"],
  ["killer", "n", 12631, "Word"],
  ["assign", "v", 12836, "Word"],
  ["gang", "n", 12662, "Word"],
  ["developing", "j", 13171, "Word"],
  ["classic", "j", 12610, "Word"],
  ["chemical", "n", 12673, "Word"],
  ["wave", "v", 13567, "Word"],
  ["label", "n", 12497, "Word"],
  ["teen", "n", 12663, "Word"],
  ["index", "n", 12751, "Word"],
  ["vacation", "n", 12424, "Word"],
  ["advocate", "n", 12565, "Word"],
  ["draft", "n", 12684, "Word"],
  ["extraordinary", "j", 12356, "Word"],
  ["heaven", "n", 12461, "Word"],
  ["rough", "j", 12365, "Word"],
  ["yell", "v", 13131, "Word"],
  ["pregnant", "j", 12329, "Word"],
  ["distant", "j", 12542, "Word"],
  ["drama", "n", 12393, "Word"],
  ["satellite", "n", 12438, "Word"],
  ["personally", "r", 12317, "Word"],
  ["wonder", "n", 12265, "Word"],
  ["clock", "n", 12395, "Word"],
  ["chocolate", "n", 12682, "Word"],
  ["italian", "j", 12384, "Word"],
  ["canadian", "j", 12820, "Word"],
  ["ceiling", "n", 12668, "Word"],
  ["sweep", "v", 12431, "Word"],
  ["advertising", "n", 12601, "Word"],
  ["universal", "j", 12590, "Word"],
  ["spin", "v", 12673, "Word"],
  ["house", "v", 12268, "Word"],
  ["button", "n", 12552, "Word"],
  ["bell", "n", 12421, "Word"],
  ["rank", "n", 12236, "Word"],
  ["darkness", "n", 13275, "Word"],
  ["ahead", "i", 12125, "Word"],
  ["clothing", "n", 12078, "Word"],
  ["super", "j", 12586, "Word"],
  ["yield", "v", 12634, "Word"],
  ["fence", "n", 12390, "Word"],
  ["portrait", "n", 12340, "Word"],
  ["paint", "n", 12673, "Word"],
  ["survival", "n", 12335, "Word"],
  ["roughly", "r", 12200, "Word"],
  ["lawsuit", "n", 12656, "Word"],
  ["bottom", "j", 12169, "Word"],
  ["testimony", "n", 12679, "Word"],
  ["bunch", "n", 12225, "Word"],
  ["beat", "n", 13118, "Word"],
  ["wind", "v", 12054, "Word"],
  ["found", "v", 12376, "Word"],
  ["burden", "n", 12054, "Word"],
  ["react", "v", 11874, "Word"],
  ["chamber", "n", 12034, "Word"],
  ["furniture", "n", 12461, "Word"],
  ["cooperation", "n", 12833, "Word"],
  ["string", "n", 11974, "Word"],
  ["ceremony", "n", 11923, "Word"],
  ["communicate", "v", 12179, "Word"],
  ["taste", "v", 12397, "Word"],
  ["cheek", "n", 13411, "Word"],
  ["lost", "j", 11886, "Word"],
  ["profile", "n", 12089, "Word"],
  ["mechanism", "n", 12948, "Word"],
  ["disagree", "v", 12280, "Word"],
  ["like", "r", 13238, "Word"],
  ["penalty", "n", 12310, "Word"],
  ["match", "n", 12025, "Word"],
  ["ie", "r", 13724, "Word"],
  ["advance", "v", 12056, "Word"],
  ["resort", "n", 12379, "Word"],
  ["destruction", "n", 12356, "Word"],
  ["bear", "n", 12194, "Word"],
  ["unlikely", "j", 11869, "Word"],
  ["tissue", "n", 12342, "Word"],
  ["constitutional", "j", 12567, "Word"],
  ["pant", "n", 12457, "Word"],
  ["stranger", "n", 12261, "Word"],
  ["infection", "n", 12402, "Word"],
  ["cabinet", "n", 11846, "Word"],
  ["broken", "j", 12097, "Word"],
  ["apple", "n", 12172, "Word"],
  ["electric", "j", 12007, "Word"],
  ["proceed", "v", 11808, "Word"],
  ["track", "v", 11773, "Word"],
  ["bet", "v", 12079, "Word"],
  ["literary", "j", 12801, "Word"],
  ["virus", "n", 12106, "Word"],
  ["stupid", "j", 12467, "Word"],
  ["dispute", "n", 12203, "Word"],
  ["fortune", "n", 11815, "Word"],
  ["strategic", "j", 12347, "Word"],
  ["assistant", "n", 11828, "Word"],
  ["overcome", "v", 11801, "Word"],
  ["remarkable", "j", 11683, "Word"],
  ["occupy", "v", 11756, "Word"],
  ["statistics", "n", 12086, "Word"],
  ["shopping", "n", 11957, "Word"],
  ["cousin", "n", 12155, "Word"],
  ["encounter", "v", 11974, "Word"],
  ["wipe", "v", 12435, "Word"],
  ["initially", "r", 12022, "Word"],
  ["blind", "j", 12005, "Word"],
  ["white", "n", 11815, "Word"],
  ["port", "n", 11893, "Word"],
  ["honor", "v", 11653, "Word"],
  ["electricity", "n", 11954, "Word"],
  ["genetic", "j", 12069, "Word"],
  ["adviser", "n", 12112, "Word"],
  ["pay", "n", 11883, "Word"],
  ["spokesman", "n", 12689, "Word"],
  ["retain", "v", 12041, "Word"],
  ["latter", "d", 12527, "Word"],
  ["incentive", "n", 12105, "Word"],
  ["slave", "n", 11863, "Word"],
  ["chemical", "j", 12006, "Word"],
  ["translate", "v", 11722, "Word"],
  ["accurate", "j", 11842, "Word"],
  ["whereas", "c", 12668, "Word"],
  ["terror", "n", 12342, "Word"],
  ["though", "r", 12073, "Word"],
  ["expansion", "n", 12054, "Word"],
  ["elite", "n", 12329, "Word"],
  ["olympic", "j", 13072, "Word"],
  ["dirt", "n", 12063, "Word"],
  ["odd", "j", 11849, "Word"],
  ["rice", "n", 11944, "Word"],
  ["bullet", "n", 11706, "Word"],
  ["tight", "j", 11641, "Word"],
  ["bible", "n", 11539, "Word"],
  ["chart", "n", 11861, "Word"],
  ["solar", "j", 12121, "Word"],
  ["decline", "n", 11956, "Word"],
  ["conservative", "n", 11941, "Word"],
  ["process", "v", 11940, "Word"],
  ["square", "j", 11630, "Word"],
  ["stick", "n", 11795, "Word"],
  ["concentration", "n", 12146, "Word"],
  ["complicated", "j", 11306, "Word"],
  ["gently", "r", 12233, "Word"],
  ["champion", "n", 12247, "Word"],
  ["scenario", "n", 11747, "Word"],
  ["telescope", "n", 12754, "Word"],
  ["reflection", "n", 11882, "Word"],
  ["revolution", "n", 11800, "Word"],
  ["strip", "n", 11543, "Word"],
  ["interpret", "v", 12321, "Word"],
  ["friendly", "j", 11360, "Word"],
  ["tournament", "n", 12684, "Word"],
  ["fiction", "n", 11701, "Word"],
  ["detect", "v", 11776, "Word"],
  ["balance", "v", 11536, "Word"],
  ["likely", "r", 11551, "Word"],
  ["tremendous", "j", 11678, "Word"],
  ["lifetime", "n", 11293, "Word"],
  ["recommendation", "n", 11824, "Word"],
  ["flow", "v", 11365, "Word"],
  ["senator", "n", 12091, "Word"],
  ["market", "v", 11650, "Word"],
  ["hunting", "n", 12100, "Word"],
  ["salad", "n", 12103, "Word"],
  ["guarantee", "v", 11430, "Word"],
  ["innocent", "j", 11530, "Word"],
  ["boundary", "n", 12082, "Word"],
  ["pause", "v", 12583, "Word"],
  ["remote", "j", 11346, "Word"],
  ["satisfaction", "n", 12237, "Word"],
  ["journal", "n", 11849, "Word"],
  ["bench", "n", 11666, "Word"],
  ["lover", "n", 11499, "Word"],
  ["raw", "j", 11310, "Word"],
  ["awareness", "n", 12013, "Word"],
  ["surprising", "j", 11327, "Word"],
  ["withdraw", "v", 11352, "Word"],
  ["general", "n", 11344, "Word"],
  ["deck", "n", 11830, "Word"],
  ["similarly", "r", 12180, "Word"],
  ["newly", "r", 11388, "Word"],
  ["pole", "n", 11446, "Word"],
  ["testify", "v", 11843, "Word"],
  ["mode", "n", 12107, "Word"],
  ["dialogue", "n", 11706, "Word"],
  ["imply", "v", 12061, "Word"],
  ["naturally", "r", 11304, "Word"],
  ["mutual", "j", 11566, "Word"],
  ["founder", "n", 11570, "Word"],
  ["top", "i", 11397, "Word"],
  ["advanced", "j", 11626, "Word"],
  ["pride", "n", 11107, "Word"],
  ["dismiss", "v", 11160, "Word"],
  ["aircraft", "n", 11591, "Word"],
  ["delivery", "n", 11323, "Word"],
  ["mainly", "r", 11367, "Word"],
  ["bake", "v", 12253, "Word"],
  ["freeze", "v", 11596, "Word"],
  ["platform", "n", 11209, "Word"],
  ["finance", "n", 11501, "Word"],
  ["sink", "v", 11592, "Word"],
  ["attractive", "j", 11119, "Word"],
  ["respect", "v", 11083, "Word"],
  ["diverse", "j", 12015, "Word"],
  ["relevant", "j", 12212, "Word"],
  ["ideal", "j", 11609, "Word"],
  ["joy", "n", 11171, "Word"],
  ["worth", "n", 11237, "Word"],
  ["regularly", "r", 11329, "Word"],
  ["working", "j", 11200, "Word"],
  ["singer", "n", 11277, "Word"],
  ["evolve", "v", 11442, "Word"],
  ["shooting", "n", 11633, "Word"],
  ["partly", "r", 11132, "Word"],
  ["unknown", "j", 11095, "Word"],
  ["assistant", "j", 11391, "Word"],
  ["offense", "n", 11680, "Word"],
  ["counter", "n", 11676, "Word"],
  ["dna", "n", 11580, "Word"],
  ["smell", "n", 12123, "Word"],
  ["potentially", "r", 11387, "Word"],
  ["transfer", "n", 11830, "Word"],
  ["thirty", "m", 11637, "Word"],
  ["justify", "v", 11409, "Word"],
  ["protest", "n", 11181, "Word"],
  ["crash", "n", 11250, "Word"],
  ["craft", "n", 11559, "Word"],
  ["treaty", "n", 11689, "Word"],
  ["terrorist", "n", 12169, "Word"],
  ["insight", "n", 11591, "Word"],
  ["possess", "v", 11474, "Word"],
  ["politically", "r", 11324, "Word"],
  ["tap", "v", 11279, "Word"],
  ["lie", "n", 11199, "Word"],
  ["extensive", "j", 11580, "Word"],
  ["episode", "n", 11070, "Word"],
  ["double", "v", 11195, "Word"],
  ["swim", "v", 11342, "Word"],
  ["tire", "v", 11494, "Word"],
  ["fault", "n", 11116, "Word"],
  ["loose", "j", 11299, "Word"],
  ["free", "v", 10898, "Word"],
  ["shortly", "r", 10864, "Word"],
  ["originally", "r", 10995, "Word"],
  ["considerable", "j", 11544, "Word"],
  ["prior", "i", 11746, "Word"],
  ["intellectual", "j", 11842, "Word"],
  ["mix", "n", 11247, "Word"],
  ["assault", "n", 11039, "Word"],
  ["relax", "v", 11202, "Word"],
  ["stair", "n", 12171, "Word"],
  ["adventure", "n", 11031, "Word"],
  ["external", "j", 12260, "Word"],
  ["proof", "n", 10775, "Word"],
  ["confident", "j", 10831, "Word"],
  ["headquarters", "n", 11135, "Word"],
  ["sudden", "j", 11370, "Word"],
  ["dirty", "j", 11112, "Word"],
  ["violation", "n", 11154, "Word"],
  ["tongue", "n", 11674, "Word"],
  ["license", "n", 10943, "Word"],
  ["hold", "n", 10984, "Word"],
  ["shelter", "n", 10891, "Word"],
  ["rub", "v", 11695, "Word"],
  ["controversy", "n", 11054, "Word"],
  ["entrance", "n", 11121, "Word"],
  ["favorite", "n", 11134, "Word"],
  ["practice", "v", 10858, "Word"],
  ["properly", "r", 10783, "Word"],
  ["fade", "v", 11373, "Word"],
  ["defensive", "j", 11442, "Word"],
  ["tragedy", "n", 11039, "Word"],
  ["net", "n", 10918, "Word"],
  ["characterize", "v", 11799, "Word"],
  ["funeral", "n", 11086, "Word"],
  ["profession", "n", 11469, "Word"],
  ["alter", "v", 11106, "Word"],
  ["spot", "v", 11143, "Word"],
  ["constitute", "v", 11945, "Word"],
  ["establishment", "n", 11355, "Word"],
  ["squeeze", "v", 11280, "Word"],
  ["imagination", "n", 10913, "Word"],
  ["target", "v", 10960, "Word"],
  ["mask", "n", 11196, "Word"],
  ["convert", "v", 10990, "Word"],
  ["comprehensive", "j", 11602, "Word"],
  ["prominent", "j", 10948, "Word"],
  ["presentation", "n", 11372, "Word"],
  ["regardless", "r", 11042, "Word"],
  ["easy", "r", 10790, "Word"],
  ["load", "v", 10736, "Word"],
  ["stable", "j", 10964, "Word"],
  ["introduction", "n", 11483, "Word"],
  ["appeal", "v", 10745, "Word"],
  ["pretend", "v", 11330, "Word"],
  ["not", "c", 11295, "Word"],
  ["elderly", "j", 10976, "Word"],
  ["representation", "n", 12063, "Word"],
  ["deer", "n", 11665, "Word"],
  ["split", "v", 10593, "Word"],
  ["violate", "v", 10822, "Word"],
  ["partnership", "n", 11115, "Word"],
  ["pollution", "n", 11396, "Word"],
  ["emission", "n", 11688, "Word"],
  ["steady", "j", 10715, "Word"],
  ["vital", "j", 10866, "Word"],
  ["neither", "d", 10587, "Word"],
  ["fate", "n", 10521, "Word"],
  ["earnings", "n", 11324, "Word"],
  ["oven", "n", 11454, "Word"],
  ["distinction", "n", 11383, "Word"],
  ["segment", "n", 10850, "Word"],
  ["nowhere", "r", 10658, "Word"],
  ["poet", "n", 10840, "Word"],
  ["mere", "j", 10778, "Word"],
  ["exciting", "j", 10595, "Word"],
  ["variation", "n", 11403, "Word"],
  ["comfort", "n", 10674, "Word"],
  ["radical", "j", 10924, "Word"],
  ["stress", "v", 10951, "Word"],
  ["adapt", "v", 10987, "Word"],
  ["irish", "j", 10833, "Word"],
  ["honey", "n", 11009, "Word"],
  ["correspondent", "n", 12008, "Word"],
  ["pale", "j", 11598, "Word"],
  ["musician", "n", 10580, "Word"],
  ["significance", "n", 11520, "Word"],
  ["load", "n", 10869, "Word"],
  ["round", "j", 10771, "Word"],
  ["vessel", "n", 10920, "Word"],
  ["storage", "n", 10893, "Word"],
  ["flee", "v", 10508, "Word"],
  ["mmhmm", "u", 13755, "Word"],
  ["leather", "n", 11155, "Word"],
  ["distribute", "v", 10747, "Word"],
  ["evolution", "n", 11066, "Word"],
  ["ill", "j", 10446, "Word"],
  ["tribe", "n", 10855, "Word"],
  ["shelf", "n", 10741, "Word"],
  ["can", "n", 10718, "Word"],
  ["grandfather", "n", 11003, "Word"],
  ["lawn", "n", 10815, "Word"],
  ["buyer", "n", 10953, "Word"],
  ["dining", "n", 10843, "Word"],
  ["wisdom", "n", 10561, "Word"],
  ["council", "n", 10695, "Word"],
  ["vulnerable", "j", 10397, "Word"],
  ["instance", "n", 10885, "Word"],
  ["garlic", "n", 11449, "Word"],
  ["capability", "n", 10906, "Word"],
  ["poetry", "n", 10671, "Word"],
  ["celebrity", "n", 10756, "Word"],
  ["gradually", "r", 10537, "Word"],
  ["stability", "n", 10901, "Word"],
  ["doubt", "v", 10352, "Word"],
  ["fantasy", "n", 10448, "Word"],
  ["scared", "j", 10752, "Word"],
  ["guide", "v", 10524, "Word"],
  ["plot", "n", 10484, "Word"],
  ["framework", "n", 11710, "Word"],
  ["gesture", "n", 10739, "Word"],
  ["depending", "i", 10461, "Word"],
  ["ongoing", "j", 10721, "Word"],
  ["psychology", "n", 11316, "Word"],
  ["since", "r", 10334, "Word"],
  ["counselor", "n", 12170, "Word"],
  ["witness", "v", 10178, "Word"],
  ["chapter", "n", 10579, "Word"],
  ["fellow", "n", 10339, "Word"],
  ["divorce", "n", 10322, "Word"],
  ["owe", "v", 10141, "Word"],
  ["pipe", "n", 10432, "Word"],
  ["athletic", "j", 10803, "Word"],
  ["slight", "j", 10500, "Word"],
  ["math", "n", 10430, "Word"],
  ["shade", "n", 10677, "Word"],
  ["tail", "n", 10663, "Word"],
  ["sustain", "v", 10550, "Word"],
  ["mount", "v", 10303, "Word"],
  ["obligation", "n", 10538, "Word"],
  ["angle", "n", 10456, "Word"],
  ["palm", "n", 11014, "Word"],
  ["differ", "v", 11222, "Word"],
  ["custom", "n", 10408, "Word"],
  ["store", "v", 10446, "Word"],
  ["economist", "n", 10625, "Word"],
  ["fifteen", "m", 10739, "Word"],
  ["soup", "n", 10571, "Word"],
  ["celebration", "n", 10209, "Word"],
  ["efficient", "j", 10452, "Word"],
  ["damage", "v", 10163, "Word"],
  ["composition", "n", 10961, "Word"],
  ["satisfy", "v", 10194, "Word"],
  ["pile", "n", 10594, "Word"],
  ["briefly", "r", 10125, "Word"],
  ["carbon", "n", 10799, "Word"],
  ["closer", "j", 9976, "Word"],
  ["consume", "v", 10308, "Word"],
  ["scheme", "n", 10428, "Word"],
  ["crack", "n", 10330, "Word"],
  ["frequency", "n", 11284, "Word"],
  ["tobacco", "n", 10600, "Word"],
  ["survivor", "n", 10317, "Word"],
  ["besides", "r", 10537, "Word"],
  ["in", "c", 9996, "Word"],
  ["psychologist", "n", 10698, "Word"],
  ["wealthy", "j", 10054, "Word"],
  ["galaxy", "n", 11159, "Word"],
  ["given", "j", 10622, "Word"],
  ["fund", "v", 10218, "Word"],
  ["ski", "n", 11656, "Word"],
  ["limitation", "n", 11038, "Word"],
  ["ok", "j", 10733, "Word"],
  ["trace", "v", 10033, "Word"],
  ["appointment", "n", 9993, "Word"],
  ["preference", "n", 10833, "Word"],
  ["meter", "n", 10246, "Word"],
  ["explosion", "n", 10148, "Word"],
  ["arrest", "n", 10250, "Word"],
  ["publicly", "r", 10169, "Word"],
  ["incredible", "j", 10342, "Word"],
  ["fighter", "n", 10306, "Word"],
  ["rapid", "j", 10427, "Word"],
  ["admission", "n", 10356, "Word"],
  ["hunter", "n", 10839, "Word"],
  ["educate", "v", 10171, "Word"],
  ["painful", "j", 9896, "Word"],
  ["friendship", "n", 10130, "Word"],
  ["aide", "n", 10335, "Word"],
  ["infant", "n", 10205, "Word"],
  ["calculate", "v", 10485, "Word"],
  ["fifty", "m", 10524, "Word"],
  ["rid", "v", 10104, "Word"],
  ["porch", "n", 10894, "Word"],
  ["tendency", "n", 10642, "Word"],
  ["uniform", "n", 10193, "Word"],
  ["formation", "n", 10553, "Word"],
  ["scholarship", "n", 10305, "Word"],
  ["reservation", "n", 10215, "Word"],
  ["efficiency", "n", 10579, "Word"],
  ["waste", "v", 9956, "Word"],
  ["qualify", "v", 10090, "Word"],
  ["mall", "n", 10189, "Word"],
  ["derive", "v", 10913, "Word"],
  ["scandal", "n", 10403, "Word"],
  ["pc", "n", 11072, "Word"],
  ["helpful", "j", 10120, "Word"],
  ["impress", "v", 9896, "Word"],
  ["heel", "n", 10288, "Word"],
  ["resemble", "v", 10050, "Word"],
  ["privacy", "n", 9956, "Word"],
  ["fabric", "n", 10320, "Word"],
  ["surprise", "v", 10023, "Word"],
  ["contest", "n", 10002, "Word"],
  ["proportion", "n", 10521, "Word"],
  ["guideline", "n", 10303, "Word"],
  ["rifle", "n", 10204, "Word"],
  ["maintenance", "n", 10167, "Word"],
  ["conviction", "n", 9834, "Word"],
  ["trick", "n", 10041, "Word"],
  ["organic", "j", 10572, "Word"],
  ["tent", "n", 10110, "Word"],
  ["examination", "n", 10722, "Word"],
  ["publisher", "n", 10282, "Word"],
  ["strengthen", "v", 10159, "Word"],
  ["french", "n", 9845, "Word"],
  ["proposed", "j", 10288, "Word"],
  ["myth", "n", 10161, "Word"],
  ["sophisticated", "j", 9886, "Word"],
  ["cow", "n", 10032, "Word"],
  ["etc", "r", 10112, "Word"],
  ["standing", "n", 9691, "Word"],
  ["asleep", "j", 10684, "Word"],
  ["tennis", "n", 9985, "Word"],
  ["nerve", "n", 9933, "Word"],
  ["barrel", "n", 9951, "Word"],
  ["bombing", "n", 10462, "Word"],
  ["membership", "n", 10273, "Word"],
  ["ratio", "n", 10457, "Word"],
  ["menu", "n", 10238, "Word"],
  ["purchase", "n", 10001, "Word"],
  ["controversial", "j", 9918, "Word"],
  ["desperate", "j", 9795, "Word"],
  ["rate", "v", 10244, "Word"],
  ["lifestyle", "n", 9904, "Word"],
  ["humor", "n", 9654, "Word"],
  ["loud", "j", 10324, "Word"],
  ["glove", "n", 10218, "Word"],
  ["suspect", "n", 10204, "Word"],
  ["sufficient", "j", 10275, "Word"],
  ["narrative", "n", 10954, "Word"],
  ["photographer", "n", 9739, "Word"],
  ["helicopter", "n", 9986, "Word"],
  ["catholic", "n", 9955, "Word"],
  ["modest", "j", 9746, "Word"],
  ["provider", "n", 10196, "Word"],
  ["delay", "n", 9868, "Word"],
  ["agricultural", "j", 10535, "Word"],
  ["explode", "v", 9821, "Word"],
  ["stroke", "n", 9891, "Word"],
  ["scope", "n", 10115, "Word"],
  ["punishment", "n", 9986, "Word"],
  ["handful", "n", 9735, "Word"],
  ["badly", "r", 9652, "Word"],
  ["horizon", "n", 9857, "Word"],
  ["curious", "j", 9738, "Word"],
  ["downtown", "j", 10172, "Word"],
  ["girlfriend", "n", 9832, "Word"],
  ["prompt", "v", 9741, "Word"],
  ["request", "v", 9619, "Word"],
  ["cholesterol", "n", 10641, "Word"],
  ["absorb", "v", 9762, "Word"],
  ["adjustment", "n", 10309, "Word"],
  ["taxpayer", "n", 10113, "Word"],
  ["eager", "j", 9627, "Word"],
  ["principal", "n", 9920, "Word"],
  ["detailed", "j", 10006, "Word"],
  ["motivation", "n", 10566, "Word"],
  ["assignment", "n", 9874, "Word"],
  ["restriction", "n", 9928, "Word"],
  ["across", "r", 9825, "Word"],
  ["palestinian", "n", 10484, "Word"],
  ["laboratory", "n", 9972, "Word"],
  ["workshop", "n", 9926, "Word"],
  ["differently", "r", 9565, "Word"],
  ["auto", "n", 10006, "Word"],
  ["romantic", "j", 9561, "Word"],
  ["cotton", "n", 9734, "Word"],
  ["motor", "n", 9977, "Word"],
  ["sue", "v", 9797, "Word"],
  ["flavor", "n", 10143, "Word"],
  ["overlook", "v", 9553, "Word"],
  ["float", "v", 9982, "Word"],
  ["undergo", "v", 9826, "Word"],
  ["sequence", "n", 10041, "Word"],
  ["demonstration", "n", 9729, "Word"],
  ["jet", "n", 9690, "Word"],
  ["orange", "j", 9755, "Word"],
  ["consumption", "n", 10162, "Word"],
  ["assert", "v", 10125, "Word"],
  ["blade", "n", 10394, "Word"],
  ["temporary", "j", 9465, "Word"],
  ["medication", "n", 9789, "Word"],
  ["print", "v", 9508, "Word"],
  ["cabin", "n", 9955, "Word"],
  ["bite", "v", 9982, "Word"],
  ["relative", "j", 10283, "Word"],
  ["edition", "n", 9638, "Word"],
  ["valley", "n", 9708, "Word"],
  ["yours", "p", 10004, "Word"],
  ["pitch", "n", 9586, "Word"],
  ["pine", "n", 9824, "Word"],
  ["brilliant", "j", 9493, "Word"],
  ["versus", "i", 10007, "Word"],
  ["manufacturing", "n", 9822, "Word"],
  ["risk", "v", 9285, "Word"],
  ["christian", "n", 9751, "Word"],
  ["complex", "n", 9573, "Word"],
  ["absolute", "j", 9622, "Word"],
  ["chef", "n", 10131, "Word"],
  ["discrimination", "n", 9872, "Word"],
  ["offensive", "j", 9902, "Word"],
  ["german", "n", 9586, "Word"],
  ["suit", "v", 9477, "Word"],
  ["boom", "n", 9457, "Word"],
  ["register", "v", 9389, "Word"],
  ["appoint", "v", 9541, "Word"],
  ["heritage", "n", 9670, "Word"],
  ["god", "n", 9694, "Word"],
  ["terrorist", "j", 10390, "Word"],
  ["dominant", "j", 10143, "Word"],
  ["successfully", "r", 9728, "Word"],
  ["shit", "n", 11047, "Word"],
  ["lemon", "n", 10095, "Word"],
  ["hungry", "j", 9653, "Word"],
  ["sense", "v", 9697, "Word"],
  ["dry", "v", 9678, "Word"],
  ["wander", "v", 9822, "Word"],
  ["submit", "v", 9507, "Word"],
  ["economics", "n", 9758, "Word"],
  ["naked", "j", 9897, "Word"],
  ["anticipate", "v", 9345, "Word"],
  ["nut", "n", 9605, "Word"],
  ["legacy", "n", 9461, "Word"],
  ["extension", "n", 9672, "Word"],
  ["shrug", "v", 10689, "Word"],
  ["fly", "n", 9689, "Word"],
  ["battery", "n", 9793, "Word"],
  ["arrival", "n", 9302, "Word"],
  ["legitimate", "j", 9543, "Word"],
  ["orientation", "n", 10613, "Word"],
  ["inflation", "n", 9758, "Word"],
  ["cope", "v", 9952, "Word"],
  ["flame", "n", 9716, "Word"],
  ["cluster", "n", 9821, "Word"],
  ["host", "v", 9750, "Word"],
  ["wound", "n", 9404, "Word"],
  ["dependent", "j", 10029, "Word"],
  ["shower", "n", 9624, "Word"],
  ["institutional", "j", 10397, "Word"],
  ["depict", "v", 9721, "Word"],
  ["operating", "n", 9480, "Word"],
  ["flesh", "n", 9867, "Word"],
  ["garage", "n", 9573, "Word"],
  ["operator", "n", 9351, "Word"],
  ["instructor", "n", 9912, "Word"],
  ["collapse", "v", 9163, "Word"],
  ["borrow", "v", 9150, "Word"],
  ["furthermore", "r", 10349, "Word"],
  ["comedy", "n", 9596, "Word"],
  ["mortgage", "n", 10022, "Word"],
  ["sanction", "n", 10163, "Word"],
  ["civilian", "j", 9684, "Word"],
  ["twelve", "m", 9638, "Word"],
  ["weekly", "j", 9297, "Word"],
  ["habitat", "n", 9665, "Word"],
  ["grain", "n", 9520, "Word"],
  ["brush", "v", 9806, "Word"],
  ["consciousness", "n", 9758, "Word"],
  ["devote", "v", 9326, "Word"],
  ["crack", "v", 9334, "Word"],
  ["measurement", "n", 10187, "Word"],
  ["province", "n", 9491, "Word"],
  ["ease", "v", 9247, "Word"],
  ["seize", "v", 9106, "Word"],
  ["ethics", "n", 9561, "Word"],
  ["nomination", "n", 9698, "Word"],
  ["permission", "n", 9298, "Word"],
  ["wise", "j", 9101, "Word"],
  ["actress", "n", 9448, "Word"],
  ["summit", "n", 9628, "Word"],
  ["acid", "n", 9772, "Word"],
  ["odds", "n", 9094, "Word"],
  ["gifted", "j", 10941, "Word"],
  ["frustration", "n", 9005, "Word"],
  ["medium", "n", 9523, "Word"],
  ["function", "v", 9570, "Word"],
  ["physically", "r", 9039, "Word"],
  ["grant", "n", 9372, "Word"],
  ["distinguish", "v", 9664, "Word"],
  ["shore", "n", 9191, "Word"],
  ["repeatedly", "r", 9059, "Word"],
  ["lung", "n", 9206, "Word"],
  ["firm", "j", 9036, "Word"],
  ["running", "j", 9271, "Word"],
  ["correct", "v", 8988, "Word"],
  ["distinct", "j", 9682, "Word"],
  ["artistic", "j", 9423, "Word"],
  ["discourse", "n", 10560, "Word"],
  ["basket", "n", 9316, "Word"],
  ["ah", "u", 9788, "Word"],
  ["fighting", "n", 9274, "Word"],
  ["impressive", "j", 9100, "Word"],
  ["competitor", "n", 9377, "Word"],
  ["ugly", "j", 9267, "Word"],
  ["worried", "j", 9215, "Word"],
  ["portray", "v", 9202, "Word"],
  ["powder", "n", 9639, "Word"],
  ["ghost", "n", 9564, "Word"],
  ["persuade", "v", 8998, "Word"],
  ["moderate", "j", 9220, "Word"],
  ["subsequent", "j", 9796, "Word"],
  ["continued", "j", 9402, "Word"],
  ["cookie", "n", 9480, "Word"],
  ["carrier", "n", 9251, "Word"],
  ["cooking", "n", 9403, "Word"],
  ["frequent", "j", 9267, "Word"],
  ["ban", "v", 9226, "Word"],
  ["swing", "n", 9214, "Word"],
  ["orange", "n", 9219, "Word"],
  ["awful", "j", 9300, "Word"],
  ["admire", "v", 9016, "Word"],
  ["pet", "n", 9118, "Word"],
  ["miracle", "n", 8981, "Word"],
  ["exceed", "v", 9362, "Word"],
  ["rhythm", "n", 9010, "Word"],
  ["widespread", "j", 9319, "Word"],
  ["killing", "n", 9109, "Word"],
  ["lovely", "j", 9327, "Word"],
  ["sin", "n", 9048, "Word"],
  ["charity", "n", 9054, "Word"],
  ["script", "n", 8924, "Word"],
  ["tactic", "n", 8993, "Word"],
  ["identification", "n", 9793, "Word"],
  ["transformation", "n", 9561, "Word"],
  ["everyday", "j", 9065, "Word"],
  ["headline", "n", 9272, "Word"],
  ["crash", "v", 9101, "Word"],
  ["venture", "n", 9278, "Word"],
  ["invasion", "n", 9412, "Word"],
  ["military", "n", 9288, "Word"],
  ["nonetheless", "r", 9168, "Word"],
  ["adequate", "j", 9406, "Word"],
  ["piano", "n", 8968, "Word"],
  ["grocery", "n", 9017, "Word"],
  ["intensity", "n", 9238, "Word"],
  ["exhibit", "v", 9459, "Word"],
  ["high", "r", 8771, "Word"],
  ["blanket", "n", 9385, "Word"],
  ["margin", "n", 8994, "Word"],
  ["principal", "j", 9218, "Word"],
  ["quarterback", "n", 10014, "Word"],
  ["mouse", "n", 9449, "Word"],
  ["rope", "n", 9300, "Word"],
  ["concrete", "j", 8871, "Word"],
  ["prescription", "n", 9169, "Word"],
  ["chase", "v", 8980, "Word"],
  ["document", "v", 9274, "Word"],
  ["brick", "n", 9069, "Word"],
  ["recruit", "v", 9028, "Word"],
  ["patch", "n", 9155, "Word"],
  ["consensus", "n", 9250, "Word"],
  ["horror", "n", 8875, "Word"],
  ["recording", "n", 8866, "Word"],
  ["changing", "j", 9055, "Word"],
  ["painter", "n", 9153, "Word"],
  ["colonial", "j", 9628, "Word"],
  ["pie", "n", 9019, "Word"],
  ["sake", "n", 8954, "Word"],
  ["gaze", "n", 9852, "Word"],
  ["courage", "n", 8720, "Word"],
  ["pregnancy", "n", 8944, "Word"],
  ["swear", "v", 9181, "Word"],
  ["defeat", "v", 8945, "Word"],
  ["clue", "n", 8732, "Word"],
  ["reinforce", "v", 9345, "Word"],
  ["win", "n", 9584, "Word"],
  ["confusion", "n", 8751, "Word"],
  ["slice", "n", 9337, "Word"],
  ["occupation", "n", 9115, "Word"],
  ["dear", "j", 9151, "Word"],
  ["coal", "n", 8928, "Word"],
  ["sacred", "j", 9018, "Word"],
  ["criminal", "n", 8854, "Word"],
  ["formula", "n", 8965, "Word"],
  ["cognitive", "j", 10166, "Word"],
  ["collective", "j", 9384, "Word"],
  ["exact", "j", 8643, "Word"],
  ["uncle", "n", 9355, "Word"],
  ["square", "n", 8906, "Word"],
  ["captain", "n", 9087, "Word"],
  ["sigh", "v", 10099, "Word"],
  ["attribute", "v", 9228, "Word"],
  ["dare", "v", 9033, "Word"],
  ["okay", "j", 9499, "Word"],
  ["homeless", "j", 9149, "Word"],
  ["cool", "v", 8980, "Word"],
  ["gallery", "n", 8963, "Word"],
  ["soccer", "n", 9212, "Word"],
  ["defendant", "n", 9230, "Word"],
  ["tunnel", "n", 9024, "Word"],
  ["fitness", "n", 9261, "Word"],
  ["lap", "n", 9260, "Word"],
  ["grave", "n", 8868, "Word"],
  ["toe", "n", 9047, "Word"],
  ["container", "n", 8956, "Word"],
  ["virtue", "n", 9111, "Word"],
  ["abroad", "r", 8788, "Word"],
  ["architect", "n", 8969, "Word"],
  ["dramatically", "r", 8712, "Word"],
  ["makeup", "n", 8916, "Word"],
  ["inquiry", "n", 9136, "Word"],
  ["rose", "n", 8988, "Word"],
  ["surprisingly", "r", 8704, "Word"],
  ["highlight", "v", 9119, "Word"],
  ["decrease", "v", 9291, "Word"],
  ["indication", "n", 8715, "Word"],
  ["rail", "n", 8826, "Word"],
  ["anniversary", "n", 8676, "Word"],
  ["couch", "n", 9209, "Word"],
  ["alliance", "n", 8948, "Word"],
  ["hypothesis", "n", 9890, "Word"],
  ["boyfriend", "n", 8755, "Word"],
  ["compose", "v", 8866, "Word"],
  ["peer", "v", 9099, "Word"],
  ["mess", "n", 8745, "Word"],
  ["rank", "v", 8879, "Word"],
  ["legend", "n", 8660, "Word"],
  ["regulate", "v", 8856, "Word"],
  ["adolescent", "n", 10568, "Word"],
  ["shine", "v", 8984, "Word"],
  ["norm", "n", 9360, "Word"],
  ["upset", "v", 8570, "Word"],
  ["remark", "n", 8453, "Word"],
  ["resign", "v", 8867, "Word"],
  ["reward", "n", 8662, "Word"],
  ["gentle", "j", 8766, "Word"],
  ["related", "j", 9227, "Word"],
  ["organ", "n", 8596, "Word"],
  ["lightly", "r", 8893, "Word"],
  ["concerning", "i", 9329, "Word"],
  ["invent", "v", 8461, "Word"],
  ["laughter", "n", 9019, "Word"],
  ["fit", "j", 8550, "Word"],
  ["northwest", "n", 8650, "Word"],
  ["counseling", "n", 9303, "Word"],
  ["tight", "r", 8980, "Word"],
  ["receiver", "n", 8882, "Word"],
  ["ritual", "n", 8883, "Word"],
  ["insect", "n", 8814, "Word"],
  ["interrupt", "v", 8757, "Word"],
  ["salmon", "n", 8860, "Word"],
  ["favor", "n", 8403, "Word"],
  ["trading", "n", 8787, "Word"],
  ["concern", "v", 8702, "Word"],
  ["magic", "j", 8506, "Word"],
  ["superior", "j", 8632, "Word"],
  ["combat", "n", 8675, "Word"],
  ["stem", "n", 8963, "Word"],
  ["surgeon", "n", 8519, "Word"],
  ["acceptable", "j", 8723, "Word"],
  ["physics", "n", 9184, "Word"],
  ["rape", "n", 8790, "Word"],
  ["counsel", "n", 9387, "Word"],
  ["brush", "n", 8821, "Word"],
  ["jeans", "n", 8851, "Word"],
  ["hunt", "v", 8812, "Word"],
  ["continuous", "j", 8996, "Word"],
  ["log", "n", 8606, "Word"],
  ["echo", "v", 8477, "Word"],
  ["pill", "n", 8537, "Word"],
  ["excited", "j", 8491, "Word"],
  ["sculpture", "n", 8659, "Word"],
  ["compound", "n", 8573, "Word"],
  ["integrate", "v", 9060, "Word"],
  ["flour", "n", 9103, "Word"],
  ["bitter", "j", 8353, "Word"],
  ["bare", "j", 8943, "Word"],
  ["slope", "n", 8573, "Word"],
  ["rent", "v", 8446, "Word"],
  ["presidency", "n", 8814, "Word"],
  ["serving", "n", 9453, "Word"],
  ["subtle", "j", 8442, "Word"],
  ["greatly", "r", 8648, "Word"],
  ["bishop", "n", 8639, "Word"],
  ["drinking", "n", 8446, "Word"],
  ["delay", "v", 8333, "Word"],
  ["cry", "n", 8648, "Word"],
  ["acceptance", "n", 8870, "Word"],
  ["collapse", "n", 8397, "Word"],
  ["shop", "v", 8509, "Word"],
  ["pump", "n", 8617, "Word"],
  ["candy", "n", 8633, "Word"],
  ["evil", "n", 8391, "Word"],
  ["final", "n", 8964, "Word"],
  ["finance", "v", 8571, "Word"],
  ["pleased", "j", 8502, "Word"],
  ["medal", "n", 8991, "Word"],
  ["beg", "v", 8542, "Word"],
  ["sponsor", "v", 8542, "Word"],
  ["ethical", "j", 9018, "Word"],
  ["secondary", "j", 9121, "Word"],
  ["slam", "v", 9076, "Word"],
  ["export", "n", 9013, "Word"],
  ["experimental", "j", 9019, "Word"],
  ["melt", "v", 8533, "Word"],
  ["midnight", "n", 8440, "Word"],
  ["net", "j", 8563, "Word"],
  ["curve", "n", 8397, "Word"],
  ["integrity", "n", 8463, "Word"],
  ["entitle", "v", 8381, "Word"],
  ["evident", "j", 8753, "Word"],
  ["logic", "n", 8646, "Word"],
  ["essence", "n", 8388, "Word"],
  ["park", "v", 8650, "Word"],
  ["exclude", "v", 8780, "Word"],
  ["harsh", "j", 8092, "Word"],
  ["closet", "n", 8597, "Word"],
  ["suburban", "j", 8463, "Word"],
  ["greet", "v", 8367, "Word"],
  ["favor", "i", 8357, "Word"],
  ["interior", "n", 8370, "Word"],
  ["corridor", "n", 8842, "Word"],
  ["murder", "v", 8462, "Word"],
  ["retail", "j", 8600, "Word"],
  ["pitcher", "n", 8835, "Word"],
  ["march", "v", 8267, "Word"],
  ["snake", "n", 8523, "Word"],
  ["pitch", "v", 8628, "Word"],
  ["excuse", "v", 8722, "Word"],
  ["cross", "n", 8207, "Word"],
  ["weakness", "n", 8275, "Word"],
  ["pig", "n", 8307, "Word"],
  ["cold", "n", 8527, "Word"],
  ["classical", "j", 8475, "Word"],
  ["estimated", "j", 8464, "Word"],
  ["tshirt", "n", 8386, "Word"],
  ["online", "r", 8729, "Word"],
  ["unemployment", "n", 8471, "Word"],
  ["civilization", "n", 8447, "Word"],
  ["fold", "v", 8630, "Word"],
  ["patient", "j", 8504, "Word"],
  ["pop", "n", 8316, "Word"],
  ["daily", "r", 8405, "Word"],
  ["reverse", "v", 8154, "Word"],
  ["missing", "j", 8232, "Word"],
  ["correlation", "n", 9584, "Word"],
  ["humanity", "n", 8259, "Word"],
  ["flash", "v", 8815, "Word"],
  ["developer", "n", 8671, "Word"],
  ["reliable", "j", 8302, "Word"],
  ["excitement", "n", 8160, "Word"],
  ["beef", "n", 8416, "Word"],
  ["islam", "n", 8755, "Word"],
  ["roman", "j", 8299, "Word"],
  ["stretch", "n", 8285, "Word"],
  ["architecture", "n", 8449, "Word"],
  ["occasional", "j", 8162, "Word"],
  ["administrative", "j", 8895, "Word"],
  ["elbow", "n", 8536, "Word"],
  ["deadly", "j", 8196, "Word"],
  ["muslim", "n", 8498, "Word"],
  ["hispanic", "j", 8690, "Word"],
  ["allegation", "n", 8675, "Word"],
  ["tip", "v", 8300, "Word"],
  ["confuse", "v", 8045, "Word"],
  ["airplane", "n", 8254, "Word"],
  ["monthly", "j", 8335, "Word"],
  ["duck", "n", 8335, "Word"],
  ["dose", "n", 8291, "Word"],
  ["korean", "j", 8441, "Word"],
  ["plead", "v", 8140, "Word"],
  ["initiate", "v", 8630, "Word"],
  ["lecture", "n", 8278, "Word"],
  ["van", "n", 8364, "Word"],
  ["sixth", "m", 8170, "Word"],
  ["bay", "n", 8209, "Word"],
  ["mainstream", "j", 8170, "Word"],
  ["suburb", "n", 8249, "Word"],
  ["sandwich", "n", 8298, "Word"],
  ["unlike", "j", 8103, "Word"],
  ["trunk", "n", 8400, "Word"],
  ["rumor", "n", 8023, "Word"],
  ["implementation", "n", 9293, "Word"],
  ["swallow", "v", 8583, "Word"],
  ["motivate", "v", 8324, "Word"],
  ["render", "v", 8288, "Word"],
  ["longtime", "j", 8439, "Word"],
  ["trap", "v", 8030, "Word"],
  ["restrict", "v", 8340, "Word"],
  ["cloth", "n", 8287, "Word"],
  ["seemingly", "r", 8010, "Word"],
  ["legislative", "j", 8384, "Word"],
  ["effectiveness", "n", 8889, "Word"],
  ["enforce", "v", 8162, "Word"],
  ["lens", "n", 8313, "Word"],
  ["reach", "n", 7982, "Word"],
  ["inspector", "n", 8652, "Word"],
  ["lend", "v", 7961, "Word"],
  ["plain", "j", 8015, "Word"],
  ["fraud", "n", 8176, "Word"],
  ["companion", "n", 8136, "Word"],
  ["contend", "v", 8249, "Word"],
  ["nail", "n", 8255, "Word"],
  ["array", "n", 8167, "Word"],
  ["strict", "j", 8013, "Word"],
  ["assemble", "v", 7996, "Word"],
  ["frankly", "r", 8620, "Word"],
  ["rat", "n", 8177, "Word"],
  ["burst", "v", 8393, "Word"],
  ["hallway", "n", 8717, "Word"],
  ["cave", "n", 8261, "Word"],
  ["inevitable", "j", 7889, "Word"],
  ["southwest", "n", 8190, "Word"],
  ["monster", "n", 8164, "Word"],
  ["speed", "v", 7936, "Word"],
  ["protest", "v", 7871, "Word"],
  ["unexpected", "j", 7829, "Word"],
  ["obstacle", "n", 8075, "Word"],
  ["facilitate", "v", 8946, "Word"],
  ["encounter", "n", 8073, "Word"],
  ["rip", "v", 8164, "Word"],
  ["herb", "n", 8279, "Word"],
  ["overwhelming", "j", 7831, "Word"],
  ["integration", "n", 8847, "Word"],
  ["crystal", "n", 8035, "Word"],
  ["recession", "n", 8809, "Word"],
  ["wish", "n", 7789, "Word"],
  ["top", "v", 8162, "Word"],
  ["written", "j", 8371, "Word"],
  ["motive", "n", 8029, "Word"],
  ["label", "v", 7969, "Word"],
  ["flood", "n", 7986, "Word"],
  ["pen", "n", 8117, "Word"],
  ["ownership", "n", 8177, "Word"],
  ["nightmare", "n", 7895, "Word"],
  ["notice", "n", 7762, "Word"],
  ["inspection", "n", 8128, "Word"],
  ["supervisor", "n", 8049, "Word"],
  ["consult", "v", 7778, "Word"],
  ["arena", "n", 8008, "Word"],
  ["laugh", "n", 8164, "Word"],
  ["diagnosis", "n", 8454, "Word"],
  ["possession", "n", 7828, "Word"],
  ["forgive", "v", 8003, "Word"],
  ["warm", "v", 7973, "Word"],
  ["consistently", "r", 8104, "Word"],
  ["basement", "n", 7988, "Word"],
  ["project", "v", 7871, "Word"],
  ["drift", "v", 8311, "Word"],
  ["drain", "v", 8123, "Word"],
  ["last", "r", 8415, "Word"],
  ["prosecution", "n", 8941, "Word"],
  ["maximum", "j", 8075, "Word"],
  ["announcement", "n", 7863, "Word"],
  ["warrior", "n", 7975, "Word"],
  ["prediction", "n", 7996, "Word"],
  ["bacteria", "n", 8256, "Word"],
  ["questionnaire", "n", 9123, "Word"],
  ["mud", "n", 8026, "Word"],
  ["infrastructure", "n", 7961, "Word"],
  ["hurry", "v", 8723, "Word"],
  ["privilege", "n", 7923, "Word"],
  ["temple", "n", 7949, "Word"],
  ["medium", "j", 8586, "Word"],
  ["outdoor", "j", 8101, "Word"],
  ["suck", "v", 8204, "Word"],
  ["andor", "c", 8481, "Word"],
  ["broadcast", "n", 8130, "Word"],
  ["re", "i", 15773, "Word"],
  ["leap", "v", 8376, "Word"],
  ["random", "j", 7818, "Word"],
  ["past", "r", 7921, "Word"],
  ["wrist", "n", 8250, "Word"],
  ["curtain", "n", 8204, "Word"],
  ["monitor", "n", 7879, "Word"],
  ["pond", "n", 7971, "Word"],
  ["domain", "n", 8641, "Word"],
  ["guilt", "n", 7746, "Word"],
  ["cattle", "n", 7827, "Word"],
  ["subject", "i", 8087, "Word"],
  ["walking", "n", 7847, "Word"],
  ["playoff", "n", 8803, "Word"],
  ["minimum", "j", 8011, "Word"],
  ["fiscal", "j", 8005, "Word"],
  ["skirt", "n", 8167, "Word"],
  ["dump", "v", 7689, "Word"],
  ["hence", "r", 8468, "Word"],
  ["database", "n", 8094, "Word"],
  ["uncomfortable", "j", 7667, "Word"],
  ["aim", "n", 7973, "Word"],
  ["execute", "v", 7671, "Word"],
  ["limb", "n", 8018, "Word"],
  ["ideology", "n", 8485, "Word"],
  ["average", "v", 8106, "Word"],
  ["welcome", "j", 7798, "Word"],
  ["tune", "v", 7934, "Word"],
  ["continuing", "j", 7845, "Word"],
  ["harm", "n", 7619, "Word"],
  ["railroad", "n", 7796, "Word"],
  ["endure", "v", 7586, "Word"],
  ["radiation", "n", 7912, "Word"],
  ["horn", "n", 7813, "Word"],
  ["chronic", "j", 8036, "Word"],
  ["peaceful", "j", 7704, "Word"],
  ["innovation", "n", 8099, "Word"],
  ["strain", "n", 7713, "Word"],
  ["guitar", "n", 7712, "Word"],
  ["replacement", "n", 7734, "Word"],
  ["behave", "v", 7546, "Word"],
  ["administer", "v", 8135, "Word"],
  ["simultaneously", "r", 7960, "Word"],
  ["dancer", "n", 7664, "Word"],
  ["amendment", "n", 8132, "Word"],
  ["guard", "v", 7659, "Word"],
  ["pad", "n", 7846, "Word"],
  ["transmission", "n", 7829, "Word"],
  ["await", "v", 7550, "Word"],
  ["retired", "j", 7869, "Word"],
  ["trigger", "v", 7630, "Word"],
  ["spill", "v", 7798, "Word"],
  ["grateful", "j", 7597, "Word"],
  ["grace", "n", 7634, "Word"],
  ["virtual", "j", 7776, "Word"],
  ["response", "i", 7868, "Word"],
  ["colony", "n", 7822, "Word"],
  ["adoption", "n", 7986, "Word"],
  ["slide", "n", 7780, "Word"],
  ["indigenous", "j", 8785, "Word"],
  ["closed", "j", 7500, "Word"],
  ["convict", "v", 7940, "Word"],
  ["civilian", "n", 7889, "Word"],
  ["towel", "n", 7978, "Word"],
  ["modify", "v", 8997, "Word"],
  ["particle", "n", 7925, "Word"],
  ["award", "v", 7666, "Word"],
  ["glance", "n", 8122, "Word"],
  ["prize", "n", 7519, "Word"],
  ["landing", "n", 7646, "Word"],
  ["conduct", "n", 7821, "Word"],
  ["blue", "n", 7671, "Word"],
  ["boost", "v", 7863, "Word"],
  ["bat", "n", 7694, "Word"],
  ["alarm", "n", 7631, "Word"],
  ["festival", "n", 7775, "Word"],
  ["grip", "n", 7724, "Word"],
  ["weird", "j", 7709, "Word"],
  ["undermine", "v", 7762, "Word"],
  ["freshman", "n", 7849, "Word"],
  ["sweat", "n", 8036, "Word"],
  ["outer", "j", 7618, "Word"],
  ["european", "n", 7688, "Word"],
  ["drunk", "j", 7890, "Word"],
  ["survey", "v", 7556, "Word"],
  ["research", "v", 7618, "Word"],
  ["separation", "n", 7760, "Word"],
  ["traditionally", "r", 7717, "Word"],
  ["stuff", "v", 7687, "Word"],
  ["govern", "v", 7668, "Word"],
  ["southeast", "n", 7589, "Word"],
  ["intelligent", "j", 7373, "Word"],
  ["wherever", "r", 7382, "Word"],
  ["ballot", "n", 9217, "Word"],
  ["rhetoric", "n", 7765, "Word"],
  ["convinced", "j", 7389, "Word"],
  ["driving", "j", 7438, "Word"],
  ["vitamin", "n", 8245, "Word"],
  ["enthusiasm", "n", 7368, "Word"],
  ["accommodate", "v", 7571, "Word"],
  ["praise", "v", 7379, "Word"],
  ["injure", "v", 7574, "Word"],
  ["wilderness", "n", 7916, "Word"],
  ["nearby", "r", 7545, "Word"],
  ["endless", "j", 7458, "Word"],
  ["mandate", "n", 7704, "Word"],
  ["pause", "n", 8140, "Word"],
  ["excuse", "n", 7388, "Word"],
  ["respectively", "r", 8223, "Word"],
  ["uncertainty", "n", 7671, "Word"],
  ["chaos", "n", 7304, "Word"],
  ["short", "r", 7495, "Word"],
  ["mechanical", "j", 7835, "Word"],
  ["canvas", "n", 7795, "Word"],
  ["forty", "m", 7692, "Word"],
  ["matter", "d", 7396, "Word"],
  ["lobby", "n", 7556, "Word"],
  ["profound", "j", 7477, "Word"],
  ["format", "n", 7910, "Word"],
  ["trait", "n", 7980, "Word"],
  ["currency", "n", 7753, "Word"],
  ["turkey", "n", 7757, "Word"],
  ["reserve", "n", 7585, "Word"],
  ["beam", "n", 7625, "Word"],
  ["abuse", "v", 7554, "Word"],
  ["astronomer", "n", 8157, "Word"],
  ["corruption", "n", 7518, "Word"],
  ["contractor", "n", 7634, "Word"],
  ["apologize", "v", 7485, "Word"],
  ["doctrine", "n", 8081, "Word"],
  ["genuine", "j", 7336, "Word"],
  ["thumb", "n", 7659, "Word"],
  ["unity", "n", 7810, "Word"],
  ["compromise", "n", 7469, "Word"],
  ["horrible", "j", 7538, "Word"],
  ["behavioral", "j", 8320, "Word"],
  ["exclusive", "j", 7402, "Word"],
  ["scatter", "v", 7461, "Word"],
  ["commonly", "r", 7861, "Word"],
  ["convey", "v", 7529, "Word"],
  ["rush", "n", 7458, "Word"],
  ["twist", "v", 7875, "Word"],
  ["complexity", "n", 7813, "Word"],
  ["fork", "n", 7742, "Word"],
  ["disk", "n", 7898, "Word"],
  ["relieve", "v", 7352, "Word"],
  ["suspicion", "n", 7187, "Word"],
  ["lock", "n", 7605, "Word"],
  ["finish", "n", 7585, "Word"],
  ["residence", "n", 7332, "Word"],
  ["shame", "n", 7347, "Word"],
  ["meaningful", "j", 7719, "Word"],
  ["sidewalk", "n", 7668, "Word"],
  ["olympics", "n", 8039, "Word"],
  ["technological", "j", 7780, "Word"],
  ["signature", "n", 7349, "Word"],
  ["pleasant", "j", 7425, "Word"],
  ["wow", "u", 8016, "Word"],
  ["suspend", "v", 7228, "Word"],
  ["rebel", "n", 7525, "Word"],
  ["frozen", "j", 7474, "Word"],
  ["desire", "v", 7454, "Word"],
  ["spouse", "n", 7510, "Word"],
  ["fluid", "n", 7464, "Word"],
  ["pension", "n", 7660, "Word"],
  ["resume", "v", 7243, "Word"],
  ["theoretical", "j", 8320, "Word"],
  ["sodium", "n", 7998, "Word"],
  ["blow", "n", 7324, "Word"],
  ["promotion", "n", 7416, "Word"],
  ["delicate", "j", 7332, "Word"],
  ["forehead", "n", 8123, "Word"],
  ["rebuild", "v", 7474, "Word"],
  ["bounce", "v", 7451, "Word"],
  ["electrical", "j", 7347, "Word"],
  ["hook", "n", 7382, "Word"],
  ["detective", "n", 7441, "Word"],
  ["traveler", "n", 7336, "Word"],
  ["click", "v", 7549, "Word"],
  ["compensation", "n", 7512, "Word"],
  ["signal", "v", 7171, "Word"],
  ["exit", "n", 7333, "Word"],
  ["attraction", "n", 7280, "Word"],
  ["dedicate", "v", 7226, "Word"],
  ["altogether", "r", 7147, "Word"],
  ["pickup", "n", 7415, "Word"],
  ["carve", "v", 7294, "Word"],
  ["needle", "n", 7378, "Word"],
  ["belly", "n", 7784, "Word"],
  ["ship", "v", 7264, "Word"],
  ["scare", "v", 7367, "Word"],
  ["portfolio", "n", 7663, "Word"],
  ["shuttle", "n", 7593, "Word"],
  ["invisible", "j", 7355, "Word"],
  ["timing", "n", 7345, "Word"],
  ["engagement", "n", 7675, "Word"],
  ["ankle", "n", 7397, "Word"],
  ["transaction", "n", 7418, "Word"],
  ["rescue", "v", 7187, "Word"],
  ["counterpart", "n", 7484, "Word"],
  ["historically", "r", 7426, "Word"],
  ["firmly", "r", 7189, "Word"],
  ["mild", "j", 7211, "Word"],
  ["rider", "n", 7450, "Word"],
  ["doll", "n", 7351, "Word"],
  ["noon", "n", 7425, "Word"],
  ["exhibit", "n", 7326, "Word"],
  ["amid", "i", 7264, "Word"],
  ["identical", "j", 7247, "Word"],
  ["precise", "j", 7211, "Word"],
  ["anxious", "j", 7096, "Word"],
  ["structural", "j", 7870, "Word"],
  ["residential", "j", 7415, "Word"],
  ["loud", "r", 7541, "Word"],
  ["diagnose", "v", 7255, "Word"],
  ["carbohydrate", "n", 8057, "Word"],
  ["liberty", "n", 7442, "Word"],
  ["poster", "n", 7069, "Word"],
  ["theology", "n", 7866, "Word"],
  ["nonprofit", "j", 7482, "Word"],
  ["crawl", "v", 7596, "Word"],
  ["oxygen", "n", 7191, "Word"],
  ["handsome", "j", 7512, "Word"],
  ["magic", "n", 7415, "Word"],
  ["sum", "n", 7217, "Word"],
  ["provided", "c", 7431, "Word"],
  ["businessman", "n", 7190, "Word"],
  ["promising", "j", 7075, "Word"],
  ["conscious", "j", 7152, "Word"],
  ["determination", "n", 7170, "Word"],
  ["donor", "n", 7231, "Word"],
  ["hers", "p", 7770, "Word"],
  ["pastor", "n", 7260, "Word"],
  ["jazz", "n", 7220, "Word"],
  ["opera", "n", 7332, "Word"],
  ["japanese", "n", 7419, "Word"],
  ["bite", "n", 7160, "Word"],
  ["frame", "v", 7063, "Word"],
  ["evil", "j", 7045, "Word"],
  ["acquisition", "n", 7338, "Word"],
  ["pit", "n", 7132, "Word"],
  ["hug", "v", 7478, "Word"],
  ["wildlife", "n", 7361, "Word"],
  ["punish", "v", 6955, "Word"],
  ["giant", "n", 7102, "Word"],
  ["primary", "n", 7823, "Word"],
  ["equity", "n", 7277, "Word"],
  ["wrong", "r", 7038, "Word"],
  ["doorway", "n", 7914, "Word"],
  ["departure", "n", 7008, "Word"],
  ["elevator", "n", 7535, "Word"],
  ["teenage", "j", 7030, "Word"],
  ["guidance", "n", 7399, "Word"],
  ["happiness", "n", 7036, "Word"],
  ["statue", "n", 7058, "Word"],
  ["pursuit", "n", 7070, "Word"],
  ["repair", "n", 7088, "Word"],
  ["decent", "j", 6954, "Word"],
  ["gym", "n", 7229, "Word"],
  ["oral", "j", 7448, "Word"],
  ["clerk", "n", 7234, "Word"],
  ["israeli", "n", 7673, "Word"],
  ["envelope", "n", 7241, "Word"],
  ["reporting", "n", 7184, "Word"],
  ["destination", "n", 7039, "Word"],
  ["fist", "n", 7729, "Word"],
  ["endorse", "v", 7167, "Word"],
  ["exploration", "n", 7294, "Word"],
  ["generous", "j", 6907, "Word"],
  ["bath", "n", 7149, "Word"],
  ["rescue", "n", 7094, "Word"],
  ["thereby", "r", 7588, "Word"],
  ["hay", "n", 7225, "Word"],
  ["indicator", "n", 7574, "Word"],
  ["sunlight", "n", 7349, "Word"],
  ["feedback", "n", 7735, "Word"],
  ["spectrum", "n", 7216, "Word"],
  ["purple", "j", 7216, "Word"],
  ["laser", "n", 7197, "Word"],
  ["bold", "j", 6936, "Word"],
  ["reluctant", "j", 6842, "Word"],
  ["starting", "n", 7118, "Word"],
  ["expertise", "n", 7219, "Word"],
  ["practically", "r", 6914, "Word"],
  ["program", "v", 7015, "Word"],
  ["picture", "v", 7103, "Word"],
  ["tune", "n", 6887, "Word"],
  ["eating", "n", 7067, "Word"],
  ["age", "v", 7005, "Word"],
  ["volunteer", "v", 6864, "Word"],
  ["hint", "n", 6951, "Word"],
  ["sharply", "r", 6966, "Word"],
  ["parade", "n", 7010, "Word"],
  ["advocate", "v", 7198, "Word"],
  ["realm", "n", 7261, "Word"],
  ["ban", "n", 7070, "Word"],
  ["strip", "v", 6894, "Word"],
  ["cancel", "v", 6932, "Word"],
  ["blend", "v", 7238, "Word"],
  ["therapist", "n", 7170, "Word"],
  ["slice", "v", 7316, "Word"],
  ["peel", "v", 7182, "Word"],
  ["pizza", "n", 7130, "Word"],
  ["recipient", "n", 7126, "Word"],
  ["hesitate", "v", 7462, "Word"],
  ["flip", "v", 7274, "Word"],
  ["accounting", "n", 7319, "Word"],
  ["debate", "v", 6952, "Word"],
  ["bias", "n", 7388, "Word"],
  ["huh", "u", 7563, "Word"],
  ["metaphor", "n", 7461, "Word"],
  ["candle", "n", 7204, "Word"],
  ["handle", "n", 7101, "Word"],
  ["worry", "n", 6811, "Word"],
  ["judicial", "j", 7213, "Word"],
  ["entity", "n", 7276, "Word"],
  ["suffering", "n", 6955, "Word"],
  ["fulltime", "j", 6945, "Word"],
  ["feel", "n", 6939, "Word"],
  ["lamp", "n", 7290, "Word"],
  ["garbage", "n", 6933, "Word"],
  ["servant", "n", 7064, "Word"],
  ["addition", "n", 7027, "Word"],
  ["regulatory", "j", 7274, "Word"],
  ["diplomatic", "j", 7193, "Word"],
  ["elegant", "j", 6974, "Word"],
  ["inside", "n", 6955, "Word"],
  ["reception", "n", 6896, "Word"],
  ["vanish", "v", 7154, "Word"],
  ["automatically", "r", 6823, "Word"],
  ["chin", "n", 7585, "Word"],
  ["trail", "v", 7049, "Word"],
  ["necessity", "n", 7071, "Word"],
  ["confess", "v", 6815, "Word"],
  ["racism", "n", 7026, "Word"],
  ["starter", "n", 7487, "Word"],
  ["interior", "j", 6931, "Word"],
  ["banking", "n", 7120, "Word"],
  ["casual", "j", 6946, "Word"],
  ["gravity", "n", 7000, "Word"],
  ["enroll", "v", 7053, "Word"],
  ["diminish", "v", 6875, "Word"],
  ["prevention", "n", 7352, "Word"],
  ["arab", "n", 7222, "Word"],
  ["value", "v", 7000, "Word"],
  ["minimize", "v", 7139, "Word"],
  ["chop", "v", 7452, "Word"],
  ["performer", "n", 6896, "Word"],
  ["intent", "n", 6879, "Word"],
  ["isolate", "v", 6920, "Word"],
  ["pump", "v", 6765, "Word"],
  ["inventory", "n", 7090, "Word"],
  ["productive", "j", 7006, "Word"],
  ["assembly", "n", 6914, "Word"],
  ["civic", "j", 7088, "Word"],
  ["silk", "n", 7128, "Word"],
  ["magnitude", "n", 7115, "Word"],
  ["steep", "j", 6972, "Word"],
  ["hostage", "n", 8605, "Word"],
  ["collector", "n", 6976, "Word"],
  ["popularity", "n", 6836, "Word"],
  ["kiss", "n", 7195, "Word"],
  ["alien", "n", 7134, "Word"],
  ["dynamic", "j", 7175, "Word"],
  ["scary", "j", 6865, "Word"],
  ["equation", "n", 7372, "Word"],
  ["angel", "n", 6978, "Word"],
  ["switch", "n", 6781, "Word"],
  ["offering", "n", 6856, "Word"],
  ["rage", "n", 6877, "Word"],
  ["photography", "n", 7087, "Word"],
  ["repair", "v", 6730, "Word"],
  ["toilet", "n", 6939, "Word"],
  ["disappointed", "j", 6724, "Word"],
  ["precious", "j", 6716, "Word"],
  ["prohibit", "v", 6896, "Word"],
  ["representative", "j", 7008, "Word"],
  ["content", "j", 7113, "Word"],
  ["realistic", "j", 6786, "Word"],
  ["russian", "n", 6891, "Word"],
  ["hidden", "j", 6787, "Word"],
  ["command", "v", 6688, "Word"],
  ["tender", "j", 7013, "Word"],
  ["wake", "n", 6630, "Word"],
  ["gathering", "n", 6671, "Word"],
  ["outstanding", "j", 6803, "Word"],
  ["stumble", "v", 7076, "Word"],
  ["lonely", "j", 6868, "Word"],
  ["automobile", "n", 6829, "Word"],
  ["artificial", "j", 6730, "Word"],
  ["dawn", "n", 6929, "Word"],
  ["abstract", "j", 7165, "Word"],
  ["descend", "v", 6896, "Word"],
  ["silly", "j", 6857, "Word"],
  ["hook", "v", 6800, "Word"],
  ["tide", "n", 6698, "Word"],
  ["shared", "j", 7069, "Word"],
  ["hopefully", "r", 6986, "Word"],
  ["readily", "r", 6964, "Word"],
  ["cooperate", "v", 6763, "Word"],
  ["revolutionary", "j", 6886, "Word"],
  ["romance", "n", 6705, "Word"],
  ["hardware", "n", 6951, "Word"],
  ["pillow", "n", 7122, "Word"],
  ["kit", "n", 6912, "Word"],
  ["cook", "n", 6866, "Word"],
  ["spread", "n", 6686, "Word"],
  ["continent", "n", 6730, "Word"],
  ["seal", "v", 6660, "Word"],
  ["circuit", "n", 6747, "Word"],
  ["sink", "n", 7052, "Word"],
  ["ruling", "n", 6947, "Word"],
  ["shortage", "n", 6776, "Word"],
  ["annually", "r", 6928, "Word"],
  ["lately", "r", 6696, "Word"],
  ["trap", "n", 6694, "Word"],
  ["scan", "v", 6879, "Word"],
  ["fool", "n", 6990, "Word"],
  ["deadline", "n", 6932, "Word"],
  ["rear", "j", 6910, "Word"],
  ["processing", "n", 7220, "Word"],
  ["ranch", "n", 6759, "Word"],
  ["coastal", "j", 6880, "Word"],
  ["undertake", "v", 7044, "Word"],
  ["softly", "r", 7487, "Word"],
  ["reserve", "v", 6636, "Word"],
  ["burning", "j", 6665, "Word"],
  ["verbal", "j", 7227, "Word"],
  ["tribal", "j", 6995, "Word"],
  ["ridiculous", "j", 6695, "Word"],
  ["automatic", "j", 6580, "Word"],
  ["diamond", "n", 6743, "Word"],
  ["credibility", "n", 6742, "Word"],
  ["import", "n", 6944, "Word"],
  ["sexually", "r", 6806, "Word"],
  ["spring", "v", 6689, "Word"],
  ["way", "r", 6604, "Word"],
  ["divine", "j", 7059, "Word"],
  ["sentiment", "n", 6658, "Word"],
  ["cart", "n", 6822, "Word"],
  ["oversee", "v", 6773, "Word"],
  ["stem", "v", 6712, "Word"],
  ["elder", "n", 6808, "Word"],
  ["pro", "n", 6867, "Word"],
  ["inspiration", "n", 6584, "Word"],
  ["dutch", "j", 6690, "Word"],
  ["quantity", "n", 6833, "Word"],
  ["trailer", "n", 6713, "Word"],
  ["mate", "n", 6693, "Word"],
  ["oclock", "r", 6972, "Word"],
  ["greek", "j", 6642, "Word"],
  ["genius", "n", 6509, "Word"],
  ["monument", "n", 6680, "Word"],
  ["bid", "n", 6954, "Word"],
  ["quest", "n", 6613, "Word"],
  ["sacrifice", "n", 6591, "Word"],
  ["invitation", "n", 6460, "Word"],
  ["accuracy", "n", 6942, "Word"],
  ["juror", "n", 7497, "Word"],
  ["officially", "r", 6462, "Word"],
  ["broker", "n", 6929, "Word"],
  ["treasure", "n", 6648, "Word"],
  ["loyalty", "n", 6604, "Word"],
  ["credit", "v", 6611, "Word"],
  ["shock", "v", 6492, "Word"],
  ["talented", "j", 6584, "Word"],
  ["gasoline", "n", 6696, "Word"],
  ["stiff", "j", 6697, "Word"],
  ["output", "n", 7041, "Word"],
  ["nominee", "n", 7138, "Word"],
  ["extended", "j", 6641, "Word"],
  ["please", "v", 6565, "Word"],
  ["diabetes", "n", 7073, "Word"],
  ["slap", "v", 7015, "Word"],
  ["toxic", "j", 6672, "Word"],
  ["alleged", "j", 6658, "Word"],
  ["jaw", "n", 6922, "Word"],
  ["grief", "n", 6591, "Word"],
  ["mysterious", "j", 6484, "Word"],
  ["rocket", "n", 6722, "Word"],
  ["donate", "v", 6606, "Word"],
  ["inmate", "n", 6718, "Word"],
  ["tackle", "v", 6644, "Word"],
  ["dynamics", "n", 7084, "Word"],
  ["bow", "n", 6800, "Word"],
  ["ours", "p", 6422, "Word"],
  ["senior", "n", 6768, "Word"],
  ["dignity", "n", 6585, "Word"],
  ["carpet", "n", 6658, "Word"],
  ["parental", "j", 7251, "Word"],
  ["bubble", "n", 6570, "Word"],
  ["heat", "v", 6650, "Word"],
  ["buddy", "n", 6554, "Word"],
  ["barn", "n", 6785, "Word"],
  ["sword", "n", 7029, "Word"],
  ["flash", "n", 6741, "Word"],
  ["seventh", "m", 6517, "Word"],
  ["glory", "n", 6443, "Word"],
  ["tightly", "r", 6643, "Word"],
  ["protective", "j", 6503, "Word"],
  ["tuck", "v", 6847, "Word"],
  ["drum", "n", 6423, "Word"],
  ["faint", "j", 6886, "Word"],
  ["post", "v", 6558, "Word"],
  ["queen", "n", 6606, "Word"],
  ["dilemma", "n", 6703, "Word"],
  ["input", "n", 7004, "Word"],
  ["specialize", "v", 6538, "Word"],
  ["northeast", "n", 6500, "Word"],
  ["shallow", "j", 6512, "Word"],
  ["liability", "n", 6775, "Word"],
  ["sail", "v", 6525, "Word"],
  ["merchant", "n", 6474, "Word"],
  ["stadium", "n", 6977, "Word"],
  ["improved", "j", 6720, "Word"],
  ["bloody", "j", 6513, "Word"],
  ["defeat", "n", 6380, "Word"],
  ["associated", "j", 6754, "Word"],
  ["withdrawal", "n", 6624, "Word"],
  ["refrigerator", "n", 6497, "Word"],
  ["nest", "n", 6541, "Word"],
  ["near", "j", 6296, "Word"],
  ["thoroughly", "r", 6384, "Word"],
  ["lane", "n", 6540, "Word"],
  ["ancestor", "n", 6529, "Word"],
  ["condemn", "v", 6418, "Word"],
  ["steam", "n", 6426, "Word"],
  ["accent", "n", 6486, "Word"],
  ["escape", "n", 6358, "Word"],
  ["optimistic", "j", 6412, "Word"],
  ["unite", "v", 6391, "Word"],
  ["cage", "n", 6621, "Word"],
  ["equip", "v", 6436, "Word"],
  ["shrimp", "n", 6631, "Word"],
  ["homeland", "n", 6726, "Word"],
  ["exchange", "v", 6394, "Word"],
  ["rack", "n", 6677, "Word"],
  ["costume", "n", 6349, "Word"],
  ["wolf", "n", 6718, "Word"],
  ["courtroom", "n", 6772, "Word"],
  ["statute", "n", 6838, "Word"],
  ["cartoon", "n", 6403, "Word"],
  ["besides", "i", 6198, "Word"],
  ["productivity", "n", 6651, "Word"],
  ["grin", "v", 7242, "Word"],
  ["symbolic", "j", 6755, "Word"],
  ["seal", "n", 6543, "Word"],
  ["bug", "n", 6541, "Word"],
  ["bless", "v", 6293, "Word"],
  ["aunt", "n", 6662, "Word"],
  ["agriculture", "n", 6621, "Word"],
  ["rock", "v", 6473, "Word"],
  ["hostile", "j", 6319, "Word"],
  ["root", "v", 6276, "Word"],
  ["conceive", "v", 6486, "Word"],
  ["combined", "j", 6495, "Word"],
  ["instantly", "r", 6476, "Word"],
  ["bankruptcy", "n", 6690, "Word"],
  ["vaccine", "n", 6761, "Word"],
  ["bonus", "n", 6531, "Word"],
  ["collaboration", "n", 6889, "Word"],
  ["mixed", "j", 6327, "Word"],
  ["opposed", "i", 6418, "Word"],
  ["orbit", "n", 6490, "Word"],
  ["grasp", "v", 6357, "Word"],
  ["patience", "n", 6532, "Word"],
  ["spite", "i", 6339, "Word"],
  ["tropical", "j", 6473, "Word"],
  ["voting", "n", 6621, "Word"],
  ["patrol", "n", 6382, "Word"],
  ["willingness", "n", 6461, "Word"],
  ["position", "v", 6288, "Word"],
  ["revelation", "n", 6297, "Word"],
  ["rent", "n", 6360, "Word"],
  ["calm", "v", 6484, "Word"],
  ["jewelry", "n", 6338, "Word"],
  ["cuban", "j", 6601, "Word"],
  ["haul", "v", 6401, "Word"],
  ["concede", "v", 6303, "Word"],
  ["trace", "n", 6345, "Word"],
  ["wagon", "n", 6577, "Word"],
  ["afterward", "r", 6353, "Word"],
  ["spectacular", "j", 6276, "Word"],
  ["ruin", "v", 6267, "Word"],
  ["sheer", "j", 6209, "Word"],
  ["prior", "j", 6720, "Word"],
  ["immune", "j", 6492, "Word"],
  ["reliability", "n", 7053, "Word"],
  ["ass", "n", 6911, "Word"],
  ["alongside", "i", 6166, "Word"],
  ["bush", "n", 6307, "Word"],
  ["exotic", "j", 6185, "Word"],
  ["fascinating", "j", 6231, "Word"],
  ["secure", "j", 6138, "Word"],
  ["clip", "n", 6660, "Word"],
  ["thigh", "n", 6576, "Word"],
  ["bull", "n", 6346, "Word"],
  ["drawer", "n", 6665, "Word"],
  ["regard", "i", 6665, "Word"],
  ["sheep", "n", 6320, "Word"],
  ["discourage", "v", 6177, "Word"],
  ["coordinator", "n", 6478, "Word"],
  ["ideological", "j", 6793, "Word"],
  ["runner", "n", 6333, "Word"],
  ["secular", "j", 6560, "Word"],
  ["intimate", "j", 6143, "Word"],
  ["empire", "n", 6268, "Word"],
  ["cab", "n", 6561, "Word"],
  ["divorce", "v", 6086, "Word"],
  ["exam", "n", 6176, "Word"],
  ["documentary", "n", 6321, "Word"],
  ["neutral", "j", 6331, "Word"],
  ["biology", "n", 6334, "Word"],
  ["flexible", "j", 6312, "Word"],
  ["progressive", "j", 6387, "Word"],
  ["web", "n", 6411, "Word"],
  ["conspiracy", "n", 6261, "Word"],
  ["catch", "n", 6377, "Word"],
  ["casualty", "n", 6439, "Word"],
  ["republic", "n", 7519, "Word"],
  ["execution", "n", 6278, "Word"],
  ["terrific", "j", 6356, "Word"],
  ["whale", "n", 6928, "Word"],
  ["functional", "j", 6736, "Word"],
  ["star", "v", 6328, "Word"],
  ["draft", "v", 6234, "Word"],
  ["instinct", "n", 6076, "Word"],
  ["teammate", "n", 6572, "Word"],
  ["aluminum", "n", 6468, "Word"],
  ["whoever", "p", 6250, "Word"],
  ["ministry", "n", 6250, "Word"],
  ["verdict", "n", 6529, "Word"],
  ["instruct", "v", 6117, "Word"],
  ["skull", "n", 6313, "Word"],
  ["selfesteem", "n", 6727, "Word"],
  ["ease", "n", 6103, "Word"],
  ["cooperative", "j", 6671, "Word"],
  ["manipulate", "v", 6159, "Word"],
  ["bee", "n", 6342, "Word"],
  ["practitioner", "n", 6670, "Word"],
  ["loop", "n", 6175, "Word"],
  ["edit", "v", 6157, "Word"],
  ["whip", "v", 6255, "Word"],
  ["puzzle", "n", 6153, "Word"],
  ["mushroom", "n", 6454, "Word"],
  ["subsidy", "n", 6236, "Word"],
  ["boil", "v", 6180, "Word"],
  ["tragic", "j", 6038, "Word"],
  ["mathematics", "n", 6828, "Word"],
  ["mechanic", "n", 6092, "Word"],
  ["jar", "n", 6284, "Word"],
  ["respect", "i", 6659, "Word"],
  ["earthquake", "n", 6439, "Word"],
  ["pork", "n", 6261, "Word"],
  ["creativity", "n", 6452, "Word"],
  ["safely", "r", 5959, "Word"],
  ["underlying", "j", 6340, "Word"],
  ["dessert", "n", 6286, "Word"],
  ["sympathy", "n", 6044, "Word"],
  ["fisherman", "n", 6317, "Word"],
  ["incredibly", "r", 6141, "Word"],
  ["isolation", "n", 6251, "Word"],
  ["sock", "n", 6273, "Word"],
  ["near", "r", 6097, "Word"],
  ["jump", "n", 6088, "Word"],
  ["eleven", "m", 6239, "Word"],
  ["sexy", "j", 6300, "Word"],
  ["entrepreneur", "n", 6238, "Word"],
  ["syndrome", "n", 6157, "Word"],
  ["bureau", "n", 6199, "Word"],
  ["seat", "v", 6227, "Word"],
  ["workplace", "n", 6186, "Word"],
  ["ambition", "n", 5962, "Word"],
  ["touchdown", "n", 6954, "Word"],
  ["utilize", "v", 6607, "Word"],
  ["breeze", "n", 6423, "Word"],
  ["costly", "j", 6130, "Word"],
  ["ambitious", "j", 6040, "Word"],
  ["christianity", "n", 6312, "Word"],
  ["presumably", "r", 6039, "Word"],
  ["influential", "j", 6161, "Word"],
  ["translation", "n", 6266, "Word"],
  ["uncertain", "j", 5951, "Word"],
  ["dissolve", "v", 6157, "Word"],
  ["object", "v", 5909, "Word"],
  ["statistical", "j", 6634, "Word"],
  ["gut", "n", 6067, "Word"],
  ["metropolitan", "j", 6149, "Word"],
  ["rolling", "j", 6066, "Word"],
  ["aesthetic", "j", 6647, "Word"],
  ["spell", "v", 5887, "Word"],
  ["insert", "v", 6067, "Word"],
  ["booth", "n", 6191, "Word"],
  ["helmet", "n", 6203, "Word"],
  ["waist", "n", 6394, "Word"],
  ["expected", "j", 6144, "Word"],
  ["lion", "n", 6075, "Word"],
  ["accomplishment", "n", 5980, "Word"],
  ["royal", "j", 6028, "Word"],
  ["panic", "n", 6087, "Word"],
  ["cast", "n", 6007, "Word"],
  ["crush", "v", 5985, "Word"],
  ["actively", "r", 6232, "Word"],
  ["cliff", "n", 6117, "Word"],
  ["minimal", "j", 6176, "Word"],
  ["cord", "n", 6071, "Word"],
  ["fortunately", "r", 5974, "Word"],
  ["cocaine", "n", 6207, "Word"],
  ["illusion", "n", 6035, "Word"],
  ["anonymous", "j", 5949, "Word"],
  ["tolerate", "v", 5871, "Word"],
  ["appreciation", "n", 6054, "Word"],
  ["commissioner", "n", 6303, "Word"],
  ["harm", "v", 5810, "Word"],
  ["flexibility", "n", 6149, "Word"],
  ["instructional", "j", 7014, "Word"],
  ["scramble", "v", 6061, "Word"],
  ["casino", "n", 6205, "Word"],
  ["tumor", "n", 6427, "Word"],
  ["decorate", "v", 5997, "Word"],
  ["sort", "v", 5809, "Word"],
  ["charge", "i", 5893, "Word"],
  ["pulse", "n", 5992, "Word"],
  ["equivalent", "n", 5935, "Word"],
  ["fixed", "j", 6042, "Word"],
  ["experienced", "j", 6031, "Word"],
  ["donation", "n", 6086, "Word"],
  ["diary", "n", 5945, "Word"],
  ["sibling", "n", 6102, "Word"],
  ["irony", "n", 5818, "Word"],
  ["spoon", "n", 6194, "Word"],
  ["midst", "n", 5760, "Word"],
  ["alley", "n", 6236, "Word"],
  ["upset", "j", 5954, "Word"],
  ["interact", "v", 6247, "Word"],
  ["soap", "n", 5952, "Word"],
  ["cute", "j", 6089, "Word"],
  ["rival", "n", 6005, "Word"],
  ["shortterm", "j", 5998, "Word"],
  ["punch", "v", 6071, "Word"],
  ["pin", "n", 6000, "Word"],
  ["hockey", "n", 6171, "Word"],
  ["passing", "j", 5903, "Word"],
  ["persist", "v", 6013, "Word"],
  ["supplier", "n", 6038, "Word"],
  ["known", "j", 5892, "Word"],
  ["momentum", "n", 5911, "Word"],
  ["purse", "n", 6161, "Word"],
  ["shed", "v", 5769, "Word"],
  ["liquid", "n", 5948, "Word"],
  ["icon", "n", 6030, "Word"],
  ["elephant", "n", 6038, "Word"],
  ["consequently", "r", 6463, "Word"],
  ["legislature", "n", 6027, "Word"],
  ["associate", "n", 5860, "Word"],
  ["franchise", "n", 6332, "Word"],
  ["correctly", "r", 5884, "Word"],
  ["mentally", "r", 5793, "Word"],
  ["foster", "v", 6237, "Word"],
  ["bicycle", "n", 5960, "Word"],
  ["encouraging", "j", 5868, "Word"],
  ["cheat", "v", 5819, "Word"],
  ["access", "v", 6023, "Word"],
  ["heal", "v", 5797, "Word"],
  ["fever", "n", 5874, "Word"],
  ["filter", "n", 6210, "Word"],
  ["rabbit", "n", 6095, "Word"],
  ["coin", "n", 5947, "Word"],
  ["exploit", "v", 5909, "Word"],
  ["accessible", "j", 5965, "Word"],
  ["organism", "n", 6210, "Word"],
  ["sensation", "n", 5882, "Word"],
  ["partially", "r", 5882, "Word"],
  ["stay", "n", 5779, "Word"],
  ["upstairs", "r", 6271, "Word"],
  ["dried", "j", 6109, "Word"],
  ["minimum", "n", 5867, "Word"],
  ["pro", "j", 6101, "Word"],
  ["conservation", "n", 6129, "Word"],
  ["shove", "v", 6327, "Word"],
  ["backyard", "n", 5890, "Word"],
  ["charter", "n", 6011, "Word"],
  ["stove", "n", 6082, "Word"],
  ["consent", "n", 6239, "Word"],
  ["comprise", "v", 6258, "Word"],
  ["reminder", "n", 5681, "Word"],
  ["alike", "r", 5740, "Word"],
  ["placement", "n", 6275, "Word"],
  ["dough", "n", 6294, "Word"],
  ["grandchild", "n", 5826, "Word"],
  ["dam", "n", 5968, "Word"],
  ["reportedly", "r", 5911, "Word"],
  ["wellknown", "j", 5782, "Word"],
  ["surrounding", "j", 5826, "Word"],
  ["ecological", "j", 6443, "Word"],
  ["outfit", "n", 5850, "Word"],
  ["unprecedented", "j", 5798, "Word"],
  ["columnist", "n", 5997, "Word"],
  ["workout", "n", 6414, "Word"],
  ["preliminary", "j", 5908, "Word"],
  ["patent", "n", 6024, "Word"],
  ["shy", "j", 5806, "Word"],
  ["quote", "n", 6228, "Word"],
  ["trash", "n", 5811, "Word"],
  ["disabled", "j", 5952, "Word"],
  ["gross", "j", 5802, "Word"],
  ["damn", "j", 6269, "Word"],
  ["hormone", "n", 6230, "Word"],
  ["texture", "n", 5994, "Word"],
  ["counter", "v", 5722, "Word"],
  ["pencil", "n", 5936, "Word"],
  ["associate", "j", 5859, "Word"],
  ["frontier", "n", 5897, "Word"],
  ["spray", "n", 6005, "Word"],
  ["bet", "n", 5801, "Word"],
  ["disclose", "v", 5879, "Word"],
  ["custody", "n", 5957, "Word"],
  ["banker", "n", 5933, "Word"],
  ["beast", "n", 6052, "Word"],
  ["interfere", "v", 5709, "Word"],
  ["oak", "n", 5938, "Word"],
  ["case", "c", 5791, "Word"],
  ["eighth", "m", 5814, "Word"],
  ["notebook", "n", 5884, "Word"],
  ["outline", "v", 5859, "Word"],
  ["gaze", "v", 6379, "Word"],
  ["attendance", "n", 5963, "Word"],
  ["speculation", "n", 5698, "Word"],
  ["uncover", "v", 5696, "Word"],
  ["behalf", "i", 5727, "Word"],
  ["innovative", "j", 5921, "Word"],
  ["shark", "n", 6164, "Word"],
  ["reward", "v", 5676, "Word"],
  ["mill", "n", 5761, "Word"],
  ["installation", "n", 5938, "Word"],
  ["stimulate", "v", 5898, "Word"],
  ["tag", "n", 5735, "Word"],
  ["vertical", "j", 5950, "Word"],
  ["swimming", "n", 5735, "Word"],
  ["fleet", "n", 5781, "Word"],
  ["catalog", "n", 5925, "Word"],
  ["outsider", "n", 5690, "Word"],
  ["sacrifice", "v", 5641, "Word"],
  ["desperately", "r", 5701, "Word"],
  ["stance", "n", 5812, "Word"],
  ["compel", "v", 5728, "Word"],
  ["sensitivity", "n", 5981, "Word"],
  ["someday", "r", 5744, "Word"],
  ["instant", "j", 5765, "Word"],
  ["debut", "n", 5986, "Word"],
  ["proclaim", "v", 5716, "Word"],
  ["worldwide", "r", 5781, "Word"],
  ["hike", "v", 6111, "Word"],
  ["required", "j", 5953, "Word"],
  ["confrontation", "n", 5762, "Word"],
  ["colorful", "j", 5773, "Word"],
  ["ideal", "n", 5978, "Word"],
  ["constitution", "n", 6111, "Word"],
  ["trainer", "n", 5845, "Word"],
  ["thanksgiving", "n", 5859, "Word"],
  ["scent", "n", 6050, "Word"],
  ["stack", "n", 5858, "Word"],
  ["eyebrow", "n", 6212, "Word"],
  ["sack", "n", 5880, "Word"],
  ["cease", "v", 5679, "Word"],
  ["inherit", "v", 5563, "Word"],
  ["tray", "n", 6021, "Word"],
  ["pioneer", "n", 5755, "Word"],
  ["organizational", "j", 6445, "Word"],
  ["textbook", "n", 5968, "Word"],
  ["uh", "u", 6155, "Word"],
  ["nasty", "j", 5682, "Word"],
  ["shrink", "v", 5617, "Word"],
  ["model", "v", 5925, "Word"],
  ["emerging", "j", 5942, "Word"],
  ["dot", "n", 5795, "Word"],
  ["wheat", "n", 5730, "Word"],
  ["fierce", "j", 5622, "Word"],
  ["envision", "v", 5619, "Word"],
  ["rational", "j", 5941, "Word"],
  ["kingdom", "n", 5674, "Word"],
  ["aisle", "n", 5828, "Word"],
  ["weaken", "v", 5640, "Word"],
  ["protocol", "n", 6031, "Word"],
  ["exclusively", "r", 5852, "Word"],
  ["vocal", "j", 5727, "Word"],
  ["marketplace", "n", 5650, "Word"],
  ["openly", "r", 5558, "Word"],
  ["unfair", "j", 5656, "Word"],
  ["terrain", "n", 5873, "Word"],
  ["deploy", "v", 5674, "Word"],
  ["risky", "j", 5632, "Word"],
  ["pasta", "n", 6062, "Word"],
  ["genre", "n", 5919, "Word"],
  ["distract", "v", 5720, "Word"],
  ["merit", "n", 5670, "Word"],
  ["planner", "n", 5797, "Word"],
  ["depressed", "j", 5543, "Word"],
  ["chunk", "n", 5682, "Word"],
  ["closest", "j", 5506, "Word"],
  ["discount", "n", 5809, "Word"],
  ["no", "d", 5591, "Word"],
  ["ladder", "n", 5720, "Word"],
  ["jungle", "n", 5693, "Word"],
  ["migration", "n", 6067, "Word"],
  ["breathing", "n", 5798, "Word"],
  ["invade", "v", 5653, "Word"],
  ["hurricane", "n", 6439, "Word"],
  ["retailer", "n", 5915, "Word"],
  ["classify", "v", 5964, "Word"],
  ["wound", "v", 5660, "Word"],
  ["coup", "n", 6179, "Word"],
  ["aid", "v", 5676, "Word"],
  ["ambassador", "n", 5847, "Word"],
  ["density", "n", 6040, "Word"],
  ["supportive", "j", 5662, "Word"],
  ["curiosity", "n", 5630, "Word"],
  ["skip", "v", 5635, "Word"],
  ["aggression", "n", 6215, "Word"],
  ["stimulus", "n", 6215, "Word"],
  ["journalism", "n", 5684, "Word"],
  ["robot", "n", 6023, "Word"],
  ["flood", "v", 5531, "Word"],
  ["dip", "v", 5664, "Word"],
  ["likewise", "r", 5821, "Word"],
  ["informal", "j", 5931, "Word"],
  ["persian", "j", 6340, "Word"],
  ["feather", "n", 5744, "Word"],
  ["sphere", "n", 5881, "Word"],
  ["tighten", "v", 5665, "Word"],
  ["boast", "v", 5731, "Word"],
  ["pat", "v", 5968, "Word"],
  ["perceived", "j", 6386, "Word"],
  ["sole", "j", 5535, "Word"],
  ["publicity", "n", 5557, "Word"],
  ["major", "n", 5678, "Word"],
  ["unfold", "v", 5420, "Word"],
  ["joke", "v", 5576, "Word"],
  ["wellbeing", "n", 6005, "Word"],
  ["validity", "n", 6401, "Word"],
  ["ecosystem", "n", 6215, "Word"],
  ["strictly", "r", 5532, "Word"],
  ["partial", "j", 5716, "Word"],
  ["collar", "n", 5791, "Word"],
  ["weed", "n", 5642, "Word"],
  ["compliance", "n", 5973, "Word"],
  ["streak", "n", 5826, "Word"],
  ["supposedly", "r", 5451, "Word"],
  ["added", "j", 5616, "Word"],
  ["builder", "n", 5720, "Word"],
  ["glimpse", "n", 5567, "Word"],
  ["premise", "n", 5695, "Word"],
  ["specialty", "n", 5622, "Word"],
  ["deem", "v", 5639, "Word"],
  ["artifact", "n", 5773, "Word"],
  ["sneak", "v", 5690, "Word"],
  ["monkey", "n", 5698, "Word"],
  ["mentor", "n", 5757, "Word"],
  ["twothirds", "m", 5558, "Word"],
  ["listener", "n", 5621, "Word"],
  ["lightning", "n", 5674, "Word"],
  ["legally", "r", 5484, "Word"],
  ["sleeve", "n", 5855, "Word"],
  ["disappointment", "n", 5464, "Word"],
  ["disturb", "v", 5532, "Word"],
  ["rib", "n", 5662, "Word"],
  ["excessive", "j", 5633, "Word"],
  ["hightech", "j", 5681, "Word"],
  ["debris", "n", 5491, "Word"],
  ["pile", "v", 5609, "Word"],
  ["rod", "n", 5788, "Word"],
  ["logical", "j", 5644, "Word"],
  ["liberal", "n", 5677, "Word"],
  ["ash", "n", 5647, "Word"],
  ["socially", "r", 5814, "Word"],
  ["parish", "n", 5723, "Word"],
  ["slavery", "n", 5712, "Word"],
  ["blank", "j", 5585, "Word"],
  ["commodity", "n", 5659, "Word"],
  ["cure", "n", 5468, "Word"],
  ["mineral", "n", 5758, "Word"],
  ["hunger", "n", 5460, "Word"],
  ["dying", "j", 5504, "Word"],
  ["developmental", "j", 6255, "Word"],
  ["faster", "j", 5538, "Word"],
  ["spare", "v", 5492, "Word"],
  ["halfway", "r", 5695, "Word"],
  ["cure", "v", 5444, "Word"],
  ["equality", "n", 5841, "Word"],
  ["cemetery", "n", 5584, "Word"],
  ["harassment", "n", 5882, "Word"],
  ["deliberately", "r", 5388, "Word"],
  ["fame", "n", 5485, "Word"],
  ["regret", "v", 5479, "Word"],
  ["striking", "j", 5516, "Word"],
  ["likelihood", "n", 5790, "Word"],
  ["carrot", "n", 5718, "Word"],
  ["atop", "i", 5578, "Word"],
  ["toll", "n", 5587, "Word"],
  ["rim", "n", 5676, "Word"],
  ["embarrassed", "j", 5627, "Word"],
  ["fucking", "j", 6472, "Word"],
  ["cling", "v", 5678, "Word"],
  ["isolated", "j", 5482, "Word"],
  ["blink", "v", 6160, "Word"],
  ["suspicious", "j", 5412, "Word"],
  ["wheelchair", "n", 5670, "Word"],
  ["squad", "n", 5520, "Word"],
  ["eligible", "j", 5529, "Word"],
  ["processor", "n", 5882, "Word"],
  ["plunge", "v", 5490, "Word"],
  ["this", "r", 5517, "Word"],
  ["sponsor", "n", 5622, "Word"],
  ["grin", "n", 5944, "Word"],
  ["color", "v", 5465, "Word"],
  ["demographic", "j", 6103, "Word"],
  ["rain", "v", 5597, "Word"],
  ["chill", "n", 5675, "Word"],
  ["refuge", "n", 5505, "Word"],
  ["steer", "v", 5452, "Word"],
  ["legislator", "n", 5593, "Word"],
  ["rally", "n", 5619, "Word"],
  ["programming", "n", 5507, "Word"],
  ["cheer", "v", 5442, "Word"],
  ["outlet", "n", 5497, "Word"],
  ["intact", "j", 5350, "Word"],
  ["vendor", "n", 5543, "Word"],
  ["thrive", "v", 5472, "Word"],
  ["peanut", "n", 5604, "Word"],
  ["chew", "v", 5669, "Word"],
  ["elaborate", "j", 5349, "Word"],
  ["intellectual", "n", 5773, "Word"],
  ["conception", "n", 5953, "Word"],
  ["auction", "n", 5621, "Word"],
  ["steak", "n", 5596, "Word"],
  ["comply", "v", 5452, "Word"],
  ["triumph", "n", 5337, "Word"],
  ["shareholder", "n", 5742, "Word"],
  ["comparable", "j", 5600, "Word"],
  ["transport", "v", 5336, "Word"],
  ["conscience", "n", 5317, "Word"],
  ["calculation", "n", 5577, "Word"],
  ["considerably", "r", 5481, "Word"],
  ["interval", "n", 5708, "Word"],
  ["scratch", "v", 5629, "Word"],
  ["awake", "j", 5806, "Word"],
  ["jurisdiction", "n", 5691, "Word"],
  ["inevitably", "r", 5420, "Word"],
  ["feminist", "j", 5719, "Word"],
  ["constraint", "n", 5942, "Word"],
  ["emotionally", "r", 5321, "Word"],
  ["expedition", "n", 5467, "Word"],
  ["allegedly", "r", 5451, "Word"],
  ["compromise", "v", 5322, "Word"],
  ["strain", "v", 5435, "Word"],
  ["similarity", "n", 5709, "Word"],
  ["butt", "n", 5490, "Word"],
  ["lid", "n", 5558, "Word"],
  ["dumb", "j", 5486, "Word"],
  ["bulk", "n", 5324, "Word"],
  ["sprinkle", "v", 5777, "Word"],
  ["mortality", "n", 5785, "Word"],
  ["philosophical", "j", 5731, "Word"],
  ["conversion", "n", 5627, "Word"],
  ["patron", "n", 5331, "Word"],
  ["municipal", "j", 5536, "Word"],
  ["any", "r", 5352, "Word"],
  ["liver", "n", 5415, "Word"],
  ["harmony", "n", 5410, "Word"],
  ["solely", "r", 5456, "Word"],
  ["tolerance", "n", 5513, "Word"],
  ["instant", "n", 5755, "Word"],
  ["goat", "n", 5459, "Word"],
  ["arm", "v", 5232, "Word"],
  ["blessing", "n", 5222, "Word"],
  ["banana", "n", 5458, "Word"],
  ["running", "n", 5271, "Word"],
  ["palace", "n", 5347, "Word"],
  ["formerly", "r", 5350, "Word"],
  ["peasant", "n", 5609, "Word"],
  ["neat", "j", 5359, "Word"],
  ["grandparent", "n", 5334, "Word"],
  ["lawmaker", "n", 5755, "Word"],
  ["supermarket", "n", 5389, "Word"],
  ["cruise", "n", 5434, "Word"],
  ["mobile", "j", 5339, "Word"],
  ["plain", "n", 5337, "Word"],
  ["part", "i", 5503, "Word"],
  ["calendar", "n", 5258, "Word"],
  ["widow", "n", 5309, "Word"],
  ["deposit", "n", 5497, "Word"],
  ["beard", "n", 5528, "Word"],
  ["brake", "n", 5522, "Word"],
  ["downtown", "r", 5483, "Word"],
  ["screening", "n", 5407, "Word"],
  ["impulse", "n", 5305, "Word"],
  ["forbid", "v", 5167, "Word"],
  ["fur", "n", 5466, "Word"],
  ["brutal", "j", 5193, "Word"],
  ["predator", "n", 5400, "Word"],
  ["poke", "v", 5575, "Word"],
  ["opt", "v", 5371, "Word"],
  ["voluntary", "j", 5504, "Word"],
  ["trouble", "v", 5177, "Word"],
  ["valid", "j", 5509, "Word"],
  ["forum", "n", 5419, "Word"],
  ["dancing", "n", 5243, "Word"],
  ["happily", "r", 5331, "Word"],
  ["soar", "v", 5344, "Word"],
  ["removal", "n", 5488, "Word"],
  ["autonomy", "n", 5849, "Word"],
  ["enact", "v", 5441, "Word"],
  ["round", "v", 5247, "Word"],
  ["thread", "n", 5340, "Word"],
  ["light", "i", 5458, "Word"],
  ["landmark", "n", 5332, "Word"],
  ["unhappy", "j", 5162, "Word"],
  ["offender", "n", 5417, "Word"],
  ["coming", "j", 5181, "Word"],
  ["privately", "r", 5212, "Word"],
  ["fraction", "n", 5313, "Word"],
  ["distinctive", "j", 5373, "Word"],
  ["tourism", "n", 5429, "Word"],
  ["threshold", "n", 5490, "Word"],
  ["calm", "j", 5309, "Word"],
  ["routinely", "r", 5269, "Word"],
  ["suite", "n", 5321, "Word"],
  ["remark", "v", 5289, "Word"],
  ["regulator", "n", 5462, "Word"],
  ["straw", "n", 5297, "Word"],
  ["theological", "j", 5684, "Word"],
  ["apart", "i", 5216, "Word"],
  ["exhaust", "v", 5204, "Word"],
  ["globe", "n", 5166, "Word"],
  ["fragile", "j", 5125, "Word"],
  ["objection", "n", 5276, "Word"],
  ["chemistry", "n", 5192, "Word"],
  ["oldfashioned", "j", 5200, "Word"],
  ["crowded", "j", 5196, "Word"],
  ["circle", "v", 5401, "Word"],
  ["blast", "n", 5241, "Word"],
  ["prevail", "v", 5207, "Word"],
  ["overnight", "r", 5170, "Word"],
  ["denial", "n", 5207, "Word"],
  ["rental", "n", 5357, "Word"],
  ["fantastic", "j", 5269, "Word"],
  ["fragment", "n", 5308, "Word"],
  ["level", "j", 5367, "Word"],
  ["screw", "v", 5361, "Word"],
  ["warmth", "n", 5383, "Word"],
  ["undergraduate", "n", 5683, "Word"],
  ["liquid", "j", 5265, "Word"],
  ["headache", "n", 5256, "Word"],
  ["policeman", "n", 5278, "Word"],
  ["yield", "n", 5648, "Word"],
  ["projection", "n", 5288, "Word"],
  ["battle", "v", 5240, "Word"],
  ["suitable", "j", 5291, "Word"],
  ["mention", "n", 5137, "Word"],
  ["graduation", "n", 5218, "Word"],
  ["drill", "v", 5279, "Word"],
  ["cruel", "j", 5128, "Word"],
  ["mansion", "n", 5233, "Word"],
  ["regard", "n", 5263, "Word"],
  ["grape", "n", 5318, "Word"],
  ["authorize", "v", 5209, "Word"],
  ["cottage", "n", 5328, "Word"],
  ["driveway", "n", 5459, "Word"],
  ["charm", "n", 5157, "Word"],
  ["sexuality", "n", 5531, "Word"],
  ["loyal", "j", 5066, "Word"],
  ["clay", "n", 5287, "Word"],
  ["pound", "v", 5327, "Word"],
  ["balloon", "n", 5187, "Word"],
  ["invention", "n", 5211, "Word"],
  ["ego", "n", 5239, "Word"],
  ["fare", "n", 5362, "Word"],
  ["homework", "n", 5093, "Word"],
  ["disc", "n", 5403, "Word"],
  ["sofa", "n", 5499, "Word"],
  ["guarantee", "n", 5205, "Word"],
  ["availability", "n", 5519, "Word"],
  ["radar", "n", 5221, "Word"],
  ["frown", "v", 5903, "Word"],
  ["regain", "v", 5041, "Word"],
  ["leave", "n", 5087, "Word"],
  ["permit", "n", 5305, "Word"],
  ["sweater", "n", 5392, "Word"],
  ["rehabilitation", "n", 5547, "Word"],
  ["rubber", "j", 5190, "Word"],
  ["retreat", "n", 5132, "Word"],
  ["molecule", "n", 5445, "Word"],
  ["freely", "r", 5085, "Word"],
  ["favorable", "j", 5324, "Word"],
  ["steadily", "r", 5085, "Word"],
  ["veteran", "j", 5312, "Word"],
  ["integrated", "j", 5455, "Word"],
  ["ha", "u", 5372, "Word"],
  ["youngster", "n", 5184, "Word"],
  ["broadcast", "v", 5138, "Word"],
  ["premium", "n", 5279, "Word"],
  ["accountability", "n", 5409, "Word"],
  ["overwhelm", "v", 4976, "Word"],
  ["onethird", "m", 5210, "Word"],
  ["contemplate", "v", 5027, "Word"],
  ["update", "v", 5227, "Word"],
  ["spark", "v", 5071, "Word"],
  ["ironically", "r", 5154, "Word"],
  ["fatigue", "n", 5214, "Word"],
  ["beyond", "r", 5036, "Word"],
  ["speculate", "v", 5012, "Word"],
  ["marker", "n", 5156, "Word"],
  ["low", "r", 5024, "Word"],
  ["preach", "v", 5063, "Word"],
  ["bucket", "n", 5233, "Word"],
  ["bomb", "v", 5275, "Word"],
  ["blond", "j", 5479, "Word"],
  ["confession", "n", 5074, "Word"],
  ["provoke", "v", 5063, "Word"],
  ["marble", "n", 5210, "Word"],
  ["substantially", "r", 5344, "Word"],
  ["twist", "n", 5060, "Word"],
  ["defender", "n", 5136, "Word"],
  ["fish", "v", 5183, "Word"],
  ["explicit", "j", 5461, "Word"],
  ["transport", "n", 5313, "Word"],
  ["disturbing", "j", 4990, "Word"],
  ["surveillance", "n", 5132, "Word"],
  ["magnetic", "j", 5377, "Word"],
  ["technician", "n", 5048, "Word"],
  ["mutter", "v", 5753, "Word"],
  ["devastating", "j", 5055, "Word"],
  ["depart", "v", 5035, "Word"],
  ["arrow", "n", 5208, "Word"],
  ["trauma", "n", 5880, "Word"],
  ["neighboring", "j", 5082, "Word"],
  ["soak", "v", 5186, "Word"],
  ["ribbon", "n", 5159, "Word"],
  ["meantime", "n", 5022, "Word"],
  ["transmit", "v", 5117, "Word"],
  ["screen", "v", 5079, "Word"],
  ["harvest", "n", 5125, "Word"],
  ["consecutive", "j", 5471, "Word"],
  ["republican", "j", 5297, "Word"],
  ["coordinate", "v", 5152, "Word"],
  ["worldwide", "j", 5089, "Word"],
  ["within", "r", 5085, "Word"],
  ["spy", "n", 5076, "Word"],
  ["slot", "n", 5130, "Word"],
  ["riot", "n", 5203, "Word"],
  ["nutrient", "n", 5426, "Word"],
  ["citizenship", "n", 5602, "Word"],
  ["severely", "r", 5035, "Word"],
  ["sovereignty", "n", 5541, "Word"],
  ["ridge", "n", 5229, "Word"],
  ["brave", "j", 5061, "Word"],
  ["lighting", "n", 5081, "Word"],
  ["specify", "v", 5379, "Word"],
  ["contributor", "n", 5084, "Word"],
  ["frustrate", "v", 4933, "Word"],
  ["crowd", "v", 5083, "Word"],
  ["articulate", "v", 5350, "Word"],
  ["importantly", "r", 5130, "Word"],
  ["transit", "n", 5226, "Word"],
  ["dense", "j", 5061, "Word"],
  ["seminar", "n", 5106, "Word"],
  ["electronics", "n", 5116, "Word"],
  ["sunny", "j", 5117, "Word"],
  ["shorts", "n", 5148, "Word"],
  ["swell", "v", 5125, "Word"],
  ["accusation", "n", 4987, "Word"],
  ["soften", "v", 5112, "Word"],
  ["photograph", "v", 5006, "Word"],
  ["straighten", "v", 5352, "Word"],
  ["terribly", "r", 5096, "Word"],
  ["cue", "n", 5186, "Word"],
  ["sudden", "r", 5211, "Word"],
  ["bride", "n", 5613, "Word"],
  ["biography", "n", 5015, "Word"],
  ["hazard", "n", 5159, "Word"],
  ["compelling", "j", 4995, "Word"],
  ["seldom", "r", 5009, "Word"],
  ["tile", "n", 5200, "Word"],
  ["economically", "r", 5169, "Word"],
  ["honestly", "r", 5042, "Word"],
  ["troubled", "j", 4991, "Word"],
  ["bow", "v", 5293, "Word"],
  ["twentieth", "m", 5458, "Word"],
  ["balanced", "j", 5486, "Word"],
  ["foreigner", "n", 5076, "Word"],
  ["launch", "n", 5080, "Word"],
  ["convenience", "n", 4972, "Word"],
  ["delight", "n", 5038, "Word"],
  ["weave", "v", 5015, "Word"],
  ["timber", "n", 5150, "Word"],
  ["till", "c", 5269, "Word"],
  ["accurately", "r", 5156, "Word"],
  ["plea", "n", 5000, "Word"],
  ["bulb", "n", 5157, "Word"],
  ["copy", "v", 4970, "Word"],
  ["flying", "j", 5056, "Word"],
  ["sustainable", "j", 5339, "Word"],
  ["devil", "n", 5051, "Word"],
  ["bolt", "n", 5148, "Word"],
  ["cargo", "n", 4991, "Word"],
  ["spine", "n", 5110, "Word"],
  ["seller", "n", 5083, "Word"],
  ["skilled", "j", 5047, "Word"],
  ["managing", "j", 5126, "Word"],
  ["public", "r", 4877, "Word"],
  ["marine", "j", 5154, "Word"],
  ["dock", "n", 5178, "Word"],
  ["organized", "j", 5011, "Word"],
  ["fog", "n", 5186, "Word"],
  ["diplomat", "n", 5185, "Word"],
  ["boring", "j", 4922, "Word"],
  ["sometime", "r", 4938, "Word"],
  ["summary", "n", 5304, "Word"],
  ["missionary", "n", 5438, "Word"],
  ["epidemic", "n", 5010, "Word"],
  ["fatal", "j", 4875, "Word"],
  ["trim", "v", 5113, "Word"],
  ["warehouse", "n", 5020, "Word"],
  ["accelerate", "v", 4923, "Word"],
  ["butterfly", "n", 5031, "Word"],
  ["bronze", "n", 5118, "Word"],
  ["drown", "v", 5097, "Word"],
  ["inherent", "j", 5339, "Word"],
  ["praise", "n", 4896, "Word"],
  ["nationwide", "r", 5104, "Word"],
  ["spit", "v", 5286, "Word"],
  ["harvest", "v", 5065, "Word"],
  ["kneel", "v", 5467, "Word"],
  ["vacuum", "n", 5028, "Word"],
  ["selected", "j", 5315, "Word"],
  ["dictate", "v", 4935, "Word"],
  ["stereotype", "n", 5109, "Word"],
  ["sensor", "n", 5154, "Word"],
  ["laundry", "n", 5063, "Word"],
  ["manual", "n", 5040, "Word"],
  ["pistol", "n", 5261, "Word"],
  ["naval", "j", 4990, "Word"],
  ["plaintiff", "n", 5312, "Word"],
  ["kid", "v", 5094, "Word"],
  ["middleclass", "j", 5025, "Word"],
  ["apology", "n", 4972, "Word"],
  ["till", "i", 5079, "Word"],
  ["10", "g", 5000, "Number"],
  ["11", "g", 5000, "Number"],
  ["12", "g", 5000, "Number"],
  ["13", "g", 5000, "Number"],
  ["14", "g", 5000, "Number"],
  ["15", "g", 5000, "Number"],
  ["16", "g", 5000, "Number"],
  ["17", "g", 5000, "Number"],
  ["18", "g", 5000, "Number"],
  ["19", "g", 5000, "Number"],
  ["20", "g", 5000, "Number"],
  ["21", "g", 5000, "Number"],
  ["22", "g", 5000, "Number"],
  ["23", "g", 5000, "Number"],
  ["24", "g", 5000, "Number"],
  ["25", "g", 5000, "Number"],
  ["26", "g", 5000, "Number"],
  ["27", "g", 5000, "Number"],
  ["28", "g", 5000, "Number"],
  ["29", "g", 5000, "Number"],
  ["30", "g", 5000, "Number"],
  ["31", "g", 5000, "Number"],
  ["32", "g", 5000, "Number"],
  ["33", "g", 5000, "Number"],
  ["34", "g", 5000, "Number"],
  ["35", "g", 5000, "Number"],
  ["36", "g", 5000, "Number"],
  ["37", "g", 5000, "Number"],
  ["38", "g", 5000, "Number"],
  ["39", "g", 5000, "Number"],
  ["40", "g", 5000, "Number"],
  ["41", "g", 5000, "Number"],
  ["42", "g", 5000, "Number"],
  ["43", "g", 5000, "Number"],
  ["44", "g", 5000, "Number"],
  ["45", "g", 5000, "Number"],
  ["46", "g", 5000, "Number"],
  ["47", "g", 5000, "Number"],
  ["48", "g", 5000, "Number"],
  ["49", "g", 5000, "Number"],
  ["50", "g", 5000, "Number"],
  ["51", "g", 5000, "Number"],
  ["52", "g", 5000, "Number"],
  ["53", "g", 5000, "Number"],
  ["54", "g", 5000, "Number"],
  ["55", "g", 5000, "Number"],
  ["56", "g", 5000, "Number"],
  ["57", "g", 5000, "Number"],
  ["58", "g", 5000, "Number"],
  ["59", "g", 5000, "Number"],
  ["60", "g", 5000, "Number"],
  ["61", "g", 5000, "Number"],
  ["62", "g", 5000, "Number"],
  ["63", "g", 5000, "Number"],
  ["64", "g", 5000, "Number"],
  ["65", "g", 5000, "Number"],
  ["66", "g", 5000, "Number"],
  ["67", "g", 5000, "Number"],
  ["68", "g", 5000, "Number"],
  ["69", "g", 5000, "Number"],
  ["70", "g", 5000, "Number"],
  ["71", "g", 5000, "Number"],
  ["72", "g", 5000, "Number"],
  ["73", "g", 5000, "Number"],
  ["74", "g", 5000, "Number"],
  ["75", "g", 5000, "Number"],
  ["76", "g", 5000, "Number"],
  ["77", "g", 5000, "Number"],
  ["78", "g", 5000, "Number"],
  ["79", "g", 5000, "Number"],
  ["80", "g", 5000, "Number"],
  ["81", "g", 5000, "Number"],
  ["82", "g", 5000, "Number"],
  ["83", "g", 5000, "Number"],
  ["84", "g", 5000, "Number"],
  ["85", "g", 5000, "Number"],
  ["86", "g", 5000, "Number"],
  ["87", "g", 5000, "Number"],
  ["88", "g", 5000, "Number"],
  ["89", "g", 5000, "Number"],
  ["90", "g", 5000, "Number"],
  ["91", "g", 5000, "Number"],
  ["92", "g", 5000, "Number"],
  ["93", "g", 5000, "Number"],
  ["94", "g", 5000, "Number"],
  ["95", "g", 5000, "Number"],
  ["96", "g", 5000, "Number"],
  ["97", "g", 5000, "Number"],
  ["98", "g", 5000, "Number"],
  ["99", "g", 5000, "Number"],
  ["100", "g", 5000, "Number"],
  ["119", "g", 5000, "Number"],
  ["129", "g", 5000, "Number"],
  ["139", "g", 5000, "Number"],
  ["149", "g", 5000, "Number"],
  ["159", "g", 5000, "Number"],
  ["169", "g", 5000, "Number"],
  ["179", "g", 5000, "Number"],
  ["189", "g", 5000, "Number"],
  ["199", "g", 5000, "Number"],
  ["219", "g", 5000, "Number"],
  ["229", "g", 5000, "Number"],
  ["239", "g", 5000, "Number"],
  ["249", "g", 5000, "Number"],
  ["259", "g", 5000, "Number"],
  ["269", "g", 5000, "Number"],
  ["279", "g", 5000, "Number"],
  ["289", "g", 5000, "Number"],
  ["299", "g", 5000, "Number"],
  ["319", "g", 5000, "Number"],
  ["329", "g", 5000, "Number"],
  ["339", "g", 5000, "Number"],
  ["349", "g", 5000, "Number"],
  ["359", "g", 5000, "Number"],
  ["369", "g", 5000, "Number"],
  ["379", "g", 5000, "Number"],
  ["389", "g", 5000, "Number"],
  ["399", "g", 5000, "Number"],
  ["419", "g", 5000, "Number"],
  ["429", "g", 5000, "Number"],
  ["439", "g", 5000, "Number"],
  ["449", "g", 5000, "Number"],
  ["459", "g", 5000, "Number"],
  ["469", "g", 5000, "Number"],
  ["479", "g", 5000, "Number"],
  ["489", "g", 5000, "Number"],
  ["499", "g", 5000, "Number"],
  ["519", "g", 5000, "Number"],
  ["529", "g", 5000, "Number"],
  ["539", "g", 5000, "Number"],
  ["549", "g", 5000, "Number"],
  ["559", "g", 5000, "Number"],
  ["569", "g", 5000, "Number"],
  ["579", "g", 5000, "Number"],
  ["589", "g", 5000, "Number"],
  ["599", "g", 5000, "Number"],
  ["1900", "g", 5000, "Year"],
  ["1901", "g", 5000, "Year"],
  ["1902", "g", 5000, "Year"],
  ["1903", "g", 5000, "Year"],
  ["1904", "g", 5000, "Year"],
  ["1905", "g", 5000, "Year"],
  ["1906", "g", 5000, "Year"],
  ["1907", "g", 5000, "Year"],
  ["1908", "g", 5000, "Year"],
  ["1909", "g", 5000, "Year"],
  ["1910", "g", 5000, "Year"],
  ["1911", "g", 5000, "Year"],
  ["1912", "g", 5000, "Year"],
  ["1913", "g", 5000, "Year"],
  ["1914", "g", 5000, "Year"],
  ["1915", "g", 5000, "Year"],
  ["1916", "g", 5000, "Year"],
  ["1917", "g", 5000, "Year"],
  ["1918", "g", 5000, "Year"],
  ["1919", "g", 5000, "Year"],
  ["1920", "g", 5000, "Year"],
  ["1921", "g", 5000, "Year"],
  ["1922", "g", 5000, "Year"],
  ["1923", "g", 5000, "Year"],
  ["1924", "g", 5000, "Year"],
  ["1925", "g", 5000, "Year"],
  ["1926", "g", 5000, "Year"],
  ["1927", "g", 5000, "Year"],
  ["1928", "g", 5000, "Year"],
  ["1929", "g", 5000, "Year"],
  ["1930", "g", 5000, "Year"],
  ["1931", "g", 5000, "Year"],
  ["1932", "g", 5000, "Year"],
  ["1933", "g", 5000, "Year"],
  ["1934", "g", 5000, "Year"],
  ["1935", "g", 5000, "Year"],
  ["1936", "g", 5000, "Year"],
  ["1937", "g", 5000, "Year"],
  ["1938", "g", 5000, "Year"],
  ["1939", "g", 5000, "Year"],
  ["1940", "g", 5000, "Year"],
  ["1941", "g", 5000, "Year"],
  ["1942", "g", 5000, "Year"],
  ["1943", "g", 5000, "Year"],
  ["1944", "g", 5000, "Year"],
  ["1945", "g", 5000, "Year"],
  ["1946", "g", 5000, "Year"],
  ["1947", "g", 5000, "Year"],
  ["1948", "g", 5000, "Year"],
  ["1949", "g", 5000, "Year"],
  ["1950", "g", 5000, "Year"],
  ["1951", "g", 5000, "Year"],
  ["1952", "g", 5000, "Year"],
  ["1953", "g", 5000, "Year"],
  ["1954", "g", 5000, "Year"],
  ["1955", "g", 5000, "Year"],
  ["1956", "g", 5000, "Year"],
  ["1957", "g", 5000, "Year"],
  ["1958", "g", 5000, "Year"],
  ["1959", "g", 5000, "Year"],
  ["1960", "g", 5000, "Year"],
  ["1961", "g", 5000, "Year"],
  ["1962", "g", 5000, "Year"],
  ["1963", "g", 5000, "Year"],
  ["1964", "g", 5000, "Year"],
  ["1965", "g", 5000, "Year"],
  ["1966", "g", 5000, "Year"],
  ["1967", "g", 5000, "Year"],
  ["1968", "g", 5000, "Year"],
  ["1969", "g", 5000, "Year"],
  ["1970", "g", 5000, "Year"],
  ["1971", "g", 5000, "Year"],
  ["1972", "g", 5000, "Year"],
  ["1973", "g", 5000, "Year"],
  ["1974", "g", 5000, "Year"],
  ["1975", "g", 5000, "Year"],
  ["1976", "g", 5000, "Year"],
  ["1977", "g", 5000, "Year"],
  ["1978", "g", 5000, "Year"],
  ["1979", "g", 5000, "Year"],
  ["1980", "g", 5000, "Year"],
  ["1981", "g", 5000, "Year"],
  ["1982", "g", 5000, "Year"],
  ["1983", "g", 5000, "Year"],
  ["1984", "g", 5000, "Year"],
  ["1985", "g", 5000, "Year"],
  ["1986", "g", 5000, "Year"],
  ["1987", "g", 5000, "Year"],
  ["1988", "g", 5000, "Year"],
  ["1989", "g", 5000, "Year"],
  ["1990", "g", 5000, "Year"],
  ["1991", "g", 5000, "Year"],
  ["1992", "g", 5000, "Year"],
  ["1993", "g", 5000, "Year"],
  ["1994", "g", 5000, "Year"],
  ["1995", "g", 5000, "Year"],
  ["1996", "g", 5000, "Year"],
  ["1997", "g", 5000, "Year"],
  ["1998", "g", 5000, "Year"],
  ["1999", "g", 5000, "Year"],
  ["2000", "g", 5000, "Year"],
  ["2001", "g", 5000, "Year"],
  ["2002", "g", 5000, "Year"],
  ["2003", "g", 5000, "Year"],
  ["2004", "g", 5000, "Year"],
  ["2005", "g", 5000, "Year"],
  ["2006", "g", 5000, "Year"],
  ["2007", "g", 5000, "Year"],
  ["2008", "g", 5000, "Year"],
  ["2009", "g", 5000, "Year"],
  ["2010", "g", 5000, "Year"],
  ["2011", "g", 5000, "Year"],
  ["2012", "g", 5000, "Year"],
  ["2013", "g", 5000, "Year"],
  ["2014", "g", 5000, "Year"],
  ["2015", "g", 5000, "Year"],
  ["2016", "g", 5000, "Year"],
  ["2017", "g", 5000, "Year"],
  ["2018", "g", 5000, "Year"],
  ["2019", "g", 5000, "Year"],
  ["2020", "g", 5000, "Year"],
  ["2021", "g", 5000, "Year"],
  ["2022", "g", 5000, "Year"],
  ["2023", "g", 5000, "Year"],
  ["2024", "g", 5000, "Year"],
  ["2025", "g", 5000, "Year"],
  ["2026", "g", 5000, "Year"],
  ["2027", "g", 5000, "Year"],
  ["2028", "g", 5000, "Year"],
  ["2029", "g", 5000, "Year"],
  ["2030", "g", 5000, "Year"],
  ["2031", "g", 5000, "Year"],
  ["2032", "g", 5000, "Year"],
  ["2033", "g", 5000, "Year"],
  ["2034", "g", 5000, "Year"],
  ["2035", "g", 5000, "Year"],
  ["2036", "g", 5000, "Year"],
  ["2037", "g", 5000, "Year"],
  ["2038", "g", 5000, "Year"],
  ["2039", "g", 5000, "Year"],
  ["2040", "g", 5000, "Year"],
  ["2041", "g", 5000, "Year"],
  ["2042", "g", 5000, "Year"],
  ["2043", "g", 5000, "Year"],
  ["2044", "g", 5000, "Year"],
  ["2045", "g", 5000, "Year"],
  ["2046", "g", 5000, "Year"],
  ["2047", "g", 5000, "Year"],
  ["2048", "g", 5000, "Year"],
  ["2049", "g", 5000, "Year"],
  ["2050", "g", 5000, "Year"],
  ["73", "u", 200000, "Abbreviation"],
  ["72", "u", 100000, "Abbreviation"],
  ["88", "u", 100000, "Abbreviation"],
  ["agn", "r", 150000, "Abbreviation"],
  ["ant", "n", 150000, "Abbreviation"],
  ["b4", "i", 172769, "Abbreviation"],
  ["b4", "c", 107448, "Abbreviation"],
  ["b4", "r", 55608, "Abbreviation"],
  ["bk", "n", 200000, "Abbreviation"],
  ["btr", "j", 83895, "Abbreviation"],
  ["btr", "r", 78334, "Abbreviation"],
  ["btw", "n", 50000, "Abbreviation"],
  ["bug", "n", 50000, "Word"],
  ["buro", "n", 100000, "Word"],
  ["cfm", "v", 19327, "Abbreviation"],
  ["ck", "v", 45760, "Abbreviation"],
  ["ck", "n", 18764, "Abbreviation"],
  ["cl", "v", 100000, "Abbreviation"],
  ["cnt", "v", 28836, "Abbreviation"],
  ["cnt", "n", 13007, "Abbreviation"],
  ["condx", "n", 100000, "Abbreviation"],
  ["cpy", "v", 200000, "Abbreviation"],
  ["cpy", "n", 200000, "Abbreviation"],
  ["cq", "v", 250000, "Abbreviation"],
  ["cu", "v", 100000, "Abbreviation"],
  ["cuagn", "v", 100000, "Abbreviation"],
  ["cud", "v", 617932, "Abbreviation"],
  ["cul", "v", 100000, "Abbreviation"],
  ["cuz", "c", 438539, "Abbreviation"],
  ["cuz", "i", 77116, "Abbreviation"],
  ["dx", "n", 100000, "Abbreviation"],
  ["es", "c", 10741073, "Abbreviation"],
  ["fb", "n", 200000, "Abbreviation"],
  ["ga", "n", 200000, "Abbreviation"],
  ["ge", "n", 200000, "Abbreviation"],
  ["gm", "n", 200000, "Abbreviation"],
  ["gl", "n", 200000, "Abbreviation"],
  ["gg", "v", 10000, "Abbreviation"],
  ["hi", "u", 200000, "Abbreviation"],
  ["hpe", "v", 81385, "Abbreviation"],
  ["hpe", "n", 33019, "Abbreviation"],
  ["hr", "r", 412315, "Abbreviation"],
  ["hw", "r", 538893, "Abbreviation"],
  ["khz", "n", 10000, "Abbreviation"],
  ["mhz", "n", 10000, "Abbreviation"],
  ["nr", "n", 186005, "Abbreviation"],
  ["om", "n", 150000, "Abbreviation"],
  ["pse", "r", 34709, "Abbreviation"],
  ["pse", "v", 6565, "Abbreviation"],
  ["pwr", "n", 150000, "Abbreviation"],
  ["op", "n", 150000, "Abbreviation"],
  ["rig", "n", 400000, "Abbreviation"],
  ["hv", "n", 5000, "Abbreviation"],
  ["xyl", "n", 5000, "Abbreviation"],
  ["yl", "n", 2500, "Abbreviation"],
  ["nw", "r", 5000, "Abbreviation"],
  ["sri", "j", 5000, "Abbreviation"],
  ["gud", "j", 50000, "Abbreviation"],
  ["rpt", "n", 50000, "Abbreviation"],
  ["nil", "v", 25000, "Abbreviation"],
  ["wud", "v", 5000, "Abbreviation"],
  ["sked", "n", 5000, "Abbreviation"],
  ["sig", "n", 25000, "Abbreviation"],
  ["abt", "n", 5000, "Abbreviation"],
  ["ur", "v", 50000, "Abbreviation"],
  ["rcvr", "v", 5000, "Abbreviation"],
  ["qrl", "v", 150000, "Q Code"],
  ["qrm", "n", 150000, "Q Code"],
  ["qrn", "n", 150000, "Q Code"],
  ["qrq", "v", 150000, "Q Code"],
  ["qrs", "v", 150000, "Q Code"],
  ["qrz", "v", 200000, "Q Code"],
  ["qsb", "n", 150000, "Q Code"],
  ["qsy", "v", 150000, "Q Code"],
  ["qth", "n", 350000, "Q Code"],
  ["qso", "n", 200000, "Q Code"],
  ["qrp", "n", 200000, "Q Code"],
  ["qro", "n", 150000, "Q Code"],
  ["qsl", "n", 150000, "Q Code"],
  ["james", "n", 25000, "US Name"],
  ["jon", "n", 25000, "US Name"],
  ["john", "n", 25000, "US Name"],
  ["rob", "n", 25000, "US Name"],
  ["michael", "n", 25000, "US Name"],
  ["will", "n", 25000, "US Name"],
  ["david", "n", 25000, "US Name"],
  ["richard", "n", 25000, "US Name"],
  ["charles", "n", 25000, "US Name"],
  ["joe", "n", 25000, "US Name"],
  ["tom", "n", 25000, "US Name"],
  ["chris", "n", 25000, "US Name"],
  ["dan", "n", 25000, "US Name"],
  ["paul", "n", 25000, "US Name"],
  ["mark", "n", 25000, "US Name"],
  ["don", "n", 25000, "US Name"],
  ["george", "n", 25000, "US Name"],
  ["ken", "n", 25000, "US Name"],
  ["steve", "n", 25000, "US Name"],
  ["ed", "n", 25000, "US Name"],
  ["brian", "n", 25000, "US Name"],
  ["ron", "n", 25000, "US Name"],
  ["anthony", "n", 25000, "US Name"],
  ["kevin", "n", 25000, "US Name"],
  ["jason", "n", 25000, "US Name"],
  ["matt", "n", 25000, "US Name"],
  ["gary", "n", 25000, "US Name"],
  ["jose", "n", 25000, "US Name"],
  ["larry", "n", 25000, "US Name"],
  ["jeff", "n", 25000, "US Name"],
  ["frank", "n", 25000, "US Name"],
  ["scott", "n", 25000, "US Name"],
  ["eric", "n", 25000, "US Name"],
  ["stephen", "n", 25000, "US Name"],
  ["andrew", "n", 25000, "US Name"],
  ["ray", "n", 25000, "US Name"],
  ["greg", "n", 25000, "US Name"],
  ["josh", "n", 25000, "US Name"],
  ["jerry", "n", 25000, "US Name"],
  ["dennis", "n", 25000, "US Name"],
  ["walter", "n", 25000, "US Name"],
  ["pat", "n", 25000, "US Name"],
  ["peter", "n", 25000, "US Name"],
  ["harold", "n", 25000, "US Name"],
  ["doug", "n", 25000, "US Name"],
  ["henry", "n", 25000, "US Name"],
  ["carol", "n", 25000, "US Name"],
  ["arthur", "n", 25000, "US Name"],
  ["ryan", "n", 25000, "US Name"],
  ["roger", "n", 25000, "US Name"],
  ["juan", "n", 25000, "US Name"],
  ["jack", "n", 25000, "US Name"],
  ["albert", "n", 25000, "US Name"],
  ["justin", "n", 25000, "US Name"],
  ["terry", "n", 25000, "US Name"],
  ["gerald", "n", 25000, "US Name"],
  ["keith", "n", 25000, "US Name"],
  ["sam", "n", 25000, "US Name"],
  ["willie", "n", 25000, "US Name"],
  ["ralph", "n", 25000, "US Name"],
  ["lawrence", "n", 25000, "US Name"],
  ["nick", "n", 25000, "US Name"],
  ["roy", "n", 25000, "US Name"],
  ["ben", "n", 25000, "US Name"],
  ["bruce", "n", 25000, "US Name"],
  ["brandon", "n", 25000, "US Name"],
  ["adam", "n", 25000, "US Name"],
  ["harry", "n", 25000, "US Name"],
  ["fred", "n", 25000, "US Name"],
  ["wayne", "n", 25000, "US Name"],
  ["billy", "n", 25000, "US Name"],
  ["louis", "n", 25000, "US Name"],
  ["jeremy", "n", 25000, "US Name"],
  ["arron", "n", 25000, "US Name"],
  ["randy", "n", 25000, "US Name"],
  ["howard", "n", 25000, "US Name"],
  ["eugene", "n", 25000, "US Name"],
  ["carolos", "n", 25000, "US Name"],
  ["russel", "n", 25000, "US Name"],
  ["bob", "n", 25000, "US Name"],
  ["victor", "n", 25000, "US Name"],
  ["martin", "n", 25000, "US Name"],
  ["ernest", "n", 25000, "US Name"],
  ["phil", "n", 25000, "US Name"],
  ["todd", "n", 25000, "US Name"],
  ["jesse", "n", 25000, "US Name"],
  ["craig", "n", 25000, "US Name"],
  ["alan", "n", 25000, "US Name"],
  ["shawn", "n", 25000, "US Name"],
  ["clarence", "n", 25000, "US Name"],
  ["sean", "n", 25000, "US Name"],
  ["chris", "n", 25000, "US Name"],
  ["earl", "n", 25000, "US Name"],
  ["jon", "n", 25000, "US Name"],
  ["jim", "n", 25000, "US Name"],
  ["antonio", "n", 25000, "US Name"],
  ["dan", "n", 25000, "US Name"],
  ["jon", "n", 25000, "US Name"],
  ["kurt", "n", 25000, "US Name"],
  ["curt", "n", 25000, "US Name"],
  ["rst", "n", 250000, "Abbreviation"],
  ["sos", "n", 5000, "Abbreviation"],
  ["tnx", "n", 23061, "Abbreviation"],
  ["tt", "c", 3430996, "Abbreviation"],
  ["tt", "d", 1712406, "Abbreviation"],
  ["tt", "r", 43359, "Abbreviation"],
  ["tu", "n", 23061, "Abbreviation"],
  ["ufb", "n", 154468, "Abbreviation"],
  ["vfb", "n", 154468, "Abbreviation"],
  ["vy", "r", 391821, "Abbreviation"],
  ["vy", "j", 31807, "Abbreviation"],
  ["wx", "n", 22586, "Abbreviation"],
  ["AL", "n", 50000, "US State Abbreviation"],
  ["AK", "n", 50000, "US State Abbreviation"],
  ["AZ", "n", 50000, "US State Abbreviation"],
  ["AR", "n", 50000, "US State Abbreviation"],
  ["CA", "n", 50000, "US State Abbreviation"],
  ["CO", "n", 50000, "US State Abbreviation"],
  ["CT", "n", 50000, "US State Abbreviation"],
  ["DE", "n", 50000, "US State Abbreviation"],
  ["FL", "n", 50000, "US State Abbreviation"],
  ["GA", "n", 50000, "US State Abbreviation"],
  ["HI", "n", 50000, "US State Abbreviation"],
  ["ID", "n", 50000, "US State Abbreviation"],
  ["IL", "n", 50000, "US State Abbreviation"],
  ["IN", "n", 50000, "US State Abbreviation"],
  ["IA", "n", 50000, "US State Abbreviation"],
  ["KS", "n", 50000, "US State Abbreviation"],
  ["KY", "n", 50000, "US State Abbreviation"],
  ["LA", "n", 50000, "US State Abbreviation"],
  ["ME", "n", 50000, "US State Abbreviation"],
  ["MD", "n", 50000, "US State Abbreviation"],
  ["MA", "n", 50000, "US State Abbreviation"],
  ["MI", "n", 50000, "US State Abbreviation"],
  ["MN", "n", 50000, "US State Abbreviation"],
  ["MS", "n", 50000, "US State Abbreviation"],
  ["MO", "n", 50000, "US State Abbreviation"],
  ["MT", "n", 50000, "US State Abbreviation"],
  ["NE", "n", 50000, "US State Abbreviation"],
  ["NV", "n", 50000, "US State Abbreviation"],
  ["NH", "n", 50000, "US State Abbreviation"],
  ["NJ", "n", 50000, "US State Abbreviation"],
  ["NM", "n", 50000, "US State Abbreviation"],
  ["NY", "n", 50000, "US State Abbreviation"],
  ["NC", "n", 50000, "US State Abbreviation"],
  ["ND", "n", 50000, "US State Abbreviation"],
  ["OH", "n", 50000, "US State Abbreviation"],
  ["OK", "n", 50000, "US State Abbreviation"],
  ["OR", "n", 50000, "US State Abbreviation"],
  ["PA", "n", 50000, "US State Abbreviation"],
  ["RI", "n", 50000, "US State Abbreviation"],
  ["SC", "n", 50000, "US State Abbreviation"],
  ["SD", "n", 50000, "US State Abbreviation"],
  ["TN", "n", 50000, "US State Abbreviation"],
  ["TX", "n", 50000, "US State Abbreviation"],
  ["UT", "n", 50000, "US State Abbreviation"],
  ["VT", "n", 50000, "US State Abbreviation"],
  ["VA", "n", 50000, "US State Abbreviation"],
  ["WA", "n", 50000, "US State Abbreviation"],
  ["WV", "n", 50000, "US State Abbreviation"],
  ["WI", "n", 50000, "US State Abbreviation"],
  ["WY", "n", 50000, "US State Abbreviation"],
  ["Afghanistan", "n", 5000, "Country"],
  ["Albania", "n", 5000, "Country"],
  ["Algeria", "n", 5000, "Country"],
  ["Andorra", "n", 5000, "Country"],
  ["Angola", "n", 5000, "Country"],
  ["Antigua", "n", 5000, "Country"],
  ["Barbuda", "n", 5000, "Country"],
  ["Argentina", "n", 5000, "Country"],
  ["Armenia", "n", 5000, "Country"],
  ["Australia", "n", 5000, "Country"],
  ["Austria", "n", 5000, "Country"],
  ["Azerbaijan", "n", 5000, "Country"],
  ["Bahamas", "n", 5000, "Country"],
  ["Bangladesh", "n", 5000, "Country"],
  ["Barbados", "n", 5000, "Country"],
  ["Belarus", "n", 5000, "Country"],
  ["Belgium", "n", 5000, "Country"],
  ["Belize", "n", 5000, "Country"],
  ["Benin", "n", 5000, "Country"],
  ["Bhutan", "n", 5000, "Country"],
  ["Bolivia", "n", 5000, "Country"],
  ["Bosnia", "n", 5000, "Country"],
  ["Botswana", "n", 5000, "Country"],
  ["Brazil", "n", 5000, "Country"],
  ["Brunei", "n", 5000, "Country"],
  ["Bulgaria", "n", 5000, "Country"],
  ["Burkina Faso", "n", 5000, "Country"],
  ["Burundi", "n", 5000, "Country"],
  ["Cabo Verde", "n", 5000, "Country"],
  ["Cambodia", "n", 5000, "Country"],
  ["Cameroon", "n", 5000, "Country"],
  ["Canada", "n", 5000, "Country"],
  ["Central African Republic", "n", 5000, "Country"],
  ["Chad", "n", 5000, "Country"],
  ["Chile", "n", 5000, "Country"],
  ["Colombia", "n", 5000, "Country"],
  ["Comoros", "n", 5000, "Country"],
  ["Congo", "n", 5000, "Country"],
  ["Costa Rica", "n", 5000, "Country"],
  ["Croatia", "n", 5000, "Country"],
  ["Cuba", "n", 5000, "Country"],
  ["Cyprus", "n", 5000, "Country"],
  ["Czech Republic", "n", 5000, "Country"],
  ["Denmark", "n", 5000, "Country"],
  ["Djibouti", "n", 5000, "Country"],
  ["Dominica", "n", 5000, "Country"],
  ["Dominican Republic", "n", 5000, "Country"],
  ["East Timor", "n", 5000, "Country"],
  ["Ecuador", "n", 5000, "Country"],
  ["Egypt", "n", 5000, "Country"],
  ["El Salvador", "n", 5000, "Country"],
  ["Equatorial Guinea", "n", 5000, "Country"],
  ["Eritrea", "n", 5000, "Country"],
  ["Estonia", "n", 5000, "Country"],
  ["Ethiopia", "n", 5000, "Country"],
  ["Fiji", "n", 5000, "Country"],
  ["Finland", "n", 5000, "Country"],
  ["France", "n", 5000, "Country"],
  ["Gabon", "n", 5000, "Country"],
  ["Gambia", "n", 5000, "Country"],
  ["Georgia", "n", 5000, "Country"],
  ["Germany", "n", 5000, "Country"],
  ["Ghana", "n", 5000, "Country"],
  ["Greece", "n", 5000, "Country"],
  ["Grenada", "n", 5000, "Country"],
  ["Guatemala", "n", 5000, "Country"],
  ["Guinea", "n", 5000, "Country"],
  ["Guyana", "n", 5000, "Country"],
  ["Haiti", "n", 5000, "Country"],
  ["Honduras", "n", 5000, "Country"],
  ["Hungary", "n", 5000, "Country"],
  ["Iceland", "n", 5000, "Country"],
  ["India", "n", 5000, "Country"],
  ["Indonesia", "n", 5000, "Country"],
  ["Iran", "n", 5000, "Country"],
  ["Iraq", "n", 5000, "Country"],
  ["Ireland", "n", 5000, "Country"],
  ["Israel", "n", 5000, "Country"],
  ["Italy", "n", 5000, "Country"],
  ["Jamaica", "n", 5000, "Country"],
  ["Japan", "n", 5000, "Country"],
  ["Jordan", "n", 5000, "Country"],
  ["Kazakhstan", "n", 5000, "Country"],
  ["Kenya", "n", 5000, "Country"],
  ["Kiribati", "n", 5000, "Country"],
  ["North Korea", "n", 5000, "Country"],
  ["South Korea", "n", 5000, "Country"],
  ["Kosovo", "n", 5000, "Country"],
  ["Kuwait", "n", 5000, "Country"],
  ["Kyrgyzstan", "n", 5000, "Country"],
  ["Laos", "n", 5000, "Country"],
  ["Latvia", "n", 5000, "Country"],
  ["Lebanon", "n", 5000, "Country"],
  ["Lesotho", "n", 5000, "Country"],
  ["Liberia", "n", 5000, "Country"],
  ["Libya", "n", 5000, "Country"],
  ["Liechtenstein", "n", 5000, "Country"],
  ["Lithuania", "n", 5000, "Country"],
  ["Luxembourg", "n", 5000, "Country"],
  ["Macedonia", "n", 5000, "Country"],
  ["Madagascar", "n", 5000, "Country"],
  ["Malawi", "n", 5000, "Country"],
  ["Malaysia", "n", 5000, "Country"],
  ["Maldives", "n", 5000, "Country"],
  ["Mali", "n", 5000, "Country"],
  ["Malta", "n", 5000, "Country"],
  ["Marshall Islands", "n", 5000, "Country"],
  ["Mauritania", "n", 5000, "Country"],
  ["Mexico", "n", 5000, "Country"],
  ["Micronesia", "n", 5000, "Country"],
  ["Moldova", "n", 5000, "Country"],
  ["Monaco", "n", 5000, "Country"],
  ["Mongolia", "n", 5000, "Country"],
  ["Montenegro", "n", 5000, "Country"],
  ["Morocco", "n", 5000, "Country"],
  ["Mozambique", "n", 5000, "Country"],
  ["Myanmar", "n", 5000, "Country"],
  ["Burma", "n", 5000, "Country"],
  ["Namibia", "n", 5000, "Country"],
  ["Nauru", "n", 5000, "Country"],
  ["Nepal", "n", 5000, "Country"],
  ["Netherlands", "n", 5000, "Country"],
  ["New Zealand", "n", 5000, "Country"],
  ["Nicaragua", "n", 5000, "Country"],
  ["Niger", "n", 5000, "Country"],
  ["Nigeria", "n", 5000, "Country"],
  ["Norway", "n", 5000, "Country"],
  ["Oman", "n", 5000, "Country"],
  ["Pakistan", "n", 5000, "Country"],
  ["Palau", "n", 5000, "Country"],
  ["Panama", "n", 5000, "Country"],
  ["Papua New Guinea", "n", 5000, "Country"],
  ["Paraguay", "n", 5000, "Country"],
  ["Peru", "n", 5000, "Country"],
  ["Philippines", "n", 5000, "Country"],
  ["Poland", "n", 5000, "Country"],
  ["Portugal", "n", 5000, "Country"],
  ["Qatar", "n", 5000, "Country"],
  ["Romania", "n", 5000, "Country"],
  ["Russia", "n", 5000, "Country"],
  ["Rwanda", "n", 5000, "Country"],
  ["Saint Kitts and Nevis", "n", 5000, "Country"],
  ["Saint Lucia", "n", 5000, "Country"],
  ["Samoa", "n", 5000, "Country"],
  ["San Marino", "n", 5000, "Country"],
  ["Sao Tome and Principe", "n", 5000, "Country"],
  ["Saudi Arabia", "n", 5000, "Country"],
  ["Senegal", "n", 5000, "Country"],
  ["Serbia", "n", 5000, "Country"],
  ["Seychelles", "n", 5000, "Country"],
  ["Sierra Leone", "n", 5000, "Country"],
  ["Singapore", "n", 5000, "Country"],
  ["Slovakia", "n", 5000, "Country"],
  ["Slovenia", "n", 5000, "Country"],
  ["Solomon Islands", "n", 5000, "Country"],
  ["Somalia", "n", 5000, "Country"],
  ["South Africa", "n", 5000, "Country"],
  ["Spain", "n", 5000, "Country"],
  ["Sri Lanka", "n", 5000, "Country"],
  ["Sudan", "n", 5000, "Country"],
  ["Suriname", "n", 5000, "Country"],
  ["Swaziland", "n", 5000, "Country"],
  ["Sweden", "n", 5000, "Country"],
  ["Switzerland", "n", 5000, "Country"],
  ["Syria", "n", 5000, "Country"],
  ["Taiwan", "n", 5000, "Country"],
  ["Tajikistan", "n", 5000, "Country"],
  ["Tanzania", "n", 5000, "Country"],
  ["Thailand", "n", 5000, "Country"],
  ["Togo", "n", 5000, "Country"],
  ["Tonga", "n", 5000, "Country"],
  ["Trinidad and Tobago", "n", 5000, "Country"],
  ["Tunisia", "n", 5000, "Country"],
  ["Turkey", "n", 5000, "Country"],
  ["Turkmenistan", "n", 5000, "Country"],
  ["Tuvalu", "n", 5000, "Country"],
  ["Uganda", "n", 5000, "Country"],
  ["Ukraine", "n", 5000, "Country"],
  ["United Arab Emirates", "n", 5000, "Country"],
  ["UAE", "n", 5000, "Country"],
  ["United Kingdom", "n", 5000, "Country"],
  ["UK", "n", 5000, "Country"],
  ["United States", "n", 5000, "Country"],
  ["USA", "n", 5000, "Country"],
  ["Uruguay", "n", 5000, "Country"],
  ["Uzbekistan", "n", 5000, "Country"],
  ["Vanuatu", "n", 5000, "Country"],
  ["Vatican City", "n", 5000, "Country"],
  ["Venezuela", "n", 5000, "Country"],
  ["Vietnam", "n", 5000, "Country"],
  ["Yemen", "n", 5000, "Country"],
  ["Zambia", "n", 5000, "Country"],
  ["Zimbabwe", "n", 5000, "Country"],
  ["N8XLJ", "n", 5000, "USA Callsign"],
  ["N5RP", "n", 5000, "USA Callsign"],
  ["KI8JD", "n", 5000, "USA Callsign"],
  ["KE4BXK", "n", 5000, "USA Callsign"],
  ["KI7M", "n", 5000, "USA Callsign"],
  ["KO1U", "n", 5000, "USA Callsign"],
  ["K1RX", "n", 5000, "USA Callsign"],
  ["W1YRC", "n", 5000, "USA Callsign"],
  ["W6AFA", "n", 5000, "USA Callsign"],
  ["N9NH", "n", 5000, "USA Callsign"],
  ["W6SX", "n", 5000, "USA Callsign"],
  ["NX5M", "n", 5000, "USA Callsign"],
  ["W3DF", "n", 5000, "USA Callsign"],
  ["K1LT", "n", 5000, "USA Callsign"],
  ["WB6QLF", "n", 5000, "USA Callsign"],
  ["AA7A", "n", 5000, "USA Callsign"],
  ["W6PH", "n", 5000, "USA Callsign"],
  ["W3RJ", "n", 5000, "USA Callsign"],
  ["K0STP", "n", 5000, "USA Callsign"],
  ["W4UK", "n", 5000, "USA Callsign"],
  ["K0GU", "n", 5000, "USA Callsign"],
  ["N1EU", "n", 5000, "USA Callsign"],
  ["W4JSI", "n", 5000, "USA Callsign"],
  ["KN7T", "n", 5000, "USA Callsign"],
  ["W0FLS", "n", 5000, "USA Callsign"],
  ["KC4D", "n", 5000, "USA Callsign"],
  ["K8LJG", "n", 5000, "USA Callsign"],
  ["W7NYC", "n", 5000, "USA Callsign"],
  ["W9OA", "n", 5000, "USA Callsign"],
  ["K9HSS", "n", 5000, "USA Callsign"],
  ["KL9A", "n", 5000, "USA Callsign"],
  ["N4RJ", "n", 5000, "USA Callsign"],
  ["WD1W", "n", 5000, "USA Callsign"],
  ["KE2SR", "n", 5000, "USA Callsign"],
  ["K6JSI", "n", 5000, "USA Callsign"],
  ["W9LO", "n", 5000, "USA Callsign"],
  ["W1JR", "n", 5000, "USA Callsign"],
  ["W3BTX", "n", 5000, "USA Callsign"],
  ["N3TEE", "n", 5000, "USA Callsign"],
  ["N4AVV", "n", 5000, "USA Callsign"],
  ["N7CIO", "n", 5000, "USA Callsign"],
  ["K0VM", "n", 5000, "USA Callsign"],
  ["W1MU", "n", 5000, "USA Callsign"],
  ["N8CMS", "n", 5000, "USA Callsign"],
  ["KE5AAO", "n", 5000, "USA Callsign"],
  ["WC4F", "n", 5000, "USA Callsign"],
  ["WD0T", "n", 5000, "USA Callsign"],
  ["W7KFI", "n", 5000, "USA Callsign"],
  ["W9DLN", "n", 5000, "USA Callsign"],
  ["W8GEX", "n", 5000, "USA Callsign"],
  ["N7CQQ", "n", 5000, "USA Callsign"],
  ["N5JB", "n", 5000, "USA Callsign"],
  ["W4PA", "n", 5000, "USA Callsign"],
  ["W7EW", "n", 5000, "USA Callsign"],
  ["W1GD", "n", 5000, "USA Callsign"],
  ["W4DII", "n", 5000, "USA Callsign"],
  ["W4ABC", "n", 5000, "USA Callsign"],
  ["K5LZ", "n", 5000, "USA Callsign"],
  ["K7TFJ", "n", 5000, "USA Callsign"],
  ["W4TAA", "n", 5000, "USA Callsign"],
  ["N7EOJ", "n", 5000, "USA Callsign"],
  ["KC7BLI", "n", 5000, "USA Callsign"],
  ["WY4A", "n", 5000, "USA Callsign"],
  ["N6VVY", "n", 5000, "USA Callsign"],
  ["KE6KJL", "n", 5000, "USA Callsign"],
  ["KU4A", "n", 5000, "USA Callsign"],
  ["K1ZE", "n", 5000, "USA Callsign"],
  ["WA6FGV", "n", 5000, "USA Callsign"],
  ["WA6LIE", "n", 5000, "USA Callsign"],
  ["KJ6Y", "n", 5000, "USA Callsign"],
  ["AB6VR", "n", 5000, "USA Callsign"],
  ["K6XN", "n", 5000, "USA Callsign"],
  ["KG4WW", "n", 5000, "USA Callsign"],
  ["N5RSQ", "n", 5000, "USA Callsign"],
  ["W9HLY", "n", 5000, "USA Callsign"],
  ["KQ4E", "n", 5000, "USA Callsign"],
  ["W4XO", "n", 5000, "USA Callsign"],
  ["N6CZE", "n", 5000, "USA Callsign"],
  ["W2RS", "n", 5000, "USA Callsign"],
  ["W6JKV", "n", 5000, "USA Callsign"],
  ["N9IO", "n", 5000, "USA Callsign"],
  ["KD7WNN", "n", 5000, "USA Callsign"],
  ["N9ADG", "n", 5000, "USA Callsign"],
  ["N9AKR", "n", 5000, "USA Callsign"],
  ["AA4MC", "n", 5000, "USA Callsign"],
  ["WA2YUN", "n", 5000, "USA Callsign"],
  ["KB2YRE", "n", 5000, "USA Callsign"],
  ["KE5FAQ", "n", 5000, "USA Callsign"],
  ["K9MK", "n", 5000, "USA Callsign"],
  ["KC7UBS", "n", 5000, "USA Callsign"],
  ["N0BIV", "n", 5000, "USA Callsign"],
  ["WG6K", "n", 5000, "USA Callsign"],
  ["KB4GYT", "n", 5000, "USA Callsign"],
  ["NT0F", "n", 5000, "USA Callsign"],
  ["N3GGT", "n", 5000, "USA Callsign"],
  ["WB8NBI", "n", 5000, "USA Callsign"],
  ["N6TI", "n", 5000, "USA Callsign"],
  ["W3HC", "n", 5000, "USA Callsign"],
  ["NC4SW", "n", 5000, "USA Callsign"],
  ["K9AKS", "n", 5000, "USA Callsign"],
  ["NX9T", "n", 5000, "USA Callsign"],
  ["W9IND", "n", 5000, "USA Callsign"],
  ["WB8WKQ", "n", 5000, "USA Callsign"],
  ["WA0UAY", "n", 5000, "USA Callsign"],
  ["KE4YZ", "n", 5000, "USA Callsign"],
  ["N2PL", "n", 5000, "USA Callsign"],
  ["W0SJS", "n", 5000, "USA Callsign"],
  ["KB9SLU", "n", 5000, "USA Callsign"],
  ["NT9K", "n", 5000, "USA Callsign"],
  ["N6XPG", "n", 5000, "USA Callsign"],
  ["K1EEE", "n", 5000, "USA Callsign"],
  ["K3NM", "n", 5000, "USA Callsign"],
  ["W9IH", "n", 5000, "USA Callsign"],
  ["W2YJ", "n", 5000, "USA Callsign"],
  ["N2FI", "n", 5000, "USA Callsign"],
  ["N9JLY", "n", 5000, "USA Callsign"],
  ["W1YIF", "n", 5000, "USA Callsign"],
  ["N4URW", "n", 5000, "USA Callsign"],
  ["N2NBC", "n", 5000, "USA Callsign"],
  ["N4PD", "n", 5000, "USA Callsign"],
  ["KK6AW", "n", 5000, "USA Callsign"],
  ["WS7L", "n", 5000, "USA Callsign"],
  ["WM4T", "n", 5000, "USA Callsign"],
  ["AA1TT", "n", 5000, "USA Callsign"],
  ["K2RP", "n", 5000, "USA Callsign"],
  ["W1VD", "n", 5000, "USA Callsign"],
  ["N6NHG", "n", 5000, "USA Callsign"],
  ["KE7CCR", "n", 5000, "USA Callsign"],
  ["W0QQG", "n", 5000, "USA Callsign"],
  ["KE4PIV", "n", 5000, "USA Callsign"],
  ["KA2QWC", "n", 5000, "USA Callsign"],
  ["WB7RBN", "n", 5000, "USA Callsign"],
  ["K6YR", "n", 5000, "USA Callsign"],
  ["AA1XQ", "n", 5000, "USA Callsign"],
  ["K4ABT", "n", 5000, "USA Callsign"],
  ["W0VB", "n", 5000, "USA Callsign"],
  ["W7QQQ", "n", 5000, "USA Callsign"],
  ["N6UKO", "n", 5000, "USA Callsign"],
  ["K1QN", "n", 5000, "USA Callsign"],
  ["W5IB", "n", 5000, "USA Callsign"],
  ["NY0P", "n", 5000, "USA Callsign"],
  ["KB9TU", "n", 5000, "USA Callsign"],
  ["K7FAY", "n", 5000, "USA Callsign"],
  ["W5ZIT", "n", 5000, "USA Callsign"],
  ["W4PUL", "n", 5000, "USA Callsign"],
  ["KB7IJ", "n", 5000, "USA Callsign"],
  ["W8AKS", "n", 5000, "USA Callsign"],
  ["N5CR", "n", 5000, "USA Callsign"],
  ["KF7IQ", "n", 5000, "USA Callsign"],
  ["K5KDN", "n", 5000, "USA Callsign"],
  ["N0IU", "n", 5000, "USA Callsign"],
  ["WD5ILR", "n", 5000, "USA Callsign"],
  ["KD4IQ", "n", 5000, "USA Callsign"],
  ["W8TY", "n", 5000, "USA Callsign"],
  ["KA9CQC", "n", 5000, "USA Callsign"],
  ["WA4GIF", "n", 5000, "USA Callsign"],
  ["WY4D", "n", 5000, "USA Callsign"],
  ["N4JRZ", "n", 5000, "USA Callsign"],
  ["KB7UP", "n", 5000, "USA Callsign"],
  ["K4DII", "n", 5000, "USA Callsign"],
  ["KC7OD", "n", 5000, "USA Callsign"],
  ["N8GMY", "n", 5000, "USA Callsign"],
  ["N1IRL", "n", 5000, "USA Callsign"],
  ["WB8EEW", "n", 5000, "USA Callsign"],
  ["WA4WKL", "n", 5000, "USA Callsign"],
  ["WQ5S", "n", 5000, "USA Callsign"],
  ["K7PGL", "n", 5000, "USA Callsign"],
  ["K0OO", "n", 5000, "USA Callsign"],
  ["NO5E", "n", 5000, "USA Callsign"],
  ["KC0SDV", "n", 5000, "USA Callsign"],
  ["KF6MSN", "n", 5000, "USA Callsign"],
  ["K3FHC", "n", 5000, "USA Callsign"],
  ["N7ZZF", "n", 5000, "USA Callsign"],
  ["W2GFF", "n", 5000, "USA Callsign"],
  ["W6IWW", "n", 5000, "USA Callsign"],
  ["KB0ETC", "n", 5000, "USA Callsign"],
  ["N4KIT", "n", 5000, "USA Callsign"],
  ["W6YW", "n", 5000, "USA Callsign"],
  ["K4JNY", "n", 5000, "USA Callsign"],
  ["N4CH", "n", 5000, "USA Callsign"],
  ["KA2KDJ", "n", 5000, "USA Callsign"],
  ["KA3HED", "n", 5000, "USA Callsign"],
  ["K3PRN", "n", 5000, "USA Callsign"],
  ["W8JXM", "n", 5000, "USA Callsign"],
  ["WB9RIM", "n", 5000, "USA Callsign"],
  ["WB9QZB", "n", 5000, "USA Callsign"],
  ["W4BXI", "n", 5000, "USA Callsign"],
  ["WO3T", "n", 5000, "USA Callsign"],
  ["N4LF", "n", 5000, "USA Callsign"],
  ["KE5DJA", "n", 5000, "USA Callsign"],
  ["N4FPS", "n", 5000, "USA Callsign"],
  ["K9DUR", "n", 5000, "USA Callsign"],
  ["W9NVK", "n", 5000, "USA Callsign"],
  ["WA3QPX", "n", 5000, "USA Callsign"],
  ["K1KOB", "n", 5000, "USA Callsign"],
  ["KB9ILT", "n", 5000, "USA Callsign"],
  ["KC8PHB", "n", 5000, "USA Callsign"],
  ["W2BZY", "n", 5000, "USA Callsign"],
  ["KC5R", "n", 5000, "USA Callsign"],
  ["W1GA", "n", 5000, "USA Callsign"],
  ["K4SFD", "n", 5000, "USA Callsign"],
  ["KA5DOB", "n", 5000, "USA Callsign"],
  ["AE0G", "n", 5000, "USA Callsign"],
  ["K4SAK", "n", 5000, "USA Callsign"],
  ["KL7SK", "n", 5000, "USA Callsign"],
  ["K6PLR", "n", 5000, "USA Callsign"],
  ["K0ZX", "n", 5000, "USA Callsign"],
  ["N4QH", "n", 5000, "USA Callsign"],
  ["W5TVW", "n", 5000, "USA Callsign"],
  ["W6LEN", "n", 5000, "USA Callsign"],
  ["KC8IXN", "n", 5000, "USA Callsign"],
  ["WA1BYL", "n", 5000, "USA Callsign"],
  ["KK6TV", "n", 5000, "USA Callsign"],
  ["KB2QEI", "n", 5000, "USA Callsign"],
  ["NF8M", "n", 5000, "USA Callsign"],
  ["WB8EJN", "n", 5000, "USA Callsign"],
  ["K4MDZ", "n", 5000, "USA Callsign"],
  ["KA1TDQ", "n", 5000, "USA Callsign"],
  ["WA1ZEV", "n", 5000, "USA Callsign"],
  ["NB1U", "n", 5000, "USA Callsign"],
  ["W5LI", "n", 5000, "USA Callsign"],
  ["K9GAS", "n", 5000, "USA Callsign"],
  ["W7AMI", "n", 5000, "USA Callsign"],
  ["WA4DOS", "n", 5000, "USA Callsign"],
  ["KH2BI", "n", 5000, "USA Callsign"],
  ["K1DFT", "n", 5000, "USA Callsign"],
  ["W4SNT", "n", 5000, "USA Callsign"],
  ["W8DCQ", "n", 5000, "USA Callsign"],
  ["W1TXT", "n", 5000, "USA Callsign"],
  ["KD4NUE", "n", 5000, "USA Callsign"],
  ["KV2M", "n", 5000, "USA Callsign"],
  ["WP4HSZ", "n", 5000, "USA Callsign"],
  ["KC4WTG", "n", 5000, "USA Callsign"],
  ["KB8QLG", "n", 5000, "USA Callsign"],
  ["N5FJ", "n", 5000, "USA Callsign"],
  ["N3SBA", "n", 5000, "USA Callsign"],
  ["WB9CIF", "n", 5000, "USA Callsign"],
  ["KZ5A", "n", 5000, "USA Callsign"],
  ["WX2NJ", "n", 5000, "USA Callsign"],
  ["K4MFC", "n", 5000, "USA Callsign"],
  ["KG0SY", "n", 5000, "USA Callsign"],
  ["K4XX", "n", 5000, "USA Callsign"],
  ["WA9PWP", "n", 5000, "USA Callsign"],
  ["WG0K", "n", 5000, "USA Callsign"],
  ["KI4AGL", "n", 5000, "USA Callsign"],
  ["AD0H", "n", 5000, "USA Callsign"],
  ["K7TA", "n", 5000, "USA Callsign"],
  ["K3GOD", "n", 5000, "USA Callsign"],
  ["NM3M", "n", 5000, "USA Callsign"],
  ["AG4ZV", "n", 5000, "USA Callsign"],
  ["N1PW", "n", 5000, "USA Callsign"],
  ["N7GTE", "n", 5000, "USA Callsign"],
  ["WA0D", "n", 5000, "USA Callsign"],
  ["N5KIP", "n", 5000, "USA Callsign"],
  ["WA8DVD", "n", 5000, "USA Callsign"],
  ["K0VH", "n", 5000, "USA Callsign"],
  ["W4PCA", "n", 5000, "USA Callsign"],
  ["N3VE", "n", 5000, "USA Callsign"],
  ["WX5ATX", "n", 5000, "USA Callsign"],
  ["N8DQU", "n", 5000, "USA Callsign"],
  ["AA7EE", "n", 5000, "USA Callsign"],
  ["WB3DYE", "n", 5000, "USA Callsign"],
  ["N5UCF", "n", 5000, "USA Callsign"],
  ["K2YG", "n", 5000, "USA Callsign"],
  ["KB7BGS", "n", 5000, "USA Callsign"],
  ["KC0PBR", "n", 5000, "USA Callsign"],
  ["K7VAY", "n", 5000, "USA Callsign"],
  ["WB2FAU", "n", 5000, "USA Callsign"],
  ["WD8EEQ", "n", 5000, "USA Callsign"],
  ["W4ZYD", "n", 5000, "USA Callsign"],
  ["KA2CAQ", "n", 5000, "USA Callsign"],
  ["K8CCP", "n", 5000, "USA Callsign"],
  ["K2JHU", "n", 5000, "USA Callsign"],
  ["KB3KVA", "n", 5000, "USA Callsign"],
  ["W9FI", "n", 5000, "USA Callsign"],
  ["KO6SY", "n", 5000, "USA Callsign"],
  ["WB0VHF", "n", 5000, "USA Callsign"],
  ["K8NQC", "n", 5000, "USA Callsign"],
  ["N3OHI", "n", 5000, "USA Callsign"],
  ["K5JYD", "n", 5000, "USA Callsign"],
  ["K1KG", "n", 5000, "USA Callsign"],
  ["N5PVL", "n", 5000, "USA Callsign"],
  ["K7EC", "n", 5000, "USA Callsign"],
  ["K9JK", "n", 5000, "USA Callsign"],
  ["K4BI", "n", 5000, "USA Callsign"],
  ["N9MQN", "n", 5000, "USA Callsign"],
  ["KC4QLP", "n", 5000, "USA Callsign"],
  ["AL2B", "n", 5000, "USA Callsign"],
  ["N5GGG", "n", 5000, "USA Callsign"],
  ["W2HD", "n", 5000, "USA Callsign"],
  ["AD4RE", "n", 5000, "USA Callsign"],
  ["W9ZMR", "n", 5000, "USA Callsign"],
  ["WC9V", "n", 5000, "USA Callsign"],
  ["KC4GXZ", "n", 5000, "USA Callsign"],
  ["W1NR", "n", 5000, "USA Callsign"],
  ["KF6VOT", "n", 5000, "USA Callsign"],
  ["N8ER", "n", 5000, "USA Callsign"],
  ["N1ZN", "n", 5000, "USA Callsign"],
  ["N7FG", "n", 5000, "USA Callsign"],
  ["KB6LZW", "n", 5000, "USA Callsign"],
  ["W5VTM", "n", 5000, "USA Callsign"],
  ["K2UY", "n", 5000, "USA Callsign"],
  ["N4RK", "n", 5000, "USA Callsign"],
  ["KB7OGE", "n", 5000, "USA Callsign"],
  ["N5CN", "n", 5000, "USA Callsign"],
  ["KD8BUS", "n", 5000, "USA Callsign"],
  ["W4RCC", "n", 5000, "USA Callsign"],
  ["N7NEI", "n", 5000, "USA Callsign"],
  ["K4CWO", "n", 5000, "USA Callsign"],
  ["N1PQ", "n", 5000, "USA Callsign"],
  ["K5OCQ", "n", 5000, "USA Callsign"],
  ["WA5UTK", "n", 5000, "USA Callsign"],
  ["KA3UNB", "n", 5000, "USA Callsign"],
  ["N0TOP", "n", 5000, "USA Callsign"],
  ["N6RSX", "n", 5000, "USA Callsign"],
  ["K4RBM", "n", 5000, "USA Callsign"],
  ["W3IDX", "n", 5000, "USA Callsign"],
  ["N6BOO", "n", 5000, "USA Callsign"],
  ["WA9JTU", "n", 5000, "USA Callsign"],
  ["WB2BLJ", "n", 5000, "USA Callsign"],
  ["K6TVI", "n", 5000, "USA Callsign"],
  ["W2PDY", "n", 5000, "USA Callsign"],
  ["W9RZR", "n", 5000, "USA Callsign"],
  ["W7FD", "n", 5000, "USA Callsign"],
  ["K9GO", "n", 5000, "USA Callsign"],
  ["K9UT", "n", 5000, "USA Callsign"],
  ["K5CW", "n", 5000, "USA Callsign"],
  ["AA5Z", "n", 5000, "USA Callsign"],
  ["W4PFM", "n", 5000, "USA Callsign"],
  ["N7EU", "n", 5000, "USA Callsign"],
  ["N6PNK", "n", 5000, "USA Callsign"],
  ["WF4U", "n", 5000, "USA Callsign"],
  ["WA1TTL", "n", 5000, "USA Callsign"],
  ["K6LZ", "n", 5000, "USA Callsign"],
  ["KB3MXK", "n", 5000, "USA Callsign"],
  ["K9HDE", "n", 5000, "USA Callsign"],
  ["K5HDU", "n", 5000, "USA Callsign"],
  ["W2BXR", "n", 5000, "USA Callsign"],
  ["WN5H", "n", 5000, "USA Callsign"],
  ["WA6ARA", "n", 5000, "USA Callsign"],
  ["WB5BXJ", "n", 5000, "USA Callsign"],
  ["K8RLM", "n", 5000, "USA Callsign"],
  ["W7MZG", "n", 5000, "USA Callsign"],
  ["N3ATQ", "n", 5000, "USA Callsign"],
  ["K2MPE", "n", 5000, "USA Callsign"],
  ["N4TW", "n", 5000, "USA Callsign"],
  ["NC7B", "n", 5000, "USA Callsign"],
  ["KE4WUP", "n", 5000, "USA Callsign"],
  ["AB5SG", "n", 5000, "USA Callsign"],
  ["WA0LPV", "n", 5000, "USA Callsign"],
  ["KF2TI", "n", 5000, "USA Callsign"],
  ["W9VHF", "n", 5000, "USA Callsign"],
  ["W7JEF", "n", 5000, "USA Callsign"],
  ["AA0WF", "n", 5000, "USA Callsign"],
  ["K5EWS", "n", 5000, "USA Callsign"],
  ["N5WD", "n", 5000, "USA Callsign"],
  ["K9KZ", "n", 5000, "USA Callsign"],
  ["KA9HUK", "n", 5000, "USA Callsign"],
  ["K3DX", "n", 5000, "USA Callsign"],
  ["K7MAX", "n", 5000, "USA Callsign"],
  ["KR3JA", "n", 5000, "USA Callsign"],
  ["K2DX", "n", 5000, "USA Callsign"],
  ["AA5CH", "n", 5000, "USA Callsign"],
  ["WA7UHR", "n", 5000, "USA Callsign"],
  ["WA1Y", "n", 5000, "USA Callsign"],
  ["N1LO", "n", 5000, "USA Callsign"],
  ["W4TR", "n", 5000, "USA Callsign"],
  ["KK7B", "n", 5000, "USA Callsign"],
  ["AA4HF", "n", 5000, "USA Callsign"],
  ["N4CU", "n", 5000, "USA Callsign"],
  ["WC4N", "n", 5000, "USA Callsign"],
  ["KC8SGO", "n", 5000, "USA Callsign"],
  ["AB1BX", "n", 5000, "USA Callsign"],
  ["WD5DDH", "n", 5000, "USA Callsign"],
  ["N2MB", "n", 5000, "USA Callsign"],
  ["KC4LCD", "n", 5000, "USA Callsign"],
  ["KN9C", "n", 5000, "USA Callsign"],
  ["AE6OR", "n", 5000, "USA Callsign"],
  ["W7WMO", "n", 5000, "USA Callsign"],
  ["WA5Y", "n", 5000, "USA Callsign"],
  ["AA2WN", "n", 5000, "USA Callsign"],
  ["WO9B", "n", 5000, "USA Callsign"],
  ["W5NNH", "n", 5000, "USA Callsign"],
  ["K1DWZ", "n", 5000, "USA Callsign"],
  ["W6KB", "n", 5000, "USA Callsign"],
  ["K3MT", "n", 5000, "USA Callsign"],
  ["K2GG", "n", 5000, "USA Callsign"],
  ["K1PIJ", "n", 5000, "USA Callsign"],
  ["W6NBC", "n", 5000, "USA Callsign"],
  ["K9JY", "n", 5000, "USA Callsign"],
  ["W1FMR", "n", 5000, "USA Callsign"],
  ["K5WNU", "n", 5000, "USA Callsign"],
  ["AI3CD", "n", 5000, "USA Callsign"],
  ["N4JDW", "n", 5000, "USA Callsign"],
  ["K3DRP", "n", 5000, "USA Callsign"],
  ["KB7QWZ", "n", 5000, "USA Callsign"],
  ["KA5YEU", "n", 5000, "USA Callsign"],
  ["N0QE", "n", 5000, "USA Callsign"],
  ["N4WEQ", "n", 5000, "USA Callsign"],
  ["WD9CHG", "n", 5000, "USA Callsign"],
  ["WB2NEW", "n", 5000, "USA Callsign"],
  ["KB2OOJ", "n", 5000, "USA Callsign"],
  ["W3EW", "n", 5000, "USA Callsign"],
  ["N5DD", "n", 5000, "USA Callsign"],
  ["NG3U", "n", 5000, "USA Callsign"],
  ["W4KFC", "n", 5000, "USA Callsign"],
  ["WA2BFW", "n", 5000, "USA Callsign"],
  ["WA5VFA", "n", 5000, "USA Callsign"],
  ["KC8ADU", "n", 5000, "USA Callsign"],
  ["K5DJ", "n", 5000, "USA Callsign"],
  ["AF9R", "n", 5000, "USA Callsign"],
  ["KA4SSH", "n", 5000, "USA Callsign"],
  ["KF4LGV", "n", 5000, "USA Callsign"],
  ["N5VDQ", "n", 5000, "USA Callsign"],
  ["K2XE", "n", 5000, "USA Callsign"],
  ["K3MIY", "n", 5000, "USA Callsign"],
  ["N3FU", "n", 5000, "USA Callsign"],
  ["W4PF", "n", 5000, "USA Callsign"],
  ["N8BHT", "n", 5000, "USA Callsign"],
  ["N4TTJ", "n", 5000, "USA Callsign"],
  ["KU0E", "n", 5000, "USA Callsign"],
  ["K5TVC", "n", 5000, "USA Callsign"],
  ["WB5OXQ", "n", 5000, "USA Callsign"],
  ["AF4KK", "n", 5000, "USA Callsign"],
  ["AA1OV", "n", 5000, "USA Callsign"],
  ["N4ETT", "n", 5000, "USA Callsign"],
  ["KC2CKO", "n", 5000, "USA Callsign"],
  ["KB5AE", "n", 5000, "USA Callsign"],
  ["NO9T", "n", 5000, "USA Callsign"],
  ["KC8GTX", "n", 5000, "USA Callsign"],
  ["W1WLJ", "n", 5000, "USA Callsign"],
  ["KT4KP", "n", 5000, "USA Callsign"],
  ["K5IRH", "n", 5000, "USA Callsign"],
  ["WB0POH", "n", 5000, "USA Callsign"],
  ["WD6CDN", "n", 5000, "USA Callsign"],
  ["NX7R", "n", 5000, "USA Callsign"],
  ["KC4RUM", "n", 5000, "USA Callsign"],
  ["N3JB", "n", 5000, "USA Callsign"],
  ["W3GYK", "n", 5000, "USA Callsign"],
  ["KO4XB", "n", 5000, "USA Callsign"],
  ["KB7DY", "n", 5000, "USA Callsign"],
  ["WV0H", "n", 5000, "USA Callsign"],
  ["WA8ZXZ", "n", 5000, "USA Callsign"],
  ["NA7U", "n", 5000, "USA Callsign"],
  ["W4MCQ", "n", 5000, "USA Callsign"],
  ["NY4FD", "n", 5000, "USA Callsign"],
  ["WV7I", "n", 5000, "USA Callsign"],
  ["N5WA", "n", 5000, "USA Callsign"],
  ["WB0FTL", "n", 5000, "USA Callsign"],
  ["K5EEE", "n", 5000, "USA Callsign"],
  ["W7EFJ", "n", 5000, "USA Callsign"],
  ["KB9RQZ", "n", 5000, "USA Callsign"],
  ["KB0VVD", "n", 5000, "USA Callsign"],
  ["N7UX", "n", 5000, "USA Callsign"],
  ["KB9ZM", "n", 5000, "USA Callsign"],
  ["WA6BFG", "n", 5000, "USA Callsign"],
  ["W7RNA", "n", 5000, "USA Callsign"],
  ["K9HFX", "n", 5000, "USA Callsign"],
  ["WA4DYD", "n", 5000, "USA Callsign"],
  ["KE6SCS", "n", 5000, "USA Callsign"],
  ["N1EO", "n", 5000, "USA Callsign"],
  ["K4CW", "n", 5000, "USA Callsign"],
  ["N9KD", "n", 5000, "USA Callsign"],
  ["N7ZEV", "n", 5000, "USA Callsign"],
  ["WL7CES", "n", 5000, "USA Callsign"],
  ["WD8DAS", "n", 5000, "USA Callsign"],
  ["K8KGM", "n", 5000, "USA Callsign"],
  ["WA4NFO", "n", 5000, "USA Callsign"],
  ["KB3IUL", "n", 5000, "USA Callsign"],
  ["W8FIR", "n", 5000, "USA Callsign"],
  ["AF6F", "n", 5000, "USA Callsign"],
  ["W1DOG", "n", 5000, "USA Callsign"],
  ["N7OEP", "n", 5000, "USA Callsign"],
  ["KL4J", "n", 5000, "USA Callsign"],
  ["W1CTC", "n", 5000, "USA Callsign"],
  ["KC2FTT", "n", 5000, "USA Callsign"],
  ["K2ND", "n", 5000, "USA Callsign"],
  ["KD4HPR", "n", 5000, "USA Callsign"],
  ["K5BST", "n", 5000, "USA Callsign"],
  ["W2KGY", "n", 5000, "USA Callsign"],
  ["KB3CBS", "n", 5000, "USA Callsign"],
  ["K3RH", "n", 5000, "USA Callsign"],
  ["AI4PJ", "n", 5000, "USA Callsign"],
  ["KD7E", "n", 5000, "USA Callsign"],
  ["KA5RHK", "n", 5000, "USA Callsign"],
  ["W1HZ", "n", 5000, "USA Callsign"],
  ["KL7IZW", "n", 5000, "USA Callsign"],
  ["AA6RK", "n", 5000, "USA Callsign"],
  ["K4HI", "n", 5000, "USA Callsign"],
  ["AA6FT", "n", 5000, "USA Callsign"],
  ["W2VS", "n", 5000, "USA Callsign"],
  ["N3KKF", "n", 5000, "USA Callsign"],
  ["WB7GR", "n", 5000, "USA Callsign"],
  ["K1GUG", "n", 5000, "USA Callsign"],
  ["AB4RJ", "n", 5000, "USA Callsign"],
  ["N0QCN", "n", 5000, "USA Callsign"],
  ["K6WL", "n", 5000, "USA Callsign"],
  ["NA7CS", "n", 5000, "USA Callsign"],
  ["W4JR", "n", 5000, "USA Callsign"],
  ["K5AQ", "n", 5000, "USA Callsign"],
  ["N3CVJ", "n", 5000, "USA Callsign"],
  ["AA3M", "n", 5000, "USA Callsign"],
  ["W1JZ", "n", 5000, "USA Callsign"],
  ["N6TKW", "n", 5000, "USA Callsign"],
  ["N2ZSO", "n", 5000, "USA Callsign"],
  ["N2LLM", "n", 5000, "USA Callsign"],
  ["N2KA", "n", 5000, "USA Callsign"],
  ["W4HWD", "n", 5000, "USA Callsign"],
  ["KF4UOR", "n", 5000, "USA Callsign"],
  ["NL7CQ", "n", 5000, "USA Callsign"],
  ["K4DLG", "n", 5000, "USA Callsign"],
  ["K1JC", "n", 5000, "USA Callsign"],
  ["N1NBQ", "n", 5000, "USA Callsign"],
  ["WA4FXT", "n", 5000, "USA Callsign"],
  ["KY2D", "n", 5000, "USA Callsign"],
  ["K0ZDA", "n", 5000, "USA Callsign"],
  ["KA5CJJ", "n", 5000, "USA Callsign"],
  ["KB8TL", "n", 5000, "USA Callsign"],
  ["K6KX", "n", 5000, "USA Callsign"],
  ["K6GNX", "n", 5000, "USA Callsign"],
  ["K4LTX", "n", 5000, "USA Callsign"],
  ["KD4NJA", "n", 5000, "USA Callsign"],
  ["K7WWA", "n", 5000, "USA Callsign"],
  ["AG4AL", "n", 5000, "USA Callsign"],
  ["NR8O", "n", 5000, "USA Callsign"],
  ["KI4FMJ", "n", 5000, "USA Callsign"],
  ["W7ANA", "n", 5000, "USA Callsign"],
  ["K9IO", "n", 5000, "USA Callsign"],
  ["KA4WJA", "n", 5000, "USA Callsign"],
  ["AA4GY", "n", 5000, "USA Callsign"],
  ["W4LO", "n", 5000, "USA Callsign"],
  ["KG6CPS", "n", 5000, "USA Callsign"],
  ["N2JZ", "n", 5000, "USA Callsign"],
  ["WA7NWL", "n", 5000, "USA Callsign"],
  ["K1EEA", "n", 5000, "USA Callsign"],
  ["W8GB", "n", 5000, "USA Callsign"],
  ["WA7ITZ", "n", 5000, "USA Callsign"],
  ["KD7RJ", "n", 5000, "USA Callsign"],
  ["KB9BIT", "n", 5000, "USA Callsign"],
  ["W4JH", "n", 5000, "USA Callsign"],
  ["N3LGA", "n", 5000, "USA Callsign"],
  ["W9MJ", "n", 5000, "USA Callsign"],
  ["N8ZKT", "n", 5000, "USA Callsign"],
  ["N8MUS", "n", 5000, "USA Callsign"],
  ["KE6EEZ", "n", 5000, "USA Callsign"],
  ["NF0J", "n", 5000, "USA Callsign"],
  ["N7CAR", "n", 5000, "USA Callsign"],
  ["W6CO", "n", 5000, "USA Callsign"],
  ["KB2MQR", "n", 5000, "USA Callsign"],
  ["W2IRK", "n", 5000, "USA Callsign"],
  ["W6TLD", "n", 5000, "USA Callsign"],
  ["KA1QMA", "n", 5000, "USA Callsign"],
  ["K4GPS", "n", 5000, "USA Callsign"],
  ["AA0FY", "n", 5000, "USA Callsign"],
  ["K7MZ", "n", 5000, "USA Callsign"],
  ["WB2QMY", "n", 5000, "USA Callsign"],
  ["K8PP", "n", 5000, "USA Callsign"],
  ["KC8HVM", "n", 5000, "USA Callsign"],
  ["W9ZX", "n", 5000, "USA Callsign"],
  ["WA2B", "n", 5000, "USA Callsign"],
  ["KD6ET", "n", 5000, "USA Callsign"],
  ["KC6D", "n", 5000, "USA Callsign"],
  ["N2HDU", "n", 5000, "USA Callsign"],
  ["N5QPJ", "n", 5000, "USA Callsign"],
  ["W4OQG", "n", 5000, "USA Callsign"],
  ["NN1J", "n", 5000, "USA Callsign"],
  ["N4APE", "n", 5000, "USA Callsign"],
  ["K9NY", "n", 5000, "USA Callsign"],
  ["W4AZR", "n", 5000, "USA Callsign"],
  ["WB9SLI", "n", 5000, "USA Callsign"],
  ["WB9QAF", "n", 5000, "USA Callsign"],
  ["WS8B", "n", 5000, "USA Callsign"],
  ["WA0RRZ", "n", 5000, "USA Callsign"],
  ["KB5ELT", "n", 5000, "USA Callsign"],
  ["KK3S", "n", 5000, "USA Callsign"],
  ["K5YK", "n", 5000, "USA Callsign"],
  ["W1USA", "n", 5000, "USA Callsign"],
  ["N7KP", "n", 5000, "USA Callsign"],
  ["N0KLU", "n", 5000, "USA Callsign"],
  ["W7HG", "n", 5000, "USA Callsign"],
  ["NV8V", "n", 5000, "USA Callsign"],
  ["W2MW", "n", 5000, "USA Callsign"],
  ["W3FEY", "n", 5000, "USA Callsign"],
  ["KN4QT", "n", 5000, "USA Callsign"],
  ["WB5DTW", "n", 5000, "USA Callsign"],
  ["W9MT", "n", 5000, "USA Callsign"],
  ["N4UCM", "n", 5000, "USA Callsign"],
  ["WA5PNT", "n", 5000, "USA Callsign"],
  ["K6WS", "n", 5000, "USA Callsign"],
  ["AA5QJ", "n", 5000, "USA Callsign"],
  ["N3HQN", "n", 5000, "USA Callsign"],
  ["N0NDP", "n", 5000, "USA Callsign"],
  ["KC2GWB", "n", 5000, "USA Callsign"],
  ["N1DXR", "n", 5000, "USA Callsign"],
  ["KB9WAA", "n", 5000, "USA Callsign"],
  ["K5ZH", "n", 5000, "USA Callsign"],
  ["W2UFO", "n", 5000, "USA Callsign"],
  ["W9SFB", "n", 5000, "USA Callsign"],
  ["K0UXC", "n", 5000, "USA Callsign"],
  ["K7DAN", "n", 5000, "USA Callsign"],
  ["N6ZZO", "n", 5000, "USA Callsign"],
  ["KB0DTI", "n", 5000, "USA Callsign"],
  ["KC4QPR", "n", 5000, "USA Callsign"],
  ["N4VBH", "n", 5000, "USA Callsign"],
  ["N4WAW", "n", 5000, "USA Callsign"],
  ["K9GC", "n", 5000, "USA Callsign"],
  ["K1JKR", "n", 5000, "USA Callsign"],
  ["WA7DX", "n", 5000, "USA Callsign"],
  ["AB0OX", "n", 5000, "USA Callsign"],
  ["W6BSY", "n", 5000, "USA Callsign"],
  ["AB5RT", "n", 5000, "USA Callsign"],
  ["K4TY", "n", 5000, "USA Callsign"],
  ["KB8FE", "n", 5000, "USA Callsign"],
  ["KC2AUP", "n", 5000, "USA Callsign"],
  ["KB9EKA", "n", 5000, "USA Callsign"],
  ["KA5WZY", "n", 5000, "USA Callsign"],
  ["KD5JAA", "n", 5000, "USA Callsign"],
  ["K8AGN", "n", 5000, "USA Callsign"],
  ["K2LJM", "n", 5000, "USA Callsign"],
  ["N0VW", "n", 5000, "USA Callsign"],
  ["N2UBP", "n", 5000, "USA Callsign"],
  ["W0MW", "n", 5000, "USA Callsign"],
  ["K8NQQ", "n", 5000, "USA Callsign"],
  ["WM2C", "n", 5000, "USA Callsign"],
  ["AB0RG", "n", 5000, "USA Callsign"],
  ["KB8GHQ", "n", 5000, "USA Callsign"],
  ["AK4EA", "n", 5000, "USA Callsign"],
  ["KA7ZRD", "n", 5000, "USA Callsign"],
  ["KA4RRM", "n", 5000, "USA Callsign"],
  ["KA1LA", "n", 5000, "USA Callsign"],
  ["W1ICW", "n", 5000, "USA Callsign"],
  ["KA9IKK", "n", 5000, "USA Callsign"],
  ["WA4BXC", "n", 5000, "USA Callsign"],
  ["KA6W", "n", 5000, "USA Callsign"],
  ["KB4TZK", "n", 5000, "USA Callsign"],
  ["WA7KYI", "n", 5000, "USA Callsign"],
  ["N3CTM", "n", 5000, "USA Callsign"],
  ["AD1R", "n", 5000, "USA Callsign"],
  ["N3JFS", "n", 5000, "USA Callsign"],
  ["N5UAC", "n", 5000, "USA Callsign"],
  ["KA4AFI", "n", 5000, "USA Callsign"],
  ["N0XAQ", "n", 5000, "USA Callsign"],
  ["KP3O", "n", 5000, "USA Callsign"],
  ["KE4HMD", "n", 5000, "USA Callsign"],
  ["KA6DFP", "n", 5000, "USA Callsign"],
  ["W0NFL", "n", 5000, "USA Callsign"],
  ["N1ZRA", "n", 5000, "USA Callsign"],
  ["KB8NTM", "n", 5000, "USA Callsign"],
  ["KE6YC", "n", 5000, "USA Callsign"],
  ["AA1RI", "n", 5000, "USA Callsign"],
  ["W3CEI", "n", 5000, "USA Callsign"],
  ["N9UN", "n", 5000, "USA Callsign"],
  ["N2RRR", "n", 5000, "USA Callsign"],
  ["KA5SCU", "n", 5000, "USA Callsign"],
  ["N4FS", "n", 5000, "USA Callsign"],
  ["W7UH", "n", 5000, "USA Callsign"],
  ["KD5SMF", "n", 5000, "USA Callsign"],
  ["W4RKS", "n", 5000, "USA Callsign"],
  ["WB7REL", "n", 5000, "USA Callsign"],
  ["W4WN", "n", 5000, "USA Callsign"],
  ["K9KUZ", "n", 5000, "USA Callsign"],
  ["W5SQA", "n", 5000, "USA Callsign"],
  ["W2NYC", "n", 5000, "USA Callsign"],
  ["N4OWB", "n", 5000, "USA Callsign"],
  ["N1DP", "n", 5000, "USA Callsign"],
  ["K3QZ", "n", 5000, "USA Callsign"],
  ["NR9J", "n", 5000, "USA Callsign"],
  ["WA3WAW", "n", 5000, "USA Callsign"],
  ["KD4WFW", "n", 5000, "USA Callsign"],
  ["KB2ZAM", "n", 5000, "USA Callsign"],
  ["N4GVA", "n", 5000, "USA Callsign"],
  ["WB3FME", "n", 5000, "USA Callsign"],
  ["W8SCC", "n", 5000, "USA Callsign"],
  ["KC8LNO", "n", 5000, "USA Callsign"],
  ["W4MPQ", "n", 5000, "USA Callsign"],
  ["WB2KKI", "n", 5000, "USA Callsign"],
  ["W1YP", "n", 5000, "USA Callsign"],
  ["N2KDZ", "n", 5000, "USA Callsign"],
  ["K5DQ", "n", 5000, "USA Callsign"],
  ["WA8TTZ", "n", 5000, "USA Callsign"],
  ["KD5ZD", "n", 5000, "USA Callsign"],
  ["KI4KGK", "n", 5000, "USA Callsign"],
  ["W4AKE", "n", 5000, "USA Callsign"],
  ["WF2Z", "n", 5000, "USA Callsign"],
  ["KF4WOL", "n", 5000, "USA Callsign"],
  ["NC0S", "n", 5000, "USA Callsign"],
  ["KN1H", "n", 5000, "USA Callsign"],
  ["W4APH", "n", 5000, "USA Callsign"],
  ["N4NHO", "n", 5000, "USA Callsign"],
  ["W1JAB", "n", 5000, "USA Callsign"],
  ["N4WYR", "n", 5000, "USA Callsign"],
  ["K5LO", "n", 5000, "USA Callsign"],
  ["KB2IBZ", "n", 5000, "USA Callsign"],
  ["KF4ECG", "n", 5000, "USA Callsign"],
  ["KA2PVT", "n", 5000, "USA Callsign"],
  ["KB8IUH", "n", 5000, "USA Callsign"],
  ["W5CJN", "n", 5000, "USA Callsign"],
  ["KB8AQK", "n", 5000, "USA Callsign"],
  ["KB1GCK", "n", 5000, "USA Callsign"],
  ["KC8LBZ", "n", 5000, "USA Callsign"],
  ["KA1CIV", "n", 5000, "USA Callsign"],
  ["KA5HOM", "n", 5000, "USA Callsign"],
  ["KC0PLZ", "n", 5000, "USA Callsign"],
  ["NY5Q", "n", 5000, "USA Callsign"],
  ["WB0YRQ", "n", 5000, "USA Callsign"],
  ["KB5ZCG", "n", 5000, "USA Callsign"],
  ["W8XO", "n", 5000, "USA Callsign"],
  ["KB7NJV", "n", 5000, "USA Callsign"],
  ["N5ROB", "n", 5000, "USA Callsign"],
  ["N8WK", "n", 5000, "USA Callsign"],
  ["W9OC", "n", 5000, "USA Callsign"],
  ["W8WVM", "n", 5000, "USA Callsign"],
  ["W1AYC", "n", 5000, "USA Callsign"],
  ["N7LPA", "n", 5000, "USA Callsign"],
  ["W0RJE", "n", 5000, "USA Callsign"],
  ["K9VON", "n", 5000, "USA Callsign"],
  ["K7MQF", "n", 5000, "USA Callsign"],
  ["KC4YBR", "n", 5000, "USA Callsign"],
  ["AC7TK", "n", 5000, "USA Callsign"],
  ["WA9ZBW", "n", 5000, "USA Callsign"],
  ["K4GJ", "n", 5000, "USA Callsign"],
  ["KD7TAS", "n", 5000, "USA Callsign"],
  ["K4MIK", "n", 5000, "USA Callsign"],
  ["W3WTJ", "n", 5000, "USA Callsign"],
  ["AJ4D", "n", 5000, "USA Callsign"],
  ["W0GAN", "n", 5000, "USA Callsign"],
  ["W4QE", "n", 5000, "USA Callsign"],
  ["K3JM", "n", 5000, "USA Callsign"],
  ["KG4C", "n", 5000, "USA Callsign"],
  ["KE4NZG", "n", 5000, "USA Callsign"],
  ["WB0QHJ", "n", 5000, "USA Callsign"],
  ["K5PK", "n", 5000, "USA Callsign"],
  ["K7RO", "n", 5000, "USA Callsign"],
  ["KB5ELV", "n", 5000, "USA Callsign"],
  ["KG8FE", "n", 5000, "USA Callsign"],
  ["N3FD", "n", 5000, "USA Callsign"],
  ["KG0RI", "n", 5000, "USA Callsign"],
  ["W6BGR", "n", 5000, "USA Callsign"],
  ["KG4LVY", "n", 5000, "USA Callsign"],
  ["W3HII", "n", 5000, "USA Callsign"],
  ["K0EH", "n", 5000, "USA Callsign"],
  ["N0DYF", "n", 5000, "USA Callsign"],
  ["K7CTW", "n", 5000, "USA Callsign"],
  ["N2GL", "n", 5000, "USA Callsign"],
  ["K6DDR", "n", 5000, "USA Callsign"],
  ["N0JP", "n", 5000, "USA Callsign"],
  ["W5SUP", "n", 5000, "USA Callsign"],
  ["K9HWL", "n", 5000, "USA Callsign"],
  ["W6SHR", "n", 5000, "USA Callsign"],
  ["WA2PIF", "n", 5000, "USA Callsign"],
  ["W5FRS", "n", 5000, "USA Callsign"],
  ["N7OKN", "n", 5000, "USA Callsign"],
  ["KE4TEW", "n", 5000, "USA Callsign"],
  ["KF4ESH", "n", 5000, "USA Callsign"],
  ["KE6BXT", "n", 5000, "USA Callsign"],
  ["NI8A", "n", 5000, "USA Callsign"],
  ["KE0GB", "n", 5000, "USA Callsign"],
  ["NX1H", "n", 5000, "USA Callsign"],
  ["KC2IVI", "n", 5000, "USA Callsign"],
  ["N8DL", "n", 5000, "USA Callsign"],
  ["NF1D", "n", 5000, "USA Callsign"],
  ["W9MAT", "n", 5000, "USA Callsign"],
  ["W4TN", "n", 5000, "USA Callsign"],
  ["KA6DAC", "n", 5000, "USA Callsign"],
  ["W9NAW", "n", 5000, "USA Callsign"],
  ["KA4DQJ", "n", 5000, "USA Callsign"],
  ["WD5BJR", "n", 5000, "USA Callsign"],
  ["N5EEO", "n", 5000, "USA Callsign"],
  ["WA9FFV", "n", 5000, "USA Callsign"],
  ["K8JDC", "n", 5000, "USA Callsign"],
  ["K5MMH", "n", 5000, "USA Callsign"],
  ["NN4TT", "n", 5000, "USA Callsign"],
  ["W5VSI", "n", 5000, "USA Callsign"],
  ["KB2ITO", "n", 5000, "USA Callsign"],
  ["AA4IX", "n", 5000, "USA Callsign"],
  ["W5XO", "n", 5000, "USA Callsign"],
  ["N8HTV", "n", 5000, "USA Callsign"],
  ["KC7FYD", "n", 5000, "USA Callsign"],
  ["W8WG", "n", 5000, "USA Callsign"],
  ["N3JWJ", "n", 5000, "USA Callsign"],
  ["N7XCG", "n", 5000, "USA Callsign"],
  ["KA5QMA", "n", 5000, "USA Callsign"],
  ["WB2GMK", "n", 5000, "USA Callsign"],
  ["K7TNY", "n", 5000, "USA Callsign"],
  ["KK4XA", "n", 5000, "USA Callsign"],
  ["KB9WVK", "n", 5000, "USA Callsign"],
  ["K4FYM", "n", 5000, "USA Callsign"],
  ["W4REN", "n", 5000, "USA Callsign"],
  ["N6FUP", "n", 5000, "USA Callsign"],
  ["NP2DJ", "n", 5000, "USA Callsign"],
  ["WD0GLP", "n", 5000, "USA Callsign"],
  ["WA5KYV", "n", 5000, "USA Callsign"],
  ["N8HXV", "n", 5000, "USA Callsign"],
  ["WB6WJW", "n", 5000, "USA Callsign"],
  ["KQ4AZ", "n", 5000, "USA Callsign"],
  ["KK7L", "n", 5000, "USA Callsign"],
  ["W8ICE", "n", 5000, "USA Callsign"],
  ["W6GA", "n", 5000, "USA Callsign"],
  ["KT4OO", "n", 5000, "USA Callsign"],
  ["K0RAJ", "n", 5000, "USA Callsign"],
  ["N2NVP", "n", 5000, "USA Callsign"],
  ["N1II", "n", 5000, "USA Callsign"],
  ["W6UFO", "n", 5000, "USA Callsign"],
  ["KA2JGR", "n", 5000, "USA Callsign"],
  ["W7LFC", "n", 5000, "USA Callsign"],
  ["WW9GT", "n", 5000, "USA Callsign"],
  ["AA1IP", "n", 5000, "USA Callsign"],
  ["N6MUZ", "n", 5000, "USA Callsign"],
  ["KA7QVE", "n", 5000, "USA Callsign"],
  ["N0BNG", "n", 5000, "USA Callsign"],
  ["AA2BY", "n", 5000, "USA Callsign"],
  ["WB7QBS", "n", 5000, "USA Callsign"],
  ["KB9KCT", "n", 5000, "USA Callsign"],
  ["KK7K", "n", 5000, "USA Callsign"],
  ["N0WM", "n", 5000, "USA Callsign"],
  ["N8GS", "n", 5000, "USA Callsign"],
  ["KD4EDV", "n", 5000, "USA Callsign"],
  ["K0UN", "n", 5000, "USA Callsign"],
  ["W7XYB", "n", 5000, "USA Callsign"],
  ["WA8VTD", "n", 5000, "USA Callsign"],
  ["WB2SFZ", "n", 5000, "USA Callsign"],
  ["WA4FUR", "n", 5000, "USA Callsign"],
  ["K5WTR", "n", 5000, "USA Callsign"],
  ["WB8NDC", "n", 5000, "USA Callsign"],
  ["WA4MBI", "n", 5000, "USA Callsign"],
  ["KD7CAO", "n", 5000, "USA Callsign"],
  ["N3HOO", "n", 5000, "USA Callsign"],
  ["N5NBK", "n", 5000, "USA Callsign"],
  ["AB7FJ", "n", 5000, "USA Callsign"],
  ["KC2OMU", "n", 5000, "USA Callsign"],
  ["KD7MPA", "n", 5000, "USA Callsign"],
  ["K8ZH", "n", 5000, "USA Callsign"],
  ["KF4IXM", "n", 5000, "USA Callsign"],
  ["K0PA", "n", 5000, "USA Callsign"],
  ["W8XD", "n", 5000, "USA Callsign"],
  ["AG4YX", "n", 5000, "USA Callsign"],
  ["K3PFW", "n", 5000, "USA Callsign"],
  ["KE4PKR", "n", 5000, "USA Callsign"],
  ["KO1I", "n", 5000, "USA Callsign"],
  ["K8LSK", "n", 5000, "USA Callsign"],
  ["W0AG", "n", 5000, "USA Callsign"],
  ["K3QAM", "n", 5000, "USA Callsign"],
  ["KB8ARL", "n", 5000, "USA Callsign"],
  ["KC0HXB", "n", 5000, "USA Callsign"],
  ["AI4I", "n", 5000, "USA Callsign"],
  ["N1AO", "n", 5000, "USA Callsign"],
  ["W2AJM", "n", 5000, "USA Callsign"],
  ["N0KGK", "n", 5000, "USA Callsign"],
  ["WA2KKF", "n", 5000, "USA Callsign"],
  ["W2ESX", "n", 5000, "USA Callsign"],
  ["K6OIX", "n", 5000, "USA Callsign"],
  ["KC6ESN", "n", 5000, "USA Callsign"],
  ["AA7KN", "n", 5000, "USA Callsign"],
  ["WA4SSJ", "n", 5000, "USA Callsign"],
  ["W1YV", "n", 5000, "USA Callsign"],
  ["KA6RES", "n", 5000, "USA Callsign"],
  ["KC5MC", "n", 5000, "USA Callsign"],
  ["K4CHT", "n", 5000, "USA Callsign"],
  ["KG4SGP", "n", 5000, "USA Callsign"],
  ["K3FOP", "n", 5000, "USA Callsign"],
  ["N4QPM", "n", 5000, "USA Callsign"],
  ["KE4NBP", "n", 5000, "USA Callsign"],
  ["KG4PXG", "n", 5000, "USA Callsign"],
  ["WB7AWL", "n", 5000, "USA Callsign"],
  ["W5CNN", "n", 5000, "USA Callsign"],
  ["N3MPS", "n", 5000, "USA Callsign"],
  ["N8IT", "n", 5000, "USA Callsign"],
  ["W0CE", "n", 5000, "USA Callsign"],
  ["KB4FXC", "n", 5000, "USA Callsign"],
  ["KB0WIL", "n", 5000, "USA Callsign"],
  ["AA8RV", "n", 5000, "USA Callsign"],
  ["KD7ZNC", "n", 5000, "USA Callsign"],
  ["N4SPP", "n", 5000, "USA Callsign"],
  ["N4YN", "n", 5000, "USA Callsign"],
  ["KF6POG", "n", 5000, "USA Callsign"],
  ["WB2TJN", "n", 5000, "USA Callsign"],
  ["WX4HW", "n", 5000, "USA Callsign"],
  ["KC7JLS", "n", 5000, "USA Callsign"],
  ["KV4EB", "n", 5000, "USA Callsign"],
  ["K7JY", "n", 5000, "USA Callsign"],
  ["W6BLS", "n", 5000, "USA Callsign"],
  ["W1VRY", "n", 5000, "USA Callsign"],
  ["KA4WRR", "n", 5000, "USA Callsign"],
  ["NF9A", "n", 5000, "USA Callsign"],
  ["KB0NPW", "n", 5000, "USA Callsign"],
  ["AB9CN", "n", 5000, "USA Callsign"],
  ["W8JJO", "n", 5000, "USA Callsign"],
  ["WA2PUU", "n", 5000, "USA Callsign"],
  ["NK4P", "n", 5000, "USA Callsign"],
  ["N7LH", "n", 5000, "USA Callsign"],
  ["N8MPX", "n", 5000, "USA Callsign"],
  ["N4RUM", "n", 5000, "USA Callsign"],
  ["KB9PVL", "n", 5000, "USA Callsign"],
  ["WJ1C", "n", 5000, "USA Callsign"],
  ["W9GR", "n", 5000, "USA Callsign"],
  ["W1WSN", "n", 5000, "USA Callsign"],
  ["NL7EL", "n", 5000, "USA Callsign"],
  ["K0BGL", "n", 5000, "USA Callsign"],
  ["W4AV", "n", 5000, "USA Callsign"],
  ["KB3DEN", "n", 5000, "USA Callsign"],
  ["KF2LN", "n", 5000, "USA Callsign"],
  ["K6KW", "n", 5000, "USA Callsign"],
  ["K1MVP", "n", 5000, "USA Callsign"],
  ["K7ALR", "n", 5000, "USA Callsign"],
  ["N2NEI", "n", 5000, "USA Callsign"],
  ["AA8IK", "n", 5000, "USA Callsign"],
  ["AD7U", "n", 5000, "USA Callsign"],
  ["KF4GZN", "n", 5000, "USA Callsign"],
  ["KC6YFG", "n", 5000, "USA Callsign"],
  ["W4WHN", "n", 5000, "USA Callsign"],
  ["WA1ZHM", "n", 5000, "USA Callsign"],
  ["N6ORS", "n", 5000, "USA Callsign"],
  ["N3TE", "n", 5000, "USA Callsign"],
  ["WA4KJZ", "n", 5000, "USA Callsign"],
  ["K4EMA", "n", 5000, "USA Callsign"],
  ["K7GJT", "n", 5000, "USA Callsign"],
  ["WA2MNA", "n", 5000, "USA Callsign"],
  ["W4UA", "n", 5000, "USA Callsign"],
  ["K7HO", "n", 5000, "USA Callsign"],
  ["WD8DAU", "n", 5000, "USA Callsign"],
  ["NV1C", "n", 5000, "USA Callsign"],
  ["N5CFM", "n", 5000, "USA Callsign"],
  ["KF4SEK", "n", 5000, "USA Callsign"],
  ["N7SO", "n", 5000, "USA Callsign"],
  ["AL0I", "n", 5000, "USA Callsign"],
  ["KA2VBT", "n", 5000, "USA Callsign"],
  ["K8SWD", "n", 5000, "USA Callsign"],
  ["KD5KTB", "n", 5000, "USA Callsign"],
  ["KF4REN", "n", 5000, "USA Callsign"],
  ["N3ZKI", "n", 5000, "USA Callsign"],
  ["WN3N", "n", 5000, "USA Callsign"],
  ["NJ0C", "n", 5000, "USA Callsign"],
  ["WD8MHM", "n", 5000, "USA Callsign"],
  ["KA2PBT", "n", 5000, "USA Callsign"],
  ["KE4KLQ", "n", 5000, "USA Callsign"],
  ["KC7IYU", "n", 5000, "USA Callsign"],
  ["N4BUN", "n", 5000, "USA Callsign"],
  ["WA1SPY", "n", 5000, "USA Callsign"],
  ["K2ZKA", "n", 5000, "USA Callsign"],
  ["KB4OID", "n", 5000, "USA Callsign"],
  ["W2RW", "n", 5000, "USA Callsign"],
  ["KC0FXY", "n", 5000, "USA Callsign"],
  ["N7JG", "n", 5000, "USA Callsign"],
  ["AF4QM", "n", 5000, "USA Callsign"],
  ["W8MNQ", "n", 5000, "USA Callsign"],
  ["WB5AOH", "n", 5000, "USA Callsign"],
  ["KI4IDB", "n", 5000, "USA Callsign"],
  ["WD4FCJ", "n", 5000, "USA Callsign"],
  ["AB7RS", "n", 5000, "USA Callsign"],
  ["KC9ENM", "n", 5000, "USA Callsign"],
  ["WA2JRV", "n", 5000, "USA Callsign"],
  ["W8VE", "n", 5000, "USA Callsign"],
  ["N8EKU", "n", 5000, "USA Callsign"],
  ["N8KLQ", "n", 5000, "USA Callsign"],
  ["N0MNQ", "n", 5000, "USA Callsign"],
  ["W7EGO", "n", 5000, "USA Callsign"],
  ["KY0Q", "n", 5000, "USA Callsign"],
  ["KB9YSN", "n", 5000, "USA Callsign"],
  ["W6ORG", "n", 5000, "USA Callsign"],
  ["KB4PML", "n", 5000, "USA Callsign"],
  ["N0IW", "n", 5000, "USA Callsign"],
  ["KJ7NL", "n", 5000, "USA Callsign"],
  ["KC4FPE", "n", 5000, "USA Callsign"],
  ["KM5GH", "n", 5000, "USA Callsign"],
  ["KF6OYJ", "n", 5000, "USA Callsign"],
  ["W8JKS", "n", 5000, "USA Callsign"],
  ["K4JYB", "n", 5000, "USA Callsign"],
  ["KB8VEE", "n", 5000, "USA Callsign"],
  ["WK5C", "n", 5000, "USA Callsign"],
  ["AA2FD", "n", 5000, "USA Callsign"],
  ["NU4J", "n", 5000, "USA Callsign"],
  ["KB8GRO", "n", 5000, "USA Callsign"],
  ["W9FXT", "n", 5000, "USA Callsign"],
  ["W7TV", "n", 5000, "USA Callsign"],
  ["WD4MST", "n", 5000, "USA Callsign"],
  ["KA7IJU", "n", 5000, "USA Callsign"],
  ["KR0U", "n", 5000, "USA Callsign"],
  ["K0WTZ", "n", 5000, "USA Callsign"],
  ["KB9WVI", "n", 5000, "USA Callsign"],
  ["KF4LYU", "n", 5000, "USA Callsign"],
  ["KC8TVW", "n", 5000, "USA Callsign"],
  ["W4ZOO", "n", 5000, "USA Callsign"],
  ["W9FJ", "n", 5000, "USA Callsign"],
  ["KA9WAP", "n", 5000, "USA Callsign"],
  ["NN6RF", "n", 5000, "USA Callsign"],
  ["KB1FXK", "n", 5000, "USA Callsign"],
  ["W8PSK", "n", 5000, "USA Callsign"],
  ["W1TTT", "n", 5000, "USA Callsign"],
  ["K5ATV", "n", 5000, "USA Callsign"],
  ["WA6GFE", "n", 5000, "USA Callsign"],
  ["K8HK", "n", 5000, "USA Callsign"],
  ["AK1A", "n", 5000, "USA Callsign"],
  ["AC4MK", "n", 5000, "USA Callsign"],
  ["KC2IAY", "n", 5000, "USA Callsign"],
  ["KW1Q", "n", 5000, "USA Callsign"],
  ["WD5ILB", "n", 5000, "USA Callsign"],
  ["W2WA", "n", 5000, "USA Callsign"],
  ["W0BIS", "n", 5000, "USA Callsign"],
  ["KC8OUF", "n", 5000, "USA Callsign"],
  ["KE5FYR", "n", 5000, "USA Callsign"],
  ["WA9AGG", "n", 5000, "USA Callsign"],
  ["W4FSV", "n", 5000, "USA Callsign"],
  ["W4FOX", "n", 5000, "USA Callsign"],
  ["KA1QFE", "n", 5000, "USA Callsign"],
  ["W1QU", "n", 5000, "USA Callsign"],
  ["N5YZG", "n", 5000, "USA Callsign"],
  ["K2KV", "n", 5000, "USA Callsign"],
  ["KB6RPV", "n", 5000, "USA Callsign"],
  ["W9SS", "n", 5000, "USA Callsign"],
  ["KC7LGT", "n", 5000, "USA Callsign"],
  ["KD4RH", "n", 5000, "USA Callsign"],
  ["WB9YSD", "n", 5000, "USA Callsign"],
  ["WA9MID", "n", 5000, "USA Callsign"],
  ["N9NLE", "n", 5000, "USA Callsign"],
  ["KB9JDB", "n", 5000, "USA Callsign"],
  ["W0AVM", "n", 5000, "USA Callsign"],
  ["W3GHI", "n", 5000, "USA Callsign"],
  ["W4YB", "n", 5000, "USA Callsign"],
  ["N4AOF", "n", 5000, "USA Callsign"],
  ["K3WJL", "n", 5000, "USA Callsign"],
  ["WD4KDK", "n", 5000, "USA Callsign"],
  ["W2DGB", "n", 5000, "USA Callsign"],
  ["AA2HX", "n", 5000, "USA Callsign"],
  ["W7IC", "n", 5000, "USA Callsign"],
  ["KA6WKE", "n", 5000, "USA Callsign"],
  ["W7HQC", "n", 5000, "USA Callsign"],
  ["K7WCN", "n", 5000, "USA Callsign"],
  ["WB4BW", "n", 5000, "USA Callsign"],
  ["K4VE", "n", 5000, "USA Callsign"],
  ["W2TQS", "n", 5000, "USA Callsign"],
  ["KB5F", "n", 5000, "USA Callsign"],
  ["KA5ELD", "n", 5000, "USA Callsign"],
  ["AC7EI", "n", 5000, "USA Callsign"],
  ["KC8OPP", "n", 5000, "USA Callsign"],
  ["N0XDA", "n", 5000, "USA Callsign"],
  ["K1BR", "n", 5000, "USA Callsign"],
  ["KC8MQO", "n", 5000, "USA Callsign"],
  ["KB7A", "n", 5000, "USA Callsign"],
  ["KA2OGO", "n", 5000, "USA Callsign"],
  ["KA2UUP", "n", 5000, "USA Callsign"],
  ["W5HS", "n", 5000, "USA Callsign"],
  ["N8LFP", "n", 5000, "USA Callsign"],
  ["W6SQQ", "n", 5000, "USA Callsign"],
  ["KA6ROM", "n", 5000, "USA Callsign"],
  ["KC5BBP", "n", 5000, "USA Callsign"],
  ["N5TWI", "n", 5000, "USA Callsign"],
  ["KA9BAB", "n", 5000, "USA Callsign"],
  ["WA6AXE", "n", 5000, "USA Callsign"],
  ["WA4OYX", "n", 5000, "USA Callsign"],
  ["N1SOH", "n", 5000, "USA Callsign"],
  ["WB6ZRP", "n", 5000, "USA Callsign"],
  ["N9OV", "n", 5000, "USA Callsign"],
  ["KN0BY", "n", 5000, "USA Callsign"],
  ["W9FS", "n", 5000, "USA Callsign"],
  ["N4BB", "n", 5000, "USA Callsign"],
  ["N2XGL", "n", 5000, "USA Callsign"],
  ["WA4PUP", "n", 5000, "USA Callsign"],
  ["W1ZLY", "n", 5000, "USA Callsign"],
  ["KA6DOW", "n", 5000, "USA Callsign"],
  ["N7IQM", "n", 5000, "USA Callsign"],
  ["KE9OA", "n", 5000, "USA Callsign"],
  ["W0GOM", "n", 5000, "USA Callsign"],
  ["KB5WVK", "n", 5000, "USA Callsign"],
  ["K7WG", "n", 5000, "USA Callsign"],
  ["KG4JAO", "n", 5000, "USA Callsign"],
  ["KB5NHM", "n", 5000, "USA Callsign"],
  ["KV4K", "n", 5000, "USA Callsign"],
  ["WA1NXI", "n", 5000, "USA Callsign"],
  ["N8UD", "n", 5000, "USA Callsign"],
  ["WA2ZST", "n", 5000, "USA Callsign"],
  ["WA6BEF", "n", 5000, "USA Callsign"],
  ["W4QV", "n", 5000, "USA Callsign"],
  ["N0IT", "n", 5000, "USA Callsign"],
  ["K1PA", "n", 5000, "USA Callsign"],
  ["KC4UZY", "n", 5000, "USA Callsign"],
  ["N3PL", "n", 5000, "USA Callsign"],
  ["WA8DHB", "n", 5000, "USA Callsign"],
  ["KS4KJ", "n", 5000, "USA Callsign"],
  ["WI7M", "n", 5000, "USA Callsign"],
  ["W0BBD", "n", 5000, "USA Callsign"],
  ["KI1SSI", "n", 5000, "USA Callsign"],
  ["WA1RWN", "n", 5000, "USA Callsign"],
  ["N0PR", "n", 5000, "USA Callsign"],
  ["KC6UBP", "n", 5000, "USA Callsign"],
  ["K6RCL", "n", 5000, "USA Callsign"],
  ["KB9MQD", "n", 5000, "USA Callsign"],
  ["KB1DPD", "n", 5000, "USA Callsign"],
  ["WA3TKK", "n", 5000, "USA Callsign"],
  ["KA4TYG", "n", 5000, "USA Callsign"],
  ["AB4HQ", "n", 5000, "USA Callsign"],
  ["WA3IWI", "n", 5000, "USA Callsign"],
  ["KE6IGZ", "n", 5000, "USA Callsign"],
  ["N8SAX", "n", 5000, "USA Callsign"],
  ["WT5U", "n", 5000, "USA Callsign"],
  ["KD8BIW", "n", 5000, "USA Callsign"],
  ["KB5GXN", "n", 5000, "USA Callsign"],
  ["W7SRF", "n", 5000, "USA Callsign"],
  ["N0NJY", "n", 5000, "USA Callsign"],
  ["K9TSI", "n", 5000, "USA Callsign"],
  ["K1AID", "n", 5000, "USA Callsign"],
  ["K4EMT", "n", 5000, "USA Callsign"],
  ["WA9NPS", "n", 5000, "USA Callsign"],
  ["K6GRD", "n", 5000, "USA Callsign"],
  ["NT7C", "n", 5000, "USA Callsign"],
  ["WA1ZCN", "n", 5000, "USA Callsign"],
  ["W4AMD", "n", 5000, "USA Callsign"],
  ["N2PRT", "n", 5000, "USA Callsign"],
  ["K4WIX", "n", 5000, "USA Callsign"],
  ["KB0HNR", "n", 5000, "USA Callsign"],
  ["KD4SNL", "n", 5000, "USA Callsign"],
  ["KC4WGA", "n", 5000, "USA Callsign"],
  ["W5CM", "n", 5000, "USA Callsign"],
  ["K8KDR", "n", 5000, "USA Callsign"],
  ["KO4LJ", "n", 5000, "USA Callsign"],
  ["WJ3K", "n", 5000, "USA Callsign"],
  ["W1JCJ", "n", 5000, "USA Callsign"],
  ["KE5OX", "n", 5000, "USA Callsign"],
  ["W4ATA", "n", 5000, "USA Callsign"],
  ["W4JOE", "n", 5000, "USA Callsign"],
  ["K5BLC", "n", 5000, "USA Callsign"],
  ["K3COJ", "n", 5000, "USA Callsign"],
  ["N5TV", "n", 5000, "USA Callsign"],
  ["K4IVN", "n", 5000, "USA Callsign"],
  ["N5NDD", "n", 5000, "USA Callsign"],
  ["N5HRO", "n", 5000, "USA Callsign"],
  ["K4XE", "n", 5000, "USA Callsign"],
  ["KI0CW", "n", 5000, "USA Callsign"],
  ["KD6BNY", "n", 5000, "USA Callsign"],
  ["W8ZZ", "n", 5000, "USA Callsign"],
  ["N8XYF", "n", 5000, "USA Callsign"],
  ["WA4KWK", "n", 5000, "USA Callsign"],
  ["N2MX", "n", 5000, "USA Callsign"],
  ["N1CJS", "n", 5000, "USA Callsign"],
  ["KA6BQF", "n", 5000, "USA Callsign"],
  ["K7YSV", "n", 5000, "USA Callsign"],
  ["WA4YWM", "n", 5000, "USA Callsign"],
  ["KB5ZX", "n", 5000, "USA Callsign"],
  ["WA3GDC", "n", 5000, "USA Callsign"],
  ["WB1BUZ", "n", 5000, "USA Callsign"],
  ["WA8UMT", "n", 5000, "USA Callsign"],
  ["K3NH", "n", 5000, "USA Callsign"],
  ["K7WD", "n", 5000, "USA Callsign"],
  ["W8GWI", "n", 5000, "USA Callsign"],
  ["N3TGU", "n", 5000, "USA Callsign"],
  ["WM8Z", "n", 5000, "USA Callsign"],
  ["KD4CLJ", "n", 5000, "USA Callsign"],
  ["KD5UTC", "n", 5000, "USA Callsign"],
  ["KE5CCI", "n", 5000, "USA Callsign"],
  ["KC6WTA", "n", 5000, "USA Callsign"],
  ["KC8WRM", "n", 5000, "USA Callsign"],
  ["KB6MHK", "n", 5000, "USA Callsign"],
  ["AA9UM", "n", 5000, "USA Callsign"],
  ["N3NF", "n", 5000, "USA Callsign"],
  ["KE6JZ", "n", 5000, "USA Callsign"],
  ["K8WWA", "n", 5000, "USA Callsign"],
  ["KD5IGS", "n", 5000, "USA Callsign"],
  ["KZ2K", "n", 5000, "USA Callsign"],
  ["KI4MJK", "n", 5000, "USA Callsign"],
  ["AB4QW", "n", 5000, "USA Callsign"],
  ["K7CAX", "n", 5000, "USA Callsign"],
  ["KD6OLH", "n", 5000, "USA Callsign"],
  ["KA2AJH", "n", 5000, "USA Callsign"],
  ["N6XYN", "n", 5000, "USA Callsign"],
  ["KB7LNR", "n", 5000, "USA Callsign"],
  ["K8OVO", "n", 5000, "USA Callsign"],
  ["W3OWL", "n", 5000, "USA Callsign"],
  ["KB0TII", "n", 5000, "USA Callsign"],
  ["KC2MJT", "n", 5000, "USA Callsign"],
  ["N3PN", "n", 5000, "USA Callsign"],
  ["W2MJD", "n", 5000, "USA Callsign"],
  ["KD7REM", "n", 5000, "USA Callsign"],
  ["N9BTU", "n", 5000, "USA Callsign"],
  ["KF4ALB", "n", 5000, "USA Callsign"],
  ["W5AAX", "n", 5000, "USA Callsign"],
  ["WB2KKO", "n", 5000, "USA Callsign"],
  ["KD6NA", "n", 5000, "USA Callsign"],
  ["KC4OVY", "n", 5000, "USA Callsign"],
  ["N5TYW", "n", 5000, "USA Callsign"],
  ["AB8IW", "n", 5000, "USA Callsign"],
  ["N1DVJ", "n", 5000, "USA Callsign"],
  ["W2PWS", "n", 5000, "USA Callsign"],
  ["W4BY", "n", 5000, "USA Callsign"],
  ["KD4KMK", "n", 5000, "USA Callsign"],
  ["KQ6NO", "n", 5000, "USA Callsign"],
  ["KS2YL", "n", 5000, "USA Callsign"],
  ["KO6TH", "n", 5000, "USA Callsign"],
  ["KB0INT", "n", 5000, "USA Callsign"],
  ["KC3EV", "n", 5000, "USA Callsign"],
  ["NC4C", "n", 5000, "USA Callsign"],
  ["K5PRO", "n", 5000, "USA Callsign"],
  ["AB7BV", "n", 5000, "USA Callsign"],
  ["N5XCX", "n", 5000, "USA Callsign"],
  ["K5MGJ", "n", 5000, "USA Callsign"],
  ["WA6HTP", "n", 5000, "USA Callsign"],
  ["KE5EOO", "n", 5000, "USA Callsign"],
  ["N5GWD", "n", 5000, "USA Callsign"],
  ["WB3AFS", "n", 5000, "USA Callsign"],
  ["KD6ARJ", "n", 5000, "USA Callsign"],
  ["KB4BML", "n", 5000, "USA Callsign"],
  ["AA6U", "n", 5000, "USA Callsign"],
  ["KE2SX", "n", 5000, "USA Callsign"],
  ["K5PR", "n", 5000, "USA Callsign"],
  ["KC6NJC", "n", 5000, "USA Callsign"],
  ["W8AIR", "n", 5000, "USA Callsign"],
  ["K1AAG", "n", 5000, "USA Callsign"],
  ["KJ9K", "n", 5000, "USA Callsign"],
  ["AD6VR", "n", 5000, "USA Callsign"],
  ["KA1TKO", "n", 5000, "USA Callsign"],
  ["AH6MQ", "n", 5000, "USA Callsign"],
  ["N7TA", "n", 5000, "USA Callsign"],
  ["WA5CYI", "n", 5000, "USA Callsign"],
  ["W4MEA", "n", 5000, "USA Callsign"],
  ["KB0SW", "n", 5000, "USA Callsign"],
  ["KD6HPO", "n", 5000, "USA Callsign"],
  ["N4LTA", "n", 5000, "USA Callsign"],
  ["KB4ZAX", "n", 5000, "USA Callsign"],
  ["WB8QZZ", "n", 5000, "USA Callsign"],
  ["AA6TL", "n", 5000, "USA Callsign"],
  ["AB9CD", "n", 5000, "USA Callsign"],
  ["WA6TLW", "n", 5000, "USA Callsign"],
  ["WA4PKL", "n", 5000, "USA Callsign"],
  ["AB7YN", "n", 5000, "USA Callsign"],
  ["K7LMT", "n", 5000, "USA Callsign"],
  ["K6CEQ", "n", 5000, "USA Callsign"],
  ["W2JZ", "n", 5000, "USA Callsign"],
  ["KB3FCK", "n", 5000, "USA Callsign"],
  ["KG4HKI", "n", 5000, "USA Callsign"],
  ["N1PP", "n", 5000, "USA Callsign"],
  ["N8JOY", "n", 5000, "USA Callsign"],
  ["N1VFH", "n", 5000, "USA Callsign"],
  ["W1CF", "n", 5000, "USA Callsign"],
  ["W1VA", "n", 5000, "USA Callsign"],
  ["W3NC", "n", 5000, "USA Callsign"],
  ["K6RPF", "n", 5000, "USA Callsign"],
  ["K4IAP", "n", 5000, "USA Callsign"],
  ["K4QHM", "n", 5000, "USA Callsign"],
  ["K3CRF", "n", 5000, "USA Callsign"],
  ["W4ZWY", "n", 5000, "USA Callsign"],
  ["KA0ZJO", "n", 5000, "USA Callsign"],
  ["KX4G", "n", 5000, "USA Callsign"],
  ["AE4IV", "n", 5000, "USA Callsign"],
  ["W3CK", "n", 5000, "USA Callsign"],
  ["WA2KMS", "n", 5000, "USA Callsign"],
  ["W9QH", "n", 5000, "USA Callsign"],
  ["W2BBX", "n", 5000, "USA Callsign"],
  ["KC7TIG", "n", 5000, "USA Callsign"],
  ["KA0HNO", "n", 5000, "USA Callsign"],
  ["W8IXP", "n", 5000, "USA Callsign"],
  ["N0OAY", "n", 5000, "USA Callsign"],
  ["KC9IRY", "n", 5000, "USA Callsign"],
  ["KK6FX", "n", 5000, "USA Callsign"],
  ["NG0T", "n", 5000, "USA Callsign"],
  ["KA3IWC", "n", 5000, "USA Callsign"],
  ["WA5CAB", "n", 5000, "USA Callsign"],
  ["KG6WHG", "n", 5000, "USA Callsign"],
  ["K4TMN", "n", 5000, "USA Callsign"],
  ["N9LHD", "n", 5000, "USA Callsign"],
  ["KN4Q", "n", 5000, "USA Callsign"],
  ["KI4FJQ", "n", 5000, "USA Callsign"],
  ["WV9K", "n", 5000, "USA Callsign"],
  ["K7BY", "n", 5000, "USA Callsign"],
  ["W9KOP", "n", 5000, "USA Callsign"],
  ["K4HAM", "n", 5000, "USA Callsign"],
  ["KD5LKY", "n", 5000, "USA Callsign"],
  ["W0KY", "n", 5000, "USA Callsign"],
  ["KB9PZF", "n", 5000, "USA Callsign"],
  ["KD1BC", "n", 5000, "USA Callsign"],
  ["AA1GW", "n", 5000, "USA Callsign"],
  ["AA4YB", "n", 5000, "USA Callsign"],
  ["N9YPN", "n", 5000, "USA Callsign"],
  ["N0ZWC", "n", 5000, "USA Callsign"],
  ["K7NET", "n", 5000, "USA Callsign"],
  ["K8TCR", "n", 5000, "USA Callsign"],
  ["AD4QT", "n", 5000, "USA Callsign"],
  ["K9URT", "n", 5000, "USA Callsign"],
  ["KB0ZCO", "n", 5000, "USA Callsign"],
  ["K4BE", "n", 5000, "USA Callsign"],
  ["N6ZD", "n", 5000, "USA Callsign"],
  ["KD5IFD", "n", 5000, "USA Callsign"],
  ["WB5OOD", "n", 5000, "USA Callsign"],
  ["W7KBI", "n", 5000, "USA Callsign"],
  ["KA1LCR", "n", 5000, "USA Callsign"],
  ["W4OWJ", "n", 5000, "USA Callsign"],
  ["K4ERZ", "n", 5000, "USA Callsign"],
  ["AB1AV", "n", 5000, "USA Callsign"],
  ["WB5BUL", "n", 5000, "USA Callsign"],
  ["KB9VYX", "n", 5000, "USA Callsign"],
  ["N3JFM", "n", 5000, "USA Callsign"],
  ["KC4EE", "n", 5000, "USA Callsign"],
  ["K6EXO", "n", 5000, "USA Callsign"],
  ["N1KQY", "n", 5000, "USA Callsign"],
  ["KD4EYF", "n", 5000, "USA Callsign"],
  ["KG4NCG", "n", 5000, "USA Callsign"],
  ["KG0YE", "n", 5000, "USA Callsign"],
  ["W0RB", "n", 5000, "USA Callsign"],
  ["W6BYS", "n", 5000, "USA Callsign"],
  ["K1CH", "n", 5000, "USA Callsign"],
  ["WA6RE", "n", 5000, "USA Callsign"],
  ["KQ9L", "n", 5000, "USA Callsign"],
  ["K4MCH", "n", 5000, "USA Callsign"],
  ["K4IV", "n", 5000, "USA Callsign"],
  ["N1LQJ", "n", 5000, "USA Callsign"],
  ["K9RV", "n", 5000, "USA Callsign"],
  ["AD5OO", "n", 5000, "USA Callsign"],
  ["KP3N", "n", 5000, "USA Callsign"],
  ["N7HAE", "n", 5000, "USA Callsign"],
  ["N2LIV", "n", 5000, "USA Callsign"],
  ["KD6JNG", "n", 5000, "USA Callsign"],
  ["KA0BQN", "n", 5000, "USA Callsign"],
  ["WB5NEN", "n", 5000, "USA Callsign"],
  ["N6EXU", "n", 5000, "USA Callsign"],
  ["WB9OKQ", "n", 5000, "USA Callsign"],
  ["AB5EY", "n", 5000, "USA Callsign"],
  ["WB6RDO", "n", 5000, "USA Callsign"],
  ["W8SN", "n", 5000, "USA Callsign"],
  ["WA1HZK", "n", 5000, "USA Callsign"],
  ["K3HOT", "n", 5000, "USA Callsign"],
  ["KT4UV", "n", 5000, "USA Callsign"],
  ["W9LZE", "n", 5000, "USA Callsign"],
  ["KC0PTO", "n", 5000, "USA Callsign"],
  ["N4BGR", "n", 5000, "USA Callsign"],
  ["KK6OZ", "n", 5000, "USA Callsign"],
  ["N8VAR", "n", 5000, "USA Callsign"],
  ["WA5SLO", "n", 5000, "USA Callsign"],
  ["N5KQW", "n", 5000, "USA Callsign"],
  ["N0MBJ", "n", 5000, "USA Callsign"],
  ["K9JD", "n", 5000, "USA Callsign"],
  ["N7FUD", "n", 5000, "USA Callsign"],
  ["N5TNT", "n", 5000, "USA Callsign"],
  ["W7ZE", "n", 5000, "USA Callsign"],
  ["N1LCP", "n", 5000, "USA Callsign"],
  ["NP4ZC", "n", 5000, "USA Callsign"],
  ["KB0VOY", "n", 5000, "USA Callsign"],
  ["K4JB", "n", 5000, "USA Callsign"],
  ["N4YZW", "n", 5000, "USA Callsign"],
  ["N2AT", "n", 5000, "USA Callsign"],
  ["WB9HDO", "n", 5000, "USA Callsign"],
  ["WK5I", "n", 5000, "USA Callsign"],
  ["K1MUC", "n", 5000, "USA Callsign"],
  ["KC7DDW", "n", 5000, "USA Callsign"],
  ["W8ZU", "n", 5000, "USA Callsign"],
  ["N6FUB", "n", 5000, "USA Callsign"],
  ["K8WHT", "n", 5000, "USA Callsign"],
  ["KB0SJR", "n", 5000, "USA Callsign"],
  ["N5GB", "n", 5000, "USA Callsign"],
  ["AD0S", "n", 5000, "USA Callsign"],
  ["N8CUH", "n", 5000, "USA Callsign"],
  ["KD7JC", "n", 5000, "USA Callsign"],
  ["K5PT", "n", 5000, "USA Callsign"],
  ["KK6JB", "n", 5000, "USA Callsign"],
  ["N7KL", "n", 5000, "USA Callsign"],
  ["W3PVG", "n", 5000, "USA Callsign"],
  ["KC4PL", "n", 5000, "USA Callsign"],
  ["K0DRO", "n", 5000, "USA Callsign"],
  ["K8MGK", "n", 5000, "USA Callsign"],
  ["WT7T", "n", 5000, "USA Callsign"],
  ["WC1C", "n", 5000, "USA Callsign"],
  ["WA5LNL", "n", 5000, "USA Callsign"],
  ["KD5IKX", "n", 5000, "USA Callsign"],
  ["AE6RD", "n", 5000, "USA Callsign"],
  ["W6CH", "n", 5000, "USA Callsign"],
  ["W5HG", "n", 5000, "USA Callsign"],
  ["KF2BQ", "n", 5000, "USA Callsign"],
  ["WD5IAD", "n", 5000, "USA Callsign"],
  ["K4UHF", "n", 5000, "USA Callsign"],
  ["N6YVW", "n", 5000, "USA Callsign"],
  ["K4FMD", "n", 5000, "USA Callsign"],
  ["WA5KOS", "n", 5000, "USA Callsign"],
  ["AB4BK", "n", 5000, "USA Callsign"],
  ["WB8TVD", "n", 5000, "USA Callsign"],
  ["W9YJ", "n", 5000, "USA Callsign"],
  ["WB2YOK", "n", 5000, "USA Callsign"],
  ["WA8UNL", "n", 5000, "USA Callsign"],
  ["KB6JFL", "n", 5000, "USA Callsign"],
  ["AA9DZ", "n", 5000, "USA Callsign"],
  ["KB8AJT", "n", 5000, "USA Callsign"],
  ["K4ZP", "n", 5000, "USA Callsign"],
  ["KD5CFM", "n", 5000, "USA Callsign"],
  ["W7MJ", "n", 5000, "USA Callsign"],
  ["W7JR", "n", 5000, "USA Callsign"],
  ["KA8UHG", "n", 5000, "USA Callsign"],
  ["KV7O", "n", 5000, "USA Callsign"],
  ["K5WZF", "n", 5000, "USA Callsign"],
  ["K8UF", "n", 5000, "USA Callsign"],
  ["WW5H", "n", 5000, "USA Callsign"],
  ["AA5VN", "n", 5000, "USA Callsign"],
  ["KC8MLD", "n", 5000, "USA Callsign"],
  ["W7JYJ", "n", 5000, "USA Callsign"],
  ["KE6EWA", "n", 5000, "USA Callsign"],
  ["NE2W", "n", 5000, "USA Callsign"],
  ["KD5MM", "n", 5000, "USA Callsign"],
  ["K0EY", "n", 5000, "USA Callsign"],
  ["AK7RS", "n", 5000, "USA Callsign"],
  ["N9LCF", "n", 5000, "USA Callsign"],
  ["KB6LQS", "n", 5000, "USA Callsign"],
  ["KF4U", "n", 5000, "USA Callsign"],
  ["AB0ZK", "n", 5000, "USA Callsign"],
  ["K1KH", "n", 5000, "USA Callsign"],
  ["KD4SGY", "n", 5000, "USA Callsign"],
  ["K6KT", "n", 5000, "USA Callsign"],
  ["N3TUI", "n", 5000, "USA Callsign"],
  ["K4SLM", "n", 5000, "USA Callsign"],
  ["K4NAV", "n", 5000, "USA Callsign"],
  ["KA9VIS", "n", 5000, "USA Callsign"],
  ["W6FLU", "n", 5000, "USA Callsign"],
  ["WD5BYF", "n", 5000, "USA Callsign"],
  ["W9RL", "n", 5000, "USA Callsign"],
  ["KB5VEL", "n", 5000, "USA Callsign"],
  ["WK3W", "n", 5000, "USA Callsign"],
  ["KE3GP", "n", 5000, "USA Callsign"],
  ["KD4DLU", "n", 5000, "USA Callsign"],
  ["K4VWR", "n", 5000, "USA Callsign"],
  ["K5PFE", "n", 5000, "USA Callsign"],
  ["WD8OCS", "n", 5000, "USA Callsign"],
  ["W3VXT", "n", 5000, "USA Callsign"],
  ["N5OW", "n", 5000, "USA Callsign"],
  ["AA1OY", "n", 5000, "USA Callsign"],
  ["KC5MZA", "n", 5000, "USA Callsign"],
  ["K5HMZ", "n", 5000, "USA Callsign"],
  ["KD5FSJ", "n", 5000, "USA Callsign"],
  ["K5DKH", "n", 5000, "USA Callsign"],
  ["KC0BCZ", "n", 5000, "USA Callsign"],
  ["W9DPP", "n", 5000, "USA Callsign"],
  ["N9LKH", "n", 5000, "USA Callsign"],
  ["KA6TZJ", "n", 5000, "USA Callsign"],
  ["N3SSD", "n", 5000, "USA Callsign"],
  ["KI4E", "n", 5000, "USA Callsign"],
  ["N1ZWL", "n", 5000, "USA Callsign"],
  ["K7RS", "n", 5000, "USA Callsign"],
  ["KB3FWI", "n", 5000, "USA Callsign"],
  ["KC5GVC", "n", 5000, "USA Callsign"],
  ["WA1OHR", "n", 5000, "USA Callsign"],
  ["KB9JNO", "n", 5000, "USA Callsign"],
  ["KT4RH", "n", 5000, "USA Callsign"],
  ["AA2CU", "n", 5000, "USA Callsign"],
  ["K5TXK", "n", 5000, "USA Callsign"],
  ["WA2FSO", "n", 5000, "USA Callsign"],
  ["N0VPN", "n", 5000, "USA Callsign"],
  ["W5SP", "n", 5000, "USA Callsign"],
  ["N2CUP", "n", 5000, "USA Callsign"],
  ["KC8PEZ", "n", 5000, "USA Callsign"],
  ["N8JUP", "n", 5000, "USA Callsign"],
  ["K5FLI", "n", 5000, "USA Callsign"],
  ["K4TEE", "n", 5000, "USA Callsign"],
  ["N8JMV", "n", 5000, "USA Callsign"],
  ["W7KBE", "n", 5000, "USA Callsign"],
  ["WK7P", "n", 5000, "USA Callsign"],
  ["KA1TKS", "n", 5000, "USA Callsign"],
  ["K4SDC", "n", 5000, "USA Callsign"],
  ["WN4KPG", "n", 5000, "USA Callsign"],
  ["N2DXJ", "n", 5000, "USA Callsign"],
  ["N3PB", "n", 5000, "USA Callsign"],
  ["WA4MNT", "n", 5000, "USA Callsign"],
  ["KB3KBH", "n", 5000, "USA Callsign"],
  ["AA6US", "n", 5000, "USA Callsign"],
  ["K0HEH", "n", 5000, "USA Callsign"],
  ["KB3GIO", "n", 5000, "USA Callsign"],
  ["KG4JGR", "n", 5000, "USA Callsign"],
  ["KA3PTF", "n", 5000, "USA Callsign"],
  ["W4LRE", "n", 5000, "USA Callsign"],
  ["WF5A", "n", 5000, "USA Callsign"],
  ["W3HMO", "n", 5000, "USA Callsign"],
  ["KD5YOU", "n", 5000, "USA Callsign"],
  ["N4NCS", "n", 5000, "USA Callsign"],
  ["N9QID", "n", 5000, "USA Callsign"],
  ["K2TGX", "n", 5000, "USA Callsign"],
  ["KE7GRF", "n", 5000, "USA Callsign"],
  ["AB4WM", "n", 5000, "USA Callsign"],
  ["N4HPN", "n", 5000, "USA Callsign"],
  ["W7FN", "n", 5000, "USA Callsign"],
  ["KE4YZU", "n", 5000, "USA Callsign"],
  ["KE4YZK", "n", 5000, "USA Callsign"],
  ["KB8TUY", "n", 5000, "USA Callsign"],
  ["W8LFB", "n", 5000, "USA Callsign"],
  ["W0MTP", "n", 5000, "USA Callsign"],
  ["KB2VOY", "n", 5000, "USA Callsign"],
  ["K3KL", "n", 5000, "USA Callsign"],
  ["K4FM", "n", 5000, "USA Callsign"],
  ["W7LWS", "n", 5000, "USA Callsign"],
  ["W5AQP", "n", 5000, "USA Callsign"],
  ["WD4DCT", "n", 5000, "USA Callsign"],
  ["WA5F", "n", 5000, "USA Callsign"],
  ["N3EVL", "n", 5000, "USA Callsign"],
  ["AC4CB", "n", 5000, "USA Callsign"],
  ["WA6VPL", "n", 5000, "USA Callsign"],
  ["W0DOX", "n", 5000, "USA Callsign"],
  ["N7DOH", "n", 5000, "USA Callsign"],
  ["N8CH", "n", 5000, "USA Callsign"],
  ["KB7YNM", "n", 5000, "USA Callsign"],
  ["W1SST", "n", 5000, "USA Callsign"],
  ["W0KNX", "n", 5000, "USA Callsign"],
  ["KD7UXT", "n", 5000, "USA Callsign"],
  ["KE5FWT", "n", 5000, "USA Callsign"],
  ["N9RZD", "n", 5000, "USA Callsign"],
  ["N6DG", "n", 5000, "USA Callsign"],
  ["N1KMK", "n", 5000, "USA Callsign"],
  ["KB4BYH", "n", 5000, "USA Callsign"],
  ["AA2CO", "n", 5000, "USA Callsign"],
  ["K7EFG", "n", 5000, "USA Callsign"],
  ["WA6RZI", "n", 5000, "USA Callsign"],
  ["N4ID", "n", 5000, "USA Callsign"],
  ["W2BUS", "n", 5000, "USA Callsign"],
  ["N8BAD", "n", 5000, "USA Callsign"],
  ["KB5MPW", "n", 5000, "USA Callsign"],
  ["W8CWD", "n", 5000, "USA Callsign"],
  ["K0LGI", "n", 5000, "USA Callsign"],
  ["K7TRM", "n", 5000, "USA Callsign"],
  ["KB3JCW", "n", 5000, "USA Callsign"],
  ["N8HON", "n", 5000, "USA Callsign"],
  ["W7EMA", "n", 5000, "USA Callsign"],
  ["K1DUH", "n", 5000, "USA Callsign"],
  ["KD5MFY", "n", 5000, "USA Callsign"],
  ["N2XSG", "n", 5000, "USA Callsign"],
  ["KB8JNE", "n", 5000, "USA Callsign"],
  ["WA4G", "n", 5000, "USA Callsign"],
  ["WB7PTR", "n", 5000, "USA Callsign"],
  ["AB5LY", "n", 5000, "USA Callsign"],
  ["K7CCD", "n", 5000, "USA Callsign"],
  ["KG4IMC", "n", 5000, "USA Callsign"],
  ["N8JUQ", "n", 5000, "USA Callsign"],
  ["N9LTT", "n", 5000, "USA Callsign"],
  ["WB8TEK", "n", 5000, "USA Callsign"],
  ["N6RM", "n", 5000, "USA Callsign"],
  ["W4FWM", "n", 5000, "USA Callsign"],
  ["KD0OD", "n", 5000, "USA Callsign"],
  ["N5JFQ", "n", 5000, "USA Callsign"],
  ["N1RU", "n", 5000, "USA Callsign"],
  ["KJ6XE", "n", 5000, "USA Callsign"],
  ["K4END", "n", 5000, "USA Callsign"],
  ["WB7TNH", "n", 5000, "USA Callsign"],
  ["KC9BVS", "n", 5000, "USA Callsign"],
  ["WB1AAT", "n", 5000, "USA Callsign"],
  ["WA4IZG", "n", 5000, "USA Callsign"],
  ["AC5EH", "n", 5000, "USA Callsign"],
  ["N1LC", "n", 5000, "USA Callsign"],
  ["WA6DDF", "n", 5000, "USA Callsign"],
  ["N4TBM", "n", 5000, "USA Callsign"],
  ["KB5OBT", "n", 5000, "USA Callsign"],
  ["N1OLX", "n", 5000, "USA Callsign"],
  ["KV5I", "n", 5000, "USA Callsign"],
  ["K6SLK", "n", 5000, "USA Callsign"],
  ["WB0FFI", "n", 5000, "USA Callsign"],
  ["KI0RN", "n", 5000, "USA Callsign"],
  ["W5VGZ", "n", 5000, "USA Callsign"],
  ["KD7GXW", "n", 5000, "USA Callsign"],
  ["W0HSC", "n", 5000, "USA Callsign"],
  ["WB4FGV", "n", 5000, "USA Callsign"],
  ["KV4O", "n", 5000, "USA Callsign"],
  ["KN4YT", "n", 5000, "USA Callsign"],
  ["N6EK", "n", 5000, "USA Callsign"],
  ["KE4KVC", "n", 5000, "USA Callsign"],
  ["KB8BJR", "n", 5000, "USA Callsign"],
  ["K4VHX", "n", 5000, "USA Callsign"],
  ["N6DDX", "n", 5000, "USA Callsign"],
  ["K4BAB", "n", 5000, "USA Callsign"],
  ["N0VKI", "n", 5000, "USA Callsign"],
  ["K8MLT", "n", 5000, "USA Callsign"],
  ["AC7LU", "n", 5000, "USA Callsign"],
  ["WB0LXV", "n", 5000, "USA Callsign"],
  ["K7BFA", "n", 5000, "USA Callsign"],
  ["K4TFW", "n", 5000, "USA Callsign"],
  ["KC8OYK", "n", 5000, "USA Callsign"],
  ["KB8NKG", "n", 5000, "USA Callsign"],
  ["K4GDQ", "n", 5000, "USA Callsign"],
  ["KB0WTH", "n", 5000, "USA Callsign"],
  ["KA2MEK", "n", 5000, "USA Callsign"],
  ["KD4LOF", "n", 5000, "USA Callsign"],
  ["KC0UFP", "n", 5000, "USA Callsign"],
  ["KD5LJV", "n", 5000, "USA Callsign"],
  ["KD5YBG", "n", 5000, "USA Callsign"],
  ["KC7OVF", "n", 5000, "USA Callsign"],
  ["W6ELL", "n", 5000, "USA Callsign"],
  ["N1PXX", "n", 5000, "USA Callsign"],
  ["W1CTT", "n", 5000, "USA Callsign"],
  ["KT4YE", "n", 5000, "USA Callsign"],
  ["WB2VUF", "n", 5000, "USA Callsign"],
  ["N3WJ", "n", 5000, "USA Callsign"],
  ["KH2TX", "n", 5000, "USA Callsign"],
  ["KB0EW", "n", 5000, "USA Callsign"],
  ["KA1ZEX", "n", 5000, "USA Callsign"],
  ["N9HWP", "n", 5000, "USA Callsign"],
  ["KB5JTJ", "n", 5000, "USA Callsign"],
  ["KB4BKV", "n", 5000, "USA Callsign"],
  ["W0KKS", "n", 5000, "USA Callsign"],
  ["KB0NRT", "n", 5000, "USA Callsign"],
  ["WB9PQI", "n", 5000, "USA Callsign"],
  ["KJ7ZT", "n", 5000, "USA Callsign"],
  ["KA7VIS", "n", 5000, "USA Callsign"],
  ["KC0OKP", "n", 5000, "USA Callsign"],
  ["KB2SHD", "n", 5000, "USA Callsign"],
  ["AI4AN", "n", 5000, "USA Callsign"],
  ["N2GTO", "n", 5000, "USA Callsign"],
  ["WD7N", "n", 5000, "USA Callsign"],
  ["K5GD", "n", 5000, "USA Callsign"],
  ["K7NHB", "n", 5000, "USA Callsign"],
  ["WB0ATB", "n", 5000, "USA Callsign"],
  ["KB2AVN", "n", 5000, "USA Callsign"],
  ["WN4J", "n", 5000, "USA Callsign"],
  ["W5XL", "n", 5000, "USA Callsign"],
  ["W5SJZ", "n", 5000, "USA Callsign"],
  ["W4JRB", "n", 5000, "USA Callsign"],
  ["AL1R", "n", 5000, "USA Callsign"],
  ["N2QEE", "n", 5000, "USA Callsign"],
  ["KG4WSB", "n", 5000, "USA Callsign"],
  ["W3KI", "n", 5000, "USA Callsign"],
  ["N8AHO", "n", 5000, "USA Callsign"],
  ["N6CID", "n", 5000, "USA Callsign"],
  ["W0RDE", "n", 5000, "USA Callsign"],
  ["W5LON", "n", 5000, "USA Callsign"],
  ["K4IB", "n", 5000, "USA Callsign"],
  ["W6DFG", "n", 5000, "USA Callsign"],
  ["N2LS", "n", 5000, "USA Callsign"],
  ["KC2OUU", "n", 5000, "USA Callsign"],
  ["AA2FL", "n", 5000, "USA Callsign"],
  ["N8LXM", "n", 5000, "USA Callsign"],
  ["W4JFB", "n", 5000, "USA Callsign"],
  ["KG4SMV", "n", 5000, "USA Callsign"],
  ["W0SSR", "n", 5000, "USA Callsign"],
  ["KF6ARX", "n", 5000, "USA Callsign"],
  ["W7EFZ", "n", 5000, "USA Callsign"],
  ["W5OXX", "n", 5000, "USA Callsign"],
  ["W5PU", "n", 5000, "USA Callsign"],
  ["KB5ITS", "n", 5000, "USA Callsign"],
  ["KF6BL", "n", 5000, "USA Callsign"],
  ["KB7FHQ", "n", 5000, "USA Callsign"],
  ["WB6RNH", "n", 5000, "USA Callsign"],
  ["N1ICL", "n", 5000, "USA Callsign"],
  ["AA2NJ", "n", 5000, "USA Callsign"],
  ["N2JSV", "n", 5000, "USA Callsign"],
  ["KN6AJ", "n", 5000, "USA Callsign"],
  ["K4MPY", "n", 5000, "USA Callsign"],
  ["N3HJQ", "n", 5000, "USA Callsign"],
  ["KG5AI", "n", 5000, "USA Callsign"],
  ["N2SZ", "n", 5000, "USA Callsign"],
  ["W9JDR", "n", 5000, "USA Callsign"],
  ["KB2EPI", "n", 5000, "USA Callsign"],
  ["W6EOE", "n", 5000, "USA Callsign"],
  ["KE4MBM", "n", 5000, "USA Callsign"],
  ["W2XP", "n", 5000, "USA Callsign"],
  ["WB2YJS", "n", 5000, "USA Callsign"],
  ["KB0ECO", "n", 5000, "USA Callsign"],
  ["W4EC", "n", 5000, "USA Callsign"],
  ["KE1BB", "n", 5000, "USA Callsign"],
  ["W6RTG", "n", 5000, "USA Callsign"],
  ["K0HGJ", "n", 5000, "USA Callsign"],
  ["KB6OT", "n", 5000, "USA Callsign"],
  ["W8CV", "n", 5000, "USA Callsign"],
  ["AD7HL", "n", 5000, "USA Callsign"],
  ["KB8ZGK", "n", 5000, "USA Callsign"],
  ["W2JS", "n", 5000, "USA Callsign"],
  ["W1PLK", "n", 5000, "USA Callsign"],
  ["W4NNN", "n", 5000, "USA Callsign"],
  ["N9ZW", "n", 5000, "USA Callsign"],
  ["N8WFX", "n", 5000, "USA Callsign"],
  ["N4CSX", "n", 5000, "USA Callsign"],
  ["WB4BPS", "n", 5000, "USA Callsign"],
  ["W3SIX", "n", 5000, "USA Callsign"],
  ["N0RSP", "n", 5000, "USA Callsign"],
  ["K6LS", "n", 5000, "USA Callsign"],
  ["KA3RXE", "n", 5000, "USA Callsign"],
  ["KD5LIB", "n", 5000, "USA Callsign"],
  ["W4OJV", "n", 5000, "USA Callsign"],
  ["N0XK", "n", 5000, "USA Callsign"],
  ["WA7JN", "n", 5000, "USA Callsign"],
  ["K3BK", "n", 5000, "USA Callsign"],
  ["WB2QDS", "n", 5000, "USA Callsign"],
  ["KC2JAV", "n", 5000, "USA Callsign"],
  ["WB6ASU", "n", 5000, "USA Callsign"],
  ["N5WYU", "n", 5000, "USA Callsign"],
  ["W4TWA", "n", 5000, "USA Callsign"],
  ["K8PA", "n", 5000, "USA Callsign"],
  ["W9MI", "n", 5000, "USA Callsign"],
  ["KG9OV", "n", 5000, "USA Callsign"],
  ["WB6TMH", "n", 5000, "USA Callsign"],
  ["KL7MG", "n", 5000, "USA Callsign"],
  ["K2FAA", "n", 5000, "USA Callsign"],
  ["KF4OYS", "n", 5000, "USA Callsign"],
  ["KC5UNC", "n", 5000, "USA Callsign"],
  ["K3LNV", "n", 5000, "USA Callsign"],
  ["KE4L", "n", 5000, "USA Callsign"],
  ["AI5T", "n", 5000, "USA Callsign"],
  ["W9JL", "n", 5000, "USA Callsign"],
  ["K2ISO", "n", 5000, "USA Callsign"],
  ["N7RON", "n", 5000, "USA Callsign"],
  ["W3DU", "n", 5000, "USA Callsign"],
  ["KI4RA", "n", 5000, "USA Callsign"],
  ["N2ZOE", "n", 5000, "USA Callsign"],
  ["K3OTY", "n", 5000, "USA Callsign"],
  ["K2ACX", "n", 5000, "USA Callsign"],
  ["K4WT", "n", 5000, "USA Callsign"],
  ["KU4RN", "n", 5000, "USA Callsign"],
  ["KA2ASU", "n", 5000, "USA Callsign"],
  ["K6CKM", "n", 5000, "USA Callsign"],
  ["KA0SOD", "n", 5000, "USA Callsign"],
  ["K4KGW", "n", 5000, "USA Callsign"],
  ["AC5BJ", "n", 5000, "USA Callsign"],
  ["WA2WWK", "n", 5000, "USA Callsign"],
  ["KC8LRH", "n", 5000, "USA Callsign"],
  ["WB8QGR", "n", 5000, "USA Callsign"],
  ["WB3ERA", "n", 5000, "USA Callsign"],
  ["KM6TS", "n", 5000, "USA Callsign"],
  ["AA6QF", "n", 5000, "USA Callsign"],
  ["KB4NEI", "n", 5000, "USA Callsign"],
  ["KD1MA", "n", 5000, "USA Callsign"],
  ["WB9FOL", "n", 5000, "USA Callsign"],
  ["KG6ZDL", "n", 5000, "USA Callsign"],
  ["KB8FU", "n", 5000, "USA Callsign"],
  ["N1TAB", "n", 5000, "USA Callsign"],
  ["KB3EHW", "n", 5000, "USA Callsign"],
  ["WD5DJT", "n", 5000, "USA Callsign"],
  ["WD9FRI", "n", 5000, "USA Callsign"],
  ["KC6SAX", "n", 5000, "USA Callsign"],
  ["KB8ZB", "n", 5000, "USA Callsign"],
  ["N4TUA", "n", 5000, "USA Callsign"],
  ["KA4IGR", "n", 5000, "USA Callsign"],
  ["KB2BPM", "n", 5000, "USA Callsign"],
  ["KC9HLX", "n", 5000, "USA Callsign"],
  ["K7OGW", "n", 5000, "USA Callsign"],
  ["N9YM", "n", 5000, "USA Callsign"],
  ["N7IVV", "n", 5000, "USA Callsign"],
  ["KC8JLF", "n", 5000, "USA Callsign"],
  ["KB8JDY", "n", 5000, "USA Callsign"],
  ["N2LHT", "n", 5000, "USA Callsign"],
  ["W9CLH", "n", 5000, "USA Callsign"],
  ["N3GL", "n", 5000, "USA Callsign"],
  ["N6SVX", "n", 5000, "USA Callsign"],
  ["KF4GSY", "n", 5000, "USA Callsign"],
  ["KC0IRJ", "n", 5000, "USA Callsign"],
  ["K6DQI", "n", 5000, "USA Callsign"],
  ["KD4M", "n", 5000, "USA Callsign"],
  ["W5RC", "n", 5000, "USA Callsign"],
  ["W0QFC", "n", 5000, "USA Callsign"],
  ["KC8LNE", "n", 5000, "USA Callsign"],
  ["KE6BZX", "n", 5000, "USA Callsign"],
  ["KB8OIS", "n", 5000, "USA Callsign"],
  ["WX4Y", "n", 5000, "USA Callsign"],
  ["N4RC", "n", 5000, "USA Callsign"],
  ["KD5FOP", "n", 5000, "USA Callsign"],
  ["KC7UAI", "n", 5000, "USA Callsign"],
  ["K5MZ", "n", 5000, "USA Callsign"],
  ["KB1HVS", "n", 5000, "USA Callsign"],
  ["N7XF", "n", 5000, "USA Callsign"],
  ["AC5TW", "n", 5000, "USA Callsign"],
  ["KB8HOC", "n", 5000, "USA Callsign"],
  ["W7OG", "n", 5000, "USA Callsign"],
  ["KA3UVC", "n", 5000, "USA Callsign"],
  ["NT7T", "n", 5000, "USA Callsign"],
  ["N2NQH", "n", 5000, "USA Callsign"],
  ["N2EW", "n", 5000, "USA Callsign"],
  ["KG4JAM", "n", 5000, "USA Callsign"],
  ["KF6FJL", "n", 5000, "USA Callsign"],
  ["W7RTY", "n", 5000, "USA Callsign"],
  ["K9BDN", "n", 5000, "USA Callsign"],
  ["W9YTC", "n", 5000, "USA Callsign"],
  ["WA6UNL", "n", 5000, "USA Callsign"],
  ["KR4VS", "n", 5000, "USA Callsign"],
  ["NT2P", "n", 5000, "USA Callsign"],
  ["AC5TF", "n", 5000, "USA Callsign"],
  ["WB4KST", "n", 5000, "USA Callsign"],
  ["K2YS", "n", 5000, "USA Callsign"],
  ["K6PPJ", "n", 5000, "USA Callsign"],
  ["WP4KSP", "n", 5000, "USA Callsign"],
  ["WA9GON", "n", 5000, "USA Callsign"],
  ["W2RGM", "n", 5000, "USA Callsign"],
  ["WD8C", "n", 5000, "USA Callsign"],
  ["N9CA", "n", 5000, "USA Callsign"],
  ["AB0MR", "n", 5000, "USA Callsign"],
  ["K7HSM", "n", 5000, "USA Callsign"],
  ["WN0DRC", "n", 5000, "USA Callsign"],
  ["N8IPO", "n", 5000, "USA Callsign"],
  ["AJ7S", "n", 5000, "USA Callsign"],
  ["KA3YUH", "n", 5000, "USA Callsign"],
  ["KA2YRY", "n", 5000, "USA Callsign"],
  ["WA3YCU", "n", 5000, "USA Callsign"],
  ["K8WY", "n", 5000, "USA Callsign"],
  ["KB4BS", "n", 5000, "USA Callsign"],
  ["N6CQ", "n", 5000, "USA Callsign"],
  ["AA4WT", "n", 5000, "USA Callsign"],
  ["K8MRE", "n", 5000, "USA Callsign"],
  ["W0EW", "n", 5000, "USA Callsign"],
  ["W9EZT", "n", 5000, "USA Callsign"],
  ["N9ZNF", "n", 5000, "USA Callsign"],
  ["W6SKI", "n", 5000, "USA Callsign"],
  ["N2VLS", "n", 5000, "USA Callsign"],
  ["WA4ZFL", "n", 5000, "USA Callsign"],
  ["KD5RYI", "n", 5000, "USA Callsign"],
  ["KA4ATT", "n", 5000, "USA Callsign"],
  ["KA4TFP", "n", 5000, "USA Callsign"],
  ["W6SQC", "n", 5000, "USA Callsign"],
  ["K5DGH", "n", 5000, "USA Callsign"],
  ["K1TLV", "n", 5000, "USA Callsign"],
  ["K3SZN", "n", 5000, "USA Callsign"],
  ["WK3Q", "n", 5000, "USA Callsign"],
  ["N2HQC", "n", 5000, "USA Callsign"],
  ["N9TG", "n", 5000, "USA Callsign"],
  ["KE1P", "n", 5000, "USA Callsign"],
  ["N2VIP", "n", 5000, "USA Callsign"],
  ["AC7KZ", "n", 5000, "USA Callsign"],
  ["KG0NA", "n", 5000, "USA Callsign"],
  ["KD2PM", "n", 5000, "USA Callsign"],
  ["N0RJY", "n", 5000, "USA Callsign"],
  ["KC2PBI", "n", 5000, "USA Callsign"],
  ["K0KTZ", "n", 5000, "USA Callsign"],
  ["KB0RGW", "n", 5000, "USA Callsign"],
  ["N1SKM", "n", 5000, "USA Callsign"],
  ["W6AFU", "n", 5000, "USA Callsign"],
  ["W7THG", "n", 5000, "USA Callsign"],
  ["WB4AJL", "n", 5000, "USA Callsign"],
  ["WA7TCT", "n", 5000, "USA Callsign"],
  ["N2ZGW", "n", 5000, "USA Callsign"],
  ["W1MEO", "n", 5000, "USA Callsign"],
  ["K8WMW", "n", 5000, "USA Callsign"],
  ["N7ARC", "n", 5000, "USA Callsign"],
  ["N1RN", "n", 5000, "USA Callsign"],
  ["K8BPD", "n", 5000, "USA Callsign"],
  ["W7DSB", "n", 5000, "USA Callsign"],
  ["AI4NN", "n", 5000, "USA Callsign"],
  ["KB9WWE", "n", 5000, "USA Callsign"],
  ["WB6SUB", "n", 5000, "USA Callsign"],
  ["AE4QU", "n", 5000, "USA Callsign"],
  ["WB9KVI", "n", 5000, "USA Callsign"],
  ["KC8KZM", "n", 5000, "USA Callsign"],
  ["N6BPU", "n", 5000, "USA Callsign"],
  ["KC4J", "n", 5000, "USA Callsign"],
  ["W9BP", "n", 5000, "USA Callsign"],
  ["KB3B", "n", 5000, "USA Callsign"],
  ["WB8DEL", "n", 5000, "USA Callsign"],
  ["K1CD", "n", 5000, "USA Callsign"],
  ["W6DWY", "n", 5000, "USA Callsign"],
  ["W1TB", "n", 5000, "USA Callsign"],
  ["WB9UCA", "n", 5000, "USA Callsign"],
  ["KD7JMA", "n", 5000, "USA Callsign"],
  ["WA6IJD", "n", 5000, "USA Callsign"],
  ["KD7ROB", "n", 5000, "USA Callsign"],
  ["KC2IRA", "n", 5000, "USA Callsign"],
  ["AB2UW", "n", 5000, "USA Callsign"],
  ["AK6H", "n", 5000, "USA Callsign"],
  ["KB7UPX", "n", 5000, "USA Callsign"],
  ["N7SNI", "n", 5000, "USA Callsign"],
  ["W6JVA", "n", 5000, "USA Callsign"],
  ["WA9WCN", "n", 5000, "USA Callsign"],
  ["NR8I", "n", 5000, "USA Callsign"],
  ["N6CCM", "n", 5000, "USA Callsign"],
  ["K4BHL", "n", 5000, "USA Callsign"],
  ["KC6DHI", "n", 5000, "USA Callsign"],
  ["AE4ZT", "n", 5000, "USA Callsign"],
  ["WA6CHF", "n", 5000, "USA Callsign"],
  ["W0DSF", "n", 5000, "USA Callsign"],
  ["K3FF", "n", 5000, "USA Callsign"],
  ["KB7UCS", "n", 5000, "USA Callsign"],
  ["W0EY", "n", 5000, "USA Callsign"],
  ["WB2IWC", "n", 5000, "USA Callsign"],
  ["KG4OEZ", "n", 5000, "USA Callsign"],
  ["N7NY", "n", 5000, "USA Callsign"],
  ["KC0QVU", "n", 5000, "USA Callsign"],
  ["KG6DFQ", "n", 5000, "USA Callsign"],
  ["KC9ALE", "n", 5000, "USA Callsign"],
  ["N1LB", "n", 5000, "USA Callsign"],
  ["WB4WMJ", "n", 5000, "USA Callsign"],
  ["N8FGM", "n", 5000, "USA Callsign"],
  ["W5CUD", "n", 5000, "USA Callsign"],
  ["WW6W", "n", 5000, "USA Callsign"],
  ["WA1YNJ", "n", 5000, "USA Callsign"],
  ["KA8WEM", "n", 5000, "USA Callsign"],
  ["W1HZN", "n", 5000, "USA Callsign"],
  ["AA3VO", "n", 5000, "USA Callsign"],
  ["AJ4U", "n", 5000, "USA Callsign"],
  ["KF6PJW", "n", 5000, "USA Callsign"],
  ["KM5HO", "n", 5000, "USA Callsign"],
  ["AJ2U", "n", 5000, "USA Callsign"],
  ["W7VSE", "n", 5000, "USA Callsign"],
  ["N1SFF", "n", 5000, "USA Callsign"],
  ["K8JJZ", "n", 5000, "USA Callsign"],
  ["KC5YSD", "n", 5000, "USA Callsign"],
  ["K3OZ", "n", 5000, "USA Callsign"],
  ["N1ZKH", "n", 5000, "USA Callsign"],
  ["K2JYV", "n", 5000, "USA Callsign"],
  ["W5ZTG", "n", 5000, "USA Callsign"],
  ["WA3ZIC", "n", 5000, "USA Callsign"],
  ["K8LT", "n", 5000, "USA Callsign"],
  ["KC8QXE", "n", 5000, "USA Callsign"],
  ["KA9IYZ", "n", 5000, "USA Callsign"],
  ["W8MF", "n", 5000, "USA Callsign"],
  ["WG9B", "n", 5000, "USA Callsign"],
  ["KA9PQN", "n", 5000, "USA Callsign"],
  ["KC5UTA", "n", 5000, "USA Callsign"],
  ["WB0BDX", "n", 5000, "USA Callsign"],
  ["KS0P", "n", 5000, "USA Callsign"],
  ["KB9ZSH", "n", 5000, "USA Callsign"],
  ["K7FT", "n", 5000, "USA Callsign"],
  ["WB0TCW", "n", 5000, "USA Callsign"],
  ["AK7N", "n", 5000, "USA Callsign"],
  ["W4LZP", "n", 5000, "USA Callsign"],
  ["KI4OTH", "n", 5000, "USA Callsign"],
  ["KA4WPX", "n", 5000, "USA Callsign"],
  ["WA8UCS", "n", 5000, "USA Callsign"],
  ["KC4OGF", "n", 5000, "USA Callsign"],
  ["AA7BV", "n", 5000, "USA Callsign"],
  ["KD5DZE", "n", 5000, "USA Callsign"],
  ["N9FF", "n", 5000, "USA Callsign"],
  ["KD7LWR", "n", 5000, "USA Callsign"],
  ["K4SMP", "n", 5000, "USA Callsign"],
  ["NB9U", "n", 5000, "USA Callsign"],
  ["AD4DP", "n", 5000, "USA Callsign"],
  ["N8QYG", "n", 5000, "USA Callsign"],
  ["N9NCX", "n", 5000, "USA Callsign"],
  ["AD4RD", "n", 5000, "USA Callsign"],
  ["KB3KR", "n", 5000, "USA Callsign"],
  ["W4GHZ", "n", 5000, "USA Callsign"],
  ["K9EHP", "n", 5000, "USA Callsign"],
  ["KI4NCK", "n", 5000, "USA Callsign"],
  ["KD8COE", "n", 5000, "USA Callsign"],
  ["N5NNN", "n", 5000, "USA Callsign"],
  ["AA6KB", "n", 5000, "USA Callsign"],
  ["N5FTL", "n", 5000, "USA Callsign"],
  ["N2RUB", "n", 5000, "USA Callsign"],
  ["WD8IIR", "n", 5000, "USA Callsign"],
  ["N5DGA", "n", 5000, "USA Callsign"],
  ["W7NBC", "n", 5000, "USA Callsign"],
  ["WA5GYM", "n", 5000, "USA Callsign"],
  ["N5VTP", "n", 5000, "USA Callsign"],
  ["NU0M", "n", 5000, "USA Callsign"],
  ["KE4VPN", "n", 5000, "USA Callsign"],
  ["N9WO", "n", 5000, "USA Callsign"],
  ["K4AWE", "n", 5000, "USA Callsign"],
  ["KB9LXP", "n", 5000, "USA Callsign"],
  ["KG7BF", "n", 5000, "USA Callsign"],
  ["N1EXI", "n", 5000, "USA Callsign"],
  ["KC8RRI", "n", 5000, "USA Callsign"],
  ["KC4KWY", "n", 5000, "USA Callsign"],
  ["N5DDD", "n", 5000, "USA Callsign"],
  ["WD4MDC", "n", 5000, "USA Callsign"],
  ["KD7LEE", "n", 5000, "USA Callsign"],
  ["N7KDN", "n", 5000, "USA Callsign"],
  ["AK0F", "n", 5000, "USA Callsign"],
  ["K3LQ", "n", 5000, "USA Callsign"],
  ["K2KEL", "n", 5000, "USA Callsign"],
  ["KQ4YK", "n", 5000, "USA Callsign"],
  ["N8RMY", "n", 5000, "USA Callsign"],
  ["KD6DAQ", "n", 5000, "USA Callsign"],
  ["AK4Q", "n", 5000, "USA Callsign"],
  ["WA9ZIF", "n", 5000, "USA Callsign"],
  ["K4GFU", "n", 5000, "USA Callsign"],
  ["KA9UIC", "n", 5000, "USA Callsign"],
  ["N6CHM", "n", 5000, "USA Callsign"],
  ["KC6LVY", "n", 5000, "USA Callsign"],
  ["KC5ANM", "n", 5000, "USA Callsign"],
  ["W7BT", "n", 5000, "USA Callsign"],
  ["W9NLP", "n", 5000, "USA Callsign"],
  ["KA8ETT", "n", 5000, "USA Callsign"],
  ["AB5VD", "n", 5000, "USA Callsign"],
  ["K7TLM", "n", 5000, "USA Callsign"],
  ["WA8KCZ", "n", 5000, "USA Callsign"],
  ["N0DB", "n", 5000, "USA Callsign"],
  ["KI4LDZ", "n", 5000, "USA Callsign"],
  ["KD6KWZ", "n", 5000, "USA Callsign"],
  ["N7HA", "n", 5000, "USA Callsign"],
  ["N4UUJ", "n", 5000, "USA Callsign"],
  ["KA0BZO", "n", 5000, "USA Callsign"],
  ["AA5ZV", "n", 5000, "USA Callsign"],
  ["WB2VMR", "n", 5000, "USA Callsign"],
  ["N8KKR", "n", 5000, "USA Callsign"],
  ["W7KNZ", "n", 5000, "USA Callsign"],
  ["KA2LHK", "n", 5000, "USA Callsign"],
  ["KE4KUN", "n", 5000, "USA Callsign"],
  ["AC8H", "n", 5000, "USA Callsign"],
  ["WD6EBY", "n", 5000, "USA Callsign"],
  ["KA2GRL", "n", 5000, "USA Callsign"],
  ["N1VWX", "n", 5000, "USA Callsign"],
  ["WD8EFF", "n", 5000, "USA Callsign"],
  ["KE4QJK", "n", 5000, "USA Callsign"],
  ["KC9DEX", "n", 5000, "USA Callsign"],
  ["N7HUQ", "n", 5000, "USA Callsign"],
  ["N1IDN", "n", 5000, "USA Callsign"],
  ["AJ4B", "n", 5000, "USA Callsign"],
  ["W7JH", "n", 5000, "USA Callsign"],
  ["KB7IQ", "n", 5000, "USA Callsign"],
  ["KI4MUQ", "n", 5000, "USA Callsign"],
  ["N2WRK", "n", 5000, "USA Callsign"],
  ["KG6EIP", "n", 5000, "USA Callsign"],
  ["N5PMP", "n", 5000, "USA Callsign"],
  ["W3LOV", "n", 5000, "USA Callsign"],
  ["K6YMJ", "n", 5000, "USA Callsign"],
  ["KD1V", "n", 5000, "USA Callsign"],
  ["AA6VH", "n", 5000, "USA Callsign"],
  ["N2QCU", "n", 5000, "USA Callsign"],
  ["KB1DNL", "n", 5000, "USA Callsign"],
  ["W1ZMB", "n", 5000, "USA Callsign"],
  ["KB9WI", "n", 5000, "USA Callsign"],
  ["KC2DXI", "n", 5000, "USA Callsign"],
  ["WB1D", "n", 5000, "USA Callsign"],
  ["KC0WLO", "n", 5000, "USA Callsign"],
  ["WT4S", "n", 5000, "USA Callsign"],
  ["WA9DZ", "n", 5000, "USA Callsign"],
  ["AC4GM", "n", 5000, "USA Callsign"],
  ["KC7TGL", "n", 5000, "USA Callsign"],
  ["AB2KG", "n", 5000, "USA Callsign"],
  ["WD0EHP", "n", 5000, "USA Callsign"],
  ["AA2VA", "n", 5000, "USA Callsign"],
  ["K2CZD", "n", 5000, "USA Callsign"],
  ["AD0WE", "n", 5000, "USA Callsign"],
  ["CX3TQ", "n", 5000, "Other Callsign", "Uruguay"],
  ["CN8QY", "n", 5000, "Other Callsign", "Morocco"],
  ["UA4WHX", "n", 5000, "Other Callsign", "Russia"],
  ["TG9NX", "n", 5000, "Other Callsign", "Guatemala"],
  ["M1EQN", "n", 5000, "Other Callsign", "England"],
  ["CT1FHL", "n", 5000, "Other Callsign", "Portugal"],
  ["S51ZZ", "n", 5000, "Other Callsign", "Slovenia"],
  ["IZ5GST", "n", 5000, "Other Callsign", "Italy"],
  ["SV3CIX", "n", 5000, "Other Callsign", "Greece"],
  ["LY2PX", "n", 5000, "Other Callsign", "Lithuania"],
  ["LU7YZ", "n", 5000, "Other Callsign", "Argentina"],
  ["LU1DX", "n", 5000, "Other Callsign", "Argentina"],
  ["OP2A", "n", 5000, "Other Callsign", "Belgium"],
  ["EA3AKP", "n", 5000, "Other Callsign", "Spain"],
  ["Z33Z", "n", 5000, "Other Callsign", "Macedonia"],
  ["HK4CZE", "n", 5000, "Other Callsign", "Colombia"],
  ["EW6FW", "n", 5000, "Other Callsign", "Belarus"],
  ["S51AF", "n", 5000, "Other Callsign", "Slovenia"],
  ["HD2A", "n", 5000, "Other Callsign", "Ecuador"],
  ["LU7DSY", "n", 5000, "Other Callsign", "Argentina"],
  ["DL4RCK", "n", 5000, "Other Callsign", "Germany"],
  ["WP3C", "n", 5000, "Other Callsign", "Puerto Rico"],
  ["DJ3HJ", "n", 5000, "Other Callsign", "Germany"],
  ["DL2JRM", "n", 5000, "Other Callsign", "Germany"],
  ["SM0OWX", "n", 5000, "Other Callsign", "Sweden"],
  ["DL8KAC", "n", 5000, "Other Callsign", "Germany"],
  ["S59AA", "n", 5000, "Other Callsign", "Slovenia"],
  ["HB9EBV", "n", 5000, "Other Callsign", "Switzerland"],
  ["KH6CB", "n", 5000, "Other Callsign", "Hawaii"],
  ["IK0PHY", "n", 5000, "Other Callsign", "Italy"],
  ["YV5AAX", "n", 5000, "Other Callsign", "Venezuela"],
  ["6Y1V", "n", 5000, "Other Callsign", "Jamaica"],
  ["WP3US", "n", 5000, "Other Callsign", "Puerto Rico"],
  ["UT5UDX", "n", 5000, "Other Callsign", "Ukraine"],
  ["LY2ZZ", "n", 5000, "Other Callsign", "Lithuania"],
  ["ON4ZD", "n", 5000, "Other Callsign", "Belgium"],
  ["RA4LW", "n", 5000, "Other Callsign", "Russia"],
  ["EA1AUM", "n", 5000, "Other Callsign", "Spain"],
  ["K1ER", "n", 5000, "Other Callsign", "Hawaii"],
  ["JH3EDG", "n", 5000, "Other Callsign", "Japan"],
  ["UA4HTT", "n", 5000, "Other Callsign", "Russia"],
  ["9A2DQ", "n", 5000, "Other Callsign", "Croatia"],
  ["PA1MP", "n", 5000, "Other Callsign", "Netherlands"],
  ["A45WH", "n", 5000, "Other Callsign", "Oman"],
  ["XE3N", "n", 5000, "Other Callsign", "Mexico"],
  ["HC1MD", "n", 5000, "Other Callsign", "Ecuador"],
  ["VK2BJ", "n", 5000, "Other Callsign", "Australia"],
  ["DL6UNF", "n", 5000, "Other Callsign", "Germany"],
  ["DF3FS", "n", 5000, "Other Callsign", "Germany"],
  ["M0XLT", "n", 5000, "Other Callsign", "England"],
  ["G3TBK", "n", 5000, "Other Callsign", "England"],
  ["SV3BSF", "n", 5000, "Other Callsign", "Greece"],
  ["PY7XC", "n", 5000, "Other Callsign", "Brazil"],
  ["HB9SOF", "n", 5000, "Other Callsign", "Switzerland"],
  ["SP3CMX", "n", 5000, "Other Callsign", "Poland"],
  ["YU7AU", "n", 5000, "Other Callsign", "Serbia"],
  ["PA6Z", "n", 5000, "Other Callsign", "Netherlands"],
  ["DL3KWR", "n", 5000, "Other Callsign", "Germany"],
  ["HB9CIC", "n", 5000, "Other Callsign", "Switzerland"],
  ["ZD7JC", "n", 5000, "Other Callsign", "St Helena Island"],
  ["IZ8GCB", "n", 5000, "Other Callsign", "Italy"],
  ["YB1CWU", "n", 5000, "Other Callsign", "Indonesia"],
  ["YT1BB", "n", 5000, "Other Callsign", "Serbia"],
  ["JA3GAK", "n", 5000, "Other Callsign", "Japan"],
  ["EA8BQD", "n", 5000, "Other Callsign", "Canary Islands"],
  ["LZ2JA", "n", 5000, "Other Callsign", "Bulgaria"],
  ["DG4ABE", "n", 5000, "Other Callsign", "Germany"],
  ["LU3HR", "n", 5000, "Other Callsign", "Argentina"],
  ["G0GGU", "n", 5000, "Other Callsign", "England"],
  ["IW0BET", "n", 5000, "Other Callsign", "Italy"],
  ["IV3PRK", "n", 5000, "Other Callsign", "Italy"],
  ["LU6EVD", "n", 5000, "Other Callsign", "Argentina"],
  ["OH2PM", "n", 5000, "Other Callsign", "Finland"],
  ["ON5KQ", "n", 5000, "Other Callsign", "Belgium"],
  ["HA8BE", "n", 5000, "Other Callsign", "Hungary"],
  ["JO1DZA", "n", 5000, "Other Callsign", "Japan"],
  ["IX1IIU", "n", 5000, "Other Callsign", "Italy"],
  ["YT7AW", "n", 5000, "Other Callsign", "Serbia"],
  ["SP4Z", "n", 5000, "Other Callsign", "Poland"],
  ["UA4L", "n", 5000, "Other Callsign", "Russia"],
  ["SP9MRO", "n", 5000, "Other Callsign", "Poland"],
  ["ES5RW", "n", 5000, "Other Callsign", "Estonia"],
  ["ON4PS", "n", 5000, "Other Callsign", "Belgium"],
  ["JA8ECS", "n", 5000, "Other Callsign", "Japan"],
  ["SP2LNW", "n", 5000, "Other Callsign", "Poland"],
  ["IZ5HNI", "n", 5000, "Other Callsign", "Italy"],
  ["UA3PI", "n", 5000, "Other Callsign", "Russia"],
  ["G0ORH", "n", 5000, "Other Callsign", "England"],
  ["OZ7AKT", "n", 5000, "Other Callsign", "Denmark"],
  ["I6DHY", "n", 5000, "Other Callsign", "Italy"],
  ["EA1KY", "n", 5000, "Other Callsign", "Spain"],
  ["3A2MG", "n", 5000, "Other Callsign", "Monaco"],
  ["YB2LSR", "n", 5000, "Other Callsign", "Indonesia"],
  ["F8BOJ", "n", 5000, "Other Callsign", "France"],
  ["IW3FZQ", "n", 5000, "Other Callsign", "Italy"],
  ["DL5EBE", "n", 5000, "Other Callsign", "Germany"],
  ["G4VXB", "n", 5000, "Other Callsign", "England"],
  ["DL5XJ", "n", 5000, "Other Callsign", "Germany"],
  ["DF4XX", "n", 5000, "Other Callsign", "Germany"],
  ["IV3SKB", "n", 5000, "Other Callsign", "Italy"],
  ["G4UJS", "n", 5000, "Other Callsign", "England"],
  ["UT2GA", "n", 5000, "Other Callsign", "Ukraine"],
  ["YB9BU", "n", 5000, "Other Callsign", "Indonesia"],
  ["2E1RDX", "n", 5000, "Other Callsign", "England"],
  ["EA9HU", "n", 5000, "Other Callsign", "Ceuta and Melilla"],
  ["S52QM", "n", 5000, "Other Callsign", "Slovenia"],
  ["DM3HZN", "n", 5000, "Other Callsign", "Germany"],
  ["IT9RZR", "n", 5000, "Other Callsign", "Italy"],
  ["S57O", "n", 5000, "Other Callsign", "Slovenia"],
  ["UA3FX", "n", 5000, "Other Callsign", "Russia"],
  ["DG2FRD", "n", 5000, "Other Callsign", "Germany"],
  ["DF1VB", "n", 5000, "Other Callsign", "Germany"],
  ["CU2JX", "n", 5000, "Other Callsign", "Azores"],
  ["F8DRA", "n", 5000, "Other Callsign", "France"],
  ["R0SR", "n", 5000, "Other Callsign", "Russia"],
  ["F6DMQ", "n", 5000, "Other Callsign", "France"],
  ["DL5DSM", "n", 5000, "Other Callsign", "Germany"],
  ["EA3BDE", "n", 5000, "Other Callsign", "Spain"],
  ["IZ0GRR", "n", 5000, "Other Callsign", "Italy"],
  ["PY2SP", "n", 5000, "Other Callsign", "Brazil"],
  ["DL1DG", "n", 5000, "Other Callsign", "Germany"],
  ["CX2ABC", "n", 5000, "Other Callsign", "Uruguay"],
  ["ZS1QRM", "n", 5000, "Other Callsign", "South Africa"],
  ["EA7HBC", "n", 5000, "Other Callsign", "Spain"],
  ["SV1HAK", "n", 5000, "Other Callsign", "Greece"],
  ["ON4LN", "n", 5000, "Other Callsign", "Belgium"],
  ["SV8EP", "n", 5000, "Other Callsign", "Greece"],
  ["EA2RC", "n", 5000, "Other Callsign", "Spain"],
  ["HB9O", "n", 5000, "Other Callsign", "Switzerland"],
  ["YL2GD", "n", 5000, "Other Callsign", "Latvia"],
  ["PY2SRB", "n", 5000, "Other Callsign", "Brazil"],
  ["EA3CS", "n", 5000, "Other Callsign", "Spain"],
  ["IV3SUS", "n", 5000, "Other Callsign", "Italy"],
  ["WP4EHK", "n", 5000, "Other Callsign", "Puerto Rico"],
  ["UA0SE", "n", 5000, "Other Callsign", "Asiatic Russia"],
  ["OK2BGW", "n", 5000, "Other Callsign", "Czech Republic"],
  ["JA1OVD", "n", 5000, "Other Callsign", "Japan"],
  ["LU2CHP", "n", 5000, "Other Callsign", "Argentina"],
  ["YV6MB", "n", 5000, "Other Callsign", "Venezuela"],
  ["LW2EUC", "n", 5000, "Other Callsign", "Argentina"],
  ["LU2OMT", "n", 5000, "Other Callsign", "Argentina"],
  ["KH6AT", "n", 5000, "Other Callsign", "Hawaii"],
  ["RW3AL", "n", 5000, "Other Callsign", "Russia"],
  ["WH0S", "n", 5000, "Other Callsign", "Mariana Islands"],
  ["IK0FUX", "n", 5000, "Other Callsign", "Italy"],
  ["RX1AS", "n", 5000, "Other Callsign", "Russia"],
  ["CE7SOY", "n", 5000, "Other Callsign", "Chile"],
  ["US7TJ", "n", 5000, "Other Callsign", "Ukraine"],
  ["IV3VBM", "n", 5000, "Other Callsign", "Italy"],
  ["SV9CJO", "n", 5000, "Other Callsign", "Crete"],
  ["JA7ACM", "n", 5000, "Other Callsign", "Japan"],
  ["HK4QMH", "n", 5000, "Other Callsign", "Colombia"],
  ["TU5JM", "n", 5000, "Other Callsign", "Ivory Coast"],
  ["VK5CZ", "n", 5000, "Other Callsign", "Australia"],
  ["JF1EHM", "n", 5000, "Other Callsign", "Japan"],
  ["IT9HLR", "n", 5000, "Other Callsign", "Italy"],
  ["DK1BN", "n", 5000, "Other Callsign", "Germany"],
  ["RV1AP", "n", 5000, "Other Callsign", "Russia"],
  ["G3PJT", "n", 5000, "Other Callsign", "England"],
  ["IT9JAV", "n", 5000, "Other Callsign", "Italy"],
  ["G1WEV", "n", 5000, "Other Callsign", "England"],
  ["OZ1HPS", "n", 5000, "Other Callsign", "Denmark"],
  ["DS1REE", "n", 5000, "Other Callsign", "South Korea"],
  ["G4VCJ", "n", 5000, "Other Callsign", "England"],
  ["ER5AA", "n", 5000, "Other Callsign", "Moldova"],
  ["JF1PJK", "n", 5000, "Other Callsign", "Japan"],
  ["UA1OJL", "n", 5000, "Other Callsign", "Russia"],
  ["LA9TY", "n", 5000, "Other Callsign", "Norway"],
  ["EA4WT", "n", 5000, "Other Callsign", "Spain"],
  ["SP9WZS", "n", 5000, "Other Callsign", "Poland"],
  ["DL8DXL", "n", 5000, "Other Callsign", "Germany"],
  ["UA6ARR", "n", 5000, "Other Callsign", "Russia"],
  ["HI3AB", "n", 5000, "Other Callsign", "Dominican Republic"],
  ["I1CHC", "n", 5000, "Other Callsign", "Italy"],
  ["EA3NI", "n", 5000, "Other Callsign", "Spain"],
  ["JI3MJK", "n", 5000, "Other Callsign", "Japan"],
  ["I2YYO", "n", 5000, "Other Callsign", "Italy"],
  ["IV3BBR", "n", 5000, "Other Callsign", "Italy"],
  ["OK1DRQ", "n", 5000, "Other Callsign", "Czech Republic"],
  ["F6AML", "n", 5000, "Other Callsign", "France"],
  ["LA0CX", "n", 5000, "Other Callsign", "Norway"],
  ["OE3CDS", "n", 5000, "Other Callsign", "Austria"],
  ["F8BPN", "n", 5000, "Other Callsign", "France"],
  ["PA0AWA", "n", 5000, "Other Callsign", "Netherlands"],
  ["SM3GSK", "n", 5000, "Other Callsign", "Sweden"],
  ["2W0AFA", "n", 5000, "Other Callsign", "Wales"],
  ["G3XHZ", "n", 5000, "Other Callsign", "England"],
  ["DG5OBB", "n", 5000, "Other Callsign", "Germany"],
  ["M5HFJ", "n", 5000, "Other Callsign", "England"],
  ["F6GWP", "n", 5000, "Other Callsign", "France"],
  ["HA6NY", "n", 5000, "Other Callsign", "Hungary"],
  ["UT1HX", "n", 5000, "Other Callsign", "Ukraine"],
  ["SV1ENX", "n", 5000, "Other Callsign", "Greece"],
  ["UA1ARD", "n", 5000, "Other Callsign", "Russia"],
  ["RW4WA", "n", 5000, "Other Callsign", "Russia"],
  ["TA1ED", "n", 5000, "Other Callsign", "Turkey"],
  ["Z30A", "n", 5000, "Other Callsign", "Macedonia"],
  ["IK1HXN", "n", 5000, "Other Callsign", "Italy"],
  ["OZ4FF", "n", 5000, "Other Callsign", "Denmark"],
  ["VU2PCD", "n", 5000, "Other Callsign", "India"],
  ["PA0RHA", "n", 5000, "Other Callsign", "Netherlands"],
  ["IK7LMX", "n", 5000, "Other Callsign", "Italy"],
  ["HK4CYR", "n", 5000, "Other Callsign", "Colombia"],
  ["JH2DBQ", "n", 5000, "Other Callsign", "Japan"],
  ["LB8IB", "n", 5000, "Other Callsign", "Norway"],
  ["G0HPA", "n", 5000, "Other Callsign", "England"],
  ["HB0TW", "n", 5000, "Other Callsign", "Liechtenstein"],
  ["DH6YHO", "n", 5000, "Other Callsign", "Germany"],
  ["RU3VV", "n", 5000, "Other Callsign", "Russia"],
  ["9A1X", "n", 5000, "Other Callsign", "Croatia"],
  ["SP9LCW", "n", 5000, "Other Callsign", "Poland"],
  ["CU3CP", "n", 5000, "Other Callsign", "Azores"],
  ["DL8UVG", "n", 5000, "Other Callsign", "Germany"],
  ["OZ1BZJ", "n", 5000, "Other Callsign", "Denmark"],
  ["G4RUW", "n", 5000, "Other Callsign", "England"],
  ["PA0WRS", "n", 5000, "Other Callsign", "Netherlands"],
  ["LZ1ZX", "n", 5000, "Other Callsign", "Bulgaria"],
  ["HA8TI", "n", 5000, "Other Callsign", "Hungary"],
  ["YO3BR", "n", 5000, "Other Callsign", "Romania"],
  ["SV1EIW", "n", 5000, "Other Callsign", "Greece"],
  ["IK5SRF", "n", 5000, "Other Callsign", "Italy"],
  ["EA1AHA", "n", 5000, "Other Callsign", "Spain"],
  ["RK3ANL", "n", 5000, "Other Callsign", "Russia"],
  ["RW9CC", "n", 5000, "Other Callsign", "Asiatic Russia"],
  ["RD0B", "n", 5000, "Other Callsign", "Asiatic Russia"],
  ["EI7IX", "n", 5000, "Other Callsign", "Ireland"],
  ["DM2PJ", "n", 5000, "Other Callsign", "Germany"],
  ["IZ8EZP", "n", 5000, "Other Callsign", "Italy"],
  ["IZ8DDL", "n", 5000, "Other Callsign", "Italy"],
  ["DL5GTA", "n", 5000, "Other Callsign", "Germany"],
  ["9A6BGB", "n", 5000, "Other Callsign", "Croatia"],
  ["UN8PY", "n", 5000, "Other Callsign", "Kazakhstan"],
  ["IV3NVN", "n", 5000, "Other Callsign", "Italy"],
  ["G4WJS", "n", 5000, "Other Callsign", "England"],
  ["EA5EYW", "n", 5000, "Other Callsign", "Spain"],
  ["SM6CAS", "n", 5000, "Other Callsign", "Sweden"],
  ["YO8FZ", "n", 5000, "Other Callsign", "Romania"],
  ["DK1KBB", "n", 5000, "Other Callsign", "Germany"],
  ["LW4DXH", "n", 5000, "Other Callsign", "Argentina"],
  ["MI3AIN", "n", 5000, "Other Callsign", "Northern Ireland"],
  ["G0VXE", "n", 5000, "Other Callsign", "England"],
  ["SP8EEX", "n", 5000, "Other Callsign", "Poland"],
  ["DH3RD", "n", 5000, "Other Callsign", "Germany"],
  ["XF4IH", "n", 5000, "Other Callsign", "Revillagigedo Islands"],
  ["ON4BIT", "n", 5000, "Other Callsign", "Belgium"],
  ["DL9NEF", "n", 5000, "Other Callsign", "Germany"],
  ["JA9CHI", "n", 5000, "Other Callsign", "Japan"],
  ["EA8CR", "n", 5000, "Other Callsign", "Canary Islands"],
  ["IK2DED", "n", 5000, "Other Callsign", "Italy"],
  ["IZ1AWC", "n", 5000, "Other Callsign", "Italy"],
  ["EA3ABN", "n", 5000, "Other Callsign", "Spain"],
  ["S53BV", "n", 5000, "Other Callsign", "Slovenia"],
  ["EC8AQQ", "n", 5000, "Other Callsign", "Canary Islands"],
  ["Z31CZ", "n", 5000, "Other Callsign", "Macedonia"],
  ["R2ATP", "n", 5000, "Other Callsign", "Russia"],
  ["UA1RJ", "n", 5000, "Other Callsign", "Russia"],
  ["VK2HXY", "n", 5000, "Other Callsign", "Australia"],
  ["DO2BL", "n", 5000, "Other Callsign", "Germany"],
  ["SM5CSS", "n", 5000, "Other Callsign", "Sweden"],
  ["DG4BH", "n", 5000, "Other Callsign", "Germany"],
  ["UA6JR", "n", 5000, "Other Callsign", "Russia"],
  ["SP3GVX", "n", 5000, "Other Callsign", "Poland"],
  ["OE5JKL", "n", 5000, "Other Callsign", "Austria"],
  ["UR8IF", "n", 5000, "Other Callsign", "Ukraine"],
  ["CT1EGF", "n", 5000, "Other Callsign", "Portugal"],
  ["OZ3FI", "n", 5000, "Other Callsign", "Denmark"],
  ["G3KNU", "n", 5000, "Other Callsign", "England"],
  ["SV1ACK", "n", 5000, "Other Callsign", "Greece"],
  ["SP3GTS", "n", 5000, "Other Callsign", "Poland"],
  ["LA6OJ", "n", 5000, "Other Callsign", "Norway"],
  ["ZL2FAR", "n", 5000, "Other Callsign", "New Zealand"],
  ["UX0ZAB", "n", 5000, "Other Callsign", "Ukraine"],
  ["OK1UA", "n", 5000, "Other Callsign", "Czech Republic"],
  ["OE7EHH", "n", 5000, "Other Callsign", "Austria"],
  ["G0THY", "n", 5000, "Other Callsign", "England"],
  ["G3WXX", "n", 5000, "Other Callsign", "England"],
  ["UA0APV", "n", 5000, "Other Callsign", "Asiatic Russia"],
  ["I6YOT", "n", 5000, "Other Callsign", "Italy"],
  ["JA1RTS", "n", 5000, "Other Callsign", "Japan"],
  ["SP7NHS", "n", 5000, "Other Callsign", "Poland"],
  ["S57LP", "n", 5000, "Other Callsign", "Slovenia"],
  ["R3LC", "n", 5000, "Other Callsign", "Russia"],
  ["HA7MG", "n", 5000, "Other Callsign", "Hungary"],
  ["RW3PF", "n", 5000, "Other Callsign", "Russia"],
  ["UZ5UA", "n", 5000, "Other Callsign", "Ukraine"],
  ["YT5T", "n", 5000, "Other Callsign", "Serbia"],
  ["YB7XO", "n", 5000, "Other Callsign", "Indonesia"],
  ["IW5DHN", "n", 5000, "Other Callsign", "Italy"],
  ["SP6T", "n", 5000, "Other Callsign", "Poland"],
  ["I8IUD", "n", 5000, "Other Callsign", "Italy"],
  ["UN6T", "n", 5000, "Other Callsign", "Kazakhstan"],
  ["F5VU", "n", 5000, "Other Callsign", "France"],
  ["H44MD", "n", 5000, "Other Callsign", "Solomon Islands"],
  ["EA1GA", "n", 5000, "Other Callsign", "Spain"],
  ["OE8PPK", "n", 5000, "Other Callsign", "Austria"],
  ["9A2CY", "n", 5000, "Other Callsign", "Croatia"],
  ["YT5X", "n", 5000, "Other Callsign", "Serbia"],
  ["PA2RF", "n", 5000, "Other Callsign", "Netherlands"],
  ["PT8DX", "n", 5000, "Other Callsign", "Brazil"],
  ["6Y0A", "n", 5000, "Other Callsign", "Jamaica"],
  ["S52ID", "n", 5000, "Other Callsign", "Slovenia"],
  ["M0NRH", "n", 5000, "Other Callsign", "England"],
  ["G0TQT", "n", 5000, "Other Callsign", "England"],
  ["UA3LKM", "n", 5000, "Other Callsign", "Russia"],
  ["LZ2HN", "n", 5000, "Other Callsign", "Bulgaria"],
  ["IK1DPA", "n", 5000, "Other Callsign", "Italy"],
  ["EA5CA", "n", 5000, "Other Callsign", "Spain"],
  ["DL5SDK", "n", 5000, "Other Callsign", "Germany"],
  ["EA3LA", "n", 5000, "Other Callsign", "Spain"],
  ["VU2IPL", "n", 5000, "Other Callsign", "India"],
  ["OE1TKW", "n", 5000, "Other Callsign", "Austria"],
  ["UA9CC", "n", 5000, "Other Callsign", "Asiatic Russia"],
  ["M0SSR", "n", 5000, "Other Callsign", "England"],
  ["EW7TA", "n", 5000, "Other Callsign", "Belarus"],
  ["UN7BY", "n", 5000, "Other Callsign", "Kazakhstan"],
  ["IK2RZP", "n", 5000, "Other Callsign", "Italy"],
  ["LU5EGY", "n", 5000, "Other Callsign", "Argentina"],
  ["F6HJO", "n", 5000, "Other Callsign", "France"],
  ["JA1JQY", "n", 5000, "Other Callsign", "Japan"],
  ["JA1QEU", "n", 5000, "Other Callsign", "Japan"],
  ["JA4CXX", "n", 5000, "Other Callsign", "Japan"],
  ["JA3DLE", "n", 5000, "Other Callsign", "Japan"],
  ["F1CWQ", "n", 5000, "Other Callsign", "France"],
  ["IK2GVS", "n", 5000, "Other Callsign", "Italy"],
  ["CT1EDF", "n", 5000, "Other Callsign", "Portugal"],
  ["IK2TDR", "n", 5000, "Other Callsign", "Italy"],
  ["F5NOD", "n", 5000, "Other Callsign", "France"],
  ["JA9MAT", "n", 5000, "Other Callsign", "Japan"],
  ["ON7CFX", "n", 5000, "Other Callsign", "Belgium"],
  ["RK3IWT", "n", 5000, "Other Callsign", "Russia"],
  ["DF1YQ", "n", 5000, "Other Callsign", "Germany"],
  ["M1CEC", "n", 5000, "Other Callsign", "England"],
  ["YU7RQ", "n", 5000, "Other Callsign", "Serbia"],
  ["IZ5HQB", "n", 5000, "Other Callsign", "Italy"],
  ["IU1BED", "n", 5000, "Other Callsign", "Italy"],
  ["UT8IV", "n", 5000, "Other Callsign", "Ukraine"],
  ["CT1DL", "n", 5000, "Other Callsign", "Portugal"],
  ["G0CUZ", "n", 5000, "Other Callsign", "England"],
  ["DS3CQX", "n", 5000, "Other Callsign", "South Korea"],
  ["EI8JE", "n", 5000, "Other Callsign", "Ireland"],
  ["RW9OA", "n", 5000, "Other Callsign", "Asiatic Russia"],
  ["YV5GRB", "n", 5000, "Other Callsign", "Venezuela"],
  ["RW9DW", "n", 5000, "Other Callsign", "Asiatic Russia"],
  ["G1UGH", "n", 5000, "Other Callsign", "England"],
  ["IK0OKY", "n", 5000, "Other Callsign", "Italy"],
  ["UA4HRT", "n", 5000, "Other Callsign", "Russia"],
  ["UA1WCF", "n", 5000, "Other Callsign", "Russia"],
  ["UT1HB", "n", 5000, "Other Callsign", "Ukraine"],
  ["VK3WR", "n", 5000, "Other Callsign", "Australia"],
  ["OK2PMT", "n", 5000, "Other Callsign", "Czech Republic"],
  ["IQ4RA", "n", 5000, "Other Callsign", "Italy"],
  ["G3PYI", "n", 5000, "Other Callsign", "England"],
  ["UY5QO", "n", 5000, "Other Callsign", "Ukraine"],
  ["G4SJI", "n", 5000, "Other Callsign", "England"],
  ["DG7MHR", "n", 5000, "Other Callsign", "Germany"],
  ["IW0UWO", "n", 5000, "Other Callsign", "Italy"],
  ["JA1HHL", "n", 5000, "Other Callsign", "Japan"],
  ["F1RXM", "n", 5000, "Other Callsign", "France"],
  ["LU3DXI", "n", 5000, "Other Callsign", "Argentina"],
  ["ZS5ZLB", "n", 5000, "Other Callsign", "South Africa"],
  ["IZ8FSL", "n", 5000, "Other Callsign", "Italy"],
  ["RZ6AST", "n", 5000, "Other Callsign", "Russia"],
  ["JA3AZD", "n", 5000, "Other Callsign", "Japan"],
  ["UU1JD", "n", 5000, "Other Callsign", "Ukraine"],
  ["RA3TJX", "n", 5000, "Other Callsign", "Russia"],
  ["IZ3EAW", "n", 5000, "Other Callsign", "Italy"],
  ["G7GOF", "n", 5000, "Other Callsign", "England"],
  ["EA6LP", "n", 5000, "Other Callsign", "Balearic Islands"],
  ["DG9YIH", "n", 5000, "Other Callsign", "Germany"],
  ["EA5TS", "n", 5000, "Other Callsign", "Spain"],
  ["IZ2GIU", "n", 5000, "Other Callsign", "Italy"],
  ["JA1KZP", "n", 5000, "Other Callsign", "Japan"],
  ["SV3GKT", "n", 5000, "Other Callsign", "Greece"],
  ["MW0RHD", "n", 5000, "Other Callsign", "Wales"],
  ["PY5ZHP", "n", 5000, "Other Callsign", "Brazil"],
  ["ON6YX", "n", 5000, "Other Callsign", "Belgium"],
  ["DD8VX", "n", 5000, "Other Callsign", "Germany"],
  ["OZ1TK", "n", 5000, "Other Callsign", "Denmark"],
  ["G0PPM", "n", 5000, "Other Callsign", "England"],
  ["IK2ABJ", "n", 5000, "Other Callsign", "Italy"],
  ["M0JKQ", "n", 5000, "Other Callsign", "England"],
  ["8P6GE", "n", 5000, "Other Callsign", "Barbados"],
  ["RA9LE", "n", 5000, "Other Callsign", "Asiatic Russia"],
  ["ZR1URO", "n", 5000, "Other Callsign", "South Africa"],
  ["LW8DTO", "n", 5000, "Other Callsign", "Argentina"],
  ["LU5EZV", "n", 5000, "Other Callsign", "Argentina"],
  ["M3DFW", "n", 5000, "Other Callsign", "England"],
  ["SV1FJA", "n", 5000, "Other Callsign", "Greece"],
  ["GW3XRM", "n", 5000, "Other Callsign", "Wales"],
  ["OK2AF", "n", 5000, "Other Callsign", "Czech Republic"],
  ["PY2VG", "n", 5000, "Other Callsign", "Brazil"],
  ["CT2GOY", "n", 5000, "Other Callsign", "Portugal"],
  ["IZ2KOV", "n", 5000, "Other Callsign", "Italy"],
  ["DJ9BX", "n", 5000, "Other Callsign", "Germany"],
  ["G8VDQ", "n", 5000, "Other Callsign", "England"],
  ["DL3VTA", "n", 5000, "Other Callsign", "Germany"],
  ["GM7VFR", "n", 5000, "Other Callsign", "Scotland"],
  ["EA1BCU", "n", 5000, "Other Callsign", "Spain"],
  ["UA3DCH", "n", 5000, "Other Callsign", "Russia"],
  ["OK2PYA", "n", 5000, "Other Callsign", "Czech Republic"],
  ["RA9AC", "n", 5000, "Other Callsign", "Asiatic Russia"],
  ["IK6HMF", "n", 5000, "Other Callsign", "Italy"],
  ["XW1FAN", "n", 5000, "Other Callsign", "Laos"],
  ["DG1SGE", "n", 5000, "Other Callsign", "Germany"],
  ["G3VGR", "n", 5000, "Other Callsign", "England"],
  ["7L1GOS", "n", 5000, "Other Callsign", "Japan"],
  ["PA0WBB", "n", 5000, "Other Callsign", "Netherlands"],
  ["JA3JM", "n", 5000, "Other Callsign", "Japan"],
  ["EA3NR", "n", 5000, "Other Callsign", "Spain"],
  ["BG7LHY", "n", 5000, "Other Callsign", "China"],
  ["SV6HHD", "n", 5000, "Other Callsign", "Greece"],
  ["JE1ORU", "n", 5000, "Other Callsign", "Japan"],
  ["F5NTS", "n", 5000, "Other Callsign", "France"],
  ["PJ7UQ", "n", 5000, "Other Callsign", "St. Maarten"],
  ["IK1HJQ", "n", 5000, "Other Callsign", "Italy"],
  ["JA6AP", "n", 5000, "Other Callsign", "Japan"],
  ["PA4L", "n", 5000, "Other Callsign", "Netherlands"],
  ["VE7MRP", "n", 5000, "Other Callsign", "Canada"],
  ["IK2GXE", "n", 5000, "Other Callsign", "Italy"],
  ["UT5RQ", "n", 5000, "Other Callsign", "Ukraine"],
  ["HB0BB", "n", 5000, "Other Callsign", "Liechtenstein"],
  ["DL1YGH", "n", 5000, "Other Callsign", "Germany"],
  ["PY1FB", "n", 5000, "Other Callsign", "Brazil"],
  ["OE3MSU", "n", 5000, "Other Callsign", "Austria"],
  ["DK5LH", "n", 5000, "Other Callsign", "Germany"],
  ["DL1KBQ", "n", 5000, "Other Callsign", "Germany"],
  ["IK2FIL", "n", 5000, "Other Callsign", "Italy"],
  ["G3LPU", "n", 5000, "Other Callsign", "England"],
  ["G4JVG", "n", 5000, "Other Callsign", "England"],
  ["UA3WG", "n", 5000, "Other Callsign", "Russia"],
  ["DK2MN", "n", 5000, "Other Callsign", "Germany"],
  ["ON4CKZ", "n", 5000, "Other Callsign", "Belgium"],
  ["XE2MWY", "n", 5000, "Other Callsign", "Mexico"],
  ["I8QHE", "n", 5000, "Other Callsign", "Italy"],
  ["JM1SZY", "n", 5000, "Other Callsign", "Japan"],
  ["G8XTJ", "n", 5000, "Other Callsign", "England"],
  ["UA6LJV", "n", 5000, "Other Callsign", "Russia"],
  ["YO8RFJ", "n", 5000, "Other Callsign", "Romania"],
  ["HB9JCI", "n", 5000, "Other Callsign", "Switzerland"],
  ["JF2FIU", "n", 5000, "Other Callsign", "Japan"],
  ["DR5L", "n", 5000, "Other Callsign", "Germany"],
  ["UA2FBQ", "n", 5000, "Other Callsign", "Russia"],
  ["DF3RL", "n", 5000, "Other Callsign", "Germany"],
  ["LU1XSE", "n", 5000, "Other Callsign", "Argentina"],
  ["DL2TM", "n", 5000, "Other Callsign", "Germany"],
  ["PA3BHT", "n", 5000, "Other Callsign", "Netherlands"],
  ["LU2FUI", "n", 5000, "Other Callsign", "Argentina"],
  ["IK5DHL", "n", 5000, "Other Callsign", "Italy"],
  ["CT2HQS", "n", 5000, "Other Callsign", "Portugal"],
  ["M1SLH", "n", 5000, "Other Callsign", "England"],
  ["4Z5LO", "n", 5000, "Other Callsign", "Israel"],
  ["OE3GDS", "n", 5000, "Other Callsign", "Austria"],
  ["ER1VS", "n", 5000, "Other Callsign", "Moldova"],
  ["GM4UYZ", "n", 5000, "Other Callsign", "Scotland"],
  ["JF3KON", "n", 5000, "Other Callsign", "Japan"],
  ["JL3MCM", "n", 5000, "Other Callsign", "Japan"],
  ["LU2WC", "n", 5000, "Other Callsign", "Argentina"],
  ["G0JYW", "n", 5000, "Other Callsign", "England"],
  ["V73ZZ", "n", 5000, "Other Callsign", "Marshall Islands"],
  ["EW3AA", "n", 5000, "Other Callsign", "Belarus"],
  ["TA1L", "n", 5000, "Other Callsign", "Turkey"],
  ["PA0HTW", "n", 5000, "Other Callsign", "Netherlands"],
  ["G3ASR", "n", 5000, "Other Callsign", "England"],
  ["EB8CHG", "n", 5000, "Other Callsign", "Canary Islands"],
  ["HB9TII", "n", 5000, "Other Callsign", "Switzerland"],
  ["DL9BCP", "n", 5000, "Other Callsign", "Germany"],
  ["OK2CMW", "n", 5000, "Other Callsign", "Czech Republic"],
  ["SP8LBK", "n", 5000, "Other Callsign", "Poland"],
  ["EA2CNU", "n", 5000, "Other Callsign", "Spain"],
  ["IZ1DYE", "n", 5000, "Other Callsign", "Italy"],
  ["OZ1CJS", "n", 5000, "Other Callsign", "Denmark"],
  ["EA4OA", "n", 5000, "Other Callsign", "Spain"],
  ["G7KGH", "n", 5000, "Other Callsign", "England"],
  ["EA5AZW", "n", 5000, "Other Callsign", "Spain"],
  ["M3ARB", "n", 5000, "Other Callsign", "England"],
  ["HR2PAC", "n", 5000, "Other Callsign", "Honduras"],
  ["LU7DKA", "n", 5000, "Other Callsign", "Argentina"],
  ["RN3BD", "n", 5000, "Other Callsign", "Russia"],
  ["DG8OP", "n", 5000, "Other Callsign", "Germany"],
  ["SV7CFP", "n", 5000, "Other Callsign", "Greece"],
  ["LZ2KL", "n", 5000, "Other Callsign", "Bulgaria"],
  ["ON6CQ", "n", 5000, "Other Callsign", "Belgium"],
  ["IZ0GMS", "n", 5000, "Other Callsign", "Italy"],
  ["M3PAJ", "n", 5000, "Other Callsign", "England"],
  ["F5MZE", "n", 5000, "Other Callsign", "France"],
  ["DL1JEQ", "n", 5000, "Other Callsign", "Germany"],
  ["PA5K", "n", 5000, "Other Callsign", "Netherlands"],
  ["G0JMB", "n", 5000, "Other Callsign", "England"],
  ["LU6EN", "n", 5000, "Other Callsign", "Argentina"],
  ["PY9GC", "n", 5000, "Other Callsign", "Brazil"],
  ["LZ2FM", "n", 5000, "Other Callsign", "Bulgaria"],
  ["ON2KIX", "n", 5000, "Other Callsign", "Belgium"],
  ["G3MYI", "n", 5000, "Other Callsign", "England"],
  ["OK2PM", "n", 5000, "Other Callsign", "Czech Republic"],
  ["DL1APX", "n", 5000, "Other Callsign", "Germany"],
  ["G0PDM", "n", 5000, "Other Callsign", "England"],
  ["RX3MX", "n", 5000, "Other Callsign", "Russia"],
  ["EA7URC", "n", 5000, "Other Callsign", "Spain"],
  ["G1HND", "n", 5000, "Other Callsign", "England"],
  ["YU7MS", "n", 5000, "Other Callsign", "Serbia"],
  ["G0YNM", "n", 5000, "Other Callsign", "England"],
  ["MW1EWJ", "n", 5000, "Other Callsign", "Wales"],
  ["9M6JM", "n", 5000, "Other Callsign", "East Malaysia"],
  ["DJ3PS", "n", 5000, "Other Callsign", "Germany"],
  ["PD0ORB", "n", 5000, "Other Callsign", "Netherlands"],
  ["IZ8EDH", "n", 5000, "Other Callsign", "Italy"],
  ["G0RAS", "n", 5000, "Other Callsign", "England"],
  ["DL2CK", "n", 5000, "Other Callsign", "Germany"],
  ["SP3DX", "n", 5000, "Other Callsign", "Poland"],
  ["YO2LAM", "n", 5000, "Other Callsign", "Romania"],
  ["CT1EEX", "n", 5000, "Other Callsign", "Portugal"],
  ["UN7QN", "n", 5000, "Other Callsign", "Kazakhstan"],
  ["TZ6RD", "n", 5000, "Other Callsign", "Mali"],
  ["HB9FBI", "n", 5000, "Other Callsign", "Switzerland"],
  ["EA3TJ", "n", 5000, "Other Callsign", "Spain"],
  ["GI3YBZ", "n", 5000, "Other Callsign", "Northern Ireland"],
  ["DJ2GM", "n", 5000, "Other Callsign", "Germany"],
  ["EU1CL", "n", 5000, "Other Callsign", "Belarus"],
  ["PD0PVR", "n", 5000, "Other Callsign", "Netherlands"],
  ["VO1NO", "n", 5000, "Other Callsign", "Canada"],
  ["MW0IDX", "n", 5000, "Other Callsign", "Wales"],
  ["ZL1MH", "n", 5000, "Other Callsign", "New Zealand"],
  ["HB9JND", "n", 5000, "Other Callsign", "Switzerland"],
  ["OH2BVK", "n", 5000, "Other Callsign", "Finland"],
  ["PE1RPK", "n", 5000, "Other Callsign", "Netherlands"],
  ["DL7UKM", "n", 5000, "Other Callsign", "Germany"],
  ["LA6UH", "n", 5000, "Other Callsign", "Norway"],
  ["G4XYT", "n", 5000, "Other Callsign", "England"],
  ["LX5B", "n", 5000, "Other Callsign", "Luxembourg"],
  ["DJ5IH", "n", 5000, "Other Callsign", "Germany"],
  ["ON5UE", "n", 5000, "Other Callsign", "Belgium"],
  ["RA3LAS", "n", 5000, "Other Callsign", "Russia"],
  ["ON4AMX", "n", 5000, "Other Callsign", "Belgium"],
  ["IZ1GZA", "n", 5000, "Other Callsign", "Italy"],
  ["EA3MM", "n", 5000, "Other Callsign", "Spain"],
  ["HA2SN", "n", 5000, "Other Callsign", "Hungary"],
  ["EB3FNB", "n", 5000, "Other Callsign", "ITU Geneva"],
  ["HI3NYE", "n", 5000, "Other Callsign", "Dominican Republic"],
  ["SM3CVM", "n", 5000, "Other Callsign", "Sweden"],
  ["M0COP", "n", 5000, "Other Callsign", "England"],
  ["RK1NWA", "n", 5000, "Other Callsign", "Russia"],
  ["SP3BHG", "n", 5000, "Other Callsign", "Poland"],
  ["G0NMJ", "n", 5000, "Other Callsign", "England"],
  ["PA5ROB", "n", 5000, "Other Callsign", "Netherlands"],
  ["G0JWG", "n", 5000, "Other Callsign", "England"],
  ["PY4ATB", "n", 5000, "Other Callsign", "Brazil"],
  ["PY4WAR", "n", 5000, "Other Callsign", "Brazil"],
  ["G0DCJ", "n", 5000, "Other Callsign", "England"],
  ["VK6TAZ", "n", 5000, "Other Callsign", "Australia"],
  ["IK2TKX", "n", 5000, "Other Callsign", "Italy"],
  ["EA2CCN", "n", 5000, "Other Callsign", "Spain"],
  ["OD5UR", "n", 5000, "Other Callsign", "Lebanon"],
  ["G1SAA", "n", 5000, "Other Callsign", "England"],
  ["PA3FSA", "n", 5000, "Other Callsign", "Netherlands"],
  ["PA0TMU", "n", 5000, "Other Callsign", "Netherlands"],
  ["UA3IBD", "n", 5000, "Other Callsign", "Russia"],
  ["GJ3RAX", "n", 5000, "Other Callsign", "Jersey"],
  ["IK5ROP", "n", 5000, "Other Callsign", "Italy"],
  ["HS6VW", "n", 5000, "Other Callsign", "Thailand"],
  ["CX2FB", "n", 5000, "Other Callsign", "Uruguay"],
  ["DK3RED", "n", 5000, "Other Callsign", "Germany"],
  ["GM0USI", "n", 5000, "Other Callsign", "Scotland"],
  ["UA6II", "n", 5000, "Other Callsign", "Russia"],
  ["CO7FR", "n", 5000, "Other Callsign", "Cuba"],
  ["DF6FR", "n", 5000, "Other Callsign", "Germany"],
  ["9H1US", "n", 5000, "Other Callsign", "Malta"],
  ["IK1VDN", "n", 5000, "Other Callsign", "Italy"],
  ["SQ9BDB", "n", 5000, "Other Callsign", "Poland"],
  ["HQ2DMR", "n", 5000, "Other Callsign", "Honduras"],
  ["WH6CBG", "n", 5000, "Other Callsign", "Hawaii"],
  ["LA6RHA", "n", 5000, "Other Callsign", "Norway"],
  ["N6KB", "n", 5000, "Other Callsign", "Hawaii"],
  ["PA4MC", "n", 5000, "Other Callsign", "Netherlands"],
  ["IS0XRB", "n", 5000, "Other Callsign", "Sardinia"],
  ["IW2KQK", "n", 5000, "Other Callsign", "Italy"],
  ["IW1DGV", "n", 5000, "Other Callsign", "Italy"],
  ["VU3VOC", "n", 5000, "Other Callsign", "India"],
  ["HB9DDZ", "n", 5000, "Other Callsign", "Switzerland"],
  ["JA2MOG", "n", 5000, "Other Callsign", "Japan"],
  ["YV5MBX", "n", 5000, "Other Callsign", "Venezuela"],
  ["DL1BD", "n", 5000, "Other Callsign", "Germany"],
  ["ZL1UTC", "n", 5000, "Other Callsign", "New Zealand"],
  ["VK6ZAI", "n", 5000, "Other Callsign", "Australia"],
  ["DR2006C", "n", 5000, "Other Callsign", "Germany"],
  ["IZ1DBY", "n", 5000, "Other Callsign", "Italy"],
  ["4L7O", "n", 5000, "Other Callsign", "Georgia"],
  ["KP3V", "n", 5000, "Other Callsign", "Puerto Rico"],
  ["PA3AKP", "n", 5000, "Other Callsign", "Netherlands"],
  ["G3ODO", "n", 5000, "Other Callsign", "England"],
  ["G4BDO", "n", 5000, "Other Callsign", "England"],
  ["IK2BEH", "n", 5000, "Other Callsign", "Italy"],
  ["DF6IU", "n", 5000, "Other Callsign", "Germany"],
  ["IW2DCK", "n", 5000, "Other Callsign", "Italy"],
  ["OK1TD", "n", 5000, "Other Callsign", "Czech Republic"],
  ["IK7YZI", "n", 5000, "Other Callsign", "Italy"],
  ["EC8AVE", "n", 5000, "Other Callsign", "Canary Islands"],
  ["IT9ZXX", "n", 5000, "Other Callsign", "Italy"],
  ["SV8GGI", "n", 5000, "Other Callsign", "Greece"],
  ["ON4HRT", "n", 5000, "Other Callsign", "Belgium"],
  ["DC5PS", "n", 5000, "Other Callsign", "Germany"],
  ["DG4IAN", "n", 5000, "Other Callsign", "Germany"],
  ["DF7KF", "n", 5000, "Other Callsign", "Germany"],
  ["I8HFU", "n", 5000, "Other Callsign", "Italy"],
  ["HB9MHR", "n", 5000, "Other Callsign", "Switzerland"],
  ["YB2OBL", "n", 5000, "Other Callsign", "Indonesia"],
  ["JM1LRA", "n", 5000, "Other Callsign", "Japan"],
  ["EA3EXE", "n", 5000, "Other Callsign", "Spain"],
  ["VK2VAV", "n", 5000, "Other Callsign", "Australia"],
  ["HL5BPF", "n", 5000, "Other Callsign", "South Korea"],
  ["VK3NX", "n", 5000, "Other Callsign", "Australia"],
  ["LU3FM", "n", 5000, "Other Callsign", "Argentina"],
  ["OE3MFC", "n", 5000, "Other Callsign", "Austria"],
  ["EA5QI", "n", 5000, "Other Callsign", "Spain"],
  ["EA5AZI", "n", 5000, "Other Callsign", "Spain"],
  ["IK8URC", "n", 5000, "Other Callsign", "Italy"],
  ["GI1XFC", "n", 5000, "Other Callsign", "Northern Ireland"],
  ["I8SOU", "n", 5000, "Other Callsign", "Italy"],
  ["HA5OKE", "n", 5000, "Other Callsign", "Hungary"],
  ["G4BPJ", "n", 5000, "Other Callsign", "England"],
  ["VK3UA", "n", 5000, "Other Callsign", "Australia"],
  ["YY7ABC", "n", 5000, "Other Callsign", "Venezuela"],
  ["F5PEJ", "n", 5000, "Other Callsign", "France"],
  ["DL5GA", "n", 5000, "Other Callsign", "Germany"],
  ["DL5AKF", "n", 5000, "Other Callsign", "Germany"],
  ["JA8EJO", "n", 5000, "Other Callsign", "Japan"],
  ["PY2LE", "n", 5000, "Other Callsign", "Brazil"],
  ["SP3IBS", "n", 5000, "Other Callsign", "Poland"],
  ["G3RJV", "n", 5000, "Other Callsign", "England"],
  ["DC8WPA", "n", 5000, "Other Callsign", "Germany"],
  ["IZ5HMK", "n", 5000, "Other Callsign", "Italy"],
  ["ON4NOK", "n", 5000, "Other Callsign", "Belgium"],
  ["VK3VIC", "n", 5000, "Other Callsign", "Australia"],
  ["JA3BSX", "n", 5000, "Other Callsign", "Japan"],
  ["VK2YN", "n", 5000, "Other Callsign", "Australia"],
  ["IK2SNG", "n", 5000, "Other Callsign", "Italy"],
  ["F6EBH", "n", 5000, "Other Callsign", "France"],
  ["RK3R", "n", 5000, "Other Callsign", "Russia"],
  ["CE6CS", "n", 5000, "Other Callsign", "Chile"],
  ["VK2NDK", "n", 5000, "Other Callsign", "Australia"],
  ["SQ2IHP", "n", 5000, "Other Callsign", "Poland"],
  ["EI7GL", "n", 5000, "Other Callsign", "Ireland"],
  ["DL8ZAJ", "n", 5000, "Other Callsign", "Germany"],
  ["IK6FBG", "n", 5000, "Other Callsign", "Italy"],
  ["SP7WT", "n", 5000, "Other Callsign", "Poland"],
  ["LU9DLO", "n", 5000, "Other Callsign", "Argentina"],
  ["G0NEV", "n", 5000, "Other Callsign", "England"],
  ["ZL2TJX", "n", 5000, "Other Callsign", "New Zealand"],
  ["EA3EAP", "n", 5000, "Other Callsign", "Spain"],
  ["SM6WXI", "n", 5000, "Other Callsign", "Sweden"],
  ["G0EEN", "n", 5000, "Other Callsign", "England"],
  ["HB9DRK", "n", 5000, "Other Callsign", "Switzerland"],
  ["2E1EVB", "n", 5000, "Other Callsign", "England"],
  ["EA3CIS", "n", 5000, "Other Callsign", "Spain"],
  ["PY2POL", "n", 5000, "Other Callsign", "Brazil"],
  ["SP6OJJ", "n", 5000, "Other Callsign", "Poland"],
  ["GM4DZX", "n", 5000, "Other Callsign", "Scotland"],
  ["EA3GE", "n", 5000, "Other Callsign", "Spain"],
  ["OM5AM", "n", 5000, "Other Callsign", "Slovak Republic"],
  ["PP2JCW", "n", 5000, "Other Callsign", "Brazil"],
  ["PT2NP", "n", 5000, "Other Callsign", "Brazil"],
  ["EA1EWY", "n", 5000, "Other Callsign", "Spain"],
  ["SQ3FYK", "n", 5000, "Other Callsign", "Poland"],
  ["EI6GF", "n", 5000, "Other Callsign", "Ireland"],
  ["IQ0AL", "n", 5000, "Other Callsign", "Italy"],
  ["PA0GHG", "n", 5000, "Other Callsign", "Netherlands"],
  ["HB9JBN", "n", 5000, "Other Callsign", "Switzerland"],
  ["2E1KAJ", "n", 5000, "Other Callsign", "England"],
  ["ZL1AKW", "n", 5000, "Other Callsign", "New Zealand"],
  ["DL8FBC", "n", 5000, "Other Callsign", "Germany"],
  ["RU9MU", "n", 5000, "Other Callsign", "Asiatic Russia"],
  ["IZ8BLY", "n", 5000, "Other Callsign", "Italy"],
  ["JH1CNC", "n", 5000, "Other Callsign", "Japan"],
  ["VE6FXR", "n", 5000, "Other Callsign", "Canada"],
  ["SV7DLL", "n", 5000, "Other Callsign", "Greece"],
  ["F5NBK", "n", 5000, "Other Callsign", "France"],
  ["PA3COB", "n", 5000, "Other Callsign", "Netherlands"],
  ["PA3GER", "n", 5000, "Other Callsign", "Netherlands"],
  ["SQ8JCA", "n", 5000, "Other Callsign", "Poland"],
  ["G7HYQ", "n", 5000, "Other Callsign", "England"],
  ["RW9HYY", "n", 5000, "Other Callsign", "Asiatic Russia"],
  ["SP7FP", "n", 5000, "Other Callsign", "Poland"],
  ["IK3RIY", "n", 5000, "Other Callsign", "Italy"],
  ["CU8AO", "n", 5000, "Other Callsign", "Portugal"],
  ["EA2EIE", "n", 5000, "Other Callsign", "Spain"],
  ["PA4WM", "n", 5000, "Other Callsign", "Netherlands"],
  ["G7HNY", "n", 5000, "Other Callsign", "England"],
  ["DL5HN", "n", 5000, "Other Callsign", "Germany"],
  ["KB6EGA", "n", 5000, "Other Callsign", "Hawaii"],
  ["DL4ZZ", "n", 5000, "Other Callsign", "Germany"],
  ["BG4RBA", "n", 5000, "Other Callsign", "China"],
  ["OK2PO", "n", 5000, "Other Callsign", "Czech Republic"],
  ["EA3AHT", "n", 5000, "Other Callsign", "Spain"],
  ["7Q7PF", "n", 5000, "Other Callsign", "Malawi"],
  ["DL1AXB", "n", 5000, "Other Callsign", "Germany"],
  ["DL3BWG", "n", 5000, "Other Callsign", "Germany"],
  ["JA4XRN", "n", 5000, "Other Callsign", "Japan"],
  ["FJ5AB", "n", 5000, "Other Callsign", "France"],
  ["I8YOU", "n", 5000, "Other Callsign", "Italy"],
  ["SP1XNA", "n", 5000, "Other Callsign", "Poland"],
  ["RW5F", "n", 5000, "Other Callsign", "Russia"],
  ["G6GVI", "n", 5000, "Other Callsign", "England"],
  ["I1HLC", "n", 5000, "Other Callsign", "Italy"],
  ["IW7EHC", "n", 5000, "Other Callsign", "Italy"],
  ["IK1BBX", "n", 5000, "Other Callsign", "Italy"],
  ["PD0MJC", "n", 5000, "Other Callsign", "Netherlands"],
  ["2E1KLP", "n", 5000, "Other Callsign", "England"],
  ["EI5EM", "n", 5000, "Other Callsign", "Ireland"],
  ["M0DQW", "n", 5000, "Other Callsign", "England"],
  ["I3WTT", "n", 5000, "Other Callsign", "Italy"],
  ["G1JMY", "n", 5000, "Other Callsign", "England"],
  ["JA2FEA", "n", 5000, "Other Callsign", "Japan"],
  ["A22HH", "n", 5000, "Other Callsign", "Botswana"],
  ["IU7DLD", "n", 5000, "Other Callsign", "Italy"],
  ["JE3PCP", "n", 5000, "Other Callsign", "Japan"],
  ["DF7JS", "n", 5000, "Other Callsign", "Germany"],
  ["RX3PI", "n", 5000, "Other Callsign", "Russia"],
  ["VE3NST", "n", 5000, "Other Callsign", "Canada"],
  ["ZZ7Z", "n", 5000, "Other Callsign", "Brazil"],
  ["IC8FFP", "n", 5000, "Other Callsign", "Italy"],
  ["YO7AOT", "n", 5000, "Other Callsign", "Romania"],
  ["IK8WJP", "n", 5000, "Other Callsign", "Italy"],
  ["G3ELZ", "n", 5000, "Other Callsign", "England"],
  ["R9FAE", "n", 5000, "Other Callsign", "Russia"],
  ["LA1HNA", "n", 5000, "Other Callsign", "Norway"],
  ["F4BSX", "n", 5000, "Other Callsign", "France"],
  ["PI9ISS", "n", 5000, "Other Callsign", "Netherlands"],
  ["EA4YG", "n", 5000, "Other Callsign", "Spain"],
  ["G0EEB", "n", 5000, "Other Callsign", "England"],
  ["G0DMB", "n", 5000, "Other Callsign", "England"],
  ["IW1RIN", "n", 5000, "Other Callsign", "Italy"],
  ["G8OO", "n", 5000, "Other Callsign", "England"],
  ["IK1GKD", "n", 5000, "Other Callsign", "Italy"],
  ["PD4TH", "n", 5000, "Other Callsign", "Netherlands"],
  ["2M0PMR", "n", 5000, "Other Callsign", "Scotland"],
  ["YN1ZZE", "n", 5000, "Other Callsign", "Nicaragua"],
  ["JR4DAH", "n", 5000, "Other Callsign", "Japan"],
  ["XE2SS", "n", 5000, "Other Callsign", "Mexico"],
  ["G0FVV", "n", 5000, "Other Callsign", "England"],
  ["G0BKD", "n", 5000, "Other Callsign", "England"],
  ["R7FI", "n", 5000, "Other Callsign", "Russia"],
  ["G7GMZ", "n", 5000, "Other Callsign", "England"],
  ["HB9DDC", "n", 5000, "Other Callsign", "Switzerland"],
  ["MM0DRA", "n", 5000, "Other Callsign", "Scotland"],
  ["G4PGZ", "n", 5000, "Other Callsign", "England"],
  ["F5XE", "n", 5000, "Other Callsign", "France"],
  ["UA4NAL", "n", 5000, "Other Callsign", "Russia"],
  ["EC5CYI", "n", 5000, "Other Callsign", "Spain"],
  ["OE1GZA", "n", 5000, "Other Callsign", "Austria"],
  ["DB9NFB", "n", 5000, "Other Callsign", "Germany"],
  ["XE2SOC", "n", 5000, "Other Callsign", "Mexico"],
  ["9A0R", "n", 5000, "Other Callsign", "Croatia"],
  ["G7TFX", "n", 5000, "Other Callsign", "England"],
  ["ON4ADP", "n", 5000, "Other Callsign", "Belgium"],
  ["RA3AGM", "n", 5000, "Other Callsign", "Russia"],
  ["UT3UV", "n", 5000, "Other Callsign", "Ukraine"],
  ["EA3ADV", "n", 5000, "Other Callsign", "Spain"],
  ["M0EAV", "n", 5000, "Other Callsign", "England"],
  ["UA0ADC", "n", 5000, "Other Callsign", "Asiatic Russia"],
  ["PA7NL", "n", 5000, "Other Callsign", "Netherlands"],
  ["OE9AMJ", "n", 5000, "Other Callsign", "Austria"],
  ["JE2FUP", "n", 5000, "Other Callsign", "Japan"],
  ["RX3DDD", "n", 5000, "Other Callsign", "Russia"],
  ["CT1XI", "n", 5000, "Other Callsign", "Portugal"],
  ["JH3LSS", "n", 5000, "Other Callsign", "Japan"],
  ["TB2NIM", "n", 5000, "Other Callsign", "Turkey"],
  ["I1DXD", "n", 5000, "Other Callsign", "Italy"],
  ["7M4BBQ", "n", 5000, "Other Callsign", "Japan"],
  ["JY5FA", "n", 5000, "Other Callsign", "Jordan"],
  ["UR4MP", "n", 5000, "Other Callsign", "Ukraine"],
  ["EA1HP", "n", 5000, "Other Callsign", "Spain"],
  ["SP8VJV", "n", 5000, "Other Callsign", "Poland"],
  ["IQ7ET", "n", 5000, "Other Callsign", "Italy"],
  ["RX6LIV", "n", 5000, "Other Callsign", "Russia"],
  ["LA9HH", "n", 5000, "Other Callsign", "Norway"],
  ["M0DCD", "n", 5000, "Other Callsign", "England"],
  ["G4PNI", "n", 5000, "Other Callsign", "England"],
  ["ZD7HI", "n", 5000, "Other Callsign", "St Helena Island"],
  ["ON6PJE", "n", 5000, "Other Callsign", "Belgium"],
  ["G0JTN", "n", 5000, "Other Callsign", "England"],
  ["PA3BMS", "n", 5000, "Other Callsign", "Netherlands"],
  ["JL1OEC", "n", 5000, "Other Callsign", "Japan"],
  ["DL2UX", "n", 5000, "Other Callsign", "Germany"],
  ["OE7AHJ", "n", 5000, "Other Callsign", "Austria"],
  ["HA7HD", "n", 5000, "Other Callsign", "Hungary"],
  ["M0AUG", "n", 5000, "Other Callsign", "England"],
  ["DC9BK", "n", 5000, "Other Callsign", "Germany"],
  ["DJ0PR", "n", 5000, "Other Callsign", "Germany"],
  ["VR2LW", "n", 5000, "Other Callsign", "Hong Kong"],
  ["G8LIK", "n", 5000, "Other Callsign", "England"],
  ["SP3JIA", "n", 5000, "Other Callsign", "Poland"],
  ["HL3QZH", "n", 5000, "Other Callsign", "South Korea"],
  ["OZ4FA", "n", 5000, "Other Callsign", "Denmark"],
  ["PP7AR", "n", 5000, "Other Callsign", "Brazil"],
  ["F6ADB", "n", 5000, "Other Callsign", "France"],
  ["EA1EWC", "n", 5000, "Other Callsign", "Spain"],
  ["RN1CX", "n", 5000, "Other Callsign", "Russia"],
  ["EA1KA", "n", 5000, "Other Callsign", "Spain"],
  ["HB9DUO", "n", 5000, "Other Callsign", "Switzerland"],
  ["M3ZCB", "n", 5000, "Other Callsign", "England"],
  ["UA9URI", "n", 5000, "Other Callsign", "Asiatic Russia"],
  ["EB1BAQ", "n", 5000, "Other Callsign", "Spain"],
  ["OK1KF", "n", 5000, "Other Callsign", "Czech Republic"],
  ["G3NMD", "n", 5000, "Other Callsign", "England"],
  ["F5CAR", "n", 5000, "Other Callsign", "France"],
  ["IK1PQZ", "n", 5000, "Other Callsign", "Italy"],
  ["DK1YO", "n", 5000, "Other Callsign", "Germany"],
  ["G4FWI", "n", 5000, "Other Callsign", "England"],
  ["UA3ADX", "n", 5000, "Other Callsign", "Russia"],
  ["I5YYB", "n", 5000, "Other Callsign", "Italy"],
  ["G0KME", "n", 5000, "Other Callsign", "England"],
  ["IK3DNV", "n", 5000, "Other Callsign", "Italy"],
  ["IK0YUK", "n", 5000, "Other Callsign", "Italy"],
  ["OZ6NF", "n", 5000, "Other Callsign", "Denmark"],
  ["7N2RWX", "n", 5000, "Other Callsign", "Japan"],
  ["M3NOC", "n", 5000, "Other Callsign", "England"],
  ["RW6AHV", "n", 5000, "Other Callsign", "Russia"],
  ["OK1GI", "n", 5000, "Other Callsign", "Czech Republic"],
  ["VK3AMN", "n", 5000, "Other Callsign", "Australia"],
  ["PA7GB", "n", 5000, "Other Callsign", "Netherlands"],
  ["UA6AH", "n", 5000, "Other Callsign", "Russia"],
  ["G3XBN", "n", 5000, "Other Callsign", "England"],
  ["EA3ANE", "n", 5000, "Other Callsign", "Spain"],
  ["OL7A", "n", 5000, "Other Callsign", "Czech Republic"],
  ["M1AGY", "n", 5000, "Other Callsign", "England"],
  ["JA3OOK", "n", 5000, "Other Callsign", "Japan"],
  ["LU8EHR", "n", 5000, "Other Callsign", "Argentina"],
  ["PE2PVD", "n", 5000, "Other Callsign", "Netherlands"],
  ["WP4K", "n", 5000, "Other Callsign", "Puerto Rico"],
  ["UN0B", "n", 5000, "Other Callsign", "Kazakhstan"],
  ["IK2IPY", "n", 5000, "Other Callsign", "Italy"],
  ["BV6JM", "n", 5000, "Other Callsign", "Taiwan"],
  ["S57RJ", "n", 5000, "Other Callsign", "Slovenia"],
  ["KL7CW", "n", 5000, "Other Callsign", "Alaska"],
  ["EN8ZIB", "n", 5000, "Other Callsign", "Ukraine"],
  ["IK5ZAI", "n", 5000, "Other Callsign", "Italy"],
  ["IW9GYF", "n", 5000, "Other Callsign", "Italy"],
  ["IK2TLH", "n", 5000, "Other Callsign", "Italy"],
  ["IZ1DSJ", "n", 5000, "Other Callsign", "Italy"],
  ["4Z5GU", "n", 5000, "Other Callsign", "Israel"],
  ["3D2MD", "n", 5000, "Other Callsign", "Fiji"],
  ["OK1CS", "n", 5000, "Other Callsign", "Czech Republic"],
  ["LY2XZ", "n", 5000, "Other Callsign", "Lithuania"],
  ["OE1KFR", "n", 5000, "Other Callsign", "Austria"],
  ["LU3DSI", "n", 5000, "Other Callsign", "Argentina"],
  ["TI2KI", "n", 5000, "Other Callsign", "Costa Rica"],
  ["DD3EU", "n", 5000, "Other Callsign", "Germany"],
  ["IK1MDH", "n", 5000, "Other Callsign", "Italy"],
  ["LU9AWH", "n", 5000, "Other Callsign", "Argentina"],
  ["PY2FFW", "n", 5000, "Other Callsign", "Brazil"],
  ["G0NSL", "n", 5000, "Other Callsign", "England"],
  ["DL2WG", "n", 5000, "Other Callsign", "Germany"],
  ["TA2OE", "n", 5000, "Other Callsign", "Turkey"],
  ["DL2NEW", "n", 5000, "Other Callsign", "Germany"],
  ["F1NSK", "n", 5000, "Other Callsign", "France"],
  ["G6HCW", "n", 5000, "Other Callsign", "England"],
  ["ED3AFR", "n", 5000, "Other Callsign", "Spain"],
  ["SQ2DYL", "n", 5000, "Other Callsign", "Poland"],
  ["DL3TOM", "n", 5000, "Other Callsign", "Germany"],
  ["PA7X", "n", 5000, "Other Callsign", "Netherlands"],
  ["IW5BFV", "n", 5000, "Other Callsign", "Italy"],
  ["DF1QH", "n", 5000, "Other Callsign", "Germany"],
  ["G3ZST", "n", 5000, "Other Callsign", "England"],
  ["EA3AJB", "n", 5000, "Other Callsign", "Spain"],
  ["VK3AW", "n", 5000, "Other Callsign", "Australia"],
  ["UA3RAF", "n", 5000, "Other Callsign", "Russia"],
  ["G7DSV", "n", 5000, "Other Callsign", "England"],
  ["IK4RAJ", "n", 5000, "Other Callsign", "Italy"],
  ["HS6RNX", "n", 5000, "Other Callsign", "Thailand"],
  ["G0PCF", "n", 5000, "Other Callsign", "England"],
  ["PA3FTJ", "n", 5000, "Other Callsign", "Netherlands"],
  ["IK1ZZN", "n", 5000, "Other Callsign", "Italy"],
  ["ED3VMB", "n", 5000, "Other Callsign", "Spain"],
  ["UT0MN", "n", 5000, "Other Callsign", "Ukraine"],
  ["G0OKD", "n", 5000, "Other Callsign", "England"],
  ["SQ5IZX", "n", 5000, "Other Callsign", "Poland"],
  ["IU5AJG", "n", 5000, "Other Callsign", "Italy"],
  ["RK3AZ", "n", 5000, "Other Callsign", "Russia"],
  ["PY2TI", "n", 5000, "Other Callsign", "Brazil"],
  ["PY2BO", "n", 5000, "Other Callsign", "Brazil"],
  ["OE5JFL", "n", 5000, "Other Callsign", "Austria"],
  ["G4AYR", "n", 5000, "Other Callsign", "England"],
  ["VK3PI", "n", 5000, "Other Callsign", "Australia"],
  ["DK1VI", "n", 5000, "Other Callsign", "Germany"],
  ["IS0EUJ", "n", 5000, "Other Callsign", "Sardinia"],
  ["G7JRV", "n", 5000, "Other Callsign", "England"],
  ["VK4KTX", "n", 5000, "Other Callsign", "Australia"],
  ["U3DI", "n", 5000, "Other Callsign", "Russia"],
  ["RW6AG", "n", 5000, "Other Callsign", "Russia"],
  ["DJ5ER", "n", 5000, "Other Callsign", "Germany"],
  ["DK3MI", "n", 5000, "Other Callsign", "Germany"],
  ["OE5PBN", "n", 5000, "Other Callsign", "Austria"],
  ["JA6TWU", "n", 5000, "Other Callsign", "Japan"],
  ["7N4DNM", "n", 5000, "Other Callsign", "Japan"],
  ["DL3DD", "n", 5000, "Other Callsign", "Germany"],
  ["RZ3GF", "n", 5000, "Other Callsign", "Russia"],
  ["JA7BWV", "n", 5000, "Other Callsign", "Japan"],
  ["YU7EE", "n", 5000, "Other Callsign", "Serbia"],
  ["VK2AAG", "n", 5000, "Other Callsign", "Australia"],
  ["EA1CA", "n", 5000, "Other Callsign", "Spain"],
  ["G7ORI", "n", 5000, "Other Callsign", "England"],
  ["UA6ASQ", "n", 5000, "Other Callsign", "Russia"],
  ["IZ5BIT", "n", 5000, "Other Callsign", "Italy"],
  ["EA8FT", "n", 5000, "Other Callsign", "Canary Islands"],
  ["DL5YEZ", "n", 5000, "Other Callsign", "Germany"],
  ["IK2ZFA", "n", 5000, "Other Callsign", "Italy"],
  ["ON4XAV", "n", 5000, "Other Callsign", "Belgium"],
  ["V44KR", "n", 5000, "Other Callsign", "St Kitts and Nevis"],
  ["ON4DCM", "n", 5000, "Other Callsign", "Belgium"],
  ["EI3IK", "n", 5000, "Other Callsign", "Ireland"],
  ["RA9USA", "n", 5000, "Other Callsign", "Asiatic Russia"],
  ["I1VDM", "n", 5000, "Other Callsign", "Italy"],
  ["G4LDZ", "n", 5000, "Other Callsign", "England"],
  ["IU6DKP", "n", 5000, "Other Callsign", "Italy"],
  ["UX0DZ", "n", 5000, "Other Callsign", "Ukraine"],
  ["PY5LAU", "n", 5000, "Other Callsign", "Brazil"],
  ["EA8AEW", "n", 5000, "Other Callsign", "Canary Islands"],
  ["PA9A", "n", 5000, "Other Callsign", "Netherlands"],
  ["VE3CDP", "n", 5000, "Other Callsign", "Canada"],
  ["TA3BU", "n", 5000, "Other Callsign", "Turkey"],
  ["HB9BCD", "n", 5000, "Other Callsign", "Switzerland"],
  ["VK2JBK", "n", 5000, "Other Callsign", "Australia"],
  ["PA0BNC", "n", 5000, "Other Callsign", "Netherlands"],
  ["M3LEC", "n", 5000, "Other Callsign", "England"],
  ["MW3KRN", "n", 5000, "Other Callsign", "Wales"],
  ["G8BCA", "n", 5000, "Other Callsign", "England"],
  ["OZ1AGE", "n", 5000, "Other Callsign", "Denmark"],
  ["IK0AIH", "n", 5000, "Other Callsign", "Italy"],
  ["UA9NP", "n", 5000, "Other Callsign", "Asiatic Russia"],
  ["G0SQI", "n", 5000, "Other Callsign", "England"],
  ["SQ9DXN", "n", 5000, "Other Callsign", "Poland"],
  ["DD2CU", "n", 5000, "Other Callsign", "Germany"],
  ["VK4ACB", "n", 5000, "Other Callsign", "Australia"],
  ["6Y5PA", "n", 5000, "Other Callsign", "Jamaica"],
  ["HL0EXN", "n", 5000, "Other Callsign", "South Korea"],
  ["OM4NI", "n", 5000, "Other Callsign", "Slovak Republic"],
  ["CE4MLN", "n", 5000, "Other Callsign", "Chile"],
  ["HA0NDN", "n", 5000, "Other Callsign", "Hungary"],
  ["GW4UEP", "n", 5000, "Other Callsign", "Wales"],
  ["DH3YAK", "n", 5000, "Other Callsign", "Germany"],
  ["F6FBI", "n", 5000, "Other Callsign", "France"],
  ["YB1VQD", "n", 5000, "Other Callsign", "Indonesia"],
  ["SV1FH", "n", 5000, "Other Callsign", "Greece"],
  ["SM6LPF", "n", 5000, "Other Callsign", "Sweden"],
  ["IW3IEE", "n", 5000, "Other Callsign", "Italy"],
  ["ON4WA", "n", 5000, "Other Callsign", "Belgium"],
  ["DG1US", "n", 5000, "Other Callsign", "Germany"],
  ["VE7VSQ", "n", 5000, "Other Callsign", "Canada"],
  ["SM7MUN", "n", 5000, "Other Callsign", "Sweden"],
  ["DL5KSS", "n", 5000, "Other Callsign", "Germany"],
  ["UA3TJC", "n", 5000, "Other Callsign", "Russia"],
  ["DL1DL", "n", 5000, "Other Callsign", "Germany"],
  ["ZL2ST", "n", 5000, "Other Callsign", "New Zealand"],
  ["SM3EXO", "n", 5000, "Other Callsign", "Sweden"],
  ["G4STZ", "n", 5000, "Other Callsign", "England"],
  ["G3JUL", "n", 5000, "Other Callsign", "England"],
  ["VE7AMV", "n", 5000, "Other Callsign", "Canada"],
  ["ZS5CEY", "n", 5000, "Other Callsign", "South Africa"],
  ["IK6XGR", "n", 5000, "Other Callsign", "Italy"],
  ["M5RIG", "n", 5000, "Other Callsign", "England"],
  ["HZ1FH", "n", 5000, "Other Callsign", "Saudi Arabia"],
  ["EA4BOG", "n", 5000, "Other Callsign", "Spain"],
  ["LU5HSY", "n", 5000, "Other Callsign", "Argentina"],
  ["9V1NC", "n", 5000, "Other Callsign", "Singapore"],
  ["SM5GSH", "n", 5000, "Other Callsign", "Sweden"],
  ["LX2SC", "n", 5000, "Other Callsign", "Luxembourg"],
  ["LU2KU", "n", 5000, "Other Callsign", "Argentina"],
  ["UA3SBW", "n", 5000, "Other Callsign", "Russia"],
  ["M0MBX", "n", 5000, "Other Callsign", "England"],
  ["SV1ABA", "n", 5000, "Other Callsign", "Greece"],
  ["YO7ARZ", "n", 5000, "Other Callsign", "Romania"],
  ["BX8AAA", "n", 5000, "Other Callsign", "Taiwan"],
  ["JH3OWO", "n", 5000, "Other Callsign", "Japan"],
  ["IK3ZWR", "n", 5000, "Other Callsign", "Italy"],
  ["DL1SBM", "n", 5000, "Other Callsign", "Germany"],
  ["JA1WDF", "n", 5000, "Other Callsign", "Japan"],
  ["9A1BST", "n", 5000, "Other Callsign", "Croatia"],
  ["VK2XLJ", "n", 5000, "Other Callsign", "Australia"],
  ["2M0WTE", "n", 5000, "Other Callsign", "Scotland"],
  ["IW5CSP", "n", 5000, "Other Callsign", "Italy"],
  ["OK1WIP", "n", 5000, "Other Callsign", "Czech Republic"],
  ["US5TCH", "n", 5000, "Other Callsign", "Ukraine"],
  ["SP4XBZ", "n", 5000, "Other Callsign", "Poland"],
  ["ON6UO", "n", 5000, "Other Callsign", "Belgium"],
  ["SM5DQE", "n", 5000, "Other Callsign", "Sweden"],
  ["VK4ARB", "n", 5000, "Other Callsign", "Australia"],
  ["LA4YKA", "n", 5000, "Other Callsign", "Norway"],
  ["IV3CWI", "n", 5000, "Other Callsign", "Italy"],
  ["UA0ALQ", "n", 5000, "Other Callsign", "Asiatic Russia"],
  ["I2BGQ", "n", 5000, "Other Callsign", "Italy"],
  ["SQ8GUM", "n", 5000, "Other Callsign", "Poland"],
  ["VK3HJV", "n", 5000, "Other Callsign", "Australia"],
  ["CO8NN", "n", 5000, "Other Callsign", "Cuba"],
  ["JN7CPW", "n", 5000, "Other Callsign", "Japan"],
  ["RA9FBK", "n", 5000, "Other Callsign", "Asiatic Russia"],
  ["EA3WO", "n", 5000, "Other Callsign", "Spain"],
  ["M1AHN", "n", 5000, "Other Callsign", "England"],
  ["IV3BVK", "n", 5000, "Other Callsign", "Italy"],
  ["LP1F", "n", 5000, "Other Callsign", "Argentina"],
  ["IW0AUA", "n", 5000, "Other Callsign", "Italy"],
  ["GI3ZCK", "n", 5000, "Other Callsign", "Northern Ireland"],
  ["BY1CJL", "n", 5000, "Other Callsign", "China"],
  ["9M2CJ", "n", 5000, "Other Callsign", "West Malaysia"],
  ["ZL2TX", "n", 5000, "Other Callsign", "New Zealand"],
  ["UA9MBR", "n", 5000, "Other Callsign", "Asiatic Russia"],
  ["YO6CVA", "n", 5000, "Other Callsign", "Romania"],
  ["DM9JM", "n", 5000, "Other Callsign", "Germany"],
  ["EA5TK", "n", 5000, "Other Callsign", "Spain"],
  ["CM5MA", "n", 5000, "Other Callsign", "Cuba"],
  ["JA6BC", "n", 5000, "Other Callsign", "Japan"],
  ["PD3RMV", "n", 5000, "Other Callsign", "Netherlands"],
  ["RW9UTK", "n", 5000, "Other Callsign", "Asiatic Russia"],
  ["IW1RHG", "n", 5000, "Other Callsign", "Italy"],
  ["RU6HA", "n", 5000, "Other Callsign", "Russia"],
  ["HB3YFP", "n", 5000, "Other Callsign", "Switzerland"],
  ["G1KWF", "n", 5000, "Other Callsign", "England"],
  ["F1GAB", "n", 5000, "Other Callsign", "France"],
  ["XE2LSP", "n", 5000, "Other Callsign", "Mexico"],
  ["OK1MHZ", "n", 5000, "Other Callsign", "Czech Republic"],
  ["PA3BOE", "n", 5000, "Other Callsign", "Netherlands"],
  ["TJ1AD", "n", 5000, "Other Callsign", "Cameroon"],
  ["RW9MJ", "n", 5000, "Other Callsign", "Asiatic Russia"],
  ["VK8AN", "n", 5000, "Other Callsign", "Australia"],
  ["G4SJH", "n", 5000, "Other Callsign", "England"],
  ["PA7IM", "n", 5000, "Other Callsign", "Netherlands"],
  ["KL7XO", "n", 5000, "Other Callsign", "Alaska"],
  ["DS4FKX", "n", 5000, "Other Callsign", "South Korea"],
  ["HB9DIZ", "n", 5000, "Other Callsign", "Switzerland"],
  ["SP5TZC", "n", 5000, "Other Callsign", "Poland"],
  ["AH7R", "n", 5000, "Other Callsign", "Hawaii"],
  ["DG8WH", "n", 5000, "Other Callsign", "Germany"],
  ["F4BPJ", "n", 5000, "Other Callsign", "France"],
  ["VK5RG", "n", 5000, "Other Callsign", "Australia"],
  ["GI4MUE", "n", 5000, "Other Callsign", "Northern Ireland"],
  ["YC4FIJ", "n", 5000, "Other Callsign", "Indonesia"],
  ["M3FJU", "n", 5000, "Other Callsign", "England"],
  ["IV3YWT", "n", 5000, "Other Callsign", "Italy"],
  ["SM3XKW", "n", 5000, "Other Callsign", "Sweden"],
  ["XE2KK", "n", 5000, "Other Callsign", "Mexico"],
  ["MW3ARD", "n", 5000, "Other Callsign", "Wales"],
  ["XE3ROI", "n", 5000, "Other Callsign", "Mexico"],
  ["SY2004CJU", "n", 5000, "Other Callsign", "Greece"],
  ["G0AKX", "n", 5000, "Other Callsign", "England"],
  ["PA1DX", "n", 5000, "Other Callsign", "Netherlands"],
  ["DS1NMA", "n", 5000, "Other Callsign", "South Korea"],
  ["HZ1BF", "n", 5000, "Other Callsign", "Saudi Arabia"],
  ["F4CKF", "n", 5000, "Other Callsign", "France"],
  ["YS4VO", "n", 5000, "Other Callsign", "El Salvador"],
  ["IS0AEE", "n", 5000, "Other Callsign", "Sardinia"],
  ["G4PLZ", "n", 5000, "Other Callsign", "England"],
  ["DL8CX", "n", 5000, "Other Callsign", "Germany"],
  ["NL7MM", "n", 5000, "Other Callsign", "Alaska"],
  ["VK5ZBF", "n", 5000, "Other Callsign", "Australia"],
  ["SP5TT", "n", 5000, "Other Callsign", "Poland"],
  ["YO3III", "n", 5000, "Other Callsign", "Romania"],
  ["FM5DR", "n", 5000, "Other Callsign", "Martinique"],
  ["G4BWB", "n", 5000, "Other Callsign", "England"],
  ["G0DOB", "n", 5000, "Other Callsign", "England"],
  ["LU3DHR", "n", 5000, "Other Callsign", "Argentina"],
  ["T72EB", "n", 5000, "Other Callsign", "San Marino"],
  ["IK2XRO", "n", 5000, "Other Callsign", "Italy"],
  ["G0PTA", "n", 5000, "Other Callsign", "England"],
  ["JH6INQ", "n", 5000, "Other Callsign", "Japan"],
  ["KP3AJ", "n", 5000, "Other Callsign", "Puerto Rico"],
  ["LU7FDP", "n", 5000, "Other Callsign", "Argentina"],
  ["OE3NKA", "n", 5000, "Other Callsign", "Austria"],
  ["G4LRP", "n", 5000, "Other Callsign", "England"],
  ["M0SCS", "n", 5000, "Other Callsign", "England"],
  ["RU0LK", "n", 5000, "Other Callsign", "Asiatic Russia"],
  ["GM4EMX", "n", 5000, "Other Callsign", "Scotland"],
  ["JN4RHB", "n", 5000, "Other Callsign", "Japan"],
  ["LU5NAR", "n", 5000, "Other Callsign", "Argentina"],
  ["DB8TA", "n", 5000, "Other Callsign", "Germany"],
  ["IV3TES", "n", 5000, "Other Callsign", "Italy"],
  ["PD4MB", "n", 5000, "Other Callsign", "Netherlands"],
  ["US1VB", "n", 5000, "Other Callsign", "Ukraine"],
  ["LU6DNQ", "n", 5000, "Other Callsign", "Argentina"],
  ["UA0QBA", "n", 5000, "Other Callsign", "Asiatic Russia"],
  ["RU6MP", "n", 5000, "Other Callsign", "Russia"],
  ["JA6AQV", "n", 5000, "Other Callsign", "Japan"],
  ["DF3PN", "n", 5000, "Other Callsign", "Germany"],
  ["K1OWL", "n", 5000, "Other Callsign", "Hawaii"],
  ["9M8DB", "n", 5000, "Other Callsign", "East Malaysia"],
  ["TA1AZ", "n", 5000, "Other Callsign", "Turkey"],
  ["G1ERU", "n", 5000, "Other Callsign", "England"],
  ["ON4ANE", "n", 5000, "Other Callsign", "Belgium"],
  ["EB4CUV", "n", 5000, "Other Callsign", "Spain"],
  ["SV1BTL", "n", 5000, "Other Callsign", "Greece"],
  ["JA1KQX", "n", 5000, "Other Callsign", "Japan"],
  ["DJ7IL", "n", 5000, "Other Callsign", "Germany"],
  ["UT2LY", "n", 5000, "Other Callsign", "Ukraine"],
  ["G0BJA", "n", 5000, "Other Callsign", "England"],
  ["OH9O", "n", 5000, "Other Callsign", "Finland"],
  ["LP7H", "n", 5000, "Other Callsign", "Argentina"],
  ["HB9IRF", "n", 5000, "Other Callsign", "Switzerland"],
  ["OE8PCK", "n", 5000, "Other Callsign", "Austria"],
  ["SM5YST", "n", 5000, "Other Callsign", "Sweden"],
  ["I5VOW", "n", 5000, "Other Callsign", "Italy"],
  ["9A9B", "n", 5000, "Other Callsign", "Croatia"],
  ["VE3HOG", "n", 5000, "Other Callsign", "Canada"],
  ["RA3ADR", "n", 5000, "Other Callsign", "Russia"],
  ["G5YC", "n", 5000, "Other Callsign", "England"],
  ["2E1HEB", "n", 5000, "Other Callsign", "England"],
  ["SM6XIX", "n", 5000, "Other Callsign", "Sweden"],
  ["ON1DJC", "n", 5000, "Other Callsign", "Belgium"],
  ["JA2FUP", "n", 5000, "Other Callsign", "Japan"],
  ["RV6LB", "n", 5000, "Other Callsign", "Russia"],
  ["HL1ACU", "n", 5000, "Other Callsign", "South Korea"],
  ["G4BOO", "n", 5000, "Other Callsign", "England"],
  ["LA6JNA", "n", 5000, "Other Callsign", "Norway"],
  ["ON2JLA", "n", 5000, "Other Callsign", "Belgium"],
  ["JA3KZD", "n", 5000, "Other Callsign", "Japan"],
  ["G0PLC", "n", 5000, "Other Callsign", "England"],
  ["G3EHU", "n", 5000, "Other Callsign", "England"],
  ["PA0RXO", "n", 5000, "Other Callsign", "Netherlands"],
  ["6K5AFM", "n", 5000, "Other Callsign", "South Korea"],
  ["PY3SGO", "n", 5000, "Other Callsign", "Brazil"],
  ["OH2TI", "n", 5000, "Other Callsign", "Finland"],
  ["F4AVI", "n", 5000, "Other Callsign", "France"],
  ["TA0T", "n", 5000, "Other Callsign", "Turkey"],
  ["RX4CA", "n", 5000, "Other Callsign", "Russia"],
  ["UA6WI", "n", 5000, "Other Callsign", "Russia"],
  ["RK9XWA", "n", 5000, "Other Callsign", "Asiatic Russia"],
  ["EA3FLN", "n", 5000, "Other Callsign", "Spain"],
  ["I6ZLO", "n", 5000, "Other Callsign", "Italy"],
  ["VE3KGB", "n", 5000, "Other Callsign", "Canada"],
  ["IK0SOR", "n", 5000, "Other Callsign", "Italy"],
  ["IK7XLH", "n", 5000, "Other Callsign", "Italy"],
  ["M1DBW", "n", 5000, "Other Callsign", "England"],
  ["I2NKX", "n", 5000, "Other Callsign", "Italy"],
  ["RA0AGH", "n", 5000, "Other Callsign", "Asiatic Russia"],
  ["YY5AMR", "n", 5000, "Other Callsign", "Venezuela"],
  ["SM6TUW", "n", 5000, "Other Callsign", "Sweden"],
  ["F6GCQ", "n", 5000, "Other Callsign", "France"],
  ["7M3EAK", "n", 5000, "Other Callsign", "Japan"],
  ["LU1FF", "n", 5000, "Other Callsign", "Argentina"],
  ["G3TPJ", "n", 5000, "Other Callsign", "England"],
  ["S51OE", "n", 5000, "Other Callsign", "Slovenia"],
  ["OZ8UW", "n", 5000, "Other Callsign", "Denmark"],
  ["PI4KST", "n", 5000, "Other Callsign", "Netherlands"],
  ["IW6DFW", "n", 5000, "Other Callsign", "Italy"],
  ["IZ8KIA", "n", 5000, "Other Callsign", "Italy"],
  ["RW3DL", "n", 5000, "Other Callsign", "Russia"],
  ["SP7VCX", "n", 5000, "Other Callsign", "Poland"],
  ["RA3GAG", "n", 5000, "Other Callsign", "Russia"],
  ["IW8CEL", "n", 5000, "Other Callsign", "Italy"],
  ["G6AZL", "n", 5000, "Other Callsign", "England"],
  ["JR0CGJ", "n", 5000, "Other Callsign", "Japan"],
  ["CT1FHJ", "n", 5000, "Other Callsign", "Portugal"],
  ["JE1QVH", "n", 5000, "Other Callsign", "Japan"],
  ["JA8NKQ", "n", 5000, "Other Callsign", "Japan"],
  ["UA4CFF", "n", 5000, "Other Callsign", "Russia"],
  ["LU2DJE", "n", 5000, "Other Callsign", "Argentina"],
  ["DG1FDX", "n", 5000, "Other Callsign", "Germany"],
  ["PA3BTT", "n", 5000, "Other Callsign", "Netherlands"],
  ["I4UGP", "n", 5000, "Other Callsign", "Italy"],
  ["SP5DED", "n", 5000, "Other Callsign", "Poland"],
  ["IK2BWX", "n", 5000, "Other Callsign", "Italy"],
  ["ON5WA", "n", 5000, "Other Callsign", "Belgium"],
  ["HP1EDD", "n", 5000, "Other Callsign", "Panama"],
  ["2E0JSK", "n", 5000, "Other Callsign", "England"],
  ["I4XEO", "n", 5000, "Other Callsign", "Italy"],
  ["DL1DCA", "n", 5000, "Other Callsign", "Germany"],
  ["G4FFD", "n", 5000, "Other Callsign", "England"],
  ["UK8BY", "n", 5000, "Other Callsign", "Uzbekistan"],
  ["G0AUV", "n", 5000, "Other Callsign", "England"],
  ["KL1UK", "n", 5000, "Other Callsign", "Alaska"],
  ["I4KHH", "n", 5000, "Other Callsign", "Italy"],
  ["IZ4APT", "n", 5000, "Other Callsign", "Italy"],
  ["LU6HCA", "n", 5000, "Other Callsign", "Argentina"],
  ["GM0OYT", "n", 5000, "Other Callsign", "Scotland"],
  ["S51RJ", "n", 5000, "Other Callsign", "Slovenia"],
  ["F6CZV", "n", 5000, "Other Callsign", "France"],
  ["G0HDS", "n", 5000, "Other Callsign", "England"],
  ["VU2NIL", "n", 5000, "Other Callsign", "India"],
  ["PE1RDW", "n", 5000, "Other Callsign", "Netherlands"],
  ["M0IPR", "n", 5000, "Other Callsign", "England"],
  ["I1CDB", "n", 5000, "Other Callsign", "Italy"],
  ["OE5HZM", "n", 5000, "Other Callsign", "Austria"],
  ["IK8MIG", "n", 5000, "Other Callsign", "Italy"],
  ["M1ZZZ", "n", 5000, "Other Callsign", "England"],
  ["IW5ACZ", "n", 5000, "Other Callsign", "Italy"],
  ["VK2BOZ", "n", 5000, "Other Callsign", "Australia"],
  ["DK6IP", "n", 5000, "Other Callsign", "Germany"],
  ["F5IDF", "n", 5000, "Other Callsign", "France"],
  ["PA3EKM", "n", 5000, "Other Callsign", "Netherlands"],
  ["VU2VIZ", "n", 5000, "Other Callsign", "India"],
  ["DF3DK", "n", 5000, "Other Callsign", "Germany"],
  ["CU1AAH", "n", 5000, "Other Callsign", "Azores"],
  ["GI0LRB", "n", 5000, "Other Callsign", "Northern Ireland"],
  ["G6XDI", "n", 5000, "Other Callsign", "England"],
  ["VK4NGA", "n", 5000, "Other Callsign", "Australia"],
  ["HB9TPX", "n", 5000, "Other Callsign", "Switzerland"],
  ["IZ5DKR", "n", 5000, "Other Callsign", "Italy"],
  ["RW6HEX", "n", 5000, "Other Callsign", "Russia"],
  ["CT2IWV", "n", 5000, "Other Callsign", "Portugal"],
  ["F1BIV", "n", 5000, "Other Callsign", "France"],
  ["SM6WLA", "n", 5000, "Other Callsign", "Sweden"],
  ["4X5BT", "n", 5000, "Other Callsign", "Israel"],
  ["HA2M", "n", 5000, "Other Callsign", "Hungary"],
  ["OH2ZH", "n", 5000, "Other Callsign", "Finland"],
  ["OE6DVG", "n", 5000, "Other Callsign", "Austria"],
  ["PE1RZL", "n", 5000, "Other Callsign", "Netherlands"],
  ["OH1TT", "n", 5000, "Other Callsign", "Finland"],
  ["SP1MVD", "n", 5000, "Other Callsign", "Poland"],
  ["DG1ELO", "n", 5000, "Other Callsign", "Germany"],
  ["G8IYS", "n", 5000, "Other Callsign", "England"],
  ["OE7KRH", "n", 5000, "Other Callsign", "Austria"],
  ["XE1FFZ", "n", 5000, "Other Callsign", "Mexico"],
  ["PY2RFD", "n", 5000, "Other Callsign", "Brazil"],
  ["ON6GB", "n", 5000, "Other Callsign", "Belgium"],
  ["G6NJR", "n", 5000, "Other Callsign", "England"],
  ["LW5DIX", "n", 5000, "Other Callsign", "Argentina"],
  ["M0SPS", "n", 5000, "Other Callsign", "England"],
  ["ET3KV", "n", 5000, "Other Callsign", "Ethiopia"],
  ["OK2SJI", "n", 5000, "Other Callsign", "Czech Republic"],
  ["OM9AAW", "n", 5000, "Other Callsign", "Slovak Republic"],
  ["I5XDL", "n", 5000, "Other Callsign", "Italy"],
  ["OK2FUG", "n", 5000, "Other Callsign", "Czech Republic"],
  ["I2JDZ", "n", 5000, "Other Callsign", "Italy"],
  ["RW3QF", "n", 5000, "Other Callsign", "Russia"],
  ["IK8XFU", "n", 5000, "Other Callsign", "Italy"],
  ["G4GJR", "n", 5000, "Other Callsign", "England"],
  ["JM7MFB", "n", 5000, "Other Callsign", "Japan"],
  ["G4RKB", "n", 5000, "Other Callsign", "England"],
  ["PE1PWF", "n", 5000, "Other Callsign", "Netherlands"],
  ["I3FUE", "n", 5000, "Other Callsign", "Italy"],
  ["EA1CAB", "n", 5000, "Other Callsign", "Spain"],
  ["VK3BUS", "n", 5000, "Other Callsign", "Australia"],
  ["HB9LCD", "n", 5000, "Other Callsign", "Switzerland"],
  ["G1BOP", "n", 5000, "Other Callsign", "England"],
  ["GM0DVO", "n", 5000, "Other Callsign", "Scotland"],
  ["VE1SB", "n", 5000, "Other Callsign", "Canada"],
  ["S57AJJ", "n", 5000, "Other Callsign", "Slovenia"],
  ["G3WRA", "n", 5000, "Other Callsign", "England"],
  ["HA5WE", "n", 5000, "Other Callsign", "Hungary"],
  ["KL0HI", "n", 5000, "Other Callsign", "Alaska"],
  ["KA1NVZ", "n", 5000, "Other Callsign", "Alaska"],
  ["CE3JCI", "n", 5000, "Other Callsign", "Chile"],
  ["IW2KUY", "n", 5000, "Other Callsign", "Italy"],
  ["IT9ZBC", "n", 5000, "Other Callsign", "Italy"],
  ["IK2SFR", "n", 5000, "Other Callsign", "Italy"],
  ["Z21GX", "n", 5000, "Other Callsign", "Zimbabwe"],
  ["YB7OL", "n", 5000, "Other Callsign", "Indonesia"],
  ["LU7EHR", "n", 5000, "Other Callsign", "Argentina"],
  ["IK7BIT", "n", 5000, "Other Callsign", "Italy"],
  ["S57TTI", "n", 5000, "Other Callsign", "Slovenia"],
  ["DG8PZ", "n", 5000, "Other Callsign", "Germany"],
  ["HA7AZ", "n", 5000, "Other Callsign", "Hungary"],
  ["PY3AGM", "n", 5000, "Other Callsign", "Brazil"],
  ["G0WVK", "n", 5000, "Other Callsign", "England"],
  ["PY5YR", "n", 5000, "Other Callsign", "Brazil"],
  ["GB3PO", "n", 5000, "Other Callsign", "England"],
  ["OE1DGW", "n", 5000, "Other Callsign", "Austria"],
  ["PP1KM", "n", 5000, "Other Callsign", "Brazil"],
  ["JA1ATB", "n", 5000, "Other Callsign", "Japan"],
  ["UR4FWC", "n", 5000, "Other Callsign", "Ukraine"],
  ["SM7CFR", "n", 5000, "Other Callsign", "Sweden"],
  ["JA3MNC", "n", 5000, "Other Callsign", "Japan"],
  ["KD2BK", "n", 5000, "Other Callsign", "Puerto Rico"],
  ["VK2OI", "n", 5000, "Other Callsign", "Australia"],
  ["IK8GGU", "n", 5000, "Other Callsign", "Italy"],
  ["JA6SBW", "n", 5000, "Other Callsign", "Japan"],
  ["VR2ALC", "n", 5000, "Other Callsign", "Hong Kong"],
  ["PE1MSU", "n", 5000, "Other Callsign", "Netherlands"],
  ["IK3FHP", "n", 5000, "Other Callsign", "Italy"],
  ["6K5RFO", "n", 5000, "Other Callsign", "South Korea"],
  ["RW4WE", "n", 5000, "Other Callsign", "Russia"],
  ["M0OAR", "n", 5000, "Other Callsign", "England"],
  ["G0WWU", "n", 5000, "Other Callsign", "England"],
  ["M5VIM", "n", 5000, "Other Callsign", "England"],
  ["PA0JFO", "n", 5000, "Other Callsign", "Netherlands"],
  ["WL7NWS", "n", 5000, "Other Callsign", "Alaska"],
  ["UR5XBE", "n", 5000, "Other Callsign", "Ukraine"],
  ["ZL1UZM", "n", 5000, "Other Callsign", "New Zealand"],
  ["CW1575", "n", 5000, "Other Callsign", "Uruguay"],
  ["DB3DC", "n", 5000, "Other Callsign", "Germany"],
  ["F4CYP", "n", 5000, "Other Callsign", "France"],
  ["XE1HZH", "n", 5000, "Other Callsign", "Mexico"],
  ["SV1DHU", "n", 5000, "Other Callsign", "Greece"],
  ["WP4IZI", "n", 5000, "Other Callsign", "Puerto Rico"],
  ["G8IDL", "n", 5000, "Other Callsign", "England"],
  ["DX1UST", "n", 5000, "Other Callsign", "Philippines"],
  ["YT150AD", "n", 5000, "Other Callsign", "Serbia"],
  ["VK6VU", "n", 5000, "Other Callsign", "Australia"],
  ["RA6AM", "n", 5000, "Other Callsign", "Russia"],
  ["RA0AJ", "n", 5000, "Other Callsign", "Asiatic Russia"],
  ["VE3JTZ", "n", 5000, "Other Callsign", "Canada"],
  ["OH6FBB", "n", 5000, "Other Callsign", "Finland"],
  ["LW1DQD", "n", 5000, "Other Callsign", "Argentina"],
  ["DL1RB", "n", 5000, "Other Callsign", "Germany"],
  ["LU5ACJ", "n", 5000, "Other Callsign", "Argentina"],
  ["SM5AWU", "n", 5000, "Other Callsign", "Sweden"],
  ["EA3AZL", "n", 5000, "Other Callsign", "Spain"],
  ["VK5BR", "n", 5000, "Other Callsign", "Australia"],
  ["BD5HAG", "n", 5000, "Other Callsign", "China"],
  ["CT1EFL", "n", 5000, "Other Callsign", "Portugal"],
  ["RA3AZ", "n", 5000, "Other Callsign", "Russia"],
  ["ZL2BJ", "n", 5000, "Other Callsign", "New Zealand"],
  ["DJ8MEH", "n", 5000, "Other Callsign", "Germany"],
  ["AH2CM", "n", 5000, "Other Callsign", "Guam"],
  ["M0ASJ", "n", 5000, "Other Callsign", "England"],
  ["9A3HP", "n", 5000, "Other Callsign", "Croatia"],
  ["LU1EOS", "n", 5000, "Other Callsign", "Argentina"],
  ["3B8FU", "n", 5000, "Other Callsign", "Mauritius"],
  ["VK5HDP", "n", 5000, "Other Callsign", "Australia"],
  ["KP4WP", "n", 5000, "Other Callsign", "Puerto Rico"],
  ["G4RSF", "n", 5000, "Other Callsign", "England"],
  ["VU3MDL", "n", 5000, "Other Callsign", "India"],
  ["G7TSX", "n", 5000, "Other Callsign", "England"],
  ["VU2LOC", "n", 5000, "Other Callsign", "India"],
  ["PA3FOC", "n", 5000, "Other Callsign", "Netherlands"],
  ["EI9GE", "n", 5000, "Other Callsign", "Ireland"],
  ["DA1TJO", "n", 5000, "Other Callsign", "Germany"],
  ["IZ5FSQ", "n", 5000, "Other Callsign", "Italy"],
  ["G4WFF", "n", 5000, "Other Callsign", "England"],
  ["VO1HO", "n", 5000, "Other Callsign", "Canada"],
  ["WL7CUO", "n", 5000, "Other Callsign", "Alaska"],
  ["MM0DWF", "n", 5000, "Other Callsign", "Scotland"],
  ["IN3BYV", "n", 5000, "Other Callsign", "Italy"],
  ["VE2EQ", "n", 5000, "Other Callsign", "Canada"],
  ["M1NTY", "n", 5000, "Other Callsign", "England"],
  ["VA7MP", "n", 5000, "Other Callsign", "Canada"],
  ["ZL3AC", "n", 5000, "Other Callsign", "New Zealand"],
  ["DL4SKF", "n", 5000, "Other Callsign", "Germany"],
  ["DK6WX", "n", 5000, "Other Callsign", "Germany"],
  ["OE5HPM", "n", 5000, "Other Callsign", "Austria"],
  ["OE6GWG", "n", 5000, "Other Callsign", "Austria"],
  ["LY1DA", "n", 5000, "Other Callsign", "Lithuania"],
  ["EA3EN", "n", 5000, "Other Callsign", "Spain"],
  ["RK0QWA", "n", 5000, "Other Callsign", "Asiatic Russia"],
  ["PA3HR", "n", 5000, "Other Callsign", "Netherlands"],
  ["WL7PI", "n", 5000, "Other Callsign", "Alaska"],
  ["OZ2NN", "n", 5000, "Other Callsign", "Denmark"],
  ["CT1EDQ", "n", 5000, "Other Callsign", "Portugal"],
  ["VK3AME", "n", 5000, "Other Callsign", "Australia"],
  ["PY2CCK", "n", 5000, "Other Callsign", "Brazil"],
  ["F1ABG", "n", 5000, "Other Callsign", "France"],
  ["GM0FHS", "n", 5000, "Other Callsign", "Scotland"],
  ["LU4HAL", "n", 5000, "Other Callsign", "Argentina"],
  ["GI6FHD", "n", 5000, "Other Callsign", "Northern Ireland"],
  ["BD4SW", "n", 5000, "Other Callsign", "China"],
  ["VE5SIT", "n", 5000, "Other Callsign", "Canada"],
  ["G0PPO", "n", 5000, "Other Callsign", "England"],
  ["G7FXL", "n", 5000, "Other Callsign", "England"],
  ["DV7LEB", "n", 5000, "Other Callsign", "Philippines"],
  ["NP3Y", "n", 5000, "Other Callsign", "Puerto Rico"],
  ["LW2HBF", "n", 5000, "Other Callsign", "Argentina"],
  ["CQ4I", "n", 5000, "Other Callsign", "Portugal"],
  ["LZ3WG", "n", 5000, "Other Callsign", "Bulgaria"],
  ["VU2UPZ", "n", 5000, "Other Callsign", "India"],
  ["LX2MT", "n", 5000, "Other Callsign", "Luxembourg"],
  ["DL3MIH", "n", 5000, "Other Callsign", "Germany"],
  ["G6ZOD", "n", 5000, "Other Callsign", "England"],
  ["OM1HI", "n", 5000, "Other Callsign", "Slovak Republic"],
  ["RA3AAY", "n", 5000, "Other Callsign", "Russia"],
  ["YS2RU", "n", 5000, "Other Callsign", "El Salvador"],
  ["VU2DA", "n", 5000, "Other Callsign", "India"],
  ["IW0BNE", "n", 5000, "Other Callsign", "Italy"],
  ["I6IJH", "n", 5000, "Other Callsign", "Italy"],
  ["IW7CPY", "n", 5000, "Other Callsign", "Italy"],
  ["PY2GF", "n", 5000, "Other Callsign", "Brazil"],
  ["LZ1EF", "n", 5000, "Other Callsign", "Bulgaria"],
  ["M1AIF", "n", 5000, "Other Callsign", "England"],
  ["UN7GK", "n", 5000, "Other Callsign", "Kazakhstan"],
  ["UR5MDD", "n", 5000, "Other Callsign", "Ukraine"],
  ["CT2HBZ", "n", 5000, "Other Callsign", "Portugal"],
  ["JR9SLB", "n", 5000, "Other Callsign", "Japan"],
  ["G2DGB", "n", 5000, "Other Callsign", "England"],
  ["CU3ABB", "n", 5000, "Other Callsign", "Portugal"],
  ["G6JLI", "n", 5000, "Other Callsign", "England"],
  ["ER1M", "n", 5000, "Other Callsign", "Moldova"],
  ["G0GSD", "n", 5000, "Other Callsign", "England"],
  ["IW6BDE", "n", 5000, "Other Callsign", "Italy"],
  ["UR5ACR", "n", 5000, "Other Callsign", "Ukraine"],
  ["G0GCA", "n", 5000, "Other Callsign", "England"],
  ["ZX3B", "n", 5000, "Other Callsign", "Brazil"],
  ["US2IHV", "n", 5000, "Other Callsign", "Ukraine"],
  ["XE3AM", "n", 5000, "Other Callsign", "Mexico"],
  ["M0JAK", "n", 5000, "Other Callsign", "England"],
  ["9W2EZ", "n", 5000, "Other Callsign", "West Malaysia"],
  ["M3ITE", "n", 5000, "Other Callsign", "England"],
  ["LU4DAK", "n", 5000, "Other Callsign", "Argentina"],
  ["G6PIB", "n", 5000, "Other Callsign", "England"],
  ["HL2BQE", "n", 5000, "Other Callsign", "South Korea"],
  ["XQ8ABF", "n", 5000, "Other Callsign", "Chile"],
  ["VU3RGP", "n", 5000, "Other Callsign", "India"],
  ["VR2BT", "n", 5000, "Other Callsign", "Hong Kong"],
  ["DF0DC", "n", 5000, "Other Callsign", "Germany"],
  ["PP5RR", "n", 5000, "Other Callsign", "Brazil"],
  ["IW7EDL", "n", 5000, "Other Callsign", "Italy"],
  ["OK1KCI", "n", 5000, "Other Callsign", "Czech Republic"],
  ["LU4ATB", "n", 5000, "Other Callsign", "Argentina"],
  ["G8BEK", "n", 5000, "Other Callsign", "England"],
  ["VU2OSJ", "n", 5000, "Other Callsign", "India"],
  ["IW4EQI", "n", 5000, "Other Callsign", "Italy"],
  ["G3XHP", "n", 5000, "Other Callsign", "England"],
  ["VK2TOP", "n", 5000, "Other Callsign", "Australia"],
  ["PT2PYC", "n", 5000, "Other Callsign", "Brazil"],
  ["IK1RDV", "n", 5000, "Other Callsign", "Italy"],
  ["CX3CN", "n", 5000, "Other Callsign", "Uruguay"],
  ["EI9IE", "n", 5000, "Other Callsign", "Ireland"],
  ["G0APD", "n", 5000, "Other Callsign", "England"],
  ["UA0QGM", "n", 5000, "Other Callsign", "Asiatic Russia"],
  ["RZ6MF", "n", 5000, "Other Callsign", "Russia"],
  ["4M5WA", "n", 5000, "Other Callsign", "Venezuela"],
  ["KH6BS", "n", 5000, "Other Callsign", "Hawaii"],
  ["RU3ZI", "n", 5000, "Other Callsign", "Russia"],
  ["IZ4HBF", "n", 5000, "Other Callsign", "Italy"],
  ["PA7JM", "n", 5000, "Other Callsign", "Netherlands"],
  ["M0IFR", "n", 5000, "Other Callsign", "England"],
  ["VK3TMW", "n", 5000, "Other Callsign", "Australia"],
  ["VK6TH", "n", 5000, "Other Callsign", "Australia"],
  ["CT1FUS", "n", 5000, "Other Callsign", "Portugal"],
  ["UE3SGK", "n", 5000, "Other Callsign", "Russia"],
  ["DC9SD", "n", 5000, "Other Callsign", "Germany"],
  ["MM3RKA", "n", 5000, "Other Callsign", "Scotland"],
  ["EC4AHX", "n", 5000, "Other Callsign", "Spain"],
  ["G7GNS", "n", 5000, "Other Callsign", "England"],
  ["UA9APL", "n", 5000, "Other Callsign", "Asiatic Russia"],
  ["YZ1W", "n", 5000, "Other Callsign", "Serbia"],
  ["HA1SZ", "n", 5000, "Other Callsign", "Hungary"],
  ["DF4TW", "n", 5000, "Other Callsign", "Germany"],
  ["PE1PBA", "n", 5000, "Other Callsign", "Netherlands"],
  ["VE3OAG", "n", 5000, "Other Callsign", "Canada"],
  ["2D0BCR", "n", 5000, "Other Callsign", "Isle of Man"],
  ["DF4AE", "n", 5000, "Other Callsign", "Germany"],
  ["VE6APE", "n", 5000, "Other Callsign", "Canada"],
  ["EW6DI", "n", 5000, "Other Callsign", "Belarus"],
  ["YU150BM", "n", 5000, "Other Callsign", "Serbia"],
  ["8P6DO", "n", 5000, "Other Callsign", "Barbados"],
  ["VA3UE", "n", 5000, "Other Callsign", "Canada"],
  ["LZ1MYL", "n", 5000, "Other Callsign", "Bulgaria"],
  ["GU8ITE", "n", 5000, "Other Callsign", "Guernsey"],
  ["TA1P", "n", 5000, "Other Callsign", "Turkey"],
  ["PA6EU", "n", 5000, "Other Callsign", "Netherlands"],
  ["VK3FNIK", "n", 5000, "Other Callsign", "Australia"],
  ["YO7LHN", "n", 5000, "Other Callsign", "Romania"],
  ["G3YPS", "n", 5000, "Other Callsign", "England"],
  ["G3WPM", "n", 5000, "Other Callsign", "England"],
  ["LU2JLR", "n", 5000, "Other Callsign", "Argentina"],
  ["LU9MHH", "n", 5000, "Other Callsign", "Argentina"],
  ["VK5AKR", "n", 5000, "Other Callsign", "Australia"],
  ["VE3IXL", "n", 5000, "Other Callsign", "Canada"],
  ["M0HAL", "n", 5000, "Other Callsign", "England"],
  ["9K2QQ", "n", 5000, "Other Callsign", "Kuwait"],
  ["IK2ILD", "n", 5000, "Other Callsign", "Italy"],
  ["PA0JAW", "n", 5000, "Other Callsign", "Netherlands"],
  ["G8JPA", "n", 5000, "Other Callsign", "England"],
  ["JI8DGO", "n", 5000, "Other Callsign", "Japan"],
  ["PA0MEU", "n", 5000, "Other Callsign", "Netherlands"],
  ["GM0HBF", "n", 5000, "Other Callsign", "Scotland"],
  ["IK1BOL", "n", 5000, "Other Callsign", "Italy"],
  ["LZ1BT", "n", 5000, "Other Callsign", "Bulgaria"],
  ["OZ2UV", "n", 5000, "Other Callsign", "Denmark"],
  ["IW3BXN", "n", 5000, "Other Callsign", "Italy"],
  ["DC2TOM", "n", 5000, "Other Callsign", "Germany"],
  ["ON5XJ", "n", 5000, "Other Callsign", "Belgium"],
  ["GM0AAX", "n", 5000, "Other Callsign", "Scotland"],
  ["G3PVU", "n", 5000, "Other Callsign", "England"],
  ["9V1GK", "n", 5000, "Other Callsign", "Singapore"],
  ["EA1KN", "n", 5000, "Other Callsign", "Spain"],
  ["ZL2JO", "n", 5000, "Other Callsign", "New Zealand"],
  ["ON3EQ", "n", 5000, "Other Callsign", "Belgium"],
  ["VE3BMV", "n", 5000, "Other Callsign", "Canada"],
  ["LU1DHJ", "n", 5000, "Other Callsign", "Argentina"],
  ["UA4CML", "n", 5000, "Other Callsign", "Russia"],
  ["VK2EZY", "n", 5000, "Other Callsign", "Australia"],
  ["LU8DGV", "n", 5000, "Other Callsign", "Argentina"],
  ["YB0FMT", "n", 5000, "Other Callsign", "Indonesia"],
  ["IW3BRC", "n", 5000, "Other Callsign", "Italy"],
  ["DB1MV", "n", 5000, "Other Callsign", "Germany"],
  ["VK2NWC", "n", 5000, "Other Callsign", "Australia"],
  ["VK4CE", "n", 5000, "Other Callsign", "Australia"],
  ["M3VWL", "n", 5000, "Other Callsign", "England"],
  ["PD0MOH", "n", 5000, "Other Callsign", "Netherlands"],
  ["PY1BZ", "n", 5000, "Other Callsign", "Brazil"],
  ["G4YWY", "n", 5000, "Other Callsign", "England"],
  ["VK4WC", "n", 5000, "Other Callsign", "Australia"],
  ["PY3CNH", "n", 5000, "Other Callsign", "Brazil"],
  ["LU9EIF", "n", 5000, "Other Callsign", "Argentina"],
  ["DL0ZA", "n", 5000, "Other Callsign", "Germany"],
  ["UN7CA", "n", 5000, "Other Callsign", "Kazakhstan"],
  ["SQ9ACK", "n", 5000, "Other Callsign", "Poland"],
  ["PA3CGG", "n", 5000, "Other Callsign", "Netherlands"],
  ["G8TEX", "n", 5000, "Other Callsign", "England"],
  ["DF1RK", "n", 5000, "Other Callsign", "Germany"],
  ["HS9IFG", "n", 5000, "Other Callsign", "Thailand"],
  ["IW2NJB", "n", 5000, "Other Callsign", "Italy"],
  ["VK2JWA", "n", 5000, "Other Callsign", "Australia"],
  ["JG3GYO", "n", 5000, "Other Callsign", "Japan"],
  ["LW6DLC", "n", 5000, "Other Callsign", "Argentina"],
  ["G4FPO", "n", 5000, "Other Callsign", "England"],
  ["DF1GR", "n", 5000, "Other Callsign", "Germany"],
  ["LA7LCA", "n", 5000, "Other Callsign", "Norway"],
  ["UR5MKI", "n", 5000, "Other Callsign", "Ukraine"],
  ["BG2TAX", "n", 5000, "Other Callsign", "China"],
  ["PJ2JW", "n", 5000, "Other Callsign", "Curacao"],
  ["M3BGU", "n", 5000, "Other Callsign", "England"],
  ["IK7FRY", "n", 5000, "Other Callsign", "Italy"],
  ["DL6CHH", "n", 5000, "Other Callsign", "Germany"],
  ["UA3ERA", "n", 5000, "Other Callsign", "Russia"],
  ["RA6LJA", "n", 5000, "Other Callsign", "Russia"],
  ["VU3CNS", "n", 5000, "Other Callsign", "India"],
  ["YC1KKK", "n", 5000, "Other Callsign", "Indonesia"],
  ["CU3BJ", "n", 5000, "Other Callsign", "Portugal"],
  ["Z21KQ", "n", 5000, "Other Callsign", "Zimbabwe"],
  ["IZ2BZT", "n", 5000, "Other Callsign", "Italy"],
  ["OE3ABA", "n", 5000, "Other Callsign", "Austria"],
  ["MI0JAY", "n", 5000, "Other Callsign", "Northern Ireland"],
  ["G4AWO", "n", 5000, "Other Callsign", "England"],
  ["NH7OL", "n", 5000, "Other Callsign", "Hawaii"],
  ["VU2LAY", "n", 5000, "Other Callsign", "India"],
  ["9V1XQ", "n", 5000, "Other Callsign", "Singapore"],
  ["YU1UU", "n", 5000, "Other Callsign", "Serbia"],
  ["G3SSO", "n", 5000, "Other Callsign", "England"],
  ["VK4GF", "n", 5000, "Other Callsign", "Australia"],
  ["G3MFW", "n", 5000, "Other Callsign", "England"],
  ["G3HYG", "n", 5000, "Other Callsign", "England"],
  ["ZC4JP", "n", 5000, "Other Callsign", "Cyprus SBA"],
  ["G6WVL", "n", 5000, "Other Callsign", "England"],
  ["JA2PHO", "n", 5000, "Other Callsign", "Japan"],
  ["VK4AGK", "n", 5000, "Other Callsign", "Australia"],
  ["OH3JW", "n", 5000, "Other Callsign", "Finland"],
  ["PY2UZ", "n", 5000, "Other Callsign", "Brazil"],
  ["EB8AHT", "n", 5000, "Other Callsign", "Canary Islands"],
  ["MW3EWR", "n", 5000, "Other Callsign", "Wales"],
  ["G1LTK", "n", 5000, "Other Callsign", "England"],
  ["PU2VDJ", "n", 5000, "Other Callsign", "Brazil"],
  ["ZR6PD", "n", 5000, "Other Callsign", "South Africa"],
  ["ED7TST", "n", 5000, "Other Callsign", "Spain"],
  ["KP4EHY", "n", 5000, "Other Callsign", "Puerto Rico"],
  ["YO7BKX", "n", 5000, "Other Callsign", "Romania"],
  ["SM3KOR", "n", 5000, "Other Callsign", "Sweden"],
  ["DG9FDU", "n", 5000, "Other Callsign", "Germany"],
  ["EA8BXT", "n", 5000, "Other Callsign", "Canary Islands"],
  ["SP3NYF", "n", 5000, "Other Callsign", "Poland"],
  ["IW8ELX", "n", 5000, "Other Callsign", "Italy"],
  ["LA7UM", "n", 5000, "Other Callsign", "Norway"],
  ["RV0ADW", "n", 5000, "Other Callsign", "Asiatic Russia"],
  ["LU3MC", "n", 5000, "Other Callsign", "Argentina"],
  ["M5DNK", "n", 5000, "Other Callsign", "England"],
  ["SP3ELD", "n", 5000, "Other Callsign", "Poland"],
  ["9M2SZ", "n", 5000, "Other Callsign", "West Malaysia"],
  ["UT4MD", "n", 5000, "Other Callsign", "Ukraine"],
  ["UR8QQ", "n", 5000, "Other Callsign", "Ukraine"],
  ["BX5AG", "n", 5000, "Other Callsign", "Taiwan"],
  ["LU4AEY", "n", 5000, "Other Callsign", "Argentina"],
  ["DV1ODC", "n", 5000, "Other Callsign", "Philippines"],
  ["F6ADQ", "n", 5000, "Other Callsign", "France"],
  ["IZ4COY", "n", 5000, "Other Callsign", "Italy"],
  ["UN7FK", "n", 5000, "Other Callsign", "Kazakhstan"],
  ["PY6HK", "n", 5000, "Other Callsign", "Brazil"],
  ["IK8ENP", "n", 5000, "Other Callsign", "Italy"],
  ["LU9HK", "n", 5000, "Other Callsign", "Argentina"],
  ["M0IVW", "n", 5000, "Other Callsign", "England"],
  ["ZL2BK", "n", 5000, "Other Callsign", "New Zealand"],
  ["G8UCZ", "n", 5000, "Other Callsign", "England"],
  ["YA5Y", "n", 5000, "Other Callsign", "Afghanistan"],
  ["ON2BFQ", "n", 5000, "Other Callsign", "Belgium"],
  ["HF8JP", "n", 5000, "Other Callsign", "Poland"],
  ["IT9PEY", "n", 5000, "Other Callsign", "Italy"],
  ["IW3IGM", "n", 5000, "Other Callsign", "Italy"],
  ["XE2MZL", "n", 5000, "Other Callsign", "Mexico"],
  ["EC3AKY", "n", 5000, "Other Callsign", "Spain"],
  ["WP4MEQ", "n", 5000, "Other Callsign", "Puerto Rico"],
  ["YU1AOB", "n", 5000, "Other Callsign", "Serbia"],
  ["GB2ECR", "n", 5000, "Other Callsign", "England"],
  ["9H5PS", "n", 5000, "Other Callsign", "Malta"],
  ["PG7M", "n", 5000, "Other Callsign", "Netherlands"],
  ["CM2XJ", "n", 5000, "Other Callsign", "Cuba"],
  ["G8UCY", "n", 5000, "Other Callsign", "England"],
  ["SP2WHE", "n", 5000, "Other Callsign", "Poland"],
  ["G3ISB", "n", 5000, "Other Callsign", "England"],
  ["VK3MJD", "n", 5000, "Other Callsign", "Australia"],
  ["ZL3TJ", "n", 5000, "Other Callsign", "New Zealand"],
  ["UT5UD", "n", 5000, "Other Callsign", "Ukraine"],
  ["JH7YES", "n", 5000, "Other Callsign", "Japan"],
  ["VE2BMC", "n", 5000, "Other Callsign", "Canada"],
  ["VK5BW", "n", 5000, "Other Callsign", "Australia"],
  ["MW3IRW", "n", 5000, "Other Callsign", "Wales"],
  ["JA1BUQ", "n", 5000, "Other Callsign", "Japan"],
  ["CX1RJ", "n", 5000, "Other Callsign", "Uruguay"],
  ["OO4LN", "n", 5000, "Other Callsign", "Belgium"],
  ["TM9OBV", "n", 5000, "Other Callsign", "France"],
  ["UR4IT", "n", 5000, "Other Callsign", "Ukraine"],
  ["LU8DAA", "n", 5000, "Other Callsign", "Argentina"],
  ["DL0ODX", "n", 5000, "Other Callsign", "Germany"],
  ["HB9SJO", "n", 5000, "Other Callsign", "Switzerland"],
  ["EW8UN", "n", 5000, "Other Callsign", "Belarus"],
  ["DL8RCD", "n", 5000, "Other Callsign", "Germany"],
  ["RU6CO", "n", 5000, "Other Callsign", "Russia"],
  ["GW6ZYI", "n", 5000, "Other Callsign", "Wales"],
  ["TA1AC", "n", 5000, "Other Callsign", "Turkey"],
  ["ON5YN", "n", 5000, "Other Callsign", "Belgium"],
  ["3D2VS", "n", 5000, "Other Callsign", "Rotuma"],
  ["AP2AC", "n", 5000, "Other Callsign", "Pakistan"],
  ["G1YVR", "n", 5000, "Other Callsign", "England"],
  ["RV0AMH", "n", 5000, "Other Callsign", "Asiatic Russia"],
  ["JA2LLK", "n", 5000, "Other Callsign", "Japan"],
  ["OE2KMM", "n", 5000, "Other Callsign", "Austria"],
  ["CX3DDP", "n", 5000, "Other Callsign", "Uruguay"],
  ["LU1FFZ", "n", 5000, "Other Callsign", "Argentina"],
  ["VK4AWX", "n", 5000, "Other Callsign", "Australia"],
  ["PC7CW", "n", 5000, "Other Callsign", "Netherlands"],
  ["NH6W", "n", 5000, "Other Callsign", "Hawaii"],
  ["BG1NNI", "n", 5000, "Other Callsign", "China"],
  ["OH2LVG", "n", 5000, "Other Callsign", "Finland"],
  ["UU1JO", "n", 5000, "Other Callsign", "Ukraine"],
  ["G3ELV", "n", 5000, "Other Callsign", "England"],
  ["KL7KL", "n", 5000, "Other Callsign", "Alaska"],
  ["RA3GCU", "n", 5000, "Other Callsign", "Russia"],
  ["JA6EDJ", "n", 5000, "Other Callsign", "Japan"],
  ["YV1FTB", "n", 5000, "Other Callsign", "Venezuela"],
  ["JA2LZB", "n", 5000, "Other Callsign", "Japan"],
  ["G8WET", "n", 5000, "Other Callsign", "England"],
  ["DS2RZK", "n", 5000, "Other Callsign", "South Korea"],
  ["OK1MCV", "n", 5000, "Other Callsign", "Czech Republic"],
  ["B4RF", "n", 5000, "Other Callsign", "China"],
  ["PE7B", "n", 5000, "Other Callsign", "Netherlands"],
  ["KH7XC", "n", 5000, "Other Callsign", "Hawaii"],
  ["WP4NY", "n", 5000, "Other Callsign", "Puerto Rico"],
  ["DK3MD", "n", 5000, "Other Callsign", "Germany"],
  ["HB9DGC", "n", 5000, "Other Callsign", "Switzerland"],
  ["G4GPU", "n", 5000, "Other Callsign", "England"],
  ["9M8OM", "n", 5000, "Other Callsign", "East Malaysia"],
  ["JA9BEK", "n", 5000, "Other Callsign", "Japan"],
  ["CE1LXV", "n", 5000, "Other Callsign", "Chile"],
  ["GW7KDU", "n", 5000, "Other Callsign", "Wales"],
  ["UU4JGG", "n", 5000, "Other Callsign", "Ukraine"],
  ["9A1CHP", "n", 5000, "Other Callsign", "Croatia"],
  ["YU7BCH", "n", 5000, "Other Callsign", "Serbia"],
  ["RV3DTU", "n", 5000, "Other Callsign", "Russia"],
  ["XE1BSM", "n", 5000, "Other Callsign", "Mexico"],
  ["EA5MF", "n", 5000, "Other Callsign", "Spain"],
  ["OZ1K", "n", 5000, "Other Callsign", "Denmark"],
  ["G6ZHL", "n", 5000, "Other Callsign", "England"],
  ["JK8SBF", "n", 5000, "Other Callsign", "Japan"],
  ["DL1LRA", "n", 5000, "Other Callsign", "Germany"],
  ["DL8IY", "n", 5000, "Other Callsign", "Germany"],
  ["KP4ECE", "n", 5000, "Other Callsign", "Puerto Rico"],
  ["CO8ESE", "n", 5000, "Other Callsign", "Cuba"],
  ["YV5FJ", "n", 5000, "Other Callsign", "Venezuela"],
  ["G6YAY", "n", 5000, "Other Callsign", "England"],
  ["KP3AM", "n", 5000, "Other Callsign", "Puerto Rico"],
  ["KH6WF", "n", 5000, "Other Callsign", "Hawaii"],
  ["GW0JEQ", "n", 5000, "Other Callsign", "Wales"],
  ["VK4DGS", "n", 5000, "Other Callsign", "Australia"],
  ["G4HFI", "n", 5000, "Other Callsign", "England"],
  ["HA8BC", "n", 5000, "Other Callsign", "Hungary"],
  ["DL8MDN", "n", 5000, "Other Callsign", "Germany"],
  ["JE1JDD", "n", 5000, "Other Callsign", "Japan"],
  ["MW5VZW", "n", 5000, "Other Callsign", "Wales"],
  ["ZL3AT", "n", 5000, "Other Callsign", "New Zealand"],
  ["TI2SAG", "n", 5000, "Other Callsign", "Costa Rica"],
  ["DS3GOW", "n", 5000, "Other Callsign", "South Korea"],
  ["7P8CW", "n", 5000, "Other Callsign", "Lesotho"],
  ["IC8EGO", "n", 5000, "Other Callsign", "Italy"],
  ["G1HSM", "n", 5000, "Other Callsign", "England"],
  ["PA3FFM", "n", 5000, "Other Callsign", "Netherlands"],
  ["PE1NTN", "n", 5000, "Other Callsign", "Netherlands"],
  ["RX3QAK", "n", 5000, "Other Callsign", "Russia"],
  ["EW2ES", "n", 5000, "Other Callsign", "Belarus"],
  ["M0DZR", "n", 5000, "Other Callsign", "England"],
  ["OH9TK", "n", 5000, "Other Callsign", "Finland"],
  ["UT5UGL", "n", 5000, "Other Callsign", "Ukraine"],
  ["PY2DJB", "n", 5000, "Other Callsign", "Brazil"],
  ["M5IMI", "n", 5000, "Other Callsign", "England"],
  ["G6JBL", "n", 5000, "Other Callsign", "England"],
  ["LU5HZV", "n", 5000, "Other Callsign", "Argentina"],
  ["NP3Q", "n", 5000, "Other Callsign", "Puerto Rico"],
  ["IW3IFZ", "n", 5000, "Other Callsign", "Italy"],
  ["CO8VHS", "n", 5000, "Other Callsign", "Cuba"],
  ["UT1WA", "n", 5000, "Other Callsign", "Ukraine"],
  ["JK1AII", "n", 5000, "Other Callsign", "Japan"],
  ["ON3FR", "n", 5000, "Other Callsign", "Belgium"],
  ["BG1PIP", "n", 5000, "Other Callsign", "China"],
  ["LY3FU", "n", 5000, "Other Callsign", "Lithuania"],
  ["PJ2HB", "n", 5000, "Other Callsign", "Curacao"],
  ["PA3BTY", "n", 5000, "Other Callsign", "Netherlands"],
  ["F1UFW", "n", 5000, "Other Callsign", "France"],
  ["MI3MCZ", "n", 5000, "Other Callsign", "Northern Ireland"],
  ["CM2MI", "n", 5000, "Other Callsign", "Cuba"],
  ["US4IAE", "n", 5000, "Other Callsign", "Ukraine"],
  ["DG2MAS", "n", 5000, "Other Callsign", "Germany"],
  ["JE7KCI", "n", 5000, "Other Callsign", "Japan"],
  ["PY2DCE", "n", 5000, "Other Callsign", "Brazil"],
  ["M0CTD", "n", 5000, "Other Callsign", "England"],
  ["XE2FLR", "n", 5000, "Other Callsign", "Mexico"],
  ["VE1AW", "n", 5000, "Other Callsign", "Canada"],
  ["F1CAN", "n", 5000, "Other Callsign", "France"],
  ["YB6XFI", "n", 5000, "Other Callsign", "Indonesia"],
  ["VK2BMS", "n", 5000, "Other Callsign", "Australia"],
  ["VU2HOC", "n", 5000, "Other Callsign", "India"],
  ["LA2J", "n", 5000, "Other Callsign", "Norway"],
  ["PU1MNV", "n", 5000, "Other Callsign", "Brazil"],
  ["OA2AEL", "n", 5000, "Other Callsign", "Peru"],
  ["IK5IIR", "n", 5000, "Other Callsign", "Italy"],
  ["RA9UDS", "n", 5000, "Other Callsign", "Asiatic Russia"],
  ["9M6GY", "n", 5000, "Other Callsign", "East Malaysia"],
  ["I3GHS", "n", 5000, "Other Callsign", "Italy"],
  ["UA3DGX", "n", 5000, "Other Callsign", "Russia"],
  ["DL7AWY", "n", 5000, "Other Callsign", "Germany"],
  ["AH6EE", "n", 5000, "Other Callsign", "Hawaii"],
  ["LU1DW", "n", 5000, "Other Callsign", "Argentina"],
  ["CT1EBS", "n", 5000, "Other Callsign", "Portugal"],
  ["XE1III", "n", 5000, "Other Callsign", "Mexico"],
  ["IV3TOU", "n", 5000, "Other Callsign", "Italy"],
  ["LA9KW", "n", 5000, "Other Callsign", "Norway"],
  ["SM7DMT", "n", 5000, "Other Callsign", "Sweden"],
  ["JR2UKF", "n", 5000, "Other Callsign", "Japan"],
  ["PY1ZAB", "n", 5000, "Other Callsign", "Brazil"],
  ["G7UFW", "n", 5000, "Other Callsign", "England"],
  ["ON4TU", "n", 5000, "Other Callsign", "Belgium"],
  ["IK2BSJ", "n", 5000, "Other Callsign", "Italy"],
  ["G7IZU", "n", 5000, "Other Callsign", "England"],
  ["5R8DL", "n", 5000, "Other Callsign", "Madagascar"],
  ["VP9A", "n", 5000, "Other Callsign", "Bermuda"],
  ["DH4NAQ", "n", 5000, "Other Callsign", "Germany"],
  ["SV1DLR", "n", 5000, "Other Callsign", "Greece"],
  ["VE1BY", "n", 5000, "Other Callsign", "Canada"],
  ["M3MLR", "n", 5000, "Other Callsign", "England"],
  ["M3YTM", "n", 5000, "Other Callsign", "England"],
  ["LW4DUQ", "n", 5000, "Other Callsign", "Argentina"],
  ["JR5PWV", "n", 5000, "Other Callsign", "Japan"],
  ["IK6ARS", "n", 5000, "Other Callsign", "Italy"],
  ["YB0GG", "n", 5000, "Other Callsign", "Indonesia"],
  ["IZ6AVE", "n", 5000, "Other Callsign", "Italy"],
  ["DD2DR", "n", 5000, "Other Callsign", "Germany"],
  ["RZ0JWE", "n", 5000, "Other Callsign", "Asiatic Russia"],
  ["YY4BCD", "n", 5000, "Other Callsign", "Venezuela"],
  ["VK3OP", "n", 5000, "Other Callsign", "Australia"],
  ["9H1FY", "n", 5000, "Other Callsign", "Malta"],
  ["F2YS", "n", 5000, "Other Callsign", "France"],
  ["I1WWE", "n", 5000, "Other Callsign", "Italy"],
  ["ZS0E", "n", 5000, "Other Callsign", "South Africa"],
  ["VK4PW", "n", 5000, "Other Callsign", "Australia"],
  ["GW3PEX", "n", 5000, "Other Callsign", "Wales"],
  ["PA6TXL", "n", 5000, "Other Callsign", "Netherlands"],
  ["PP5CZ", "n", 5000, "Other Callsign", "Brazil"],
  ["NH6X", "n", 5000, "Other Callsign", "Hawaii"],
  ["DL2RBY", "n", 5000, "Other Callsign", "Germany"],
  ["MW0SBX", "n", 5000, "Other Callsign", "Wales"],
  ["CT1COO", "n", 5000, "Other Callsign", "Portugal"],
  ["UR5WJA", "n", 5000, "Other Callsign", "Ukraine"],
  ["HB9MBG", "n", 5000, "Other Callsign", "Switzerland"],
  ["CW0605", "n", 5000, "Other Callsign", "Uruguay"],
  ["SM7HXK", "n", 5000, "Other Callsign", "Sweden"],
  ["LU7MH", "n", 5000, "Other Callsign", "Argentina"],
  ["IW2KGF", "n", 5000, "Other Callsign", "Italy"],
  ["RA0LT", "n", 5000, "Other Callsign", "Asiatic Russia"],
  ["SP7MUP", "n", 5000, "Other Callsign", "Poland"],
  ["G7HAS", "n", 5000, "Other Callsign", "England"],
  ["YV4EOH", "n", 5000, "Other Callsign", "Venezuela"],
  ["UU5JJ", "n", 5000, "Other Callsign", "Ukraine"],
  ["CT4QP", "n", 5000, "Other Callsign", "Portugal"],
  ["PY2BE", "n", 5000, "Other Callsign", "Brazil"],
  ["ZL1AXZ", "n", 5000, "Other Callsign", "New Zealand"],
  ["M3WTZ", "n", 5000, "Other Callsign", "England"],
  ["LU7BR", "n", 5000, "Other Callsign", "Argentina"],
  ["ZP6WIA", "n", 5000, "Other Callsign", "Paraguay"],
  ["IK1TVR", "n", 5000, "Other Callsign", "Italy"],
  ["IW3HQD", "n", 5000, "Other Callsign", "Italy"],
  ["G4JDC", "n", 5000, "Other Callsign", "England"],
  ["SP9WTS", "n", 5000, "Other Callsign", "Poland"],
  ["F1RYU", "n", 5000, "Other Callsign", "France"],
  ["TM4YL", "n", 5000, "Other Callsign", "France"],
  ["I0HLH", "n", 5000, "Other Callsign", "Italy"],
  ["PU1MEG", "n", 5000, "Other Callsign", "Brazil"],
  ["JL1BWO", "n", 5000, "Other Callsign", "Japan"],
  ["CM3AJ", "n", 5000, "Other Callsign", "Cuba"],
  ["ZL3JM", "n", 5000, "Other Callsign", "New Zealand"],
  ["DL1KAH", "n", 5000, "Other Callsign", "Germany"],
  ["HK1BYM", "n", 5000, "Other Callsign", "Colombia"],
  ["GW4YMZ", "n", 5000, "Other Callsign", "Wales"],
  ["G4BLF", "n", 5000, "Other Callsign", "England"],
  ["TA6A", "n", 5000, "Other Callsign", "Turkey"],
  ["PA3EYF", "n", 5000, "Other Callsign", "Netherlands"],
  ["PA3HBO", "n", 5000, "Other Callsign", "Netherlands"],
  ["PE1HLV", "n", 5000, "Other Callsign", "Netherlands"],
  ["IZ8DQM", "n", 5000, "Other Callsign", "Italy"],
  ["KD8BVD", "n", 5000, "Other Callsign", "Alaska"],
  ["OA3ABF", "n", 5000, "Other Callsign", "Peru"],
  ["SQ6EMN", "n", 5000, "Other Callsign", "Poland"],
  ["IC8COJ", "n", 5000, "Other Callsign", "Italy"],
  ["PP8BCA", "n", 5000, "Other Callsign", "Brazil"],
  ["M0JWA", "n", 5000, "Other Callsign", "England"],
  ["CX6CM", "n", 5000, "Other Callsign", "Uruguay"],
  ["GB3CM", "n", 5000, "Other Callsign", "Wales"],
  ["PE2YSB", "n", 5000, "Other Callsign", "Netherlands"],
  ["G3MWK", "n", 5000, "Other Callsign", "England"],
  ["M3IRC", "n", 5000, "Other Callsign", "England"],
  ["F1IZA", "n", 5000, "Other Callsign", "France"],
  ["TA3CCK", "n", 5000, "Other Callsign", "Turkey"],
  ["KP4NO", "n", 5000, "Other Callsign", "Puerto Rico"],
  ["G3RVC", "n", 5000, "Other Callsign", "England"],
  ["HS1QKR", "n", 5000, "Other Callsign", "Thailand"],
  ["WP4GPO", "n", 5000, "Other Callsign", "Puerto Rico"],
  ["2E0DXN", "n", 5000, "Other Callsign", "England"],
  ["G6AUR", "n", 5000, "Other Callsign", "England"],
  ["OH8GOU", "n", 5000, "Other Callsign", "Finland"],
  ["YI9AAQ", "n", 5000, "Other Callsign", "Iraq"],
  ["MI3SEO", "n", 5000, "Other Callsign", "Northern Ireland"],
  ["IZ2FMF", "n", 5000, "Other Callsign", "Italy"],
  ["VE3IMV", "n", 5000, "Other Callsign", "Canada"],
  ["IW2NLM", "n", 5000, "Other Callsign", "Italy"],
  ["JR7FBR", "n", 5000, "Other Callsign", "Japan"],
  ["LU1FAN", "n", 5000, "Other Callsign", "Argentina"],
  ["IK1FHC", "n", 5000, "Other Callsign", "Italy"],
  ["G3REZ", "n", 5000, "Other Callsign", "England"],
  ["WP4OM", "n", 5000, "Other Callsign", "Puerto Rico"],
  ["VE3LXS", "n", 5000, "Other Callsign", "Canada"],
  ["DS2MTE", "n", 5000, "Other Callsign", "South Korea"],
  ["VK5ZSM", "n", 5000, "Other Callsign", "Australia"],
  ["VK3RX", "n", 5000, "Other Callsign", "Australia"],
  ["VE2DAD", "n", 5000, "Other Callsign", "Canada"],
  ["IK6IHG", "n", 5000, "Other Callsign", "Italy"],
  ["G4GKY", "n", 5000, "Other Callsign", "England"],
  ["DL0AEG", "n", 5000, "Other Callsign", "Germany"],
  ["VU3GAO", "n", 5000, "Other Callsign", "India"],
  ["G4DKD", "n", 5000, "Other Callsign", "England"],
  ["ZK2BQI", "n", 5000, "Other Callsign", "Niue"],
  ["JE3KXT", "n", 5000, "Other Callsign", "Japan"],
  ["K8OUA", "n", 5000, "Other Callsign", "Alaska"],
  ["G6YQM", "n", 5000, "Other Callsign", "England"],
  ["OZ7LH", "n", 5000, "Other Callsign", "Denmark"],
  ["G4TGB", "n", 5000, "Other Callsign", "England"],
  ["9M2TS", "n", 5000, "Other Callsign", "West Malaysia"],
  ["SV2BXC", "n", 5000, "Other Callsign", "Greece"],
  ["RA3TLN", "n", 5000, "Other Callsign", "Russia"],
  ["IZ0BEC", "n", 5000, "Other Callsign", "Italy"],
  ["VA2AYN", "n", 5000, "Other Callsign", "Canada"],
  ["EA5XL", "n", 5000, "Other Callsign", "Spain"],
  ["VE0RDM", "n", 5000, "Other Callsign", "Canada"],
  ["US5MDA", "n", 5000, "Other Callsign", "Ukraine"],
  ["I4ZTO", "n", 5000, "Other Callsign", "Italy"],
  ["LU1ZMA", "n", 5000, "Other Callsign", "Argentina"],
  ["YB2DI", "n", 5000, "Other Callsign", "Indonesia"],
  ["2E1JKM", "n", 5000, "Other Callsign", "England"],
  ["CX6BBI", "n", 5000, "Other Callsign", "Uruguay"],
  ["VK6BMT", "n", 5000, "Other Callsign", "Australia"],
  ["XE1TSB", "n", 5000, "Other Callsign", "Mexico"],
  ["G4LJY", "n", 5000, "Other Callsign", "England"],
  ["LU4DA", "n", 5000, "Other Callsign", "Argentina"],
  ["RA9AMC", "n", 5000, "Other Callsign", "Asiatic Russia"],
  ["UA3VNM", "n", 5000, "Other Callsign", "Russia"],
  ["IZ3JQQ", "n", 5000, "Other Callsign", "Italy"],
  ["EA2JZ", "n", 5000, "Other Callsign", "Spain"],
  ["F1WW", "n", 5000, "Other Callsign", "France"],
  ["7N1AZY", "n", 5000, "Other Callsign", "Japan"],
  ["6M0LY", "n", 5000, "Other Callsign", "South Korea"],
  ["I5BKP", "n", 5000, "Other Callsign", "Italy"],
  ["SP2BZR", "n", 5000, "Other Callsign", "Poland"],
  ["CX1FJ", "n", 5000, "Other Callsign", "Uruguay"],
  ["PD0PZ", "n", 5000, "Other Callsign", "Netherlands"],
  ["ZL3AA", "n", 5000, "Other Callsign", "New Zealand"],
  ["UA4YDV", "n", 5000, "Other Callsign", "Russia"],
  ["G3TB", "n", 5000, "Other Callsign", "England"],
  ["VE7BAS", "n", 5000, "Other Callsign", "Canada"],
  ["G1EGE", "n", 5000, "Other Callsign", "England"],
  ["PA3GRN", "n", 5000, "Other Callsign", "Netherlands"],
  ["JE1TTI", "n", 5000, "Other Callsign", "Japan"],
  ["LU1AFD", "n", 5000, "Other Callsign", "Argentina"],
  ["VP2EBN", "n", 5000, "Other Callsign", "Anguilla"],
  ["I2SYM", "n", 5000, "Other Callsign", "Italy"],
  ["VK2WR", "n", 5000, "Other Callsign", "Australia"],
  ["PA3FFK", "n", 5000, "Other Callsign", "Netherlands"],
  ["ZL1ABL", "n", 5000, "Other Callsign", "New Zealand"],
  ["OZ1NOK", "n", 5000, "Other Callsign", "Denmark"],
  ["LA1NY", "n", 5000, "Other Callsign", "Norway"],
  ["G3VKF", "n", 5000, "Other Callsign", "England"],
  ["G4UPP", "n", 5000, "Other Callsign", "England"],
  ["SP7MUD", "n", 5000, "Other Callsign", "Poland"],
  ["UT5QW", "n", 5000, "Other Callsign", "Ukraine"],
  ["KH6BFZ", "n", 5000, "Other Callsign", "Hawaii"],
  ["VK2GJK", "n", 5000, "Other Callsign", "Australia"],
  ["N0LHN", "n", 5000, "Other Callsign", "Alaska"],
  ["G4XOU", "n", 5000, "Other Callsign", "England"],
  ["TA5AU", "n", 5000, "Other Callsign", "Turkey"],
  ["I4JMY", "n", 5000, "Other Callsign", "Italy"],
  ["M3MEO", "n", 5000, "Other Callsign", "England"],
  ["YC5VI", "n", 5000, "Other Callsign", "Indonesia"],
  ["PD0SAF", "n", 5000, "Other Callsign", "Netherlands"],
  ["IW0CMG", "n", 5000, "Other Callsign", "Italy"],
  ["EA1CRF", "n", 5000, "Other Callsign", "Spain"],
  ["DJ6EN", "n", 5000, "Other Callsign", "Germany"],
  ["G4TGW", "n", 5000, "Other Callsign", "England"],
  ["CX3RM", "n", 5000, "Other Callsign", "Uruguay"],
  ["G0ITZ", "n", 5000, "Other Callsign", "England"],
  ["G4CWD", "n", 5000, "Other Callsign", "England"],
  ["I7KKB", "n", 5000, "Other Callsign", "Italy"],
  ["SM7YTC", "n", 5000, "Other Callsign", "Sweden"],
  ["G4ROS", "n", 5000, "Other Callsign", "England"],
  ["M3AVH", "n", 5000, "Other Callsign", "England"],
  ["PY4AB", "n", 5000, "Other Callsign", "Brazil"],
  ["M3KNK", "n", 5000, "Other Callsign", "England"],
  ["ON4BAI", "n", 5000, "Other Callsign", "Belgium"],
  ["LU2MJA", "n", 5000, "Other Callsign", "Argentina"],
  ["AH2AN", "n", 5000, "Other Callsign", "Guam"],
  ["UR5LM", "n", 5000, "Other Callsign", "Ukraine"],
  ["UA1AJA", "n", 5000, "Other Callsign", "Russia"],
  ["OZ1GER", "n", 5000, "Other Callsign", "Denmark"],
  ["PY6ATA", "n", 5000, "Other Callsign", "Brazil"],
  ["HF5VD", "n", 5000, "Other Callsign", "Poland"],
  ["G6PNF", "n", 5000, "Other Callsign", "England"],
  ["WP4KPF", "n", 5000, "Other Callsign", "Puerto Rico"],
  ["G4OZN", "n", 5000, "Other Callsign", "England"],
  ["IK0IHA", "n", 5000, "Other Callsign", "Italy"],
  ["GM3TNT", "n", 5000, "Other Callsign", "Scotland"],
  ["G4MYN", "n", 5000, "Other Callsign", "England"],
  ["PY3PRV", "n", 5000, "Other Callsign", "Brazil"],
  ["UA3IJ", "n", 5000, "Other Callsign", "Russia"],
  ["DS5OJJ", "n", 5000, "Other Callsign", "South Korea"],
  ["G3XWI", "n", 5000, "Other Callsign", "England"],
  ["HL2TBL", "n", 5000, "Other Callsign", "South Korea"],
  ["IW3BAS", "n", 5000, "Other Callsign", "Italy"],
  ["LW2DFM", "n", 5000, "Other Callsign", "Argentina"],
  ["PA3LOK", "n", 5000, "Other Callsign", "Netherlands"],
  ["IK0NKH", "n", 5000, "Other Callsign", "Italy"],
  ["OE4GGA", "n", 5000, "Other Callsign", "Austria"],
  ["IK7UKG", "n", 5000, "Other Callsign", "Italy"],
  ["BA4DA", "n", 5000, "Other Callsign", "China"],
  ["RV6LNN", "n", 5000, "Other Callsign", "Russia"],
  ["KB5OWT", "n", 5000, "Other Callsign", "Hawaii"],
  ["F4EBW", "n", 5000, "Other Callsign", "France"],
  ["EA4TZ", "n", 5000, "Other Callsign", "Spain"],
  ["YV5VHF", "n", 5000, "Other Callsign", "Venezuela"],
  ["US0AA", "n", 5000, "Other Callsign", "Ukraine"],
  ["G0HEV", "n", 5000, "Other Callsign", "England"],
  ["RV3ZD", "n", 5000, "Other Callsign", "Russia"],
  ["DS5CKT", "n", 5000, "Other Callsign", "South Korea"],
  ["EA1CHC", "n", 5000, "Other Callsign", "Spain"],
  ["VK3WA", "n", 5000, "Other Callsign", "Australia"],
  ["RZ3DP", "n", 5000, "Other Callsign", "Russia"],
  ["ZL1CAL", "n", 5000, "Other Callsign", "New Zealand"],
  ["PY6JJ", "n", 5000, "Other Callsign", "Brazil"],
  ["G6SGA", "n", 5000, "Other Callsign", "England"],
  ["XV3PWP", "n", 5000, "Other Callsign", "Vietnam"],
  ["OE3SG", "n", 5000, "Other Callsign", "Austria"],
  ["UA9SFW", "n", 5000, "Other Callsign", "Russia"],
  ["J69AC", "n", 5000, "Other Callsign", "St Lucia"],
  ["4F1MIR", "n", 5000, "Other Callsign", "Philippines"],
  ["YC8RBC", "n", 5000, "Other Callsign", "Indonesia"],
  ["ZZ5EG", "n", 5000, "Other Callsign", "Brazil"],
  ["JH7VIB", "n", 5000, "Other Callsign", "Japan"],
  ["IW9CIB", "n", 5000, "Other Callsign", "Italy"],
  ["YB0HXH", "n", 5000, "Other Callsign", "Indonesia"],
  ["DB2TY", "n", 5000, "Other Callsign", "Germany"],
  ["IW0UOT", "n", 5000, "Other Callsign", "Italy"],
  ["VK4KLC", "n", 5000, "Other Callsign", "Australia"],
  ["EA4JO", "n", 5000, "Other Callsign", "Spain"],
  ["VU2PAJ", "n", 5000, "Other Callsign", "India"],
  ["HI3ENS", "n", 5000, "Other Callsign", "Dominican Republic"],
  ["CX3IX", "n", 5000, "Other Callsign", "Uruguay"],
  ["IK7XGF", "n", 5000, "Other Callsign", "Italy"],
  ["G6LAU", "n", 5000, "Other Callsign", "England"],
  ["G8IBL", "n", 5000, "Other Callsign", "England"],
  ["MW1BQO", "n", 5000, "Other Callsign", "Wales"],
  ["VK5KQX", "n", 5000, "Other Callsign", "Australia"],
  ["LW3ECD", "n", 5000, "Other Callsign", "Argentina"],
  ["KA2TAR", "n", 5000, "Other Callsign", "Puerto Rico"],
  ["DU1OP", "n", 5000, "Other Callsign", "Philippines"],
  ["I8OVH", "n", 5000, "Other Callsign", "Italy"],
  ["UA2FAO", "n", 5000, "Other Callsign", "Russia"],
  ["G0WXE", "n", 5000, "Other Callsign", "England"],
  ["DH2SRM", "n", 5000, "Other Callsign", "Germany"],
  ["RV3ALD", "n", 5000, "Other Callsign", "Russia"],
  ["RV6ARS", "n", 5000, "Other Callsign", "Russia"],
  ["YB0WK", "n", 5000, "Other Callsign", "Indonesia"],
  ["2E1ADV", "n", 5000, "Other Callsign", "England"],
  ["KL7GIH", "n", 5000, "Other Callsign", "Alaska"],
  ["G7ECN", "n", 5000, "Other Callsign", "England"],
  ["2E0PLE", "n", 5000, "Other Callsign", "England"],
  ["EZ4M", "n", 5000, "Other Callsign", "Turkmenistan"],
  ["LA1PNA", "n", 5000, "Other Callsign", "Norway"],
  ["TA9CFI", "n", 5000, "Other Callsign", "Turkey"],
  ["PR7BV", "n", 5000, "Other Callsign", "Brazil"],
  ["UA0SOZ", "n", 5000, "Other Callsign", "Asiatic Russia"],
  ["OE2XRK", "n", 5000, "Other Callsign", "Austria"],
  ["2E1GCB", "n", 5000, "Other Callsign", "England"],
  ["HL5MB", "n", 5000, "Other Callsign", "South Korea"],
  ["CU3CG", "n", 5000, "Other Callsign", "Portugal"],
  ["IK2UWC", "n", 5000, "Other Callsign", "Italy"],
  ["G0IHC", "n", 5000, "Other Callsign", "England"],
  ["SM0DOU", "n", 5000, "Other Callsign", "Sweden"],
  ["UA1CDG", "n", 5000, "Other Callsign", "Russia"],
  ["G1EYD", "n", 5000, "Other Callsign", "England"],
  ["KH7NM", "n", 5000, "Other Callsign", "Hawaii"],
  ["G0WGI", "n", 5000, "Other Callsign", "England"],
  ["DG3UH", "n", 5000, "Other Callsign", "Germany"],
  ["SM6ASD", "n", 5000, "Other Callsign", "Sweden"],
  ["CT1AIS", "n", 5000, "Other Callsign", "Portugal"],
  ["IZ1BWX", "n", 5000, "Other Callsign", "Italy"],
  ["YV5HHP", "n", 5000, "Other Callsign", "Venezuela"],
  ["VK4CCV", "n", 5000, "Other Callsign", "Australia"],
  ["HL2DWR", "n", 5000, "Other Callsign", "South Korea"],
  ["SQ3BMJ", "n", 5000, "Other Callsign", "Poland"],
  ["LW2DOT", "n", 5000, "Other Callsign", "Argentina"],
  ["VK7AV", "n", 5000, "Other Callsign", "Australia"],
  ["PE1PMV", "n", 5000, "Other Callsign", "Netherlands"],
  ["RW6AGY", "n", 5000, "Other Callsign", "Russia"],
  ["HB9GKP", "n", 5000, "Other Callsign", "Switzerland"]
];

// Verb, Noun, adJective, adveRb, Article, Conjunction, Demonstrative,
// preposItion, nuMber, Pronoun, interjection (U), diGits

class Dictionary {
  allWords = null; // Contains all the words in the dictionary
  allTypes = null; // Array containing all types in the dictionary

  typesToIncludeByDefault = {
    Word: true,
    Abbreviation: true,
    "Q Code": true,
    Number: true,
    Year: true,
    "US Name": false,
    "US State Abbreviation": false,
    Country: false,
    "USA Callsign": false,
    "Other Callsign": false
  };

  words = null;
  types = null; // Map where key=type and value=boolean
  wordFrequency = null; // Map where key=word and value=frequency
  wordType = null; // Map where key=word and value=type
  minWordLength = 0;
  maxWordLength = 0;

  constructor(allWords) {
    this.allWords = allWords;
    this.allTypes = [];

    const types = new Map();
    allWords.forEach(([word, pos, freq, type]) => {
      types.set(type, true);
      if (!this.allTypes.includes(type)) {
        this.allTypes.push(type);
      }
    });

    this.setActiveWords(Array.from(types.keys()));
  }

  setActiveWords(types) {
    this.minWordLength = Number.MAX_SAFE_INTEGER;
    this.maxWordLength = 0;
    this.wordFrequency = new Map(); // TODO: this is used in sorted value order, consider using http://www.collectionsjs.com/sorted-map
    this.wordType = new Map();

    this.types = types;

    this.allWords.forEach(([word, pos, freq, type]) => {
      if (this.types.includes(type)) {
        if (this.wordFrequency.has(word)) {
          this.wordFrequency.set(word, freq + this.wordFrequency.get(word));
        } else {
          this.wordFrequency.set(word, freq);
        }

        this.wordType.set(word, type);

        if (word.length < this.minWordLength) {
          this.minWordLength = word.length;
        }
        if (word.length > this.maxWordLength) {
          this.maxWordLength = word.length;
        }
      }
    });

    if (this.minWordLength > this.maxWordLength) {
      this.minWordLength = this.maxWordLength;
    }
  }
}

var dictionary = new Dictionary(WORDS);

export { dictionary };
